import httpClient from "../../httpClient";
import { PackagingTypeModel } from "../../models/shared/packagingTypeModel";

const fetch = async (): Promise<PackagingTypeModel[]> => {
  return httpClient.get("settings/packaging").then(({ data }) => data);
};

export const packagingService = {
  fetch,
};
