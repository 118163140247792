import httpClient from "../../httpClient";
import { ACCESS_TOKEN } from "../../constants/authConstants";
import { getStorageItem } from "../../helpers/localStorage";
import { UserModel } from "../../models/shared/userModel";
import { HttpResponseModel } from "../../models/shared/httpResponseModel";

export interface SignInResponseModel extends HttpResponseModel {
  data: { token: string };
}

export interface whoAmIResponseModel extends HttpResponseModel {
  data: UserModel;
}

const signIn = async (
  username: string,
  password: string
): Promise<SignInResponseModel> => {
  return httpClient.post("auth/login", {
    email: username,
    password,
  });
};

const whoAmI = async (): Promise<whoAmIResponseModel> => {
  return httpClient.get("auth/user");
};

const logout = (): Promise<any | never> => {
  const accessToken = getStorageItem(ACCESS_TOKEN);
  if (accessToken) {
    return httpClient.post(`auth/logout`);
  }
  return Promise.resolve();
};

export const authenticationService = {
  signIn,
  logout,
  whoAmI,
};
