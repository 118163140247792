import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import React, { FC, SyntheticEvent, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { postOfficeService } from "../../../../application/api/services/postOffice.service";
import { LIST_CUSTOMERS_ROUTE } from "../../../../application/constants/routeConstants";
import { CustomerModel } from "../../../../application/models/shared/customerModel";
import { PostOfficeModel } from "../../../../application/models/shared/postOfficeModel";
import { PhoneInputMask } from "../../../../components/InputPhone";
import AccountForm from "./accountForm";
import PricingForm from "./pricingForm";
import { customerFormSchema } from "./validationSchema";

const CustomerForm: FC<{
  defaultValues?: CustomerModel;
  loading?: boolean;
  onValide: (data: CustomerModel) => void;
}> = (props) => {
  const [cityOptions, setCityOptions] = useState<PostOfficeModel[] | []>([]);

  const formCtx = useForm<CustomerModel>({
    resolver: yupResolver(customerFormSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      phone_number: "",
      address: {
        address_line: "",
        post_office: undefined,
      },
      pricing: [],
      account: {
        email: "",
        is_company: false,

        tax_registry_number: "",
      },
      personal_identity_file: undefined,
      company_identity_file: undefined,
      ...(props?.defaultValues ? props?.defaultValues : {}),
    },
  });

  const {
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = formCtx;

  const onSubmit = (data: any) => {
    props.onValide(data);
  };

  const fetchPostOfficesOptions = (searchTerm: string) => {
    postOfficeService
      .fetch({ search: searchTerm, per_page: 20, page: 1 })
      .then(({ data }) => {
        setCityOptions(data.data);
      })
      .catch((error) => setCityOptions([]));
  };

  const formValues = watch() as CustomerModel;

  useEffect(() => {
    if (formValues?.account?.is_company === true) {
      setValue("personal_identity_file" as any, undefined);
    } else if (formValues?.account?.is_company === false) {
      setValue("company_identity_file" as any, undefined);
      setValue("account.tax_registry_number" as any, "");
    }
  }, [formValues?.account?.is_company]);

  return (
    <FormProvider {...formCtx}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12}>
            <Paper sx={{ p: 3 }}>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Typography sx={{ fontWeight: 500 }}>
                    Information personnelle
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container justifyContent="space-between" spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Controller
                            name="first_name"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                label="Nom"
                                size="small"
                                {...field}
                                autoComplete="new-password"
                                error={Boolean(errors?.first_name)}
                                helperText={
                                  errors?.first_name
                                    ? errors?.first_name.message
                                    : null
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Controller
                            name="last_name"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                label="Prenom"
                                size="small"
                                {...field}
                                autoComplete="new-password"
                                error={Boolean(errors?.last_name)}
                                helperText={
                                  errors?.last_name
                                    ? errors?.last_name.message
                                    : null
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Controller
                            name="phone_number"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                label="Téléphone"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                {...field}
                                InputProps={{
                                  inputComponent: PhoneInputMask as any,
                                }}
                                autoComplete="new-password"
                                error={Boolean(errors?.phone_number)}
                                helperText={
                                  errors?.phone_number
                                    ? errors?.phone_number.message
                                    : null
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Controller
                            name="address.address_line"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                label="Adresse"
                                size="small"
                                {...field}
                                error={Boolean(errors?.address?.address_line)}
                                helperText={
                                  errors?.address?.address_line
                                    ? errors?.address?.address_line?.message
                                    : null
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Controller
                            name="address.post_office"
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                noOptionsText=""
                                fullWidth={true}
                                size="small"
                                popupIcon={<KeyboardArrowDownIcon />}
                                id="role"
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                onInputChange={(event, newInputValue) => {
                                  fetchPostOfficesOptions(newInputValue);
                                }}
                                getOptionLabel={(option) =>
                                  `${option.name}-${option.zip_code}`
                                }
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    sx={{ "& > *": { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                  >
                                    {`${option.name}-${option.zip_code}`}
                                  </Box>
                                )}
                                groupBy={(option) => option.zip_code}
                                options={cityOptions}
                                {...field}
                                value={field.value || null}
                                onChange={(
                                  event: SyntheticEvent<Element, Event>,
                                  newValue: PostOfficeModel | null
                                ) => {
                                  event.preventDefault();
                                  field.onChange(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    autoComplete="new-password"
                                    label="Adresse postale"
                                    error={Boolean(
                                      errors?.address?.post_office
                                    )}
                                    helperText={
                                      errors?.address?.post_office
                                        ? errors?.address?.post_office?.message
                                        : null
                                    }
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "off",
                                      form: {
                                        autocomplete: "off",
                                      },
                                    }}
                                  />
                                )}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, height: "100%" }}>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Typography sx={{ fontWeight: 500 }}>
                    Configuration de prix
                  </Typography>
                </Grid>
                <Grid item>
                  <PricingForm />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, height: "100%" }}>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Typography sx={{ fontWeight: 500 }}>
                    Configuration de compte
                  </Typography>
                </Grid>
                <Grid item>
                  <AccountForm />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{ p: 3 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    color="inherit"
                    type="submit"
                    variant="text"
                    href={LIST_CUSTOMERS_ROUTE}
                    disabled={props?.loading}
                    startIcon={<ArrowBackIosIcon />}
                  >
                    Retour
                  </Button>
                </Grid>
                <Grid item>
                  <Box sx={{ mt: 1, position: "relative" }}>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props?.loading}
                    >
                      Sauvegarder
                    </Button>
                    {props?.loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: green[500],
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default CustomerForm;
