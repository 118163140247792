import {
  Alert,
  ClickAwayListener,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { FC, useEffect, useState } from "react";
import { dispatchingService } from "../../application/api/services/dispatching.service";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { ShipmentModel } from "../../application/models/shared/ShipmentModel";
import { useNotificationsStore } from "../../application/notifications/notificationState";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { CircularProgressWithLabel } from "../../components/CircularProgressWithLabel";
import { PageTitle } from "../../components/PageTitle";
import { SnackbarContext } from "../../components/snackbarProvider";
import { ShipmentsToControl } from "../../pages_components/shipments/shipmentsToControl";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(3),
  },

  input: {
    width: 1,
    height: 1,
    opacity: 0,
    overflow: "hidden",
    position: "relative",
    zIndex: -1,
    "&::before": {
      content: "''",
      width: 300,
      height: 300,
      backgroundColor: "red",
      border: "solid 1px red",
      position: "absolute",
      top: 0,
    },
  },
}));

interface TakeForDeliverStateModel {
  isLoading: boolean;
  error: string;
  data: ShipmentModel[] | [];
  total: number;
}

const TakeForDeliveryPage: FC<PagePropsModel> = (props): JSX.Element => {
  const classes = useStyles();
  const { notifications } = useNotificationsStore();
  const refInputScanner = React.useRef<HTMLInputElement>(null);
  const ref = React.useRef<HTMLDivElement>(null);
  const [checkedBarcode, setCheckedBarcode] = React.useState<string[]>([]);
  const [snackbar, setSnackbar] = React.useContext(SnackbarContext);
  const [scannerStatus, setScannerStatus] = React.useState<boolean>(false);

  const [state, setState] = useState<TakeForDeliverStateModel>({
    isLoading: false,
    error: "",
    data: [],
    total: 0,
  });

  const [refresh, setRefresh] = useState<number>(0);

  useEffect(() => {
    loadData();
  }, [notifications.shipmentReadyToDeliver]);

  const loadData = () => {
    setState((state) => ({ ...state, loading: true, error: "" }));
    dispatchingService
      .readyDeliveryShipments({
        pagination: { page: 1, per_page: 100 },
      })
      .then((response) => {
        setState((state) => ({
          ...state,
          loading: false,
          error: "",
          data: response.data.data,
          total: response.data.total,
        }));
      });
  };

  // const shipmentsQuery = useFetchReadyDeliveryShipmentsQuery({
  //   pagination: { page: 1, per_page: 100 + refresh },
  // });
  // const {
  //   data: { data, current_page, total },
  // } = get(shipmentsQuery, ["data"], {
  //   data: [],
  //   current_page: 1,
  //   total: 0,
  // }) as FetchShipmentsResponseModel;

  useEffect(() => {
    ref.current?.addEventListener("mousemove", inputAutoFocus);
    ref.current?.addEventListener("click", inputAutoFocus);
    return () => {
      ref.current?.removeEventListener("mousemove", inputAutoFocus);
      ref.current?.removeEventListener("click", inputAutoFocus);
    };
  }, []);

  const inputAutoFocus = () => {
    refInputScanner.current?.focus();
    setScannerStatus(true);
  };

  const handleClickAway = () => {
    setScannerStatus(false);
  };
  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Chargement des colis par le transporteur",
    },
  ];

  const save = (barcode: string) => {
    dispatchingService
      .takeForDelivery(barcode)
      .then((response) => {
        setCheckedBarcode((prev) => [...prev, barcode]);
        setSnackbar({
          open: true,
          severity: "success",
          message: "sauvegarde effectuée avec succès",
        });
      })
      .catch((error) => {
        setSnackbar({
          open: true,
          severity: "error",
          message: "Une erreur est survenue lors de l'enregistrement",
        });
      });
  };

  const getProgressPourcent = (): number => {
    return Number((checkedBarcode.length * 100) / state.total);
  };

  const checkBarCode = (inputBarcodeValue: string) => {
    const shipment = state.data.find(
      (item) => item.identifier === inputBarcodeValue
    );

    if (shipment && checkedBarcode.includes(shipment.identifier) === false) {
      save(shipment.identifier);
      //setCheckedBarcode((prev) => [...prev, shipment.identifier]);
    } else {
      setSnackbar({
        open: true,
        severity: "error",
        message: "Barrecode déja scanndcdccé",
      });
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{ minHeight: 400 }}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Stack direction={"row"} justifyContent="space-between">
              <Box>
                <PageTitle title="Chargement des colis par le transporteur" />
                <AppBreadcrumbs breadcrumbs={breadcrumbs} />
              </Box>
            </Stack>
          </Grid>

          {state.isLoading && (
            <Grid item>
              <LinearProgress />
            </Grid>
          )}
          {state.error && (
            <Grid item>
              <Paper>
                <Alert severity="error">{String(state.error)}</Alert>
              </Paper>
            </Grid>
          )}

          {!state.isLoading &&
            !state.error &&
            state.data !== undefined &&
            state.total === 0 && (
              <Box sx={{ pb: 3, m: 3 }}>
                <Paper>
                  <Alert severity="info">Aucune donnée disponible!</Alert>
                </Paper>
              </Box>
            )}

          {!state.isLoading && state.data?.length > 0 && (
            <Grid item>
              <Paper className={classes.paper} ref={ref}>
                {state.data && state.data.length > 0 && (
                  <Grid container spacing={3} justifyContent="space-between">
                    <Grid item>
                      <Stack direction={"column"} spacing={1}>
                        {/* <BarCodeScanner
                          ref={refInputScanner}
                          onChange={(value) => checkBarCode(value)}
                        /> 
                        <Divider flexItem />*/}
                        <ShipmentsToControl
                          data={state.data}
                          checkedBarcode={checkedBarcode}
                        />
                      </Stack>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <Grid container alignItems="flex-start" spacing={2}>
                            <Grid item>
                              {/* <Fab
                                size="small"
                                aria-label="add"
                                sx={{
                                  color: scannerStatus ? "#1A90FF" : "#C4C4C4",
                                  backgroundColor: "transparent",
                                  border: "solid 1px #C4C4C4",
                                }}
                                onClick={inputAutoFocus}
                              >
                                <DocumentScannerIcon />
                              </Fab> */}
                            </Grid>
                            <Grid item>
                              <CircularProgressWithLabel
                                size={56}
                                thickness={4}
                                color="success"
                                value={getProgressPourcent()}
                                label={`${state.total}`}
                              />
                            </Grid>
                            <Grid item>
                              {/* {checkedBarcode.length > 0 && (
                                <Fab
                                  color="secondary"
                                  aria-label="add"
                                  sx={{ color: "#fff" }}
                                 // onClick={save}
                                >
                                  <SaveIcon />
                                </Fab>
                              )} */}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </Grid>
          )}
        </Grid>
      </div>
    </ClickAwayListener>
  );
};

export default TakeForDeliveryPage;
