import { Alert, Grid, Paper, Skeleton, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { get, isUndefined } from "lodash";
import { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCreateOfficeQuery } from "../../application/api/hooks/office.hooks";
import { LIST_OFFICE_ROUTE } from "../../application/constants/routeConstants";
import { OfficeModel } from "../../application/models/shared/officeModel";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import If from "../../components/conditionalViwer";
import { PageTitle } from "../../components/PageTitle";
import { SnackbarContext } from "../../components/snackbarProvider";
import OfficeForm from "../../pages_components/offices/forms/editForm";
import { OfficeFormModel } from "../../pages_components/offices/forms/editForm/models";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
}));

const CreateOfficePage: FC<PagePropsModel> = (props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const [snackbar, setSnackbar] = useContext(SnackbarContext);
  const [data, setData] = useState<OfficeFormModel | undefined>(undefined);
  const dataAdapter = (formValue: OfficeFormModel | undefined) => {
    return formValue;
  };

  const createOfficeQuery = useCreateOfficeQuery(dataAdapter(data));
  const officeCreated = get(createOfficeQuery, ["data", "data"], undefined) as
    | OfficeModel
    | undefined;

  useEffect(() => {
    if (!isUndefined(officeCreated)) {
      history.push(LIST_OFFICE_ROUTE);
      setSnackbar({
        open: true,
        severity: "success",
        message: "Creation effectué avec succès",
      });
    }
  }, [officeCreated]);

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Nouveau bureau",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title={"Nouveau bureau"} />
        <AppBreadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid item>
        <Paper className={classes.paper}>
          <If
            condition={createOfficeQuery.isLoading}
            render={() => (
              <Skeleton variant="rectangular" width={"100%"} height={118} />
            )}
            otherwise={() => (
              <If
                condition={createOfficeQuery.isError}
                render={() => (
                  <Alert severity="error">
                    Une erreur se produise l'hort de l'ajout!
                  </Alert>
                )}
              />
            )}
          />
          <If
            condition={isUndefined(officeCreated)}
            render={() => (
              <OfficeForm
                loading={createOfficeQuery.isLoading}
                onValide={(data) => setData(data)}
              />
            )}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CreateOfficePage;
