import { UserModel } from "./../models/shared/userModel";
import {
  AbilityBuilder,
  Ability,
  AbilityClass,
  SubjectRawRule,
  ExtractSubjectType,
  MongoQuery,
} from "@casl/ability";
import { AnyObject } from "@casl/ability/dist/types/types";
import { ACSL_InvoiceModel } from "../models/shared/invoiceModel";
import { ACSL_MenuModel } from "../models/shared/menuModel";

type Actions = "manage" | "create" | "read" | "update" | "delete";
type Subjects =
  | "ACSL_InvoiceModel"
  | ACSL_InvoiceModel
  | "ACSL_MenuModel"
  | ACSL_MenuModel
  | "all"
  | "STATE_OFFICES"
  | "STATE_OFFICE"
  | "MANAGEMENT"
  | "DASHBOARD"
  | "PARCEL_MANAGEMENT"
  | "PICKUPS"
  | "BAG_MANAGEMENT"
  | "BAG_PREPARATION"
  | "TRANSFER_OF_BAGS"
  | "RECEIPT_OF_BAGS"
  | "RECEIPT_OF_BAGS_FROM_PLATFORM"
  | "DELIVERY"
  | "FUNDS_&_CRBT"
  | "DELIVERY_&_CRBT_CODE"
  | "SHIPMENT"
  | "SHIPMENT_CODES";

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export default function defineRulesFor(
  user: UserModel
): SubjectRawRule<
  Actions,
  ExtractSubjectType<Subjects>,
  MongoQuery<AnyObject>
>[] {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility);
  const roles =
    user.roles !== undefined && Array.isArray(user.roles)
      ? user.roles.map((item) => item.name)
      : [];

  const isPlatform = user.office?.is_platform;

  if (isPlatform) {
    cannot("read", "STATE_OFFICE");
    cannot("read", "MANAGEMENT");
    can("read", "BAG_MANAGEMENT");
    can("read", "RECEIPT_OF_BAGS");
    if (user.first_name === "Codbox") {
      can("read", "DASHBOARD");
      can("read", "STATE_OFFICES");
      can("read", "SHIPMENT_CODES");
      can("delete", "SHIPMENT");
    }
  } else {
    can("read", "DASHBOARD");
    cannot("read", "STATE_OFFICES");
    can("read", "STATE_OFFICE");
    can("read", "PARCEL_MANAGEMENT");
    can("read", "PICKUPS");
    can("read", "BAG_MANAGEMENT");
    can("read", "BAG_PREPARATION");
    can("read", "TRANSFER_OF_BAGS");
    can("read", "RECEIPT_OF_BAGS");
    can("read", "RECEIPT_OF_BAGS_FROM_PLATFORM");
    can("read", "DELIVERY");
    can("read", "FUNDS_&_CRBT");
    if (user.first_name === "Codbox") {
      can("read", "MANAGEMENT");
      can("read", "DELIVERY_&_CRBT_CODE");
      can("delete", "SHIPMENT");
      can("read", "SHIPMENT_CODES");
    }
    if (user.roles?.map((element) => element.name).includes("Office Manager")) {
      can("read", "DELIVERY_&_CRBT_CODE");
    }
  }

  return rules;
}

export function buildAbilityFor(user: UserModel): AppAbility {
  return new AppAbility(defineRulesFor(user), {
    detectSubjectType: (object) => object?.type,
  });
}
