import { isNaN, isUndefined } from "lodash";
import { useQuery, UseQueryResult } from "react-query";
import { OfficeFormModel } from "../../../pages_components/offices/forms/editForm/models";
import { OfficeModel } from "../../models/shared/officeModel";
import { UserModel } from "../../models/shared/userModel";
import { CreateResponseModel, officeService } from "../services/office.service";

export const useFindOneOfficeQuery = (
  id: number
): UseQueryResult<OfficeModel> => {
  return useQuery<OfficeModel>({
    queryKey: ["findOne-query", id].join("-"),
    queryFn: () => {
      return !isUndefined(id) ? officeService.findOne(id) : Promise.reject();
    },
    enabled: !isUndefined(id) && !isNaN(id),
  });
};

export const useFetchStaffQuery = (
  id: number
): UseQueryResult<UserModel[] | []> => {
  return useQuery<UserModel[] | []>({
    queryKey: ["findOne-query", id].join("-"),
    queryFn: () => {
      return !isUndefined(id) ? officeService.fetchSaff(id) : Promise.reject();
    },
    enabled: !isUndefined(id) && !isNaN(id),
  });
};

export const useCreateOfficeQuery = (
  data?: OfficeFormModel
): UseQueryResult<CreateResponseModel> => {
  return useQuery<CreateResponseModel>({
    queryKey: ["createDistrict-query", JSON.stringify(data)].join("-"),
    queryFn: () => {
      return !isUndefined(data) ? officeService.create(data) : Promise.reject();
    },
    enabled: !isUndefined(data),
  });
};

export const useUpdateOfficeQuery = (
  data?: OfficeFormModel
): UseQueryResult<CreateResponseModel> => {
  return useQuery<CreateResponseModel>({
    queryKey: ["createDistrict-query", JSON.stringify(data)].join("-"),
    queryFn: () => {
      return !isUndefined(data) ? officeService.update(data) : Promise.reject();
    },
    enabled: !isUndefined(data),
  });
};
