import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { priceFormat } from "../../../application/helpers/priceFormat";
import {
  ReimbursementItemCtrlModel,
  ReimbursementOutCtrlModel,
} from "../../../application/models/shared/reimbursementCtrlModel";

const SenderCard: FC<{ data: ReimbursementOutCtrlModel }> = ({
  data,
}): JSX.Element => {
  const { sender } = data;
  const sumCRBTReduce = (
    acc: number,
    cur: ReimbursementItemCtrlModel
  ): number => {
    const curCRBT = cur.amount;
    return acc + curCRBT;
  };

  const sumCRBT = (reimbursementItems: ReimbursementItemCtrlModel[]) => {
    return (reimbursementItems as ReimbursementItemCtrlModel[]).reduce(
      sumCRBTReduce,
      0
    );
  };

  return (
    <Paper
      sx={{
        width: "100%",
        p: 3,
        height: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          borderLeft: "solid 5px #eee",
          p: 1,
          position: "sticky",
          top: 190,
        }}
      >
        <Grid container direction="column">
          <Grid item>
            <Typography
              sx={{ fontWeight: 700, fontSize: 20, mb: 2 }}
              variant="body2"
            >
              Expéditeur
            </Typography>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row" alignItems="flex-end">
              <PersonOutlineOutlinedIcon fontSize="small" />
              <Typography variant="body2" sx={{ fontSize: 14 }}>
                {sender.first_name} {sender.last_name}
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row" alignItems="flex-end">
              <LocalPhoneOutlinedIcon fontSize="small" />
              <Typography variant="body2" sx={{ fontSize: 14 }}>
                {sender.phone_number}
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row" alignItems="flex-end">
              <PlaceOutlinedIcon fontSize="small" />
              <Typography variant="body2" sx={{ fontSize: 14 }}>
                {sender.address?.address_line}
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row" alignItems="flex-end">
              <HomeWorkOutlinedIcon fontSize="small" />
              <Typography variant="body2" noWrap sx={{ fontSize: 14 }}>
                {sender?.address?.post_office?.name} /{" "}
                {sender?.address?.post_office?.zip_code}
              </Typography>
            </Stack>
          </Grid>

          {data?.reimbursements.length > 1 && (
            <Grid item>
              <Stack spacing={2} direction="row" alignItems="flex-end">
                <MonetizationOnOutlinedIcon fontSize="small" />
                <Typography
                  variant="body2"
                  color="red"
                  noWrap
                  sx={{ fontSize: 14, fontWeight: 500 }}
                >
                  {priceFormat(sumCRBT(data?.reimbursements))}
                </Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

export default SenderCard;
