import httpClient from "../../httpClient";
import { OfficeModel } from "../../models/shared/officeModel";
import { OrderDirectionModel } from "../../models/shared/orderDirectionModel";
import { PaginationModel } from "../../models/shared/paginationModel";
import {
  OfficeFormModel,
  OfficeStaffFormModel,
} from "../../../pages_components/offices/forms/editForm/models";
import { HttpResponseModel } from "../../models/shared/httpResponseModel";
import { UserModel } from "../../models/shared/userModel";

export interface CreateResponseModel extends HttpResponseModel {
  data: OfficeModel;
}

interface FetchWithPaginationResponseModel extends HttpResponseModel {
  data: { data: OfficeModel[] | []; current_page: number; total: number };
}

export interface OrderByModel {
  orderField: keyof OfficeModel;
  orderDirection: OrderDirectionModel;
}

export type DistrictCreateModel = {
  name: "North East";
  address: {
    address_line: "12, Rue 64";
    post_office_id: 5;
  };
  structure: [
    {
      type: "governorate";
      id: 1;
    }
  ];
};

const create = async (data: OfficeFormModel): Promise<CreateResponseModel> => {
  return httpClient.post("settings/offices", {
    ...data,
  });
};

const update = async (data: OfficeFormModel): Promise<CreateResponseModel> => {
  const { id, ...other } = data;

  return httpClient.patch(`settings/offices/${id}`, {
    ...other,
  });
};

const editeSaff = async (
  data: OfficeStaffFormModel
): Promise<CreateResponseModel> => {
  const { id, ...other } = data;

  return httpClient.patch(`settings/offices/${id}/staff`, {
    ...other,
  });
};

const fetchSaff = async (id: number): Promise<UserModel[] | []> => {
  return httpClient.get(`settings/offices/${id}/staff`);
};

const remove = async (id: number): Promise<CreateResponseModel> => {
  return httpClient.delete(`settings/offices/${id}`);
};

const findOne = async (id: number): Promise<OfficeModel> => {
  return httpClient.get(`settings/offices/${id}`);
};

const fetch = async (params: {
  pagination: PaginationModel;
  search?: string;
  orderParams?: OrderByModel;
}): Promise<FetchWithPaginationResponseModel> => {
  const { pagination, search = undefined, orderParams = undefined } = params;
  return httpClient.get("settings/offices", {
    params: {
      ...pagination,
      ...(() => (search ? search : {}))(),
      ...(() => (orderParams ? orderParams : {}))(),
    },
  });
};

export const officeService = {
  create,
  update,
  remove,
  fetch,
  findOne,
  editeSaff,
  fetchSaff,
};
