import { useQuery, UseQueryResult } from "react-query";
import { PostOfficeModel } from "../models/shared/postOfficeModel";
import { cityService } from "../api/services/city.service";

export const useFetchPostOfficesOfCityQuery = (
  cityId: string
): UseQueryResult<PostOfficeModel[] | []> => {
  return useQuery<PostOfficeModel[] | []>({
    queryKey: ["cities", cityId].join("-"),
    queryFn: () => {
      return cityService.postOfficesOfCity(cityId);
    },
    enabled: cityId !== "",
  });
};
