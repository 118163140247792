import { Grid, Paper, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { get, isUndefined } from "lodash";
import { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCreateDistrictQuery } from "../../application/api/hooks/district.hook";
import { LIST_DISTRICT_ROUTE } from "../../application/constants/routeConstants";
import { OfficeModel } from "../../application/models/shared/officeModel";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import { SnackbarContext } from "../../components/snackbarProvider";
import DistrictForm from "../../pages_components/districts/forms/editForm";
import { DistrictFormModel } from "../../pages_components/districts/forms/editForm/models";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
}));

const CreateDistrictPage: FC<PagePropsModel> = (props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const [snackbar, setSnackbar] = useContext(SnackbarContext);
  const [data, setData] = useState<DistrictFormModel | undefined>(undefined);

  const createDistrictQuery = useCreateDistrictQuery(data);
  const districtCreated = get(
    createDistrictQuery,
    ["data", "data"],
    undefined
  ) as OfficeModel | undefined;

  useEffect(() => {
    if (!isUndefined(districtCreated)) {
      history.push(LIST_DISTRICT_ROUTE);
      setSnackbar({
        open: true,
        severity: "success",
        message: "Creation effectué avec succès",
      });
    }
  }, [districtCreated]);

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Nouveau district",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title={"Nouveau district"} />
        <AppBreadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid item>
        <Paper className={classes.paper} elevation={0}>
          <DistrictForm
            loading={createDistrictQuery.isLoading}
            onValide={(data) => setData(data)}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CreateDistrictPage;
