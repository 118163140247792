import { format, startOfDay } from "date-fns";
import create from "zustand";
import { dispatchingService } from "../../application/api/services/dispatching.service";
import {
  FetchQueryModel,
  pickupService,
  PickupsResponseModel,
} from "../../application/api/services/pickup.service";
import {
  DATE_ISO_FORMAT,
  DATE_TIME_ISO_FORMAT,
} from "../../application/constants/appConstants";
import { RecipientPackagesFormModel } from "../../application/models/forms/recipientPackagesFormModel";
import { ReceptionFormModel } from "../../application/models/forms/recptionFormModel";
import { PickupModel } from "../../application/models/shared/pickupModel";
import { ShipmentModel } from "../../application/models/shared/ShipmentModel";
import { ShipmentStatusEnumModel } from "../../application/models/shared/shipmentStatusModel";
import { SnackbarStateModel } from "../../components/snackbarProvider";
import { PickupsFilterFormModel } from "../../pages_components/pickup/forms/filterForm";

export enum StepModel {
  FORM = "FORM",
  VALIDATION = "VALIDATION",
}

export enum ScopeWorking {
  EDITING_PARCEL_FIND = "EDITING_PARCEL_FIND",
  EDITING_PARCEL_SAVE = "EDITING_PARCEL_SAVE",
  DETAILS_FIND_ONE = "DETAILS_FIND_ONE",
  DETAILS_RECIVE_ITEM = "DETAILS_RECIVE_ITEM",
  DETAILS_CANCEL_ITEM = "DETAILS_CANCEL_ITEM",
}
export type PickupFormModel = Omit<ReceptionFormModel, "sender">;

export interface PickupControllerModel {
  filter: {
    formValues: PickupsFilterFormModel;
    setFormValues: (data: PickupsFilterFormModel) => void;
  };
  fetching: {
    isLoading: boolean;
    error?: string;
    total: number;
    currentPage: number;
    searchText: string;
    status: ShipmentStatusEnumModel[];
    data: PickupModel[];
    snackbar: SnackbarStateModel;
    handleChangePage: (
      event: React.ChangeEvent<unknown>,
      value: number
    ) => void;
    fetch: () => void;
    setStatus: (value: ShipmentStatusEnumModel[]) => void;
  };
  details: {
    isLoading: boolean;
    error?: string;
    data: PickupModel | undefined;
    scopeWorking: ScopeWorking;
    recivedItems: number[];
    cancledItems: number[];
    findOne: (id: number) => void;
    recive: (pickupId: number, shipment: ShipmentModel) => void;
    reciveItem: (pickupId: number, shipmentid: number) => void;
    cancelItem: (pickupId: number, shipmentid: number) => void;
  };
  editingParcel: {
    isLoading: boolean;
    error?: string;
    scopeWorking: ScopeWorking;
    originalData: ShipmentModel | undefined;
    updatedData: ShipmentModel | undefined;
    storedData: ShipmentModel | undefined;
    findOne: (id: string) => void;
    save: (data: ShipmentModel) => ShipmentModel;
  };
  editing: {
    step: StepModel;
    isLoading: boolean;
    error?: string;
    data: ReceptionFormModel | undefined;
    setStep: (step: StepModel) => void;
    setData: (data: ReceptionFormModel) => void;
    receive: (params: {
      pickupId: number;
      shipments: RecipientPackagesFormModel[];
    }) => void;
  };
  api: {
    fetch: (
      params: FetchQueryModel,
      callbackThen: (response: PickupsResponseModel) => void,
      callbackCatch: (error: any) => void
    ) => void;
    findOne: (
      id: number,
      callbackThen: (response: PickupModel) => void,
      callbackCatch: (error: any) => void
    ) => void;
    receive: (
      params: {
        pickupId: number;
        shipments: RecipientPackagesFormModel[];
      },
      callbackThen: (response: any) => void,
      callbackCatch: (error: any) => void
    ) => void;
    cancelShipment: (
      params: {
        pickupId: number;
        shipmentId: number;
      },
      callbackThen: (response: any) => void,
      callbackCatch: (error: any) => void
    ) => void;
    receiveShipment: (
      params: {
        pickupId: number;
        shipmentId: number;
      },
      callbackThen: (response: any) => void,
      callbackCatch: (error: any) => void
    ) => void;
    findOneParcel: (
      id: string,
      callbackThen: (response: ShipmentModel) => void,
      callbackCatch: (error: any) => void
    ) => void;
    updateParcel: (
      data: ShipmentModel,
      callbackThen: (response: ShipmentModel) => void,
      callbackCatch: (error: any) => void
    ) => void;
  };
  methods: {
    pickupToReceptionFormModel: (data: PickupModel) => ReceptionFormModel;
    pickupParcelToReceptionFormModel: (
      data: ShipmentModel
    ) => ReceptionFormModel;
    pickupParcelToRecipientPackagesFormModel: (
      data: ShipmentModel
    ) => RecipientPackagesFormModel;
    receptionFormToShipmentModel: (data: ReceptionFormModel) => ShipmentModel;
  };
}

export const usePickupController = create<PickupControllerModel>(
  (set, get) => ({
    filter: {
      formValues: {
        date: format(startOfDay(new Date()), DATE_ISO_FORMAT),
      },
      setFormValues: (data: PickupsFilterFormModel) => {
        set((state) => ({
          ...state,
          filter: {
            ...state.filter,
            formValues: data,
          },
        }));
      },
    },
    /************************************ FETCHING ************************************/
    fetching: {
      isLoading: false,
      error: undefined,
      total: 0,
      currentPage: 1,
      searchText: "",
      status: [],
      data: [],
      action: {
        action: undefined,
        item: undefined,
      },
      snackbar: {
        open: false,
        message: "",
      },
      handleChangePage: (event: React.ChangeEvent<unknown>, value: number) => {
        set((state) => ({
          ...state,
          fetching: {
            ...state.fetching,
            currentPage: value,
          },
        }));
      },
      fetch: () => {
        set((state) => ({
          ...state,
          fetching: {
            ...state.fetching,
            isLoading: true,
            error: "",
            snackbar: {
              open: false,
              message: "",
            },
          },
        }));
        get().api.fetch(
          {
            pagination: { page: get().fetching.currentPage, per_page: 10 },
          },
          ({ data, total }) => {
            set((state) => ({
              ...state,
              fetching: {
                ...state.fetching,
                data,
                total,
                isLoading: false,
                error: "",
              },
            }));
          },
          (error) => {
            set((state) => ({
              ...state,
              fetching: {
                ...state.fetching,
                isLoading: false,
                error: String(error),
              },
            }));
          }
        );
      },
      setStatus: (value: ShipmentStatusEnumModel[] | string) => {
        const _value =
          typeof value === "string"
            ? value.split(",").map((item) => item as ShipmentStatusEnumModel)
            : (value as ShipmentStatusEnumModel[]);

        set((state) => ({
          ...state,
          fetching: { ...state.fetching, status: _value },
        }));
      },
    },
    /************************************ DETAILS ************************************/
    details: {
      isLoading: false,
      error: "string",
      recivedItems: [],
      cancledItems: [],
      data: undefined,
      scopeWorking: ScopeWorking.DETAILS_FIND_ONE,
      recive: (pickupId: number, shipment: ShipmentModel) => {
        set((state) => ({
          ...state,
          details: {
            ...state.details,
            isLoading: true,
            scopeWorking: ScopeWorking.DETAILS_RECIVE_ITEM,
            error: "",
          },
        }));

        const requestBody =
          get().methods.pickupParcelToRecipientPackagesFormModel(shipment);
        get().api.receive(
          { pickupId, shipments: [requestBody] },
          (response) => {
            get().details.findOne(Number(get().details.data?.id));
            // setTimeout(() => {
            //   set((state) => ({
            //     ...state,
            //     details: {
            //       ...state.details,
            //       isLoading: false,
            //       error: "",
            //     },
            //   }));
            // }, 1000);
          },
          (error) => {
            set((state) => ({
              ...state,
              details: {
                ...state.details,
                isLoading: false,
                error: String(error),
              },
            }));
          }
        );
      },
      cancelItem: (pickupId: number, shipmentId: number) => {
        set((state) => ({
          ...state,
          details: {
            ...state.details,
            isLoading: true,
            scopeWorking: ScopeWorking.DETAILS_CANCEL_ITEM,
            error: "",
          },
        }));
        get().api.cancelShipment(
          { pickupId, shipmentId },
          (response) => {
            set((state) => ({
              ...state,
              details: {
                ...state.details,
                isLoading: false,
                error: "",
              },
            }));
            get().details.findOne(Number(get().details.data?.id));
          },
          (error) => {
            set((state) => ({
              ...state,
              details: {
                ...state.details,
                isLoading: false,
                error: String(error),
              },
            }));
          }
        );
      },
      reciveItem: (pickupId: number, shipmentId: number) => {
        set((state) => ({
          ...state,
          details: {
            ...state.details,
            isLoading: true,
            scopeWorking: ScopeWorking.DETAILS_RECIVE_ITEM,
            error: "",
          },
        }));
        get().api.receiveShipment(
          { pickupId, shipmentId },
          (response) => {
            set((state) => ({
              ...state,
              details: {
                ...state.details,
                recivedItems: [...get().details.recivedItems, shipmentId],
                isLoading: false,
                error: "",
              },
            }));
            get().details.findOne(Number(get().details.data?.id));
          },
          (error) => {
            set((state) => ({
              ...state,
              details: {
                ...state.details,
                isLoading: false,
                error: String(error),
              },
            }));
          }
        );
      },
      findOne: (id: number) => {
        set((state) => ({
          ...state,
          details: {
            ...state.details,
            isLoading: true,
            scopeWorking: ScopeWorking.DETAILS_FIND_ONE,
            error: "",
          },
        }));
        get().api.findOne(
          id,
          (response) => {
            set((state) => ({
              ...state,
              details: {
                ...state.details,
                data: response,
                isLoading: false,
                error: "",
              },
            }));
          },
          (error) => {
            set((state) => ({
              ...state,
              details: {
                ...state.details,
                isLoading: false,
                error: String(error),
              },
            }));
          }
        );
      },
    },
    /************************************ EDITING PARCEL ************************************/
    editingParcel: {
      isLoading: false,
      error: "",
      originalData: undefined,
      updatedData: undefined,
      storedData: undefined,
      scopeWorking: ScopeWorking.EDITING_PARCEL_FIND,
      findOne: (id: string) => {
        set((state) => ({
          ...state,
          editingParcel: {
            ...state.editingParcel,
            originalData: undefined,
            updatedData: undefined,
            storedData: undefined,
            scopeWorking: ScopeWorking.EDITING_PARCEL_FIND,
            isLoading: true,
            error: "",
          },
        }));

        get().api.findOneParcel(
          id,
          (response) => {
            set((state) => ({
              ...state,
              editingParcel: {
                ...state.editingParcel,
                originalData: JSON.parse(JSON.stringify(response)),
                isLoading: false,
                error: "",
              },
            }));
          },
          (error) => {
            set((state) => ({
              ...state,
              editingParcel: {
                ...state.editingParcel,
                isLoading: false,
                error: String(error),
              },
            }));
          }
        );
      },
      save: (data: ShipmentModel) => {
        set((state) => ({
          ...state,
          editingParcel: {
            ...state.editingParcel,
            storedData: undefined,
            scopeWorking: ScopeWorking.EDITING_PARCEL_SAVE,
            isLoading: true,
            error: "",
          },
        }));

        get().api.updateParcel(
          data,
          (response) => {
            set((state) => ({
              ...state,
              editingParcel: {
                ...state.editingParcel,
                // updatedData: JSON.parse(JSON.stringify(response)),
                storedData: response,
                isLoading: false,
                error: "",
              },
            }));
          },
          (error) => {
            set((state) => ({
              ...state,
              editingParcel: {
                ...state.editingParcel,
                isLoading: false,
                error: String(error),
              },
            }));
          }
        );

        return {} as ShipmentModel;
      },
    },
    /************************************ EDITING ************************************/
    editing: {
      step: StepModel.FORM,
      isLoading: false,
      error: "",
      data: undefined,
      setStep: (step: StepModel) => {
        set((state) => ({
          ...state,
          editing: {
            ...state.editing,
            step,
          },
        }));
      },
      setData: (data: ReceptionFormModel) => {
        set((state) => ({
          ...state,
          editing: {
            ...state.editing,
            step: StepModel.VALIDATION,
            data,
          },
        }));
      },
      receive: (params: {
        pickupId: number;
        shipments: RecipientPackagesFormModel[];
      }) => {
        set((state) => ({
          ...state,
          editing: {
            ...state.editing,
            isLoading: true,
            error: "",
          },
        }));

        get().api.receive(
          params,
          (response) => {
            set((state) => ({
              ...state,
              editing: {
                ...state.editing,
                // data: response,
                isLoading: false,
                error: "",
              },
            }));
          },

          (error) => {
            set((state) => ({
              ...state,
              editing: {
                ...state.editing,
                isLoading: false,
                error: String(error),
              },
            }));
          }
        );
      },
    },
    /************************************ API ************************************/
    api: {
      fetch: (
        params: FetchQueryModel,
        callbackThen: (response: PickupsResponseModel) => void,
        callbackCatch: (error: any) => void
      ) => {
        pickupService
          .fetch({
            pagination: { page: get().fetching.currentPage, per_page: 10 },
          })
          .then(callbackThen)
          .catch(callbackCatch);
      },
      findOne: (
        id: number,
        callbackThen: (response: PickupModel) => void,
        callbackCatch: (error: any) => void
      ) => {
        pickupService.findOne(id).then(callbackThen).catch(callbackCatch);
      },
      receive: (
        params: {
          pickupId: number;
          shipments: RecipientPackagesFormModel[];
        },
        callbackThen: (response: any) => void,
        callbackCatch: (error: any) => void
      ) => {
        pickupService.receive(params).then(callbackThen).catch(callbackCatch);
      },
      cancelShipment: (
        params: {
          pickupId: number;
          shipmentId: number;
        },
        callbackThen: (response: any) => void,
        callbackCatch: (error: any) => void
      ) => {
        pickupService
          .cancelShipment(params)
          .then(callbackThen)
          .catch(callbackCatch);
      },
      findOneParcel: (
        id: string,
        callbackThen: (response: ShipmentModel) => void,
        callbackCatch: (error: any) => void
      ) => {
        dispatchingService
          .findOneShipment(String(id))
          .then(callbackThen)
          .catch(callbackCatch);
      },
      updateParcel: (
        data: ShipmentModel,
        callbackThen: (response: ShipmentModel) => void,
        callbackCatch: (error: any) => void
      ) => {
        dispatchingService
          .updateShipment(data)
          .then(callbackThen)
          .catch(callbackCatch);
      },
      receiveShipment: (
        params: {
          pickupId: number;
          shipmentId: number;
        },
        callbackThen: (response: any) => void,
        callbackCatch: (error: any) => void
      ) => {
        pickupService
          .receiveShipment(params)
          .then(callbackThen)
          .catch(callbackCatch);
      },
    },
    methods: {
      pickupToReceptionFormModel: (data: PickupModel) => {
        const sender =
          data?.shipments !== undefined && data?.shipments[0] !== undefined
            ? data?.shipments[0].sender
            : null;

        const formatedObj = (data?.shipments || []).reduce((acc, cur) => {
          const recipient = cur.recipient;
          const recipientId = Number(recipient.id);
          const packages =
            acc[recipientId] !== undefined &&
            acc[recipientId]?.packages !== undefined
              ? acc[recipientId]?.packages
              : [];

          return {
            ...acc,
            [recipientId]: {
              ...(acc[recipientId] !== undefined ? acc[recipientId] : {}),
              recipient,
              packages: [
                ...packages,
                {
                  identifier: cur.identifier,
                  weight: cur.weight,
                  price: cur.price,
                  packaging: cur.packaging,
                  reimbursement: cur.reimbursement,
                },
              ],
            },
          };
        }, {} as Record<number, RecipientPackagesFormModel>);

        return {
          sender,
          shipments: Object.values(formatedObj),
        } as ReceptionFormModel;
      },
      pickupParcelToReceptionFormModel: (data: ShipmentModel) => {
        const { sender, recipient, ...other } = data;
        return {
          sender,
          shipments: [
            {
              recipient,
              packages: [
                {
                  id: other.id,
                  identifier: other.identifier,
                  weight: other.weight,
                  price: other.price,
                  packaging: other.packaging,
                  reimbursement: other.reimbursement,
                },
              ],
            },
          ],
        } as ReceptionFormModel;
      },
      pickupParcelToRecipientPackagesFormModel: (data: ShipmentModel) => {
        const { sender, recipient, ...other } = data;
        return {
          recipient,
          packages: [
            {
              id: other.id,
              identifier: other.identifier,
              weight: other.weight,
              price: other.price,
              packaging: other.packaging,
              reimbursement: other.reimbursement,
            },
          ],
        } as RecipientPackagesFormModel;
      },
      receptionFormToShipmentModel: (data: ReceptionFormModel) => {
        return {
          sender: data.sender,
          recipient: data.shipments[0].recipient,
          ...(data.shipments[0].packages[0]
            ? data.shipments[0].packages[0]
            : {}),
        } as ShipmentModel;
      },
    },
  })
);
