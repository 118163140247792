import {
  Alert,
  Box,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { bagService } from "../../application/api/services/bag.service";
import { globalStateService } from "../../application/api/services/globalState.service";
import { BagModel } from "../../application/models/shared/bagModel";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";

interface MyTripBagsStateModel {
  isLoading: boolean;
  error: string;
  data: BagModel[];
}

const MyTripBagsPage: React.FC<PagePropsModel> = (): JSX.Element => {
  const [state, setState] = useState<MyTripBagsStateModel>({
    isLoading: true,
    error: "",
    data: [],
  });

  useEffect(() => {
    loadData();

    globalStateService
      .fetchFundsOffice()
      .then((response) => console.log({ fetchFundsOffice: response }));

    globalStateService
      .fetchFundsMine()
      .then((response) => console.log({ fetchFundsMine: response }));
  }, []);

  const loadData = () => {
    setState((state) => ({ ...state, isLoading: true, error: "" }));
    bagService
      .fetchMyTrip()
      .then((response) => {
        setState((state) => ({
          ...state,
          isLoading: false,
          error: "",
          data: response.data,
        }));
      })
      .catch((error) => {
        setState((state) => ({
          ...state,
          isLoading: false,
          error: "Server Error",
        }));
      });
  };

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Mon voyage",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="List des sacs" />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box pb={1}>
          {state.data.length > 0 && (
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography>
                  <b>{state.data.length}</b> Sacs
                </Typography>
              </Grid>
              <Grid item></Grid>
            </Grid>
          )}
        </Box>{" "}
        {state.error !== "" && state.isLoading === false && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="error">Error</Alert>
          </Box>
        )}
        {state.isLoading === false && state.data?.length === 0 && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="info">Aucune donnée disponible!</Alert>
          </Box>
        )}
        {state.isLoading && <LinearProgress />}
        {state.data.length > 0 && state.data?.length > 0 && state.error === "" && (
          <TableContainer component={Paper}>
            <Table
              sx={{ width: "100%" }}
              // size="small"
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Date d'entrée</StyledTableCell>
                  <StyledTableCell align="left">De</StyledTableCell>
                  <StyledTableCell align="left">À</StyledTableCell>
                  <StyledTableCell align="right">Nbr colis</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(state.data || []).map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {row.identifier}
                    </StyledTableCell>
                    <StyledTableCell component="th">
                      {row.sender.name}
                    </StyledTableCell>
                    <StyledTableCell>{row.recipient.name}</StyledTableCell>
                    <StyledTableCell align="right">
                      <b>{row.shipments?.length || 0}</b>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>{" "}
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};

export default MyTripBagsPage;
