import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DoDisturbOnOutlinedIcon from "@mui/icons-material/DoDisturbOnOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Box,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { EDIT_PICKUP_PARCEL_ROUTE } from "../../application/constants/routeConstants";
import { priceFormat } from "../../application/helpers/priceFormat";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { ShipmentStatusEnumModel } from "../../application/models/shared/shipmentStatusModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { BlurBackdrop } from "../../components/blurBackdrop";
import { PageTitle } from "../../components/PageTitle";
import { ShipmentStatusChip } from "../../components/shipmentStatusChip";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";
import { ScopeWorking, usePickupController } from "./controller";

const PickupDetailsPage: FC<PagePropsModel> = (props): JSX.Element => {
  const id = props?.match?.params?.id || undefined;
  const {
    isLoading,
    error,
    data,
    findOne,
    recive,
    reciveItem,
    cancelItem,
    recivedItems,
    scopeWorking,
  } = usePickupController((state) => state.details);

  useEffect(() => {
    if (id) {
      findOne(Number(id));
    }
  }, []);

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Detail pickup ",
    },
  ];

  const sum = (data?.shipments || []).reduce((acc, curr) => {
    return Number(acc + curr.price);
  }, 0);

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="Detail Pickup" />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      <Grid item>
        {Boolean(error) === true && isLoading === false && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="error">Error</Alert>
          </Box>
        )}
        {isLoading === false && data === undefined && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="info">Aucune donnée disponible!</Alert>
          </Box>
        )}
        {isLoading && scopeWorking === ScopeWorking.DETAILS_FIND_ONE && (
          <LinearProgress />
        )}
        {data !== undefined && (
          <Box>
            <Stack direction={"row"} spacing={2}>
              <Box sx={{ flexGrow: 1, position: "relative" }}>
                <BlurBackdrop
                  open={
                    isLoading &&
                    (scopeWorking === ScopeWorking.DETAILS_RECIVE_ITEM ||
                      scopeWorking === ScopeWorking.DETAILS_CANCEL_ITEM)
                  }
                />

                {data.shipments?.length > 0 && (
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ width: "100%" }}
                      //size="small"
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            Date d'entrée
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Expéditeur
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Destinateur
                          </StyledTableCell>
                          <StyledTableCell align="left">Poids</StyledTableCell>
                          <StyledTableCell align="left">Code</StyledTableCell>
                          <StyledTableCell align="right">Prix</StyledTableCell>
                          <StyledTableCell align="right"></StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(data?.shipments || []).map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <StyledTableRow
                              key={index}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                            >
                              <StyledTableCell component="th" scope="row">
                                {row.received_at}
                              </StyledTableCell>
                              <StyledTableCell component="th">
                                {row.sender?.first_name || "--"}{" "}
                                {row.sender?.last_name || "--"}
                              </StyledTableCell>
                              <StyledTableCell>
                                {row.recipient?.first_name || "--"}{" "}
                                {row.recipient.last_name || "--"}
                              </StyledTableCell>
                              <StyledTableCell>{row.weight} Kg</StyledTableCell>
                              <StyledTableCell>
                                {row?.identifier}
                              </StyledTableCell>

                              <StyledTableCell align="right">
                                {priceFormat(row.price)}
                              </StyledTableCell>

                              <StyledTableCell align="right">
                                {row.status !== "confirmed" ? (
                                  <ShipmentStatusChip
                                    active={true}
                                    status={
                                      row?.status as ShipmentStatusEnumModel
                                    }
                                    size="small"
                                    variant="outlined"
                                  />
                                ) : (
                                  <Stack
                                    direction={"row"}
                                    spacing={1}
                                    justifyContent="flex-end"
                                  >
                                    <Tooltip title="Filter list">
                                      <IconButton
                                        component={Link}
                                        size="small"
                                        color="info"
                                        to={`${EDIT_PICKUP_PARCEL_ROUTE}/${id}/${row.identifier}`}
                                      >
                                        <EditOutlinedIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Filter list">
                                      <IconButton
                                        size="small"
                                        color="success"
                                        onClick={() => {
                                          recive(Number(id), row);
                                        }}
                                      >
                                        <CheckOutlinedIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Filter list">
                                      <IconButton
                                        size="small"
                                        color="error"
                                        onClick={() => {
                                          cancelItem(
                                            Number(id),
                                            Number(row.id)
                                          );
                                        }}
                                      >
                                        <DoDisturbOnOutlinedIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Stack>
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>{" "}
                  </TableContainer>
                )}
              </Box>
              <Paper sx={{ p: 2, minWidth: 300 }}>
                <Stack
                  direction={"column"}
                  spacing={0}
                  justifyContent="flex-end"
                >
                  <Typography variant="caption" color="textSecondary">
                    <b>Nom :</b> {data.name}
                  </Typography>
                  <Divider flexItem orientation="vertical" />
                  <Typography variant="caption" color="textSecondary">
                    <b>Date :</b> {data.planned_for}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    <b>Nbr colis :</b> {data.shipments.length}
                  </Typography>

                  <Typography variant="caption" color="textSecondary">
                    <b>Total prix :</b> {priceFormat(sum)}
                  </Typography>

                  {/* <Divider flexItem sx={{ mt: 3, mb: 1 }} />

                  <Stack direction={"row"} spacing={1}>
                    <Button
                      component={Link}
                      fullWidth
                      color="secondary"
                      variant="contained"
                      to={`${EDIT_PICKUP_ROUTE}/${id}`}
                      startIcon={<EditOutlinedIcon fontSize="small" />}
                    >
                      Modifier
                    </Button>

                    <Button
                      component={Link}
                      fullWidth
                      variant="contained"
                      to={`${EDIT_PICKUP_ROUTE}/${id}`}
                      startIcon={<CheckOutlinedIcon fontSize="small" />}
                    >
                      Valider
                    </Button>
                  </Stack> */}
                </Stack>
              </Paper>
            </Stack>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
export default PickupDetailsPage;
