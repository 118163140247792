import create from "zustand";
import { reimbursementService } from "../../../application/api/services/reimbursement.service";
import { ReimbursementInCtrlModel } from "./../../../application/models/shared/reimbursementCtrlModel";

export interface ReimbursementInStateModel {
  isLoading: boolean;
  isError: boolean;
  data: ReimbursementInCtrlModel[];
  setLoading: (isLoading: boolean, isError: boolean) => void;
  fetch: () => void;
}

export const useReimbursementInStore = create<ReimbursementInStateModel>(
  (set, get) => ({
    isLoading: false,
    isError: false,
    data: [],
    setLoading: (isLoading: boolean, isError: boolean) => {
      set((state) => ({ ...state, isLoading, isError }));
    },
    fetch: async () => {
      set((state) => ({ ...state, isLoading: true, isError: false }));
      const response = await reimbursementService.fetchReimbursementIn();
      set((state) => ({
        ...state,
        data: response.data,
        isLoading: false,
        isError: false,
      }));
    },
  })
);
