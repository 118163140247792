import React from "react";
import { Redirect } from "react-router-dom";
import {
  authenticationService,
  SignInResponseModel,
  whoAmIResponseModel,
} from "../api/services/authentication.service";
import { buildAbilityFor } from "../casl/abilityConfig";
import { AbilityContext } from "../casl/abilityContext";
import { ACCESS_TOKEN } from "../constants/authConstants";
import { getStorageItem } from "../helpers/localStorage";
import getSocketEcho from "../helpers/socketEcho";
import { UserModel } from "../models/shared/userModel";
import { useNotificationsStore } from "../notifications/notificationState";

interface AuthContextModel {
  user: UserModel | null;
  signin: (
    params: { username: string; password: string },
    callbackThen: (response: SignInResponseModel) => void,
    callbackCatch: (error: any) => void
  ) => void;
  signout: (callback: VoidFunction) => void;
  whoAmI: (
    callbackThen: (response: whoAmIResponseModel) => void,
    callbackCatch: (error: any) => void
  ) => void;
}

export const AuthContext = React.createContext<AuthContextModel>(null!);

export const AuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [user, setUser] = React.useState<UserModel | null>(null);
  const signin = (
    params: {
      username: string;
      password: string;
    },
    callbackThen: (response: SignInResponseModel) => void,
    callbackCatch: (error: any) => void
  ) => {
    return authenticationService
      .signIn(params.username, params.password)
      .then(callbackThen)
      .catch(callbackCatch);
  };

  const signout = (callback: VoidFunction) => {
    authenticationService.logout().then((response) => {
      setUser(null);
      callback();
    });
  };

  const whoAmI = (
    callbackThen: (response: whoAmIResponseModel) => void,
    callbackCatch: (error: any) => void
  ) => {
    authenticationService
      .whoAmI()
      .then((response) => {
        setUser(response.data);
        callbackThen(response);
      })
      .catch((error) => {
        setUser(null);
        callbackCatch(error);
      });
  };

  const value = { user, signin, signout, whoAmI };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextModel => {
  return React.useContext(AuthContext);
};

export const RequireAuth = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { refresh } = useNotificationsStore();
  const auth = useAuth();

  React.useEffect(() => {
    const access_token = getStorageItem(ACCESS_TOKEN) || "";
    if (auth.user?.office?.id !== undefined && access_token) {
      const socketEcho = getSocketEcho(access_token);
      socketEcho
        .private(`App.Models.Office.${auth.user?.office?.id}`)
        .notification((data: any) => {
          refresh();
        });
      refresh();
    }
  }, [auth]);

  if (!auth.user) {
    return <Redirect to="/public" />;
  }

  const ability = buildAbilityFor(auth.user);
  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};
