import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { UserCreateFormModel } from "./models";

const UserCreateForm: FC<{
  defaultValues?: UserCreateFormModel;
  loading?: boolean;
  onValide: (data: UserCreateFormModel) => void;
}> = (props) => {
  const schema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    phone_number: yup.string().required(),
    email: yup.string().email().required("Le champs email est obligatoire"),
    password: yup
      .string()
      .required(`Le mot de passe est obligatoire`)
      .min(
        6,
        ({ min }) => `Le mot de passe doit avoir au moins ${min} caractères`
      ),
    password_confirmation: yup
      .string()
      .required("Le champs du confirmation du mot de passe est obligatoire")
      .oneOf(
        [yup.ref("password")],
        "Les deux mots de passe ne correspendent pas"
      ),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserCreateFormModel>({
    resolver: yupResolver(schema),
    defaultValues: props?.defaultValues || {
      first_name: "",
      last_name: "",
      phone_number: "",
      password: "",
      password_confirmation: "",
    },
  });

  const onSubmit = (data: any) => {
    props.onValide(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography sx={{ fontWeight: 500 }}>Nouveau utilisateur</Typography>
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12} md={6}>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Controller
                    name="first_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="new-password"
                        fullWidth
                        label="Nom"
                        {...field}
                        error={Boolean(errors?.first_name)}
                        helperText={
                          errors?.first_name ? errors?.first_name.message : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="new-password"
                        fullWidth
                        label="Prenom"
                        {...field}
                        error={Boolean(errors?.last_name)}
                        helperText={
                          errors?.last_name ? errors?.last_name.message : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="phone_number"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="new-password"
                        fullWidth
                        label="Téléphone"
                        {...field}
                        error={Boolean(errors?.phone_number)}
                        helperText={
                          errors?.phone_number
                            ? errors?.phone_number.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={false}>
              <Divider orientation="vertical" variant="middle" />
            </Grid>

            <Grid item xs={12} md={5}>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="new-password"
                        fullWidth
                        label="E-mail"
                        {...field}
                        error={Boolean(errors?.email)}
                        helperText={
                          errors?.email ? errors?.email.message : null
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="new-password"
                        type={"password"}
                        fullWidth
                        label="Mot de passe"
                        {...field}
                        error={Boolean(errors?.password)}
                        helperText={
                          errors?.password ? errors?.password.message : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="password_confirmation"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="new-password"
                        type={"password"}
                        fullWidth
                        label="Confirmer mot de passe"
                        {...field}
                        error={Boolean(errors?.password_confirmation)}
                        helperText={
                          errors?.password_confirmation
                            ? errors?.password_confirmation.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item></Grid>
            <Grid item>
              <Box sx={{ mt: 1, position: "relative" }}>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={props?.loading}
                >
                  Sauvegarder
                </Button>
                {props?.loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserCreateForm;
