import { Backdrop, BackdropProps, CircularProgress } from "@mui/material";
import { FC } from "react";

export const BlurBackdrop: FC<BackdropProps> = (props): JSX.Element => {
  return (
    <Backdrop
      sx={{
        color: "black",
        top: 0,
        left: 0,
        position: "absolute",
        width: "auto",
        height: "auto",
        zIndex: 99999,
        backdropFilter: "blur(1px)",
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        p: 0,
      }}
      onClick={() => console.log("")}
      {...props}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
