import { blue, deepPurple, grey } from "@mui/material/colors";
import {
  RecordShipmentStatusModel,
  ShipmentStatusEnumModel,
} from "../models/shared/shipmentStatusModel";

export const shipmentStatus: RecordShipmentStatusModel = {
  [ShipmentStatusEnumModel.received_in_platform]: {
    color: "black",
    bgColor: blue[50],
    label: "Dans la platforme",
    activeStyle: {
      color: "black",
      bgColor: "black",
    },
  },
  [ShipmentStatusEnumModel.forwarded_to_platform]: {
    color: "black",
    bgColor: blue[100],
    label: "Transmis au platforme",
    activeStyle: {
      color: "black",
      bgColor: blue[100],
    },
  },
  [ShipmentStatusEnumModel.pending]: {
    color: "black",
    bgColor: blue[200],
    label: "Au bureau",
    activeStyle: {
      color: "black",
      bgColor: blue[200],
    },
  },
  [ShipmentStatusEnumModel.received_from_customer]: {
    color: "black",
    bgColor: blue[300],
    label: "Colis ramassé",
    activeStyle: {
      color: "black",
      bgColor: blue[300],
    },
  },
  [ShipmentStatusEnumModel.waiting_for_forwarding]: {
    color: "black",
    bgColor: blue[400],
    label: "En attente de transfert",
    activeStyle: {
      color: "black",
      bgColor: blue[400],
    },
  },
  [ShipmentStatusEnumModel.ready_for_delivery_to_client]: {
    color: "black",
    bgColor: blue[500],
    label: "Prêt à être livré au client",
    activeStyle: {
      color: "#fff",
      bgColor: blue[500],
    },
  },
  [ShipmentStatusEnumModel.waiting_for_forwarding_to_reception_district]: {
    color: "black",
    bgColor: blue[600],
    label: "En attente de transfert vers le district de réception",
    activeStyle: {
      color: "#fff",
      bgColor: blue[600],
    },
  },
  [ShipmentStatusEnumModel.forwarded_to_reception_district]: {
    color: "black",
    bgColor: blue[700],
    label: "Transmis au district d'accueil",
    activeStyle: {
      color: "#fff",
      bgColor: blue[700],
    },
  },
  [ShipmentStatusEnumModel.received_in_reception_district]: {
    color: "black",
    bgColor: blue[800],
    label: "Reçu dans le district de réception",
    activeStyle: {
      color: "#fff",
      bgColor: blue[800],
    },
  },
  [ShipmentStatusEnumModel.waiting_for_forwarding_to_delivery_district]: {
    color: "black",
    bgColor: blue[900],
    label: "En attente de transfert vers le district de livraison",
    activeStyle: {
      color: "#fff",
      bgColor: blue[900],
    },
  },
  [ShipmentStatusEnumModel.forwarded_to_delivery_district]: {
    color: "black",
    bgColor: deepPurple[900],
    label: "Transmis au district de livraison",
    activeStyle: {
      color: "#fff",
      bgColor: deepPurple[900],
    },
  },
  [ShipmentStatusEnumModel.received_in_delivery_district]: {
    color: "black",
    bgColor: deepPurple[800],
    label: "Reçu dans le district de livraison",
    activeStyle: {
      color: "#fff",
      bgColor: deepPurple[800],
    },
  },
  [ShipmentStatusEnumModel.waiting_for_forwarding_to_delivery_office]: {
    color: "black",
    bgColor: deepPurple[700],
    label: "En attente de transfert au bureau de livraison",
    activeStyle: {
      color: "#fff",
      bgColor: deepPurple[700],
    },
  },
  [ShipmentStatusEnumModel.forwarded_to_delivery_office]: {
    color: "black",
    bgColor: deepPurple[600],
    label: "Transmis au bureau de livraison",
    activeStyle: {
      color: "#fff",
      bgColor: deepPurple[600],
    },
  },
  [ShipmentStatusEnumModel.received_in_delivery_office]: {
    color: "black",
    bgColor: deepPurple[500],
    label: "Reçu au bureau de livraison",
    activeStyle: {
      color: "#fff",
      bgColor: deepPurple[500],
    },
  },
  [ShipmentStatusEnumModel.given_for_delivery_officer]: {
    color: "black",
    bgColor: deepPurple[400],
    label: "Donné pour le livreur",
    activeStyle: {
      color: "#fff",
      bgColor: deepPurple[400],
    },
  },
  [ShipmentStatusEnumModel.delivered_to_client]: {
    color: "black",
    bgColor: deepPurple[300],
    label: "Livré au client",
    activeStyle: {
      color: "#fff",
      bgColor: deepPurple[300],
    },
  },
  [ShipmentStatusEnumModel.rejected_by_officer]: {
    color: "black",
    bgColor: deepPurple[200],
    label: "Annuler par ODD",
    activeStyle: {
      color: "black",
      bgColor: deepPurple[200],
    },
  },
  [ShipmentStatusEnumModel.confirmed]: {
    color: "black",
    bgColor: deepPurple[100],
    label: "Confirmé par le client",
    activeStyle: {
      color: "black",
      bgColor: deepPurple[100],
    },
  },
  [ShipmentStatusEnumModel.created_by_customer]: {
    color: "black",
    bgColor: grey[900],
    label: "Crée par le client",
    activeStyle: {
      color: "black",
      bgColor: grey[900],
    },
  },
  // [ShipmentStatusEnumModel.forwarded_to_delivery_district]: {
  //   color: "black",
  //   bgColor: "#BCBCBA",
  //   label: "En attendant",
  //   activeStyle: {
  //     color: "#fff",
  //     bgColor: "#FF5C2B",
  //   },
  // },
};
