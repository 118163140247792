import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from "@mui/material";
import { FC } from "react";

export const SkeletonListItems: FC<{ pl?: number; prefixKey: string }> = ({
  pl = 4,
  prefixKey,
}) => {
  const items: number[] = Array(3).fill(" ");

  return (
    <List component="div" disablePadding dense={true} sx={{ pr: 2 }}>
      {items.map((key, index) => (
        <ListItem
          key={`${prefixKey}_${index}`}
          sx={{ pl }}
          secondaryAction={
            <IconButton edge="end" aria-label="delete">
              <Skeleton animation="wave" width={20} height={20} />
            </IconButton>
          }
        >
          <ListItemButton>
            <ListItemIcon>
              <Skeleton animation="wave" width={20} height={20} />
            </ListItemIcon>
            <ListItemText>
              <Skeleton animation="wave" width={200} height={20} />
            </ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};
