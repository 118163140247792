import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export const CircularProgressWithLabel = (
  props: CircularProgressProps & {
    value: number;
    label: string;
    typographyProps?: TypographyProps<"div">;
  }
): JSX.Element => {
  const { label, typographyProps = {}, ...other } = props;
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      {/* <CircularProgress
        variant="determinate"
        {...other}
      /> */}
      <FacebookCircularProgress variant="determinate" {...other} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          height: props.size,
        }}
      >
        <Typography
          noWrap
          // variant="caption"
          style={{ fontWeight: 600 }}
          component="div"
          color="text.secondary"
          {...(() => (typographyProps ? typographyProps : {}))()}
        >{`${label}`}</Typography>
      </Box>
    </Box>
  );
};

function FacebookCircularProgress(props: CircularProgressProps) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 400 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}
