import {
  FORWARD_BAGS_ROUTE,
  INNER_BAGS_PREPARE_ROUTE,
  LIST_PICKUPS_ROUTE,
  OFFICE_BAGS_PREPARE_ROUTE,
  OUTER_BAGS_PREPARE_ROUTE,
  RECEIPT_PACKAGE_ROUTE,
  RECEIVE_BAGS_IN_PLATFORM_ROUTE,
  RECEIVE_BAGS_ROUTE,
  REIMBURSEMENT_IN_ROUTE,
  REIMBURSEMENT_OUT_ROUTE,
  TAKE_FOR_DELIVERY_ROUTE,
  TRIP_SHIPMENTS_ROUTE,
} from "../constants/routeConstants";

export interface NotificationsModel {
  shipments_to_control: number;
  office_bags_to_prepare: number;
  innerBags: number;
  outerBags: number;
  bagsToSend: number;
  bagsToReceive: number;
  bagsToReceiveFromPlatform: number;
  shipmentReadyToDeliver: number;
  tripShipments: number;
  incomeReimbursement: number;
  outcomeReimbursement: number;
  shipmentsWaitingForForwarding: number;
  pickups: number;
}

export interface NotifLink {
  count: number;
  label: string;
  link: string;
}

export type InitNotificationModel = Record<keyof NotificationsModel, NotifLink>;

export const initNotification: InitNotificationModel = {
  shipments_to_control: {
    count: 0,
    label: "Colis à contrôler",
    link: RECEIPT_PACKAGE_ROUTE,
  },
  office_bags_to_prepare: {
    count: 0,
    label: "Des sacs à préparer",
    link: OFFICE_BAGS_PREPARE_ROUTE,
  },
  innerBags: {
    count: 0,
    label: "Des sacs à préparer pour les bureaux internes",
    link: INNER_BAGS_PREPARE_ROUTE,
  },
  outerBags: {
    count: 0,
    label: "Des sacs à préparer pour la platforme",
    link: OUTER_BAGS_PREPARE_ROUTE,
  },
  bagsToSend: {
    count: 0,
    label: "Sacs à transferer",
    link: FORWARD_BAGS_ROUTE,
  },
  bagsToReceive: {
    count: 0,
    label: "Sacs à réceptionner",
    link: RECEIVE_BAGS_ROUTE,
  },
  bagsToReceiveFromPlatform: {
    count: 0,
    label: "Sacs à réceptionner from platform",
    link: RECEIVE_BAGS_IN_PLATFORM_ROUTE,
  },
  shipmentReadyToDeliver: {
    count: 0,
    label: "Colis : prêt à être livré",
    link: TAKE_FOR_DELIVERY_ROUTE,
  },
  tripShipments: {
    count: 0,
    label: "Expéditions de livraison",
    link: TRIP_SHIPMENTS_ROUTE,
  },
  incomeReimbursement: {
    count: 0,
    label: "CRBT : Revenu",
    link: REIMBURSEMENT_IN_ROUTE,
  },
  outcomeReimbursement: {
    count: 0,
    label: "CRBT : Remboursement",
    link: REIMBURSEMENT_OUT_ROUTE,
  },
  shipmentsWaitingForForwarding: {
    count: 0,
    label: "Colis en attente de transfert",
    link: RECEIPT_PACKAGE_ROUTE,
  },
  pickups: {
    count: 0,
    label: "Pickup",
    link: LIST_PICKUPS_ROUTE,
  },
};
