import FilterListIcon from "@mui/icons-material/FilterList";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import {
  Alert,
  Grid,
  IconButton,
  LinearProgress,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import * as React from "react";
import { Link } from "react-router-dom";
import { DETAILS_PICKUP_ROUTE } from "../../application/constants/routeConstants";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import { SelectShipmentStatus } from "../../components/SelectShipmentStatus";
import { SnackbarContext } from "../../components/snackbarProvider";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";
import PickupsFilterForm from "../../pages_components/pickup/forms/filterForm";
import { usePickupController } from "./controller";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

const ListPickupsPage: React.FC<PagePropsModel> = (): JSX.Element => {
  const [_, setSnackbar] = React.useContext(SnackbarContext);
  const {
    isLoading,
    error,
    data,
    fetch,
    handleChangePage,
    status,
    setStatus,
    total,
    currentPage,
    snackbar,
  } = usePickupController((state) => state.fetching);

  React.useEffect(() => {
    fetch();
  }, [currentPage]);

  React.useEffect(() => {
    if (snackbar.open) {
      setSnackbar(snackbar);
    }
  }, [snackbar.open]);

  const [open, setOpen] = React.useState(false);
  const toggleFilter = () => {
    setOpen((prev) => !prev);
  };

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Liste des colis envoyés",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="List des Colis" />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box sx={{ float: "right" }}>
          <PickupsFilterForm />
        </Box>
      </Grid>
      <Grid item>
        {Boolean(error) === true && isLoading === false && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="error">Error</Alert>
          </Box>
        )}
        {isLoading === false && data?.length === 0 && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="info">Aucune donnée disponible!</Alert>
          </Box>
        )}
        {isLoading && <LinearProgress />}

        <Paper sx={{ overflow: "hidden" }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography
              sx={{ flex: "1 1 100%", fontWeight: 500 }}
              id="tableTitle"
              component="div"
            >
              {total} pickups
            </Typography>

            <Tooltip title="Filter list">
              <IconButton onClick={toggleFilter}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>

          <Collapse in={open}>
            <Box sx={{ p: 2 }}>
              <SelectShipmentStatus
                value={status}
                onChange={(value) => {
                  console.log({ value });
                }}
              />
            </Box>
          </Collapse>

          {total > 0 && data?.length > 0 && (
            <TableContainer>
              <Table
                sx={{ width: "100%" }}
                //  size="small"
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Date</StyledTableCell>
                    <StyledTableCell align="left">Libelle</StyledTableCell>
                    <StyledTableCell align="left">
                      Date création
                    </StyledTableCell>
                    <StyledTableCell align="left">Nbr colis</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>

                    <StyledTableCell align="right"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(data || []).map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow key={index} hover tabIndex={-1}>
                        <StyledTableCell component="th" scope="row">
                          {row.planned_for}
                        </StyledTableCell>
                        <StyledTableCell component="th">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell>{row.created_at}</StyledTableCell>
                        <StyledTableCell>
                          {row.shipments.length}
                        </StyledTableCell>

                        <StyledTableCell>{row.status}</StyledTableCell>

                        <StyledTableCell align="right">
                          <IconButton
                            component={Link}
                            aria-label="more"
                            id="long-button"
                            color="inherit"
                            to={`${DETAILS_PICKUP_ROUTE}/${row.id}`}
                          >
                            <ReadMoreIcon fontSize="small" />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>{" "}
            </TableContainer>
          )}
        </Paper>

        <div style={{ float: "right", padding: 20 }}>
          {total > 0 && (
            <Pagination
              count={Math.ceil(Number(total) / 10)}
              color="primary"
              showFirstButton
              showLastButton
              page={currentPage}
              onChange={handleChangePage}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ListPickupsPage;
