import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { IconButton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { decode } from "js-base64";
import { FC, useState } from "react";
import { ShipmentModel } from "../../application/models/shared/ShipmentModel";

export const DeliveryAndCrbtCode: FC<{ shipmentData: ShipmentModel }> = ({
  shipmentData,
}): JSX.Element => {
  const [decodeDeliveryCode, setDecodeDeliveryCode] = useState<Boolean>(false);
  const [decodeCrbtCode, setDecodeCrbtCode] = useState<Boolean>(false);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {Boolean(shipmentData?.delivery_code) && (
        <Stack
          direction={"row"}
          justifyContent="space-between"
          sx={{ width: "100%", maxWidth: 368, mt: 2 }}
        >
          <Typography variant="caption" color="textSecondary">
            Code de livraison :{" "}
          </Typography>

          <Stack direction={"row"} spacing={1} alignItems="center">
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ fontWeight: 600 }}
            >
              {shipmentData?.delivery_code && decodeDeliveryCode
                ? decode(shipmentData?.delivery_code)
                : shipmentData?.delivery_code}
            </Typography>
            {Boolean(shipmentData?.delivery_code) && (
              <IconButton
                size="small"
                onClick={() => setDecodeDeliveryCode(!decodeDeliveryCode)}
              >
                {decodeDeliveryCode ? (
                  <VisibilityOffOutlinedIcon fontSize="small" />
                ) : (
                  <VisibilityOutlinedIcon fontSize="small" />
                )}
              </IconButton>
            )}
          </Stack>
        </Stack>
      )}

      {Boolean(shipmentData?.reimbursement_code) && (
        <Stack
          direction={"row"}
          justifyContent="space-between"
          sx={{ width: "100%", maxWidth: 368, mt: 1 }}
        >
          <Typography variant="caption" color="textSecondary">
            Code de CRPT :{" "}
          </Typography>

          <Stack direction={"row"} spacing={1} alignItems="center">
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ fontWeight: 600 }}
            >
              {shipmentData?.reimbursement_code && decodeCrbtCode
                ? decode(shipmentData?.reimbursement_code)
                : shipmentData?.reimbursement_code ?? "--"}{" "}
            </Typography>
            {Boolean(shipmentData?.reimbursement_code) && (
              <IconButton
                size="small"
                onClick={() => setDecodeCrbtCode(!decodeCrbtCode)}
              >
                {decodeCrbtCode ? (
                  <VisibilityOffOutlinedIcon fontSize="small" />
                ) : (
                  <VisibilityOutlinedIcon fontSize="small" />
                )}
              </IconButton>
            )}
          </Stack>
        </Stack>
      )}
    </Box>
  );
};
