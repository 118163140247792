import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  ListItem,
  Toolbar,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { Box } from "@mui/system";
import { FC, useState } from "react";
import { BagModel } from "../../../application/models/shared/bagModel";
import PrintIcon from "@mui/icons-material/Print";
import { BagBarcodePrint } from "../bagBarcodePrint";

interface BagsToControlPropsModel {
  data: BagModel[] | [];
  checkedBarcode: string[];
}

export const BagsToControl: FC<BagsToControlPropsModel> = ({
  data,
  checkedBarcode,
}): JSX.Element => {
  const isChecked = (barcodeItem: string): boolean => {
    return checkedBarcode.includes(barcodeItem);
  };

  if (data.length > 0 && data.length === checkedBarcode.length) {
    return (
      <Box
        sx={{
          minHeight: 172,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderLeft: "solid 5px green",
          pl: 3,
        }}
      >
        <Typography>Opération terminée avec succès</Typography>
      </Box>
    );
  }

  return (
    <List
      disablePadding
      dense
      sx={{
        width: "100%",
        maxWidth: 460,
        bgcolor: "transparent",
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {(data || [])
        .filter((item) => !checkedBarcode.includes(item.identifier))
        .map((item, index) => (
          <BagItem
            item={item}
            key={index}
            isChecked={isChecked(item.identifier)}
          />
        ))}
    </List>
  );
};

const BagItem: FC<{ item: BagModel; isChecked: boolean }> = ({
  item,
  isChecked,
}): JSX.Element => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ListItem
        button
        onClick={() => setOpen(true)}
        alignItems="center"
        secondaryAction={
          <IconButton edge="end" aria-label="delete">
            <CheckCircleOutlineIcon
              color={isChecked ? "success" : "disabled"}
            />
          </IconButton>
        }
      >
        <ListItemIcon>
          <QrCode2Icon />
        </ListItemIcon>
        <ListItemText primary={item.identifier} />
      </ListItem>
      <Drawer
        anchor={"right"}
        sx={{ marginTop: 40, maxHeight: 400 }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div style={{ minWidth: 400 }}>
          <Toolbar>
            <Grid container spacing={1}>
              <Grid item>
                <PrintIcon />
              </Grid>
              <Grid item>
                <Typography sx={{ fontWeight: 500 }}>Print</Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <Divider />

          <Box sx={{ padding: 4 }}>
            <BagBarcodePrint {...item} />
          </Box>
        </div>
      </Drawer>
    </>
  );
};
