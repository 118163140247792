import * as yup from "yup";
import { addressSchame } from "../../../reception/forms/ValidationSchema";

const accountSchema = yup.object().shape({
  is_company: yup.boolean(),
  email: yup
    .string()
    .email("E-mail non valide")
    .required("Ce champs est obligatoire"),

  tax_registry_number: yup.string().when("is_company", {
    is: true,
    then: yup.string().required("Ce champs est obligatoire"),
    otherwise: yup.string().nullable(),
  }),
});

export const customerFormSchema = yup.object().shape({
  first_name: yup.string().required("Non est obligatoire"),
  last_name: yup.string().required("Prénon est obligatoire"),
  address: addressSchame,
  phone_number: yup
    .string()
    .required("Num téléphone est obligatoire")
    .length(11),
  pricing: yup.array().of(
    yup.object().shape({
      min: yup
        .number()
        .min(0, "Minumum 0")
        .required("Ce champs est obligatoire"),
      max: yup
        .number()
        .test(
          "is-unique",
          "doit être supérieur au min",
          (value, ctx) => !value || ctx.parent.min < value
        )
        .min(0.01, "Le poids doit être supérieur à 0")
        .max(100, "Maxumum 100")
        .required("Ce champs est obligatoire"),
      price: yup
        .number()
        .min(0.01, "Le poids doit être supérieur à 0")
        .required("Ce champs est obligatoire"),
    })
  ),
  account: accountSchema,
  company_identity_file: yup.mixed().when("account.is_company", {
    is: true,
    then: yup.mixed().required("Ce champs est obligatoire"),
    otherwise: yup.mixed().nullable(),
  }),
  personal_identity_file: yup.mixed().when("account.is_company", {
    is: false,
    then: yup.mixed().required("Ce champs est obligatoire"),
    otherwise: yup.mixed().nullable(),
  }),
});
