import { isEqual } from "lodash";
import create from "zustand";
import { BagModel } from "../../../application/models/shared/bagModel";
import { OfficeShipmentsModel } from "../../../application/models/shared/officeShipmentModel";

export interface PrepareInnerBagsStateModel {
  loading: boolean;
  error: string;
  data: OfficeShipmentsModel[] | [];
  officesBags: OfficeBagsStateModel[] | [];
  alert: AlertStateModel;
  setLoading: (isLoading: boolean, error: string) => void;
  setData: (data: OfficeShipmentsModel[] | []) => void;
  setBarCode: (barCodeScanned: string) => void;
  addNewBag: (officeId: number) => void;
  setActiveBag: (officeId: number, bagIndex: number) => void;
  removeBag: (officeId: number, bagIndex: number) => void;
  removeShipmentFromBag: (
    officeId: number,
    bagIndex: number,
    barcode: string
  ) => void;
  setSavedBag: (
    officeId: number,
    bagIndex: number,
    dataSaved: BagModel
  ) => void;
  nbrBagsByDistrict: (officeId: number) => number;
  barcodesByDistrict: (officeId: number) => string[];
  checkedBarcodesByDistrict: (officeId: number) => string[];
  needNewBag: (officeId: number) => boolean;
}

export interface OfficeBagsStateModel {
  id: number;
  bags: BagItemStateModel[] | [];
}

export interface BagItemStateModel {
  active: boolean;
  saved: boolean;
  shipments: string[];
  dataSaved?: BagModel;
}

export interface AlertStateModel {
  type: "success" | "error";
  message: string;
}

export const usePrepareInnerBagsStore = create<PrepareInnerBagsStateModel>(
  (set, get) => ({
    loading: false,
    error: "",
    data: [],
    officesBags: [],
    alert: {
      type: "success",
      message: "",
    },
    setLoading: (isLoading: boolean, error: string) => {
      set((state) => ({ ...state, loading: isLoading, error }));
    },
    setData: (data: OfficeShipmentsModel[] | []) => {
      const generateInitialState = (
        responseData: OfficeShipmentsModel[] | []
      ): OfficeBagsStateModel[] | [] => {
        return (responseData || []).map((item) => {
          return {
            id: item.office.id,
            bags: [
              {
                active: true,
                saved: false,
                shipments: [],
              },
            ],
          } as OfficeBagsStateModel;
        });
      };

      set((state) => ({
        ...state,
        loading: false,
        error: "",
        data,
        officesBags: generateInitialState(data),
      }));
    },
    needNewBag: (officeId: number) => {
      return (
        get().checkedBarcodesByDistrict(officeId).length > 0 &&
        get().checkedBarcodesByDistrict(officeId).length <
          get().barcodesByDistrict(officeId).length &&
        get().barcodesByDistrict(officeId).length >
          get().nbrBagsByDistrict(officeId)
      );
    },
    addNewBag: (officeId: number) => {
      set((state) => ({
        ...state,
        officesBags: state.officesBags.map((item) => {
          if (item.id === officeId) {
            return {
              ...item,
              bags: [
                ...item.bags,
                {
                  open: true,
                  active: false,
                  saved: false,
                  shipments: [],
                },
              ],
            };
          } else {
            return item;
          }
        }),
      }));
    },
    setActiveBag: (officeId: number, bagIndex: number) => {
      set((state) => ({
        ...state,
        officesBags: state.officesBags.map((item) => {
          if (item.id === officeId) {
            return {
              ...item,
              bags: item.bags.map((bag, index) => {
                return {
                  ...bag,
                  active: index === bagIndex,
                };
              }),
            };
          } else {
            return item;
          }
        }),
      }));
    },
    removeBag: (officeId: number, bagIndex: number) => {
      set((state) => ({
        ...state,
        officesBags: state.officesBags.map((item) => {
          if (item.id === officeId) {
            return {
              ...item,
              bags: item.bags.filter((bag, index) => index !== bagIndex),
            };
          } else {
            return item;
          }
        }),
      }));
    },
    removeShipmentFromBag: (
      officeId: number,
      bagIndex: number,
      barcode: string
    ) => {
      set((state) => ({
        ...state,
        officesBags: state.officesBags.map((item) => {
          if (item.id === officeId) {
            return {
              ...item,
              bags: item.bags.map((bag, index) => {
                if (index === bagIndex) {
                  return {
                    ...bag,
                    shipments: bag.shipments.filter((ship) => ship !== barcode),
                  };
                }
                return bag;
              }),
            };
          } else {
            return item;
          }
        }),
      }));
    },
    setSavedBag: (officeId: number, bagIndex: number, dataSaved: BagModel) => {
      set(
        (state) =>
          ({
            ...state,
            officesBags: state.officesBags.map((item) => {
              if (item.id === officeId) {
                return {
                  ...item,
                  bags: item.bags.map((bag, index) => {
                    return {
                      ...bag,
                      saved: index === bagIndex ? true : bag.saved,
                      ...(() =>
                        index === bagIndex
                          ? { dataSaved }
                          : bag?.dataSaved || undefined)(),
                    };
                  }),
                };
              } else {
                return item;
              }
            }),
          } as PrepareInnerBagsStateModel)
      );
    },

    checkedBarcodesByDistrict: (officeId: number) => {
      const officeInState = get().officesBags.find(
        (office) => office.id === officeId
      );
      return (officeInState?.bags || []).reduce((acc: string[], curr) => {
        return [...acc, ...curr.shipments];
      }, []);
    },

    barcodesByDistrict: (officeId: number) => {
      const office = get().data.find((item) => item.office.id === officeId);
      return (office?.shipments || []).map((item) => item.identifier);
    },
    nbrBagsByDistrict: (officeId: number) => {
      const officeInState = get().officesBags.find(
        (office) => office.id === officeId
      );
      return officeInState?.bags.length || 0;
    },

    setBarCode: (barCodeScanned: string) => {
      const getDistrictIncludedBarCode = (
        barCodeScanned: string
      ): OfficeShipmentsModel | undefined => {
        return get().data.find((item) => {
          return item.shipments
            .map((shipment) => shipment.identifier)
            .includes(barCodeScanned);
        });
      };

      const officeData = getDistrictIncludedBarCode(barCodeScanned);
      if (officeData && barCodeScanned) {
        const updatedState = {
          ...get(),
          officesBags: get().officesBags.map((item) => {
            if (item.id === officeData.office.id) {
              return {
                ...item,
                bags: item.bags.map((bag) => {
                  if (bag.active === true && bag.saved === false) {
                    return {
                      ...bag,
                      shipments: !get()
                        .checkedBarcodesByDistrict(item.id)
                        .includes(barCodeScanned)
                        ? [...bag.shipments, barCodeScanned]
                        : bag.shipments,
                    };
                  } else {
                    return bag;
                  }
                }),
              };
            } else {
              return item;
            }
          }),
        };
        if (!isEqual(get(), updatedState)) {
          set((state) => ({
            ...updatedState,
            alert: {
              type: "success",
              message: `Barcode ${barCodeScanned}  ajouté à ${officeData.office.name}`,
            },
          }));
        } else {
          if (
            get()
              .checkedBarcodesByDistrict(Number(officeData.office.id))
              .includes(barCodeScanned)
          ) {
            set((state) => ({
              ...state,
              alert: {
                type: "error",
                message: `Barcode déja ajouté à ${officeData.office.name}`,
              },
            }));
          } else {
            set((state) => ({
              ...state,
              alert: {
                type: "error",
                message: "Merci de bien vouloir vérifier la bag active",
              },
            }));
          }
        }
      } else {
        set((state) => ({
          ...state,
          alert: {
            type: "error",
            message: "Barrecode non reconnu!",
          },
        }));
      }
    },
  })
);
