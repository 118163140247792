import MessageIcon from "@mui/icons-material/Message";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { ShipmentModel } from "../../../application/models/shared/ShipmentModel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export const AttemptsTimeLine: FC<{ shipment: ShipmentModel }> = ({
  shipment,
}): JSX.Element => {
  if (shipment?.attempts && shipment?.attempts.length === 0) {
    return <></>;
  }

  return (
    <Box
      sx={{
        maxHeight: "100%",
        maxWidth: "100%",
        overflow: "hidden",
      }}
    >
      <Timeline sx={{ maxWidth: "100%" }}>
        {(shipment?.attempts || []).map((item, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent variant="caption" color="text.secondary">
              {item.created_at}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper sx={{ p: 2, width: 250, borderTopLeftRadius: 0 }}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <PersonOutlineOutlinedIcon fontSize="small" />
                      <Typography variant="caption">
                        {`${item?.officer.first_name} ${
                          item?.officer.last_name || ""
                        }`}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <MessageIcon fontSize="small" />
                      <Typography variant="caption">
                        {`${item?.reason}`}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
};
