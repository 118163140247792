import { Grid, Paper, Typography } from "@mui/material";
import { useAuth } from "../../application/contexts/authContex";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import { FundsMineCard } from "../../pages_components/profile/fundsMineCard";
import { MyTripCard } from "../../pages_components/profile/myTrip";

const UserProfilePage: React.FC<PagePropsModel> = (): JSX.Element => {
  const auth = useAuth();

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Profile",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="Profile" />

        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction={"row"} spacing={3} alignItems="stretch">
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 4, maxWidth: 600 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography variant="body2">
                        <b> Nom & Prénom </b>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body2">
                        : {auth.user?.first_name} {auth.user?.last_name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography variant="body2">
                        <b> Télephone </b>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body2">
                        : {auth.user?.phone_number}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography variant="body2">
                        <b> E-mail </b>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body2">
                        : {auth.user?.email}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {auth.user?.office && (
                  <>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            <b> Bureau </b>
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2">
                            : {auth.user?.office?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            <b> Adresse </b>
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2">
                            {" "}
                            : {auth.user?.office?.address.address_line}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <FundsMineCard />
          </Grid>

          <Grid item xs={12} md={4}>
            <MyTripCard />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserProfilePage;
