import {
  Alert,
  Button,
  ClickAwayListener,
  Collapse,
  Grid,
  Grow,
  List,
  Paper,
  Popper,
} from "@mui/material";
import { get } from "lodash";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { useFetchStaffQuery } from "../../../../application/api/hooks/office.hooks";
import { officeService } from "../../../../application/api/services/office.service";
import { OfficeModel } from "../../../../application/models/shared/officeModel";
import { UserModel } from "../../../../application/models/shared/userModel";
import { SnackbarContext } from "../../../../components/snackbarProvider";
import { OfficeStaffFormModel } from "../editForm/models";
import { SearchStaffForm } from "./searchStaffForm";
import { StaffListItem } from "./staffListItem";
import { StafListSkeleton } from "./stafListSkeleton";

interface OfficeStaffFormPropsModel {
  district: OfficeModel;
  onClose: () => void;
}

export const OfficeStaffForm: FC<OfficeStaffFormPropsModel> = (props) => {
  const [open, setOpen] = useState(false);
  const [snackbar, setSnackbar] = useContext(SnackbarContext);
  const [selectedUsers, setSelectedUsers] = useState<UserModel[] | []>([]);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const staffQuery = useFetchStaffQuery(Number(props.district.id));
  const staff = get(staffQuery, ["data", "data"], []);

  useEffect(() => {
    if (staff.length) {
      setSelectedUsers(staff);
    }
  }, [staff]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleEditeUsers = (user: UserModel) => {
    const exist =
      selectedUsers.find((selected) => selected.id === user.id) !== undefined;
    if (exist) {
      setSelectedUsers((selectedUsers) => [
        ...[...selectedUsers].filter((item) => item.id !== user.id),
      ]);
    } else {
      setSelectedUsers((selectedUsers) => [...selectedUsers, user]);
    }
  };

  const save = () => {
    if (selectedUsers) {
      const obj = {
        id: props.district.id,
        users: selectedUsers.map((item) => item.id),
      } as OfficeStaffFormModel;

      officeService.editeSaff(obj).then((response) => {
        props.onClose();
        setSnackbar({
          open: true,
          severity: "success",
          message: "Opération effectuée avec succès",
        });
      });
    }
  };

  return (
    <>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            Trouver des utilisateurs pour "{props.district.name}"
          </Button>
        </Grid>

        {staffQuery.isLoading ? (
          <Grid item>
            <StafListSkeleton />
          </Grid>
        ) : staffQuery.isError ? (
          <Grid item>
            <Alert severity="warning">
              <>{String(staffQuery.error)}</>
            </Alert>
          </Grid>
        ) : selectedUsers.length === 0 ? (
          <Grid item>
            <Alert severity="warning">
              Aucun utilisateur n'est affecté à cet district
            </Alert>
          </Grid>
        ) : (
          <Grid item>
            <List sx={{ width: "100%", bgcolor: "background.paper" }} dense>
              <TransitionGroup>
                {selectedUsers.map((user, index) => (
                  <Collapse key={index}>
                    <StaffListItem
                      user={user}
                      handleEditeUsers={handleEditeUsers}
                    />
                  </Collapse>
                ))}
              </TransitionGroup>
            </List>
          </Grid>
        )}

        {(selectedUsers.length > 0 || staff.length > 0) && (
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => props.onClose()}
                >
                  Annuler
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => save()}
                >
                  Sauvegarder
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper
              sx={{ padding: 3, minWidth: anchorRef.current?.offsetWidth }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <SearchStaffForm
                    selectedUsers={selectedUsers}
                    addUser={(item) => handleEditeUsers(item)}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
