import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { ACCESS_TOKEN } from "../constants/authConstants";
import { RequireAuth, useAuth } from "../contexts/authContex";
import { getStorageItem } from "../helpers/localStorage";
import PrivateLayoutRoute from "./privateLayoutRoute";
import PublicLayoutRoute from "./publicLayoutRoute";

const Router = (): JSX.Element => {
  const history = useHistory();
  const auth = useAuth();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  React.useEffect(() => {
    const access_token = getStorageItem(ACCESS_TOKEN) || "";
    if (access_token && !auth.user) {
      auth.whoAmI(
        (responseWhoAmI) => {
          setIsLoading(false);
        },
        ({ message }) => {
          setIsLoading(false);
        }
      );
    } else {
      setIsLoading(false);
    }
  }, [auth.user, history]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/public"
          render={(props) =>
            !auth.user ? (
              <PublicLayoutRoute {...props} />
            ) : (
              <Redirect to="/private" />
            )
          }
        />
        <Route
          path="/private"
          render={(props) => (
            <RequireAuth>
              <PrivateLayoutRoute {...props} />
            </RequireAuth>
          )}
        />

        <Redirect from="/" to="/public" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
