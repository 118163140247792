import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";
import { priceFormat } from "../../../application/helpers/priceFormat";
import { ShipmentModel } from "../../../application/models/shared/ShipmentModel";
import { BarCode } from "../../../components/barCode";
import QRCode from "qrcode.react";

export const ShipmentBarCodeCard: FC<{ shipment: ShipmentModel }> = ({
  shipment,
}) => {
  return (
    <Box
      sx={{
        width: 384,
        height: 384,
        p: 1,
        m: 0,
        // border: "solid 1px red",
        overflow: "hidden",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={0.5}
      >
        <Grid item>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              className="img-fluid"
              src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/logo_grey.png`}
              alt="logo"
              style={{ height: 30, width: 130 }}
            />
          </Box>
        </Grid>
        <Grid item container>
          <Grid
            container
            alignItems="stretch"
            spacing={0.5}
            sx={{ height: "100%" }}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  border: "solid 1px #000",
                  p: 1,
                  height: "100%",
                  backgroundImage:
                    "linear-gradient(45deg, #000 2%, transparent 0%, transparent 50%, #000 126%, #000 50%, transparent 47%, #fff)",
                  backgroundSize: "100%",
                }}
              >
                <Grid container direction="column">
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: 500, fontSize: 11 }}
                      variant="body2"
                    >
                      Expéditeur
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" sx={{ fontSize: 10 }}>
                      {shipment.sender?.first_name || "--"}{" "}
                      {shipment.sender?.last_name || "--"} :{" "}
                      {shipment.sender?.phone_number || "--"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" sx={{ fontSize: 10 }}>
                      {shipment.sender?.address?.address_line || "--"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" noWrap sx={{ fontSize: 10 }}>
                      {shipment.sender?.address?.post_office?.name || "--"} /{" "}
                      {shipment.sender?.address?.post_office?.zip_code || "--"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  border: "solid 1px #000",
                  p: 1,
                  height: "100%",
                }}
              >
                <Grid container direction="column">
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: 500, fontSize: 11 }}
                      variant="body2"
                    >
                      Destinataire
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" sx={{ fontSize: 10 }}>
                      {shipment.recipient?.first_name || "--"}{" "}
                      {shipment.recipient?.last_name || "--"} :{" "}
                      {shipment.recipient?.phone_number || "--"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" sx={{ fontSize: 10 }}>
                      {shipment.recipient?.address?.address_line || "--"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" noWrap sx={{ fontSize: 10 }}>
                      {shipment?.recipient?.address?.post_office?.name || "--"}{" "}
                      /{" "}
                      {shipment?.recipient?.address?.post_office?.zip_code ||
                        "--"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Box sx={{ border: "solid 1px #000", p: 1 }}>
            <Grid
              container
              //  spacing={1}
              justifyContent="space-between"
              alignItems="flex-start"
              wrap="nowrap"
            >
              <Grid item>
                <Typography variant="body2" noWrap sx={{ fontSize: 10 }}>
                  <b>Date :</b> {shipment?.received_at}
                </Typography>
                <Typography variant="body2" noWrap sx={{ fontSize: 10 }}>
                  <b> Poids :</b> {shipment.weight} Kg
                </Typography>
                {shipment.reimbursement.enable &&
                  shipment.reimbursement.amount > 0 && (
                    <Typography variant="body2" noWrap sx={{ fontSize: 10 }}>
                      <b> CRBT :</b>{" "}
                      {priceFormat(shipment.reimbursement.amount)}
                    </Typography>
                  )}

                <Typography variant="body2" noWrap sx={{ fontSize: 10 }}>
                  <b> Prix :</b> {priceFormat(shipment.price)}
                </Typography>
                <Typography variant="body2" noWrap sx={{ fontSize: 10 }}>
                  <b> Identifiant :</b> {shipment.identifier}
                </Typography>
              </Grid>
              <Grid item>
                {" "}
                <QRCode value={shipment.identifier} size={80} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              border: "solid 1px #000",
              p: 0.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <BarCode value={shipment.identifier} /> */}
            {/* <Typography variant="body2" sx={{ fontSize: 10 }}>
              * ODD vous garantie un service hautement qualifié,
            </Typography> */}
            <Typography variant="body2" sx={{ fontSize: 10 }}>
              ODD n'a aucune responsabilité en cas de saisie de votre envoi par
              les autorités de l'Etat Tunisien
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
