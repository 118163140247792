import React, { FC } from "react";
import { Route, RouteProps, Switch, useRouteMatch } from "react-router-dom";
import ListBagsPage from "../../pages/bags";
import ForwardBagsPage from "../../pages/bags/forwardBags";
import MyTripBagsPage from "../../pages/bags/myTrip";
import PrepareInnerBagsPage from "../../pages/bags/prepareInnerBags";
import PrepareOfficeBagsPage from "../../pages/bags/prepareOfficeBags";
import PrepareOuterBagsPage from "../../pages/bags/prepareOuterBags";
import ReceiveBagsPage from "../../pages/bags/receiveBags";
import ReceiveBagsInPlatformPage from "../../pages/bags/receiveBagsInPlatform";
import CustomersListPage from "../../pages/customers";
import CreateCustomerPage from "../../pages/customers/createCustomer";
import CustomerProfilePage from "../../pages/customers/profile";
import UpdateCustomerPage from "../../pages/customers/updateCustomer";
import DashboardPage from "../../pages/dashboard";
import ListDistrictsPage from "../../pages/districts";
import CreateDistrictPage from "../../pages/districts/createDistrict";
import UpdateDistrictPage from "../../pages/districts/updateDistrict";
import FundsMinePage from "../../pages/funds/fundsMine";
import FundsOfficePage from "../../pages/funds/fundsOffice";
import InvoicesPage from "../../pages/invoices";
import ListOfficesPage from "../../pages/offices";
import CreateOfficePage from "../../pages/offices/createOffice";
import UpdateOfficePage from "../../pages/offices/updateOffice";
import ListPickupsPage from "../../pages/pickups";
import PickupDetailsPage from "../../pages/pickups/details";
import EditPickupsPage from "../../pages/pickups/editPickups";
import ReimbursementInPage from "../../pages/reimbursement/in";
import ReimbursementOutPage from "../../pages/reimbursement/out";
import ListPackagesPage from "../../pages/shipments";
import ReceptionPage from "../../pages/reception";
import ControlShipmentsPage from "../../pages/shipments/ControlShipments";
import ShipmentDetailsPage from "../../pages/shipments/details";
import TakeForDeliveryPage from "../../pages/shipments/TakeForDelivery";
import TripShipmentsPage from "../../pages/shipments/TripShipments";
import ChangePasswordPage from "../../pages/users/changePassword";
import CreateUserPage from "../../pages/users/createUser";
import UsersListPage from "../../pages/users/index";
import UserProfilePage from "../../pages/users/profile";
import UpdateUserPage from "../../pages/users/updateUser";
import {
  BAGS_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  CREATE_CUSTOMER_ROUTE,
  CREATE_DISTRICT_ROUTE,
  CREATE_OFFICE_ROUTE,
  CREATE_PACKAGE_ROUTE,
  CREATE_USER_ROUTE,
  DETAILS_INVOICES_ROUTE,
  DETAILS_PACKAGE_ROUTE,
  DETAILS_PICKUP_ROUTE,
  EDIT_CUSTOMER_ROUTE,
  EDIT_DISTRICT_ROUTE,
  EDIT_OFFICE_ROUTE,
  EDIT_PICKUP_PARCEL_ROUTE,
  EDIT_PICKUP_ROUTE,
  EDIT_USER_ROUTE,
  FORWARD_BAGS_ROUTE,
  FUNDS_MINE_ROUTE,
  FUNDS_OFFICE_ROUTE,
  INNER_BAGS_PREPARE_ROUTE,
  LIST_CUSTOMERS_ROUTE,
  LIST_DISTRICT_ROUTE,
  LIST_INVOICES_ROUTE,
  LIST_OFFICE_ROUTE,
  LIST_PACKAGE_ROUTE,
  LIST_PICKUPS_ROUTE,
  LIST_USERS_ROUTE,
  MY_TRIP_BAGS_ROUTE,
  OFFICE_BAGS_PREPARE_ROUTE,
  OUTER_BAGS_PREPARE_ROUTE,
  PROFILE_CUSTOMER_ROUTE,
  PROFILE_USER_ROUTE,
  RECEIPT_PACKAGE_ROUTE,
  RECEIVE_BAGS_IN_PLATFORM_ROUTE,
  RECEIVE_BAGS_ROUTE,
  REIMBURSEMENT_IN_ROUTE,
  REIMBURSEMENT_OUT_ROUTE,
  TAKE_FOR_DELIVERY_ROUTE,
  TRIP_SHIPMENTS_ROUTE,
} from "../constants/routeConstants";
import { communMenuItems } from "../data/menuItems";
import { PrivateWorkSpaceLayout } from "../layouts/privateWorkSpaceLayout";
import { MenuModel } from "../models/shared/menuModel";
import EditPickupParcelPage from "../../pages/pickups/editParcel";
import InvoiceDetailsPage from "../../pages/invoices/details";

const PrivateLayoutRoute: FC<RouteProps> = (props) => {
  const { path } = useRouteMatch();

  const menuList = (communMenuItems as Array<MenuModel>).reduce((acc, cur) => {
    const currentList = cur?.subMenuItems || [];
    return [...acc, ...currentList];
  }, [] as MenuModel[]);

  return (
    <PrivateWorkSpaceLayout>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={(props) => <DashboardPage {...props} />}
        />

        <Route
          path={`${RECEIPT_PACKAGE_ROUTE}`}
          render={(props) => <ControlShipmentsPage {...props} />}
        />

        <Route
          path={`${TRIP_SHIPMENTS_ROUTE}`}
          render={(props) => <TripShipmentsPage {...props} />}
        />

        <Route
          path={`${CREATE_PACKAGE_ROUTE}`}
          render={(props) => <ReceptionPage {...props} />}
        />

        <Route
          path={`${OFFICE_BAGS_PREPARE_ROUTE}`}
          render={(props) => <PrepareOfficeBagsPage {...props} />}
        />

        <Route
          path={`${INNER_BAGS_PREPARE_ROUTE}`}
          render={(props) => <PrepareInnerBagsPage {...props} />}
        />

        <Route
          path={`${OUTER_BAGS_PREPARE_ROUTE}`}
          render={(props) => <PrepareOuterBagsPage {...props} />}
        />

        <Route
          path={`${FORWARD_BAGS_ROUTE}`}
          render={(props) => <ForwardBagsPage {...props} />}
        />

        <Route
          path={`${RECEIVE_BAGS_ROUTE}`}
          render={(props) => <ReceiveBagsPage {...props} />}
        />
        <Route
          path={`${RECEIVE_BAGS_IN_PLATFORM_ROUTE}`}
          render={(props) => <ReceiveBagsInPlatformPage {...props} />}
        />

        <Route
          path={`${MY_TRIP_BAGS_ROUTE}`}
          render={(props) => <MyTripBagsPage {...props} />}
        />

        <Route
          path={`${FUNDS_OFFICE_ROUTE}`}
          render={(props) => <FundsOfficePage {...props} />}
        />

        <Route
          path={`${FUNDS_MINE_ROUTE}`}
          render={(props) => <FundsMinePage {...props} />}
        />

        <Route
          path={`${TAKE_FOR_DELIVERY_ROUTE}`}
          render={(props) => <TakeForDeliveryPage {...props} />}
        />

        <Route
          path={`${BAGS_ROUTE}`}
          render={(props) => <ListBagsPage {...props} />}
        />

        <Route
          path={`${DETAILS_PACKAGE_ROUTE}/:id?`}
          render={(props) => <ShipmentDetailsPage {...props} />}
        />
        <Route
          path={`${LIST_PACKAGE_ROUTE}`}
          render={(props) => <ListPackagesPage {...props} />}
        />

        <Route
          path={`${EDIT_USER_ROUTE}/:id?`}
          render={(props) => <UpdateUserPage {...props} />}
        />
        <Route
          path={`${PROFILE_USER_ROUTE}`}
          render={(props) => <UserProfilePage {...props} />}
        />
        <Route
          path={`${CREATE_USER_ROUTE}`}
          render={(props) => <CreateUserPage {...props} />}
        />
        <Route
          path={`${CHANGE_PASSWORD_ROUTE}`}
          render={(props) => <ChangePasswordPage {...props} />}
        />
        <Route
          path={`${LIST_USERS_ROUTE}`}
          render={(props) => <UsersListPage {...props} />}
        />

        <Route
          path={`${PROFILE_CUSTOMER_ROUTE}/:id?`}
          render={(props) => <CustomerProfilePage {...props} />}
        />

        <Route
          path={`${EDIT_CUSTOMER_ROUTE}/:id?`}
          render={(props) => <UpdateCustomerPage {...props} />}
        />

        <Route
          path={`${CREATE_CUSTOMER_ROUTE}`}
          render={(props) => <CreateCustomerPage {...props} />}
        />

        <Route
          path={`${LIST_CUSTOMERS_ROUTE}`}
          render={(props) => <CustomersListPage {...props} />}
        />

        <Route
          path={`${CREATE_DISTRICT_ROUTE}`}
          render={(props) => <CreateDistrictPage {...props} />}
        />

        <Route
          path={`${EDIT_DISTRICT_ROUTE}/:id?`}
          render={(props) => <UpdateDistrictPage {...props} />}
        />
        <Route
          path={`${LIST_DISTRICT_ROUTE}`}
          render={(props) => <ListDistrictsPage {...props} />}
        />

        <Route
          path={`${EDIT_OFFICE_ROUTE}/:id?`}
          render={(props) => <UpdateOfficePage {...props} />}
        />

        <Route
          path={`${CREATE_OFFICE_ROUTE}`}
          render={(props) => <CreateOfficePage {...props} />}
        />

        <Route
          path={`${LIST_OFFICE_ROUTE}`}
          render={(props) => <ListOfficesPage {...props} />}
        />

        <Route
          path={`${REIMBURSEMENT_IN_ROUTE}`}
          render={(props) => <ReimbursementInPage {...props} />}
        />

        <Route
          path={`${REIMBURSEMENT_OUT_ROUTE}`}
          render={(props) => <ReimbursementOutPage {...props} />}
        />

        <Route
          path={`${DETAILS_INVOICES_ROUTE}/:id?`}
          render={(props) => <InvoiceDetailsPage {...props} />}
        />

        <Route
          path={`${LIST_INVOICES_ROUTE}`}
          render={(props) => <InvoicesPage {...props} />}
        />

        {/*************** Pickup routes ***************/}

        <Route
          exact
          path={`${DETAILS_PICKUP_ROUTE}/:id?`}
          render={(props) => <PickupDetailsPage {...props} />}
        />

        <Route
          exact
          path={`${EDIT_PICKUP_ROUTE}/:id?`}
          render={(props) => <EditPickupsPage {...props} />}
        />

        <Route
          exact
          path={`${EDIT_PICKUP_PARCEL_ROUTE}/:id/:idParcel`}
          render={(props) => <EditPickupParcelPage {...props} />}
        />

        <Route
          exact
          path={`${LIST_PICKUPS_ROUTE}`}
          render={(props) => <ListPickupsPage {...props} />}
        />
      </Switch>
    </PrivateWorkSpaceLayout>
  );
};

export default PrivateLayoutRoute;
