import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { Box } from "@mui/system";
import { get, isUndefined } from "lodash";
import React, { FC, useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { priceFormat } from "../../../../application/helpers/priceFormat";
import { RecipientPackagesFormModel } from "../../../../application/models/forms/recipientPackagesFormModel";
import { useReceptionController } from "../receptionController";
import { ReimbursementSectionForm } from "./reimbursementSectionForm";

const useStyles = makeStyles((theme: Theme) => ({
  boxPrice: {
    border: "solid 0px #C4C4C4",
    width: "100%",
    height: 85,
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.shape.borderRadius,
  },
  boxContainer: {
    width: "100%",
    height: 85,
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.shape.borderRadius,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transparent",
    color: "#000",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const ParcelForm: FC<{
  parentIndex: number;
}> = ({ parentIndex }) => {
  const { getParcelPrice, maxWeight, getPricing, formSettings } =
    useReceptionController();
  const classes = useStyles();
  const pricingOptions = getPricing();
  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: `shipments[${parentIndex}].packages`,
  });

  const formValues = watch();

  const handleChangePrice = (recipientIndex: number, packageIndex: number) => {
    setValue(
      `shipments[${recipientIndex}].packages.[${packageIndex}].price`,
      getParcelPrice(
        formValues.shipments[recipientIndex].packages[packageIndex]
      )
    );
  };

  useEffect(() => {
    const maj = formValues.shipments.map(
      (shipment: RecipientPackagesFormModel, recipientIndex: number) => {
        return shipment.packages.map((pack, packIndex) => {
          handleChangePrice(recipientIndex, packIndex);
        });
      }
    );
  }, [pricingOptions]);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography sx={{ fontWeight: 500 }}>Les Colis</Typography>
      </Grid>
      <Grid item>
        <TableContainer
          component={Paper}
          elevation={0}
          // sx={{ border: "solid 1px #C4C4C4" }}
        >
          <Table
            sx={{ minWidth: "100%" }}
            // size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell width={"35%"}>Poids (Kg)</StyledTableCell>
                {/* <StyledTableCell width={"25%"} align="center">
                  Embalage
                </StyledTableCell> */}
                <StyledTableCell width={"40%"} align="center">
                  CRBT
                </StyledTableCell>
                <StyledTableCell width={"20%"} align="right">
                  Montant
                </StyledTableCell>
                <StyledTableCell width={"5%"} align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((field, index) => (
                <TableRow
                  hover
                  key={field.id}
                  // sx={{
                  //   "&:last-child td, &:last-child th": {
                  //     border: 0,
                  //   },
                  // }}
                >
                  <TableCell component="th" scope="row">
                    <Box className={classes.boxContainer}>
                      <Controller
                        name={`shipments[${parentIndex}].packages.[${index}].weight`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            onChange={(
                              event: React.ChangeEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >
                            ) => {
                              field.onChange(event.target.value);
                              handleChangePrice(parentIndex, index);
                            }}
                            fullWidth
                            type="number"
                            size="small"
                            inputProps={{
                              max: Number(maxWeight),
                              min: 0,
                              step: 0.01,
                            }}
                            // InputProps={{
                            //   endAdornment: (
                            //     <InputAdornment position="end">
                            //       Kg
                            //     </InputAdornment>
                            //   ),
                            // }}
                            error={
                              !isUndefined(
                                get(
                                  errors,
                                  [
                                    "shipments",
                                    parentIndex,
                                    "packages",
                                    index,
                                    "weight",
                                  ],
                                  undefined
                                )
                              )
                            }
                            helperText={get(
                              errors,
                              [
                                "shipments",
                                parentIndex,
                                "packages",
                                index,
                                "weight",
                                "message",
                              ],
                              null
                            )}
                          />
                        )}
                      />
                    </Box>
                  </TableCell>
                  {/* <TableCell align="right">
                    <PackagingSectionForm
                      parentIndex={parentIndex}
                      index={index}
                    />
                  </TableCell> */}

                  <TableCell align="right">
                    <ReimbursementSectionForm
                      parentIndex={parentIndex}
                      index={index}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Typography sx={{ fontWeight: 500 }}>
                      {priceFormat(
                        Number(
                          getValues(
                            `shipments[${parentIndex}].packages.[${index}].price`
                          )
                        )
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {fields.length > 1 && (
                      <Box className={classes.boxContainer}>
                        <IconButton
                          color="error"
                          aria-label="delete"
                          size="small"
                          onClick={() => remove(index)}
                          sx={{ float: "right", minWidth: 40 }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {formSettings?.isNested && (
        <Grid item>
          <Button
            type="button"
            variant="contained"
            color="inherit"
            size="small"
            startIcon={<AddIcon />}
            sx={{ float: "left", minWidth: 100 }}
            onClick={() =>
              append({
                weight: 0,
                price: 8,
                packaging: {
                  enable: false,
                  type: "",
                },
                reimbursement: {
                  enable: false,
                  amount: 0,
                  return_mode: "De main à main",
                },
              })
            }
          >
            COLIS
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ParcelForm;
