import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

export const myErrorHandler = (
  error: Error,
  info: { componentStack: string }
): void => {
  console.log({ error });
};

interface ErrorFallbackProps {
  error: any;
  resetErrorBoundary: any;
}

export const ErrorFallback = (props: ErrorFallbackProps): JSX.Element => {
  const { error } = props;
  const history = useHistory();

  return (
    <Container maxWidth="lg">
      <Box role="alert" sx={{ mt: 30 }}>
        <Stack direction={"row"} spacing={3} alignItems="center">
          <img
            className="img-fluid"
            src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/Oops.svg`}
            alt="logo"
            style={{ height: 350, width: 350 }}
          />{" "}
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={{ fontSize: 40, fontWeight: 800, mb: 3 }}>
              Quelque chose s'est mal passé
            </Typography>
            {/* <Typography>{error.message}</Typography> */}
            <Button
              startIcon={<ArrowBackIosNewIcon />}
              onClick={() => {
                history.goBack();
                // resetErrorBoundary()
              }}
            >
              Retour
            </Button>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};
