import httpClient from "../../httpClient";
import { CityModel } from "../../models/shared/cityModel";
import { PostOfficeModel } from "../../models/shared/postOfficeModel";

interface PaginationResponseModel {
  data: { data: CityModel[] | []; total: number; current_page: number };
}

const fetch = async (params: {
  search?: string;
  per_page?: number;
  page?: number;
}): Promise<PaginationResponseModel> => {
  const { search = undefined, per_page = undefined, page = undefined } = params;

  return httpClient.get(`settings/cities`, {
    params: {
      ...(() => (page ? { page } : {}))(),
      ...(() => (per_page ? { per_page } : {}))(),
      ...(() => (search ? { search } : {}))(),
    },
  });
};

const postOfficesOfCity = async (
  cityId: string
): Promise<PostOfficeModel[] | []> => {
  return httpClient
    .get(`settings/cities/${cityId}/post_offices`)
    .then(({ data: { data } }) => data);
};

export const cityService = {
  fetch,
  postOfficesOfCity,
};
