// import socketio from "socket.io-client";
import Echo from "laravel-echo/dist/echo";
const socketio = require("socket.io-client");

const getSocketEcho = (access_token: string): Echo => {
  return new Echo({
    host: `${process.env.REACT_APP_SOCKET_ENDPOINT}`,
    broadcaster: "socket.io",
    client: socketio,
    encrypted: false,
    transports: ["websocket"],
    pingInterval: 1000,
    auth: {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    },
  });
};

export default getSocketEcho;
