import httpClient from "../../httpClient";
import { BagModel } from "../../models/shared/bagModel";
import { BagStatusEnumModel } from "../../models/shared/bagStatusModel";
import { DistrictShipmentsModel } from "../../models/shared/districtShipmentModel";
import { HttpResponseModel } from "../../models/shared/httpResponseModel";
import { OfficeModel } from "../../models/shared/officeModel";
import { OfficeShipmentsModel } from "../../models/shared/officeShipmentModel";
import { PaginationModel } from "../../models/shared/paginationModel";
import { ShipmentModel } from "../../models/shared/ShipmentModel";

export interface FetchBagsQueryParamsModel {
  pagination: PaginationModel;
  status?: BagStatusEnumModel[] | [];
  search?: string;
}

export interface BagsPaginationResponseModel {
  data: { data: BagModel[] | []; total: number; current_page: number };
}

export interface DistrictShipmentsResponseModel extends HttpResponseModel {
  data: DistrictShipmentsModel[] | [];
}

export interface OfficeShipmentsResponseModel extends HttpResponseModel {
  data: OfficeShipmentsModel[] | [];
}

export interface prepareBagsRequestParamsModel {
  district: {
    id: number;
  };
  bags: { shipments: string[] }[];
}

export interface prepareInnerBagsRequestParamsModel {
  office: {
    id: number;
  };
  bags: { shipments: string[] }[];
}

export interface prepareBagsResponseModel {
  data: BagModel[];
}

export interface ShipmentsResponseModel extends HttpResponseModel {
  data: ShipmentModel[] | [];
}

const fetch = async (
  params: FetchBagsQueryParamsModel
): Promise<BagsPaginationResponseModel> => {
  const { pagination, search = undefined, status = undefined } = params;

  return httpClient.get("dispatching/bags", {
    params: {
      ...pagination,
      ...(() => (status ? { status } : {}))(),
    },
  });
};

const fetchToReceivedBags =
  async (): Promise<DistrictShipmentsResponseModel> => {
    return httpClient.get("dispatching/bags/received");
  };

const fetchOfficeBagsToPrepare =
  async (): Promise<DistrictShipmentsResponseModel> => {
    return httpClient.get("dispatching/bags/prepare-office");
  };

const prepareOfficeBags = async (
  params: prepareBagsRequestParamsModel[]
): Promise<prepareBagsResponseModel> => {
  return httpClient.post("dispatching/bags/prepare-office", { ...params });
};

const fetchInnerBagsToPrepare =
  async (): Promise<OfficeShipmentsResponseModel> => {
    return httpClient.get("dispatching/bags/prepare-inner");
  };

const prepareInnerBags = async (
  params: prepareInnerBagsRequestParamsModel[]
): Promise<prepareBagsResponseModel> => {
  return httpClient.post("dispatching/bags/prepare-inner", { ...params });
};

const fetchOuterBagsToPrepare =
  async (): Promise<DistrictShipmentsResponseModel> => {
    return httpClient.get("dispatching/bags/prepare-outer");
  };

const prepareOuterBags = async (
  params: prepareBagsRequestParamsModel[]
): Promise<prepareBagsResponseModel> => {
  return httpClient.post("dispatching/bags/prepare-outer", { ...params });
};

const forwardBag = async (
  identifier: string
): Promise<prepareBagsResponseModel> => {
  return httpClient.get(`dispatching/bags/forward?identifier=${identifier}`);
};

const receiveBag = async (
  identifier: string
): Promise<prepareBagsResponseModel> => {
  return httpClient.get(`dispatching/bags/receive?identifier=${identifier}`);
};

const receiveBagInPlatform = async (
  identifier: string
): Promise<prepareBagsResponseModel> => {
  return httpClient.get(
    `dispatching/bags/receive-in-platform?identifier=${identifier}`
  );
};

const fetchBagsToSend = async (
  params: FetchBagsQueryParamsModel
): Promise<BagsPaginationResponseModel> => {
  const { pagination, search = undefined, status = undefined } = params;

  return httpClient.get("dispatching/bags/to-send", {
    params: {
      ...pagination,
      ...(() => (status ? { status } : {}))(),
    },
  });
};

const fetchBagsToReceive = async (
  params: FetchBagsQueryParamsModel
): Promise<BagsPaginationResponseModel> => {
  const { pagination, search = undefined, status = undefined } = params;

  return httpClient.get("dispatching/bags/to-receive", {
    params: {
      ...pagination,
      ...(() => (status ? { status } : {}))(),
    },
  });
};

const fetchBagsToReceiveFromPlatform = async (
  params: FetchBagsQueryParamsModel
): Promise<BagsPaginationResponseModel> => {
  const { pagination, search = undefined, status = undefined } = params;

  return httpClient.get("dispatching/bags/to-receive-from-platform", {
    params: {
      ...pagination,
      ...(() => (status ? { status } : {}))(),
    },
  });
};

const fetchMyTrip = async (): Promise<{ data: BagModel[] }> => {
  return httpClient.get("dispatching/bags/my-trip");
};

export const bagService = {
  fetchOfficeBagsToPrepare,
  prepareOfficeBags,

  fetchInnerBagsToPrepare,
  prepareInnerBags,

  fetchOuterBagsToPrepare,
  prepareOuterBags,

  fetch,
  fetchToReceivedBags,

  forwardBag,
  receiveBag,
  receiveBagInPlatform,

  fetchBagsToSend,
  fetchBagsToReceive,
  fetchBagsToReceiveFromPlatform,

  fetchMyTrip,
};
