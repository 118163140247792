import axios from "axios";
import { serialize } from "object-to-formdata";
import httpClient from "../../httpClient";
import { CustomerModel } from "../../models/shared/customerModel";
import { PaginationModel } from "../../models/shared/paginationModel";
import {
  FetchShipmentsResponseModel,
  ShipmentsFetchQueryModel,
} from "./dispatching.service";

const fetch = async (search: string): Promise<CustomerModel[] | []> => {
  return httpClient.get("dispatching/customers", {
    params: {
      ...(() => (search ? { q: search } : {}))(),
    },
  });
};

const fetchPagination = async (params: {
  pagination: PaginationModel;
  search?: string;
}): Promise<CustomerModel[] | []> => {
  const { pagination, search = undefined } = params;

  return httpClient.get("settings/customers", {
    params: {
      ...pagination,
      ...(() => (search ? { search } : {}))(),
    },
  });
};

const remove = async (id: number): Promise<any> => {
  return httpClient.delete(`settings/customers/${id}`);
};

const create = async (data: CustomerModel): Promise<CustomerModel> => {
  const formData = serialize(data, {
    booleansAsIntegers: true,
    indices: true,
  });

  return httpClient.post("settings/customers", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const update = async (data: CustomerModel): Promise<CustomerModel> => {
  const { id, ...other } = data;

  const formData = serialize(other, {
    booleansAsIntegers: true,
    indices: true,
  });

  // formData.append("_method", "PUT");

  return httpClient.patch(`settings/customers/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const findOne = async (id: number): Promise<CustomerModel> => {
  return httpClient
    .get(`settings/customers/${id}`)
    .then(({ data }) => data)
    .then((response) => responseApiToCustomerModel(response));
};

export type ShipmentsCustomerQueryModel = ShipmentsFetchQueryModel & {
  id: number;
};

const fetchShipments = async (
  params: ShipmentsCustomerQueryModel & {
    start_date: string | null;
    end_date: string | null;
  }
): Promise<FetchShipmentsResponseModel> => {
  const {
    id,
    pagination,
    search = undefined,
    bar_code = undefined,
    status = undefined,
    start_date = null,
    end_date = null,
  } = params;
  return httpClient.get(`dispatching/shipments/customer/${id}`, {
    params: {
      ...pagination,
      ...(() => (status ? { status } : {}))(),
      ...(() => (bar_code ? { bar_code } : {}))(),
      ...(() => (start_date ? { start_date } : {}))(),
      ...(() => (end_date ? { end_date } : {}))(),
    },
  });
};

const responseApiToCustomerModel = (response: any): CustomerModel => {
  return {
    id: response?.id,
    uuid: response?.id,
    first_name: response?.first_name,
    last_name: response?.last_name,
    phone_number: response?.phone_number,
    second_phone_number: response?.second_phone_number,
    address: response?.address,
    pricing: response?.pricing,
    account_type: response?.account_type,
    account: {
      email: response?.account?.email,
      is_company: response?.account?.is_company,
      tax_registry_number: response?.account?.tax_registry_number,
    },
    company_identity_file:
      response?.account?.company_identity_file || undefined,
    personal_identity_file:
      response?.account?.personal_identity_file || undefined,
  };
};

export const customerService = {
  fetch,
  fetchPagination,
  remove,
  create,
  findOne,
  update,
  fetchShipments,
};
