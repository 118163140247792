import { Alert, Grid, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import { ReimbursementInCtrlItem } from "../../pages_components/reimbursement/reimbursementInCtrlItem";
import { ReimbursementOutCtrlItem } from "../../pages_components/reimbursement/reimbursementOutCtrlItem";
import SenderCard from "../../pages_components/reimbursement/senderCard";
import { useReimbursementOutStore } from "./hooks/reimbursementOutState";

const ReimbursementOutPage: React.FC<PagePropsModel> = (): JSX.Element => {
  const { isLoading, data, fetch } = useReimbursementOutStore();

  useEffect(() => {
    fetch();
  }, []);

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "CRB: Sortants",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="CRBT" />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      <Grid item>
        {isLoading === false && data.length === 0 && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="info">Aucune donnée disponible!</Alert>
          </Box>
        )}

        {isLoading && <LinearProgress />}

        <Grid container direction="column" spacing={3}>
          {data.map((item, index) => {
            return (
              <Grid item key={index}>
                <Grid container spacing={1}>
                  <Grid item container md={3} xs={12}>
                    <SenderCard data={item} />
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <Grid container direction="column" spacing={1}>
                      {(item.reimbursements || []).map(
                        (itemReimbursement, idx) => (
                          <Grid item key={idx}>
                            <ReimbursementOutCtrlItem
                              itemReimbursement={itemReimbursement}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReimbursementOutPage;
