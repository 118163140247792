import { FC, useEffect, useState } from "react";
import { globalStateService } from "../../application/api/services/globalState.service";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Typography } from "@mui/material";
import { FundModel } from "../../application/models/shared/fundModel";
import { priceFormat } from "../../application/helpers/priceFormat";
import { useHistory } from "react-router-dom";
import { FUNDS_MINE_ROUTE } from "../../application/constants/routeConstants";

interface FundsMineCardStateModel {
  isLoading: boolean;
  isError: boolean;
  data: FundModel[] | [];
}

export const FundsMineCard: FC = (): JSX.Element => {
  const history = useHistory();
  const [state, setState] = useState<FundsMineCardStateModel>({
    isLoading: false,
    isError: false,
    data: [],
  });

  const sumPriceReduce = (acc: number, cur: FundModel): number => {
    const curCRBT = cur.shipment.price;
    return acc + curCRBT;
  };

  const sumPrice = (items: FundModel[]): number => {
    return (items as FundModel[]).reduce(sumPriceReduce, 0);
  };

  useEffect(() => {
    setState((state) => ({ ...state, isLoading: true, isError: false }));
    globalStateService
      .fetchFundsMine()
      .then((response) => {
        setState((state) => ({
          ...state,
          isLoading: false,
          isError: false,
          data: response.data.funds,
        }));
      })
      .catch((error) => {
        setState((state) => ({ ...state, isLoading: false, isError: true }));
      });
  }, []);

  return (
    <Card
      sx={{
        display: "flex",
        p: 2,
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography style={{ marginBottom: 20, fontWeight: 500 }}>
            Ma caisse
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            sx={{ cursor: "pointer" }}
            onClick={() => history.push(FUNDS_MINE_ROUTE)}
          >
            {state.data.length} colis
          </Typography>
        </CardContent>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ fontSize: 24, fontWeight: 600 }}>
            {priceFormat(sumPrice(state.data))}
          </Typography>
        </Box>
      </Box>
      <CardMedia
        component="img"
        sx={{ maxWidth: 151 }}
        image={"/assets/icons/Wallet-amico.svg"}
        alt="Live from space album cover"
      />
    </Card>
  );
};
