import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { BagModel } from "../../../application/models/shared/bagModel";
import { BarCode } from "../../../components/barCode";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import QRCode from "qrcode.react";

export const BagBarcodePrint: React.FC<BagModel> = (props): JSX.Element => {
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Button startIcon={<PrintOutlinedIcon />} onClick={handlePrint}>
        Imprimer
      </Button>
      <div ref={componentRef}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            {" "}
            <BagBarcode {...props} />{" "}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export const BagBarcode: React.FC<BagModel> = (props): JSX.Element => {
  return (
    <Box
      sx={{
        maxWidth: 384,
        maxHeight: 384,
        p: 1,
        m: 0,
        overflow: "hidden",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        spacing={1}
        sx={{ width: 379 }}
      >
        <Grid item>
          <Grid
            container
            alignItems="stretch"
            spacing={1}
            sx={{ height: "100%" }}
          >
            <Grid item xs={5}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 200,
                  border: "solid 1px #000",
                  p: 2,
                }}
              >
                <Stack
                  direction={"column"}
                  justifyContent="space-between"
                  sx={{ height: "100%" }}
                >
                  <img
                    className="img-fluid"
                    src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/logo_grey.png`}
                    alt="logo"
                    style={{
                      height: 30,
                      width: 130,
                      // transform: "rotate(45deg)",
                    }}
                  />
                  {props.shipments && (
                    <Typography>
                      <b>{props?.shipments.length}</b> colis
                    </Typography>
                  )}
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Box
                sx={{
                  border: "solid 1px #000",
                  p: 2,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <QRCode value={props.identifier} />,
                <Typography variant="body2" sx={{ fontSize: 10 }}>
                  {props.identifier}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid
            container
            alignItems="stretch"
            spacing={1}
            sx={{ height: "100%" }}
          >
            <Grid item xs={12}>
              <Box sx={{ border: "solid 1px #000", p: 1, height: "100%" }}>
                <Grid container direction="column">
                  {/* <Grid item>
                    <Typography sx={{ fontWeight: 500 }} variant="body2">
                      Expéditeur
                    </Typography>
                  </Grid> */}
                  <Grid item>
                    <Typography variant="body2" sx={{ fontSize: 11 }}>
                      <b>De :</b> {props.sender.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" sx={{ fontSize: 11 }}>
                      {props.sender.address.address_line} /{" "}
                      {props.sender.address.post_office.zip_code}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ border: "solid 1px #000", p: 1, height: "100%" }}>
                <Grid container direction="column">
                  {/* <Grid item>
                    <Typography sx={{ fontWeight: 500 }} variant="body2">
                      Destinataire
                    </Typography>
                  </Grid> */}
                  <Grid item>
                    <Typography variant="body2" sx={{ fontSize: 11 }}>
                      <b>À :</b> {props.recipient.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" sx={{ fontSize: 11 }}>
                      {props.recipient.address.address_line} /{" "}
                      {props.recipient.address.post_office.zip_code}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item>
          <Box sx={{ border: "solid 1px #E0E0E0", p: 2 }}>
            <Typography variant="body2" noWrap>
              <b>Date :</b> {packageItem.received_at}
            </Typography>
            <Typography variant="body2" noWrap>
              <b> Poids :</b> {packageItem.weight} Kg
            </Typography>
          </Box>
        </Grid> */}
        {/* <Grid item>
          <Box
            sx={{
              border: "solid 1px #000",
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <QRCode value="http://facebook.github.io/react/" />,
           <BarCode value={props.identifier} /> 
          </Box>
        </Grid> */}
      </Grid>
    </Box>
  );
};
