import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { reimbursementService } from "../../../../application/api/services/reimbursement.service";
import { priceFormat } from "../../../../application/helpers/priceFormat";
import { ShipmentModel } from "../../../../application/models/shared/ShipmentModel";
import { DropzoneFile } from "../../../../components/dropzoneFile";
import { SnackbarContext } from "../../../../components/snackbarProvider";
import { useReimbursementOutStore } from "../../../../pages/reimbursement/hooks/reimbursementOutState";

const InputTextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .Mui-error": {
    border: "1px solid red",
  },
  "& .MuiInputBase-input": {
    borderRadius: theme.shape.borderRadius,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #000",
    fontSize: 16,
    width: "auto",
    height: 16.5,
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export interface ReimbursementFormModel {
  identifier: string;
  code: string;
  check_number: string;
  check_picture?: File;
}

interface StateModel {
  isLoading: boolean;
  error: string;
  openDialog: boolean;
  formValues: ReimbursementFormModel | null;
}

const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const schema = yup.object().shape({
  identifier: yup.string().required(),
  code: yup.string().required(),
  check_number: yup.string().required(),
  check_picture: yup
    .mixed()
    .required("L'image est obligatoire")
    .test(
      "fileSize",
      "Fichier trop large",
      (value) => value && value.size <= FILE_SIZE
    )
    .test(
      "fileFormat",
      "Format non supporté",
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),
});

const ReimbursementForm: FC<{ shipment: ShipmentModel }> = ({
  shipment,
}): JSX.Element => {
  const { fetch } = useReimbursementOutStore();

  const [state, setState] = useState<StateModel>({
    isLoading: false,
    error: "",
    openDialog: false,
    formValues: null,
  });

  const [snackbar, setSnackbar] = React.useContext(SnackbarContext);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ReimbursementFormModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      identifier: `${shipment.identifier}`,
      code: "",
      check_number: "",
      check_picture: undefined,
    },
  });

  const formValues = watch();

  useEffect(() => {
    setValue("identifier", shipment.identifier);
  }, [shipment]);

  const onSubmit = (data: ReimbursementFormModel) => {
    if (
      state.formValues === null &&
      shipment.reimbursement.enable &&
      shipment.reimbursement.amount > 0
    ) {
      setState((state) => ({
        ...state,
        isLoading: false,
        error: "",
        formValues: data,
        openDialog: true,
      }));
    } else {
      setReimbursementDelivery(data);
    }
  };

  const setReimbursementDelivery = (data: ReimbursementFormModel) => {
    if (data) {
      // setState((state) => ({
      //   ...state,
      //   isLoading: true,
      //   error: "",
      //   openDialog: false,
      // }));
      reimbursementService
        .reimbursementDeliver(data)
        .then((response) => {
          setSnackbar({
            open: true,
            severity: "success",
            message: "Opération effectuée avec succes",
          });
          setValue("code", "");
          setState((state) => ({
            ...state,
            isLoading: false,
            error: "",
            formValues: null,
          }));
          fetch();
        })
        .catch((error) => {
          setState((state) => ({
            ...state,
            isLoading: false,
            formValues: null,
            error: String(error),
          }));
        });
    }
  };

  if (state.openDialog && state.formValues !== null) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        spacing={0}
      >
        <Grid item>
          <Typography sx={{ mr: 3, fontWeight: 500, fontSize: 14 }}>
            Êtes-vous sûre de bien vouloir rembourser{" "}
            <span style={{ fontSize: 14, color: "red" }}>
              {priceFormat(shipment.reimbursement.amount)}
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="success"
            type="button"
            onClick={() => {
              if (state.formValues !== null) {
                setReimbursementDelivery(state.formValues);
              }
            }}
          >
            Oui
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="error"
            onClick={() => {
              setState((state) => ({
                ...state,
                openDialog: false,
                formValues: null,
              }));
            }}
          >
            Non
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        spacing={0}
      >
        {state.error && (
          <Grid item xs={12}>
            <Box sx={{ mb: 3 }}>
              <Alert severity="error">
                Une erreur s'est produite lors de l'enregistrement : Merci de
                verifier le code confidentiel
              </Alert>
            </Box>
          </Grid>
        )}
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    label="Code confidentiel"
                    {...field}
                    size="small"
                    fullWidth
                    error={Boolean(errors?.code)}
                    // helperText={get(errors, ["code"], null)}
                  />
                )}
                name="code"
                control={control}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    label="Numéro de chéque"
                    {...field}
                    size="small"
                    fullWidth
                    error={Boolean(errors?.check_number)}
                    //  helperText={get(errors, ["check_number"], null)}
                  />
                )}
                name="check_number"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <DropzoneFile
                    file={field?.value || undefined}
                    onChange={(file) => field.onChange(file)}
                  />
                )}
                name="check_picture"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} alignItems="center" spacing={2}>
                <Box sx={{ flexGrow: 1 }}>
                  {errors?.check_picture?.message && (
                    <Typography sx={{ color: "red" }} align="right">
                      {errors.check_picture.message}
                    </Typography>
                  )}
                </Box>

                <Box sx={{ position: "relative" }}>
                  <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    type="submit"
                    // fullWidth
                    disabled={state.isLoading || Object.keys(errors).length > 0}
                    sx={{
                      height: 38.84,
                    }}
                  >
                    Sauvegarder
                  </Button>
                  {state.isLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ReimbursementForm;
