import { ShipmentModel } from "./../../models/shared/ShipmentModel";
import { isUndefined } from "lodash";
import { useQuery, UseQueryResult } from "react-query";

import {
  dispatchingService,
  FetchShipmentsResponseModel,
  ReceptionResponseModel,
  ShipmentsFetchQueryModel,
} from "../services/dispatching.service";
import { FailureReasonModel } from "../../models/shared/failureReasonModel";
import { ReceptionFormModel } from "../../models/forms/recptionFormModel";

export const useReceptionQuery = (
  data?: ReceptionFormModel
): UseQueryResult<ReceptionResponseModel> => {
  return useQuery<ReceptionResponseModel>({
    queryKey: ["createReception-query", JSON.stringify(data)].join("-"),
    queryFn: () => {
      return !isUndefined(data)
        ? dispatchingService.reception(data)
        : Promise.reject();
    },
    enabled: !isUndefined(data),
    refetchOnWindowFocus: false,
  });
};

export const useFetchShipmentsQuery = (
  params: ShipmentsFetchQueryModel
): UseQueryResult<FetchShipmentsResponseModel> => {
  return useQuery<FetchShipmentsResponseModel>({
    queryKey: ["fetchShipments-query", JSON.stringify(params)].join("-"),
    queryFn: () => dispatchingService.fetch(params),
    // enabled: !isUndefined(data),
    // refetchInterval: 3000,
  });
};

export const useFetchVerifyShipmentsQuery = (
  params: ShipmentsFetchQueryModel
): UseQueryResult<FetchShipmentsResponseModel> => {
  return useQuery<FetchShipmentsResponseModel>({
    queryKey: ["fetchShipments-query", JSON.stringify(params)].join("-"),
    queryFn: () => dispatchingService.fetchVerify(params),
    // enabled: !isUndefined(data),
    // refetchInterval: 3000,
  });
};

export const useFetchReadyDeliveryShipmentsQuery = (
  params: ShipmentsFetchQueryModel
): UseQueryResult<FetchShipmentsResponseModel> => {
  return useQuery<FetchShipmentsResponseModel>({
    queryKey: ["fetchShipments-query", JSON.stringify(params)].join("-"),
    queryFn: () => dispatchingService.readyDeliveryShipments(params),
    refetchOnWindowFocus: false,
  });
};

export const useFindOneShipmentQuery = (
  id: string
): UseQueryResult<ShipmentModel> => {
  return useQuery<ShipmentModel>({
    queryKey: ["findOne-query", id].join("-"),
    queryFn: () => {
      return !isUndefined(id)
        ? dispatchingService.findOneShipment(id)
        : Promise.reject();
    },
    enabled: !isUndefined(id),
  });
};

export const useFetchTripShipmentsQuery = (): UseQueryResult<{
  data: ShipmentModel[];
}> => {
  return useQuery<{ data: ShipmentModel[] }>({
    queryKey: ["fetchTripShipments-query"].join("-"),
    queryFn: () => dispatchingService.tripShipments(),
  });
};

export const useFetchFailureReasonsQuery = (): UseQueryResult<
  FailureReasonModel[]
> => {
  return useQuery<FailureReasonModel[]>({
    queryKey: ["fetchFailureReasons-query"].join("-"),
    queryFn: () => dispatchingService.fetchFailureReasons(),
    refetchOnWindowFocus: false,
  });
};
