import { postOfficeService } from "./../api/services/postOffice.service";
import { cityService } from "./../api/services/city.service";
import { governorateService } from "./../api/services/governorate.service";
import { PostOfficeModel } from "./../models/shared/postOfficeModel";
import { CityModel } from "./../models/shared/cityModel";
import { GovernorateModel } from "./../models/shared/governorateModel";
import { useState, useEffect } from "react";

export interface AddressStateModel {
  governorates: GovernorateModel[];
  cities: CityModel[];
  postOffices: PostOfficeModel[];
  governorate: GovernorateModel | null;
  city: CityModel | null;

  fetchGovernoratesIsLoading: boolean;
  fetchCitiesIsLoading: boolean;
  fetchPostOfficesIsLoading: boolean;
}

export interface AddressStatePayloadModel {
  cities: CityModel[];
  postOffices: PostOfficeModel[];
  fetchCities: (governorateId: string) => void;
  fetchPostOffices: (cityId: string) => void;
  searchPostOffices: (searchTerm: string) => void;
  governorate: GovernorateModel | null;
  city: CityModel | null;
  handleChangeGovernorate: (value: GovernorateModel | null) => void;
  handleChangeCity: (value: CityModel | null) => void;
  fetchGovernoratesIsLoading: boolean;
  fetchCitiesIsLoading: boolean;
  fetchPostOfficesIsLoading: boolean;
}

const useAddress = (
  governorates: GovernorateModel[]
): AddressStatePayloadModel => {
  const [state, setState] = useState<AddressStateModel>({
    governorates: [],
    cities: [],
    postOffices: [],
    governorate: null,
    city: null,
    fetchGovernoratesIsLoading: true,
    fetchCitiesIsLoading: false,
    fetchPostOfficesIsLoading: false,
  });

  useEffect(() => {
    console.log("nouvelle instance");
    setState((state) => ({
      ...state,
      governorates,
      fetchGovernoratesIsLoading: false,
    }));
  }, []);

  useEffect(() => {
    if (state.governorate) {
      fetchCities(String(state.governorate?.id));
    }
  }, [state.governorate]);

  useEffect(() => {
    if (state.city) {
      fetchPostOffices(String(state.city?.id));
    }
  }, [state.city]);

  const fetchCities = (governorateId: string) => {
    setState((state) => ({
      ...state,
      fetchCitiesIsLoading: true,
    }));
    governorateService
      .fetchCitiesOfGovernorate(governorateId)
      .then((response) => {
        setState((state) => ({
          ...state,
          cities: response,
          fetchCitiesIsLoading: false,
        }));
      })
      .catch((error) => console.log({ error }));
  };

  const fetchPostOffices = (cityId: string) => {
    setState((state) => ({
      ...state,
      fetchPostOfficesIsLoading: true,
    }));
    cityService
      .postOfficesOfCity(cityId)
      .then((response) => {
        setState((state) => ({
          ...state,
          postOffices: response,
          fetchPostOfficesIsLoading: false,
        }));
      })
      .catch((error) => console.log({ error }));
  };

  const searchPostOffices = (searchTerm: string) => {
    postOfficeService
      .fetch({ search: searchTerm, per_page: 20, page: 1 })
      .then(({ data: { data } }) => {
        setState((state) => ({
          ...state,
          postOffices: data,
        }));
      })
      .catch((error) => console.log({ error }));
  };

  const handleChangeGovernorate = (value: GovernorateModel | null) => {
    setState((state) => ({
      ...state,
      governorate: value,
      cities: [],
      postOffices: [],
      city: null,
    }));
  };

  const handleChangeCity = (value: CityModel | null) => {
    setState((state) => ({
      ...state,
      city: value,
      postOffices: [],
    }));
  };

  return {
    cities: state.cities,
    postOffices: state.postOffices,
    fetchCities,
    fetchPostOffices,
    searchPostOffices,
    governorate: state.governorate,
    city: state.city,
    handleChangeGovernorate,
    handleChangeCity,
    fetchGovernoratesIsLoading: state.fetchGovernoratesIsLoading,
    fetchCitiesIsLoading: state.fetchCitiesIsLoading,
    fetchPostOfficesIsLoading: state.fetchPostOfficesIsLoading,
  };
};

export default useAddress;
