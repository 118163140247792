import create from "zustand";
import { globalStateService } from "../api/services/globalState.service";
import {
  initNotification,
  InitNotificationModel,
  NotificationsModel,
} from "./models";

interface NotificationsStateModel {
  notifications: InitNotificationModel;
  update: (data: NotificationsModel) => void;
  refresh: () => void;
}

export const useNotificationsStore = create<NotificationsStateModel>(
  (set, get) => ({
    notifications: initNotification,
    refresh: () => {
      globalStateService.fetch().then(({ data }) => {
        get().update(data);
      });
    },
    update: (data: NotificationsModel) => {
      const comingNotifications = (
        Object.keys(data) as Array<keyof typeof data>
      ).reduce((acc, cur) => {
        return {
          ...acc,
          [cur]: {
            ...initNotification[cur],
            count: data[cur],
          },
        };
      }, {});

      set((state) => ({
        ...state,
        notifications: {
          ...state.notifications,
          ...comingNotifications,
        },
      }));
    },
  })
);
