import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { ChangePasswordFormModel } from "./models";

const ChangePasswordForm: FC<{
  defaultValues?: ChangePasswordFormModel;
  loading?: boolean;
  onValide: (data: ChangePasswordFormModel) => void;
}> = (props) => {
  const schema = yup.object().shape({
    old_password: yup.string().required(),
    new_password: yup
      .string()
      .min(
        6,
        "Le mot de passe est trop court - doit comporter au moins 6 caractères."
      )
      .required(),
    new_password_confirmation: yup
      .string()
      .oneOf([yup.ref("new_password"), null], "Passwords must match"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ChangePasswordFormModel>({
    resolver: yupResolver(schema),
    defaultValues: props?.defaultValues || {
      old_password: "",
      new_password: "",
      new_password_confirmation: "",
    },
  });

  const onSubmit = (data: any) => {
    props.onValide(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography sx={{ fontWeight: 500 }}>Modification</Typography>
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12} md={6}>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Controller
                    name="old_password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="new-password"
                        fullWidth
                        type="password"
                        label="Ancien mot de passe"
                        {...field}
                        error={Boolean(errors?.old_password)}
                        helperText={
                          errors?.old_password
                            ? errors?.old_password.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="new_password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="new-password"
                        fullWidth
                        type="password"
                        label="Nouveau mot de passe"
                        {...field}
                        error={Boolean(errors?.new_password)}
                        helperText={
                          errors?.new_password
                            ? errors?.new_password.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="new_password_confirmation"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="new-password"
                        fullWidth
                        type="password"
                        label="Confirmation du mot de passe"
                        {...field}
                        error={Boolean(errors?.new_password_confirmation)}
                        helperText={
                          errors?.new_password_confirmation
                            ? errors?.new_password_confirmation.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item></Grid>
            <Grid item>
              <Box sx={{ mt: 1, position: "relative" }}>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={props?.loading}
                >
                  Sauvegarder
                </Button>
                {props?.loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ChangePasswordForm;
