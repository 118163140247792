import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, SyntheticEvent, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import useAddress from "../../../../application/hooks/useAddress";
import { CityModel } from "../../../../application/models/shared/cityModel";
import { CustomerModel } from "../../../../application/models/shared/customerModel";
import { GovernorateModel } from "../../../../application/models/shared/governorateModel";
import { PostOfficeModel } from "../../../../application/models/shared/postOfficeModel";
import { PhoneInputMask } from "../../../../components/InputPhone";
import { CustomerSearchForm } from "../../../offices/forms/customerSearchForm";
import { useReceptionController } from "../receptionController";

const SenderForm: FC<{
  resetSenderForm: () => void;
  handleSlectSender: (customer: CustomerModel) => void;
}> = (props) => {
  const { governorates } = useReceptionController();
  const {
    cities,
    postOffices,
    governorate,
    city,
    fetchGovernoratesIsLoading,
    fetchCitiesIsLoading,
    fetchPostOfficesIsLoading,
    handleChangeGovernorate,
    handleChangeCity,
  } = useAddress(governorates);

  const [open, setOpen] = useState<boolean | undefined>(false);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const formValues = watch();

  const disabledInputs = Boolean(formValues.sender?.id);

  return (
    <>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography sx={{ fontWeight: 500 }}>Expéditeur</Typography>
            </Grid>
            <Grid item>
              <Stack direction="row" alignItems={"center"} spacing={2}>
                {formValues.sender?.id !== undefined && (
                  <IconButton
                    aria-label="delete"
                    color="error"
                    size="small"
                    onClick={() => {
                      props.resetSenderForm();
                    }}
                    sx={{ float: "right", minWidth: 40 }}
                  >
                    <LinkOffIcon />
                  </IconButton>
                )}

                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => setOpen(true)}
                  sx={{ float: "right", minWidth: 40 }}
                >
                  <SearchIcon />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12} md={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="sender.first_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        disabled={disabledInputs}
                        fullWidth
                        label="Nom"
                        size="small"
                        {...field}
                        autoComplete="new-password"
                        error={Boolean(errors?.sender?.first_name)}
                        helperText={
                          errors?.sender?.first_name
                            ? errors?.sender?.first_name.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="sender.last_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        disabled={disabledInputs}
                        fullWidth
                        label="Prenom"
                        size="small"
                        {...field}
                        autoComplete="new-password"
                        error={Boolean(errors?.sender?.last_name)}
                        helperText={
                          errors?.sender?.last_name
                            ? errors?.sender?.last_name.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="sender.phone_number"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        disabled={disabledInputs}
                        fullWidth
                        label="Téléphone"
                        size="small"
                        {...field}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: PhoneInputMask as any,
                        }}
                        autoComplete="new-password"
                        error={Boolean(errors?.sender?.phone_number)}
                        helperText={
                          errors?.sender?.phone_number
                            ? errors?.sender?.phone_number.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="sender.address.address_line"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        disabled={disabledInputs}
                        label="Adresse"
                        size="small"
                        {...field}
                        error={Boolean(errors?.sender?.address?.address_line)}
                        helperText={
                          errors?.sender?.address?.address_line
                            ? errors?.sender?.address?.address_line?.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    disabled={disabledInputs}
                    noOptionsText=""
                    fullWidth={true}
                    size="small"
                    popupIcon={<KeyboardArrowDownIcon />}
                    id="governorates"
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    // onInputChange={(event, newInputValue) => {
                    //   fetchPostOfficesOptions(newInputValue);
                    // }}
                    getOptionLabel={(option) => `${option.name}`}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > *": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {`${option.name}`}
                      </Box>
                    )}
                    options={governorates}
                    value={governorate || null}
                    onChange={(
                      event: SyntheticEvent<Element, Event>,
                      newValue: GovernorateModel | null
                    ) => {
                      event.preventDefault();
                      handleChangeGovernorate(newValue);
                      setValue("sender.address.post_office", null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        autoComplete="new-password"
                        label="Governorat"
                        // error={Boolean(errors?.sender?.address?.post_office)}
                        // helperText={
                        //   errors?.sender?.address?.post_office
                        //     ? errors?.sender?.address?.post_office?.message
                        //     : null
                        // }
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Autocomplete
                    loading={fetchCitiesIsLoading}
                    disabled={disabledInputs}
                    noOptionsText=""
                    fullWidth={true}
                    size="small"
                    popupIcon={<KeyboardArrowDownIcon />}
                    id="cities"
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    // onInputChange={(event, newInputValue) => {
                    //   fetchPostOfficesOptions(newInputValue);
                    // }}
                    getOptionLabel={(option) => `${option.name}`}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > *": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {`${option.name}`}
                      </Box>
                    )}
                    options={cities}
                    value={city || null}
                    onChange={(
                      event: SyntheticEvent<Element, Event>,
                      newValue: CityModel | null
                    ) => {
                      event.preventDefault();
                      handleChangeCity(newValue);
                      setValue("sender.address.post_office", null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        autoComplete="new-password"
                        label="Ville"
                        // error={Boolean(errors?.sender?.address?.post_office)}
                        // helperText={
                        //   errors?.sender?.address?.post_office
                        //     ? errors?.sender?.address?.post_office?.message
                        //     : null
                        // }
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {fetchCitiesIsLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Controller
                    name="sender.address.post_office"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        loading={fetchPostOfficesIsLoading}
                        disabled={disabledInputs}
                        noOptionsText=""
                        fullWidth={true}
                        size="small"
                        popupIcon={<KeyboardArrowDownIcon />}
                        id="role"
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        // onInputChange={(event, newInputValue) => {
                        //   fetchPostOfficesOptions(newInputValue);
                        // }}
                        getOptionLabel={(option) =>
                          `${option.name}-${option.zip_code}`
                        }
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > *": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {`${option.name}-${option.zip_code}`}
                          </Box>
                        )}
                        groupBy={(option) => option.zip_code}
                        options={postOffices}
                        {...field}
                        value={field.value || null}
                        onChange={(
                          event: SyntheticEvent<Element, Event>,
                          newValue: PostOfficeModel | null
                        ) => {
                          event.preventDefault();
                          field.onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            autoComplete="new-password"
                            label="Adresse postale"
                            error={Boolean(
                              errors?.sender?.address?.post_office
                            )}
                            helperText={
                              errors?.sender?.address?.post_office
                                ? errors?.sender?.address?.post_office?.message
                                : null
                            }
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "off",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {fetchPostOfficesIsLoading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Drawer
        anchor={"right"}
        sx={{ marginTop: 40, maxHeight: 400 }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div style={{ minWidth: 400 }}>
          <Toolbar>
            <Grid container spacing={1}>
              <Grid item>
                <SearchIcon />
              </Grid>
              <Grid item>
                <Typography sx={{ fontWeight: 500 }}>Expéditeur</Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <Divider />
          <Box sx={{ padding: 4 }}>
            <CustomerSearchForm
              selectCustomer={(selected) => {
                setOpen(false);
                props.handleSlectSender(selected);
              }}
            />
          </Box>
        </div>
      </Drawer>
    </>
  );
};

export default SenderForm;
