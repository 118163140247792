import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { priceFormat } from "../../application/helpers/priceFormat";
import { UserModel } from "../../application/models/shared/userModel";
import {
  EnumCurrentAction,
  useFundsOfficeStore,
} from "../../pages/funds/fundsOfficeState";

const FundOfficierCard: FC<{
  officer: UserModel;
  sumPrice: number;
  sumPriceChecked: number;
  totalItems: number;
}> = ({ officer, sumPrice, sumPriceChecked, totalItems }): JSX.Element => {
  const { isCheckedAll, control, isLoading, currentAction, currentItemId } =
    useFundsOfficeStore();

  const _isLoading =
    isLoading &&
    currentAction === EnumCurrentAction.SAVE &&
    currentItemId === officer.id;

  return (
    <Paper
      sx={{
        width: "100%",
        p: 3,
        height: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          borderLeft: "solid 5px #eee",
          p: 1,
          position: "sticky",
          top: 190,
        }}
      >
        <Grid container direction="column">
          <Grid item>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
              sx={{ mb: 2 }}
            >
              <Typography
                sx={{ fontWeight: 700, fontSize: 20 }}
                variant="body2"
              >
                Collaborateur
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row" alignItems="flex-end">
              <PersonOutlineOutlinedIcon fontSize="small" />
              <Typography variant="body2" sx={{ fontSize: 14 }}>
                {officer.first_name} {officer.last_name}
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row" alignItems="flex-end">
              <LocalPhoneOutlinedIcon fontSize="small" />
              <Typography variant="body2" sx={{ fontSize: 14 }}>
                {officer.phone_number}
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row" alignItems="flex-end">
              <AlternateEmailOutlinedIcon fontSize="small" />
              <Typography variant="body2" sx={{ fontSize: 14 }}>
                {officer.email}
              </Typography>
            </Stack>
          </Grid>

          {totalItems > 1 && (
            <Grid item>
              <Stack spacing={2} direction="row" alignItems="flex-end">
                <LocalPostOfficeOutlinedIcon fontSize="small" />
                <Typography variant="body2" noWrap sx={{ fontSize: 14 }}>
                  {totalItems}
                  {" Colis"}
                </Typography>
              </Stack>
            </Grid>
          )}

          <Grid item>
            <Stack spacing={2} direction="row" alignItems="flex-end">
              <MonetizationOnOutlinedIcon fontSize="small" />
              <Typography
                variant="body2"
                color="red"
                noWrap
                sx={{ fontSize: 16, fontWeight: 900 }}
              >
                {priceFormat(sumPrice)}
              </Typography>
            </Stack>
          </Grid>
          <Divider flexItem sx={{ mt: 2, mb: 2 }} />
          <Grid item>
            <Button
              fullWidth
              variant="contained"
              disabled={sumPriceChecked === 0 || _isLoading}
              onClick={() => {
                control(Number(officer.id));
              }}
              startIcon={<SaveIcon />}
            >
              Reçu : {priceFormat(sumPriceChecked)}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default FundOfficierCard;
