import ExpandLess from "@mui/icons-material/ExpandLess";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import _ from "lodash";
import { FC, useState } from "react";
import { CityModel } from "../../../../application/models/shared/cityModel";
import { GovernorateModel } from "../../../../application/models/shared/governorateModel";
import { PostOfficeModel } from "../../../../application/models/shared/postOfficeModel";
import { useFetchPostOfficesOfCityQuery } from "../../../../application/queryHooks/useCityQuery";
import If from "../../../../components/conditionalViwer";
import { PostOfficesList } from "./postOfficesList";
import { SkeletonListItems } from "./skeletonListItems";

export const CitiesList: FC<{
  cities: CityModel[] | [];
  governorate: GovernorateModel;
}> = ({ cities, governorate }) => {
  return (
    <List component="div" disablePadding dense={true}>
      {(cities || []).map((city, index) => (
        <ListItemCity
          key={`city_${city.id}`}
          governorate={governorate}
          city={city}
        />
      ))}
    </List>
  );
};

const ListItemCity: FC<{
  city: CityModel;
  governorate: GovernorateModel;
}> = (props) => {
  const { city, governorate } = props;

  const [expand, setExpand] = useState<boolean>(false);

  const postOfficesOfCityQuery = useFetchPostOfficesOfCityQuery(
    expand ? String(city.id) : ""
  );
  const postOffices = _.get(
    postOfficesOfCityQuery,
    ["data", "data", "data"],
    []
  ) as PostOfficeModel[];

  return (
    <>
      <ListItem sx={{ pl: 4 }} disablePadding>
        <ListItemButton role={undefined} dense>
          <ListItemIcon>
            <IconButton
              size="small"
              edge="end"
              aria-label="comments"
              onClick={(event) => {
                event.stopPropagation();
                if (city.affected === false) {
                  setExpand((expand) => !expand);
                }
              }}
            >
              {expand ? <ExpandLess /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </ListItemIcon>
          <ListItemText id={String(city.id)} primary={`${city.name}`} />
        </ListItemButton>
      </ListItem>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <If
          condition={postOfficesOfCityQuery.isLoading}
          render={() => (
            <SkeletonListItems pl={8} prefixKey={`postOfficesOf${city.id}`} />
          )}
          otherwise={() => (
            <PostOfficesList
              postOffices={postOffices}
              city={city}
              governorate={governorate}
            />
          )}
        />
      </Collapse>
    </>
  );
};
