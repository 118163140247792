import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { RoleModel } from "../../../../application/models/shared/roleModel";
import { UserEditeFormModel } from "./models";

const UserEditeForm: FC<{
  defaultValues?: UserEditeFormModel;
  loading?: boolean;
  onValide: (data: UserEditeFormModel) => void;
  roleOptions: RoleModel[] | [];
}> = (props) => {
  const schema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    phone_number: yup.string().required(),
    email: yup.string().email().required("Le champs email est obligatoire"),
  });

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<UserEditeFormModel>({
    resolver: yupResolver(schema),
    defaultValues: props?.defaultValues || {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      roles: [],
    },
  });

  // const roleOptions = Object.values(RoleModel).map((item) => item);

  const onSubmit = (data: any) => {
    props.onValide(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography sx={{ fontWeight: 500 }}>Modification</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Controller
                    name="first_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="new-password"
                        fullWidth
                        label="Nom"
                        {...field}
                        error={Boolean(errors?.first_name)}
                        helperText={
                          errors?.first_name ? errors?.first_name.message : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="new-password"
                        fullWidth
                        label="Prenom"
                        {...field}
                        error={Boolean(errors?.last_name)}
                        helperText={
                          errors?.last_name ? errors?.last_name.message : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="phone_number"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="new-password"
                        fullWidth
                        label="Téléphone"
                        {...field}
                        error={Boolean(errors?.phone_number)}
                        helperText={
                          errors?.phone_number
                            ? errors?.phone_number.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="new-password"
                        fullWidth
                        label="E-mail"
                        {...field}
                        error={Boolean(errors?.email)}
                        helperText={
                          errors?.email ? errors?.email.message : null
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              {props?.roleOptions && props?.roleOptions.length > 0 && (
                <Box sx={{ pl: 4, borderLeft: "solid 5px #eee" }}>
                  <Typography sx={{ fontWeight: 800 }}>Roles :</Typography>
                  <Controller
                    name={`roles`}
                    control={control}
                    render={({ field }) => (
                      <Stack>
                        {(props?.roleOptions || []).map((item, index) => (
                          <FormControlLabel
                            key={index}
                            label={item.name}
                            control={
                              <Checkbox
                                checked={field.value.includes(item.id)}
                                onChange={(e) => {
                                  const newValue = field.value.includes(item.id)
                                    ? field.value.filter((x) => x !== item.id)
                                    : [...field.value, item.id];

                                  field.onChange(newValue);
                                }}
                              />
                            }
                          />
                        ))}
                      </Stack>
                    )}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item></Grid>
            <Grid item>
              <Box sx={{ mt: 1, position: "relative" }}>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={props?.loading}
                >
                  Sauvegarder
                </Button>
                {props?.loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserEditeForm;
