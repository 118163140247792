import { Alert, Grid, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import OfficierCard from "../../pages_components/reimbursement/officierCard";
import { ReimbursementInCtrlItem } from "../../pages_components/reimbursement/reimbursementInCtrlItem";
import { useReimbursementInStore } from "./hooks/reimbursementInState";

const ReimbursementInPage: React.FC<PagePropsModel> = (): JSX.Element => {
  const { isLoading, data, fetch } = useReimbursementInStore();

  useEffect(() => {
    fetch();
  }, []);

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "CRB: Entants",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="CRBT" />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      <Grid item>
        {isLoading === false && data.length === 0 && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="info">Aucune donnée disponible!</Alert>
          </Box>
        )}

        {isLoading && <LinearProgress />}

        <Grid container direction="column" spacing={3}>
          {data.map((item, index) => {
            return (
              <Grid item key={index}>
                <Grid container spacing={1}>
                  <Grid item container md={3} xs={12}>
                    <OfficierCard data={item} />
                  </Grid>
                  <Grid item md={9} xs={12}>
                    {(item.reimbursements || []).map(
                      (itemReimbursement, idx) => (
                        <ReimbursementInCtrlItem
                          key={idx}
                          itemReimbursement={itemReimbursement}
                        />
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReimbursementInPage;
