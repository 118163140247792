import { Paper, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FC } from "react";
import { shipmentStatus } from "../../../application/data/ShipmentStatus";
import { ShipmentHistoryModel } from "../../../application/models/shared/ShipmentHistoryModel";
import { ShipmentStatusEnumModel } from "../../../application/models/shared/shipmentStatusModel";
import { StyledTableCell } from "../../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../../components/Styled/StyledTableRow";

export const ShipmentHistory: FC<{ data: ShipmentHistoryModel[] | [] }> = ({
  data,
}): JSX.Element => {
  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: 420 }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell align="left">Bureau</StyledTableCell>
              <StyledTableCell align="left">Responsable</StyledTableCell>
              <StyledTableCell align="left">Statut</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data || []).map((item, index) => (
              <StyledTableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {item.created_at}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {item?.office?.name || "--"}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {" "}
                  {`${item?.officer?.first_name || ""} ${
                    item?.officer?.last_name || ""
                  }`}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {" "}
                  <ShipmentStatus status={item.status} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export const ShipmentStatus: FC<{ status: ShipmentStatusEnumModel }> = (
  props
): JSX.Element => {
  const propsStatus = shipmentStatus[props.status];
  const { color, bgColor } = propsStatus.activeStyle;

  return (
    <Typography
      variant="caption"
      sx={{
        color: bgColor,
        fontWeight: 500,
      }}
    >
      {" "}
      {propsStatus.label}
    </Typography>
  );
};
