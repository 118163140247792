import {
  Alert,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect } from "react";
import { priceFormat } from "../../application/helpers/priceFormat";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";
import { useDashboardController } from "./dashboardController";

export const StateOffices: FC = (): JSX.Element => {
  const { ui, filter, methods } = useDashboardController();

  useEffect(() => {
    ui.stateOffices();
  }, [filter.formValues]);

  if (ui.isLoading) {
    return <LinearProgress />;
  }

  if (!ui.isLoading && ui.isError) {
    return <Alert severity="error">Error</Alert>;
  }

  const sum = methods.sumOfficesState();

  console.log({ sum });

  return (
    <Box>
      <Stack direction={"row"} spacing={2} sx={{ mb: 3 }}>
        <Paper sx={{ p: 2, borderLeft: "solid 10px #2D0A64" }}>
          <Stack direction={"row"} spacing={3}>
            <Typography sx={{ fontWeight: 900, fontSize: 30 }}>
              Total Colis
            </Typography>
            <Box>
              <Typography
                sx={{ fontWeight: 500, fontSize: 24 }}
                color="primary"
              >
                {sum.shipments_total_number}
              </Typography>
              <Typography color="textSecondary">
                {priceFormat(sum.shipments_total_amount)}
              </Typography>
            </Box>
          </Stack>
        </Paper>
        <Paper sx={{ p: 2, borderLeft: "solid 10px blue" }}>
          <Stack direction={"row"} spacing={3}>
            <Typography sx={{ fontWeight: 900, fontSize: 30 }}>
              {" "}
              Total CRBT
            </Typography>
            <Box>
              <Typography
                sx={{ fontWeight: 500, fontSize: 24 }}
                color="secondary"
              >
                {sum.reimbursement_total_number}{" "}
              </Typography>
              <Typography color="textSecondary">
                {priceFormat(sum.reimbursement_total_amount)}
              </Typography>
            </Box>
          </Stack>
        </Paper>
      </Stack>

      <TableContainer component={Paper}>
        <Table
          sx={{ width: "100%", minWidth: 650 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Bureau</StyledTableCell>
              <StyledTableCell align="left">Colis</StyledTableCell>
              <StyledTableCell align="left">CRBT IN</StyledTableCell>
              <StyledTableCell align="left">CRBT OUT</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(ui.dataStateOffices || []).map((row, index) => {
              return (
                <StyledTableRow key={index} hover>
                  <StyledTableCell component="th" scope="row">
                    {row.office}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.shipments_total_number} |{" "}
                    <Typography
                      variant="caption"
                      color="violet"
                      sx={{ fontWeight: 500 }}
                    >
                      {priceFormat(row.shipments_total_amount)}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell>
                    {row.reimbursement_total_number} |{" "}
                    <Typography
                      variant="caption"
                      color="slateblue"
                      sx={{ fontWeight: 500 }}
                    >
                      {priceFormat(row.reimbursement_total_amount)}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell>
                    {row.reimbursement_total_number_to_pay} |{" "}
                    <Typography
                      variant="caption"
                      color="slateblue"
                      sx={{ fontWeight: 500 }}
                    >
                      {priceFormat(row.reimbursement_total_amount_to_pay)}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>{" "}
      </TableContainer>
    </Box>
  );
};
