import { yupResolver } from "@hookform/resolvers/yup";
import { DateTimePicker, StaticDateTimePicker } from "@mui/lab";
import DatePicker from "@mui/lab/DatePicker";
import { Alert, Box, Button, Grid, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import {
  addHours,
  format,
  formatISO,
  isAfter,
  isDate,
  parse,
  parseISO,
} from "date-fns";
import React, { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { DATE_TIME_ISO_FORMAT } from "../../../application/constants/appConstants";
import { useDashboardController } from "../dashboardController";

import { FilterFormModel } from "./models";

const FilterForm: FC = (props): JSX.Element => {
  const { api, ui, filter } = useDashboardController();

  const schema = yup.object().shape({
    startDate: yup.string().required(),
    endDate: yup.string().required(),
    // .test(
    //   "is-valide",
    //   "La date et l'heure doit etre après 2 heurs",
    //   (value) => {
    //     return isAfter(
    //       parse(String(value), DATE_ISO_FORMAT, new Date()),
    //       addHours(new Date(), PICKUP_ADD_HOURS)
    //     );
    //   }
    // ),
  });

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FilterFormModel>({
    resolver: yupResolver(schema),
    defaultValues: filter.formValues || {
      startDate: format(new Date(), DATE_TIME_ISO_FORMAT),
      endDate: format(new Date(), DATE_TIME_ISO_FORMAT),
    },
  });

  const onSubmit = (data: FilterFormModel) => {
    filter.setFormValues(data);
  };

  const formValues = watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="row" alignItems={"flex-end"} spacing={2}>
        <Grid item>
          <Controller
            name="startDate"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                fullWidth
                id="datetime-local"
                label="De"
                type="datetime-local"
                {...field}
                value={field.value}
                // inputProps={{
                //   min: format(new Date(), DATE_ISO_FORMAT),
                // }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors?.startDate)}
                helperText={
                  errors?.startDate ? errors?.startDate.message : null
                }
              />
            )}
          />
        </Grid>

        <Grid item>
          <Controller
            name="endDate"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                fullWidth
                id="datetime-local"
                label="À"
                type="datetime-local"
                {...field}
                value={field.value}
                // inputProps={{
                //   min: format(new Date(), DATE_ISO_FORMAT),
                // }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors?.endDate)}
                helperText={errors?.endDate ? errors?.endDate.message : null}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Box sx={{ mt: 1, position: "relative" }}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={ui.isLoading}
            >
              Envoyer
            </Button>
            {ui.isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default FilterForm;
