import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import {
  Alert,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { red } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import { get } from "lodash";
import { FC, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useFindOneShipmentQuery } from "../../application/api/hooks/dispatching";
import Can from "../../application/casl/abilityContext";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { ShipmentModel } from "../../application/models/shared/ShipmentModel";
import { ShipmentStatusEnumModel } from "../../application/models/shared/shipmentStatusModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import { ShipmentAttempts } from "../../pages_components/shipments/attempts";
import { ShipmentBarCodeCard } from "../../pages_components/shipments/barCodeContainer";
import { ShipmentHistory } from "../../pages_components/shipments/history";
import { ReturnToSender } from "../../pages_components/shipments/returnToSender";
import { DeliveryAndCrbtCode } from "./deliveryAndCrbtCode";

const ShipmentDetailsPage: FC<PagePropsModel> = (props): JSX.Element => {
  const id = props?.match?.params?.id || undefined;

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const oneShipmentQuery = useFindOneShipmentQuery(String(id));
  const shipmentData = get(
    oneShipmentQuery,
    ["data"],
    undefined
  ) as ShipmentModel;

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Detail colis ",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="Detail Colis" />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item>
            {shipmentData?.status !==
              ShipmentStatusEnumModel.delivered_to_client &&
              shipmentData?.returned_shipment_id === null &&
              shipmentData?.attempts &&
              shipmentData?.attempts?.length > 1 && (
                <ReturnToSender shipment={shipmentData} />
              )}

            {Boolean(shipmentData?.returned_shipment_id) && (
              <Typography color="error" sx={{ fontWeight: 500 }}>
                COLIS EN RETOUR : [{shipmentData?.returned_shipment_id}]
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      {oneShipmentQuery.isLoading && (
        <Grid item>
          <LinearProgress />
        </Grid>
      )}
      {!oneShipmentQuery.isLoading && oneShipmentQuery.isError && (
        <Grid item>
          <Alert severity="error">Erreur de requête</Alert>
        </Grid>
      )}
      {!oneShipmentQuery.isLoading && shipmentData && (
        <Grid item>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item xs={12} md={8}>
              <Paper>
                <Stack direction={"column"} spacing={2}>
                  <Box sx={{ height: 30, ml: 3, mt: 3 }}>
                    <Typography sx={{ fontWeight: 900 }}>
                      {" "}
                      Historique{" "}
                    </Typography>
                  </Box>
                  <ShipmentHistory data={shipmentData?.history || []} />
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ width: "100%" }}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                      <ConfirmationNumberOutlinedIcon />
                    </Avatar>
                  }
                  action={
                    <IconButton aria-label="settings" onClick={handlePrint}>
                      <PrintOutlinedIcon />
                    </IconButton>
                  }
                  title="Ticket"
                  subheader={shipmentData?.received_at}
                />

                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {shipmentData && (
                    <div ref={componentRef}>
                      <ShipmentBarCodeCard shipment={shipmentData} />
                    </div>
                  )}

                  <Can I="read" a="SHIPMENT_CODES">
                    <>
                      <Box>
                        <Divider flexItem />
                      </Box>
                      <DeliveryAndCrbtCode shipmentData={shipmentData} />
                    </>
                  </Can>
                </CardContent>
              </Card>
            </Grid>
            {shipmentData?.attempts !== undefined &&
              shipmentData?.attempts?.length > 0 && (
                <Grid item xs={12} md={8}>
                  <Stack direction={"column"} spacing={2} sx={{ mt: 4 }}>
                    <Box sx={{ height: 30 }}>
                      <Typography sx={{ fontWeight: 900, fontSize: 16 }}>
                        {" "}
                        Tentatives des livraisons{" "}
                      </Typography>
                    </Box>
                    <ShipmentAttempts data={shipmentData?.attempts || []} />
                  </Stack>
                </Grid>
              )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default ShipmentDetailsPage;
