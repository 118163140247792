import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { governorateService } from "../../../../application/api/services/governorate.service";
import {
  StructureItemModel,
  StructureItemTypeModel,
} from "../../../../application/models/shared/officeModel";
import { GovernorateModel } from "../../../../application/models/shared/governorateModel";
import { GovernoratesList } from "./governoratesList";
import { StructureContext } from "./structureContext";

const LEVEL_CONTROLLER = 2;

const StructureForm: FC = () => {
  const [governorates, setGovernorates] = useState<GovernorateModel[] | []>([]);

  /**  */
  useEffect(() => {
    governorateService
      .fetch({ search: undefined, per_page: 24, page: 1 })
      .then((response) => {
        const governorates = _.get(response, ["data"], []);
        setGovernorates(governorates);
      })
      .catch((error) => console.log(error));
  }, []);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { fields, remove, append, replace } = useFieldArray({
    control,
    name: `structure`,
    keyName: "idField",
  });

  const structureItems = watch("structure") as StructureItemModel[];

  const setStructureItems = (items: StructureItemModel[] | []): void => {
    replace(items);
  };

  /** */
  const isChecked = (item: StructureItemModel): boolean => {
    const isChecked = structureItems.find(
      (itemChecked) =>
        itemChecked.id === item.id &&
        itemChecked.type === item.type &&
        itemChecked?.parent_id === item?.parent_id
    );
    return isChecked !== undefined;
  };

  const isInPatents = (
    item: StructureItemModel,
    path?: StructureItemModel[]
  ): boolean => {
    if (path === undefined) {
      return false;
    }

    const exist = (path || []).find(
      (pathItem) => pathItem.id === item.id && pathItem.type === item.type
    );
    return Boolean(exist);
  };

  const getChildrenType = (item: StructureItemModel) => {
    if (item.type === StructureItemTypeModel.GOVERNORATE)
      return StructureItemTypeModel.CITY;
    if (item.type === StructureItemTypeModel.CITY)
      return StructureItemTypeModel.POST_OFFICE;
    return null;
  };

  /** */
  const handleCheck = (
    item: StructureItemModel,
    _checked: boolean,
    path?: StructureItemModel[]
  ) => {
    if (_checked === true) {
      append(item);
    } else {
      const updatedChildren = [...structureItems]
        .filter(
          (itemChecked) =>
            !(
              itemChecked.id === item.id &&
              itemChecked.type === item.type &&
              itemChecked?.parent_id === item?.parent_id
            )
        )
        .filter((itemChecked) => !isInPatents(itemChecked, path))
        .filter(
          (itemChecked) =>
            !(
              itemChecked?.parent_id === item.id &&
              itemChecked.type === getChildrenType(item)
            )
        );

      replace(updatedChildren);
    }
  };

  /** */
  const handleCheckAll = (items: StructureItemModel[]) => {
    const updatedChildren = [...structureItems, ...items];
    replace(updatedChildren);
  };

  /** */
  const nbrChildrenChecked = (
    id: number,
    type: StructureItemTypeModel
  ): number => {
    return [...structureItems].filter(
      (itemChecked) =>
        itemChecked?.parent_id === id && itemChecked.type === type
    ).length;
  };

  return (
    <StructureContext.Provider
      value={{
        id: watch("id"),
        levelController: LEVEL_CONTROLLER,
        structureItems: structureItems,
        dispatch: {
          isChecked,
          handleCheck,
          handleCheckAll,
          nbrChildrenChecked,
          setStructureItems,
        },
      }}
    >
      <GovernoratesList governorates={governorates} />
    </StructureContext.Provider>
  );
};

export default StructureForm;
