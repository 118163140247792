export enum BagStatusEnumModel {
  ready_for_forwarding_to_reception_district = "ready_for_forwarding_to_reception_district",
  forwarded_to_reception_district = "forwarded_to_reception_district",
  received_in_reception_district = "received_in_reception_district",
  ready_for_forwarding_to_delivery_district = "ready_for_forwarding_to_delivery_district",
  forwarded_to_delivery_district = "forwarded_to_delivery_district",
  received_in_delivery_district = "received_in_delivery_district",
  ready_for_forwarding_to_delivery_office = "ready_for_forwarding_to_delivery_office",
  forwarded_to_delivery_office = "forwarded_to_delivery_office",
}

export interface BagStatusModel {
  color: string;
  bgColor: string;
  label: string;
  activeStyle: {
    color: string;
    bgColor: string;
  };
}

export type RecordBagStatusModel = Record<BagStatusEnumModel, BagStatusModel>;
