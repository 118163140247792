import { Chip, ChipProps } from "@mui/material";
import React, { FC } from "react";
import { shipmentStatus } from "../../application/data/ShipmentStatus";
import { ShipmentStatusEnumModel } from "../../application/models/shared/shipmentStatusModel";

export const ShipmentStatusChip: FC<
  ChipProps & { status: ShipmentStatusEnumModel; active: boolean | undefined }
> = (props): JSX.Element => {
  const { active = undefined, status, ...other } = props;
  const propsStatus = shipmentStatus[status];
  const { color, bgColor } =
    active === true ? propsStatus.activeStyle : propsStatus;

  return (
    <Chip
      sx={{
        "&.MuiChip-root": {
          border: "none",
          color: color,
          backgroundColor: `${bgColor} !important`,
          "&:hover": {
            backgroundColor: bgColor,
            filter: "brightness(120%)",
          },
          "&:active": {
            boxShadow: "none",
            backgroundColor: bgColor,
            // borderColor: shipmentStatus[statusOfshipment].color,
          },
        },
        "&.MuiChip-deleteIcon": {
          color: "black",
        },
      }}
      label={propsStatus.label}
      {...other}
    />
  );
};
