import AddTaskSharpIcon from "@mui/icons-material/AddTaskSharp";
import CheckIcon from "@mui/icons-material/Check";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import _ from "lodash";
import { FC, useState } from "react";
import { useFindUsersQuery } from "../../../../application/api/hooks/user.hooks";
import { UserModel } from "../../../../application/models/shared/userModel";
import If from "../../../../components/conditionalViwer";

interface SearchStaffFormProps {
  addUser: (user: UserModel) => void;
  selectedUsers: UserModel[] | [];
}

export const SearchStaffForm: FC<SearchStaffFormProps> = ({
  addUser,
  selectedUsers,
}) => {
  const [input, setInput] = useState<string>("");

  const usersQuery = useFindUsersQuery(input);
  const users = _.get(usersQuery, ["data", "data", "data"], []) as UserModel[];

  const isSelected = (item: UserModel) => {
    return (
      selectedUsers.find((selected) => selected.id === item.id) !== undefined
    );
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <TextField
          size="small"
          fullWidth
          onChange={(e) => setInput(e.target.value)}
          placeholder={"Rechercher"}
        />
      </Grid>
      <Grid item>
        <If
          condition={usersQuery.isLoading}
          render={() => <div>...Loading</div>}
          otherwise={() => (
            <List sx={{ width: "100%", bgcolor: "background.paper" }} dense>
              {users.map((user, index) => (
                <ListItem
                  disablePadding
                  key={index}
                  secondaryAction={
                    <If
                      condition={isSelected(user)}
                      render={() => (
                        <IconButton
                          color="success"
                          edge="end"
                          aria-label="delete"
                        >
                          <AddTaskSharpIcon />
                        </IconButton>
                      )}
                      otherwise={() => (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          color="default"
                        >
                          <CheckIcon />
                        </IconButton>
                      )}
                    />
                  }
                  dense
                >
                  <ListItemButton dense onClick={() => addUser(user)}>
                    <ListItemAvatar>
                      <Avatar>
                        <PersonOutlineIcon fontSize="small" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${user.first_name} ${user.last_name}`}
                      secondary="Jan 9, 2014"
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        />
      </Grid>
    </Grid>
  );
};
