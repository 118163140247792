import create from "zustand";
import { reimbursementService } from "../../../application/api/services/reimbursement.service";
import { ReimbursementOutCtrlModel } from "../../../application/models/shared/reimbursementCtrlModel";

export interface ReimbursementOutStateModel {
  isLoading: boolean;
  isError: boolean;
  data: ReimbursementOutCtrlModel[];
  setLoading: (isLoading: boolean, isError: boolean) => void;
  fetch: () => void;
}

export const useReimbursementOutStore = create<ReimbursementOutStateModel>(
  (set, get) => ({
    isLoading: false,
    isError: false,
    data: [],
    setLoading: (isLoading: boolean, isError: boolean) => {
      set((state) => ({ ...state, isLoading, isError }));
    },
    fetch: async () => {
      set((state) => ({ ...state, isLoading: true, isError: false }));
      const { data } = await reimbursementService.fetchReimbursementOut();

      set((state) => ({
        ...state,
        data: data,
        isLoading: false,
        isError: false,
      }));
    },
  })
);
