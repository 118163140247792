import { TableCell, tableCellClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.grey[800],
    backgroundColor: "#F4F6F8",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: "#647482",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    fontWeight: 400,
    color: "#647482",
  },
}));
