import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC } from "react";
import { priceFormat } from "../../application/helpers/priceFormat";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";
import { useDashboardController } from "./dashboardController";

export const ReimbursementOfficeDisptach: FC = (): JSX.Element => {
  const { ui } = useDashboardController();
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ width: "100%", minWidth: 650 }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Bureau</StyledTableCell>
            <StyledTableCell align="left">Colis</StyledTableCell>
            <StyledTableCell align="left">CRBT</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(ui.dataStateOffice?.reimbursement_to_send || []).map(
            (row, index) => {
              return (
                <StyledTableRow key={index} hover>
                  <StyledTableCell component="th" scope="row">
                    {row.office}
                  </StyledTableCell>
                  <StyledTableCell>{row.number}</StyledTableCell>

                  <StyledTableCell>{priceFormat(row.amount)} </StyledTableCell>
                </StyledTableRow>
              );
            }
          )}
        </TableBody>
      </Table>{" "}
    </TableContainer>
  );
};
