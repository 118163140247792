import { UserModel } from "./../models/shared/userModel";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import FeaturedPlayListOutlinedIcon from "@mui/icons-material/FeaturedPlayListOutlined";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import InboxOutlinedIcon from "@mui/icons-material/InboxOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import NextWeekOutlinedIcon from "@mui/icons-material/NextWeekOutlined";
import OutboxOutlinedIcon from "@mui/icons-material/OutboxOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

import {
  CREATE_DISTRICT_ROUTE,
  CREATE_OFFICE_ROUTE,
  CREATE_PACKAGE_ROUTE,
  CREATE_USER_ROUTE,
  DETAILS_PACKAGE_ROUTE,
  EDIT_DISTRICT_ROUTE,
  EDIT_OFFICE_ROUTE,
  EDIT_USER_ROUTE,
  FORWARD_BAGS_ROUTE,
  LIST_DELIVERY_ROUTE,
  LIST_DISTRICT_ROUTE,
  LIST_OFFICE_ROUTE,
  LIST_PACKAGE_ROUTE,
  LIST_USERS_ROUTE,
  OUTER_BAGS_PREPARE_ROUTE,
  PRIVATE_ROUTE,
  RECEIPT_PACKAGE_ROUTE,
  RECEIVE_BAGS_IN_PLATFORM_ROUTE,
  RECEIVE_BAGS_ROUTE,
  REIMBURSEMENT_IN_ROUTE,
  REIMBURSEMENT_OUT_ROUTE,
  TAKE_FOR_DELIVERY_ROUTE,
  TRIP_SHIPMENTS_ROUTE,
  LIST_INVOICES_ROUTE,
  MY_TRIP_BAGS_ROUTE,
  FUNDS_OFFICE_ROUTE,
  FUNDS_MINE_ROUTE,
  LIST_CUSTOMERS_ROUTE,
  PROFILE_CUSTOMER_ROUTE,
  LIST_PICKUPS_ROUTE,
  DETAILS_INVOICES_ROUTE,
} from "../constants/routeConstants";
import { MenuModel } from "../models/shared/menuModel";
import ControlShipmentsPage from "../../pages/shipments/ControlShipments";
import ReceptionPage from "../../pages/reception";
import ListPackagesPage from "../../pages/shipments";
import DashboardPage from "../../pages/dashboard";
import PrepareOuterBagsPage from "../../pages/bags/prepareOuterBags";
import ForwardBagsPage from "../../pages/bags/forwardBags";
import ReceiveBagsPage from "../../pages/bags/receiveBags";
import ReceiveBagsInPlatformPage from "../../pages/bags/receiveBagsInPlatform";
import TakeForDeliveryPage from "../../pages/shipments/TakeForDelivery";
import TripShipmentsPage from "../../pages/shipments/TripShipments";
import ReimbursementInPage from "../../pages/reimbursement/in";
import ReimbursementOutPage from "../../pages/reimbursement/out";
import UsersListPage from "../../pages/users";
import ListDistrictsPage from "../../pages/districts";
import ListOfficesPage from "../../pages/offices";
import ShipmentDetailsPage from "../../pages/shipments/details";
import UpdateUserPage from "../../pages/users/updateUser";
import UpdateDistrictPage from "../../pages/districts/updateDistrict";
import UpdateOfficePage from "../../pages/offices/updateOffice";
import CreateOfficePage from "../../pages/offices/createOffice";
import CreateDistrictPage from "../../pages/districts/createDistrict";
import CreateUserPage from "../../pages/users/createUser";
import InvoicesPage from "../../pages/invoices";
import MyTripBagsPage from "../../pages/bags/myTrip";
import FundsOfficePage from "../../pages/funds/fundsOffice";
import FundsMinePage from "../../pages/funds/fundsMine";
import { buildAbilityFor } from "../casl/abilityConfig";
import CustomersListPage from "../../pages/customers";
import CustomerProfilePage from "../../pages/customers/profile";
import ListPickupsPage from "../../pages/pickups";

export const communMenuItems: MenuModel[] = [
  {
    label: "Tableau de Bord",
    href: PRIVATE_ROUTE,
    page: () => DashboardPage,
    visibility: true,
    icon: () => DashboardOutlinedIcon,
    image: "/assets/icons/dashboard.svg",
    ability: (user: UserModel): boolean => {
      const ability = buildAbilityFor(user);
      return ability.can("read", "DASHBOARD");
    },
  },
  {
    label: "Gestion de colis",
    icon: () => Inventory2OutlinedIcon,
    visibility: true,
    ability: (user: UserModel): boolean => {
      const ability = buildAbilityFor(user);
      return ability.can("read", "PARCEL_MANAGEMENT");
    },
    subMenuItems: [
      {
        label: "Liste des colis",
        href: LIST_PACKAGE_ROUTE,
        page: () => ListPackagesPage,
        visibility: true,
        icon: () => FeaturedPlayListOutlinedIcon,
        image: "/assets/icons/Inbox cleanup-pana.svg",
        ability: (user: UserModel): boolean => {
          const ability = buildAbilityFor(user);
          return ability.can("read", "PARCEL_MANAGEMENT");
        },
      },
      {
        label: "Detail colis",
        href: `${DETAILS_PACKAGE_ROUTE}/:id?`,
        page: () => ShipmentDetailsPage,
        visibility: false,
        icon: () => FeaturedPlayListOutlinedIcon,
        image: "/assets/icons/Inbox cleanup-pana.svg",
        ability: (user: UserModel): boolean => {
          const ability = buildAbilityFor(user);
          return ability.can("read", "PARCEL_MANAGEMENT");
        },
      },
      {
        label: "Nouveau colis",
        href: CREATE_PACKAGE_ROUTE,
        page: () => ReceptionPage,
        icon: () => ForwardToInboxIcon,
        visibility: true,
        image: "/assets/icons/QA engineers-cuate.svg",
        ability: (user: UserModel): boolean => {
          const ability = buildAbilityFor(user);
          return ability.can("read", "PARCEL_MANAGEMENT");
        },
      },
      {
        label: "Vérification des colis",
        href: RECEIPT_PACKAGE_ROUTE,
        page: () => ControlShipmentsPage,
        icon: () => ForwardToInboxIcon,
        visibility: true,
        image: "/assets/icons/QA engineers-rafiki.svg",
        ability: (user: UserModel): boolean => {
          const ability = buildAbilityFor(user);
          return ability.can("read", "PARCEL_MANAGEMENT");
        },
      },
    ],
  },

  {
    label: "Pickups",
    href: LIST_PICKUPS_ROUTE,
    page: () => ListPickupsPage,
    visibility: true,
    icon: () => AssignmentTurnedInOutlinedIcon,
    image: "/assets/icons/dashboard.svg",
    ability: (user: UserModel): boolean => {
      const ability = buildAbilityFor(user);
      return ability.can("read", "PICKUPS");
    },
  },

  {
    label: "Gestion de sac",
    icon: () => ShoppingBagOutlinedIcon,
    visibility: true,
    ability: (user: UserModel): boolean => {
      const ability = buildAbilityFor(user);
      return ability.can("read", "BAG_MANAGEMENT");
    },
    subMenuItems: [
      // {
      //   label: "Liste des sacs",
      //   allowed: ["CENTRAL_OFFICE"],
      //   href: BAGS_ROUTE,
      //   icon: () => FeaturedPlayListOutlinedIcon,
      // },
      // {
      //   label: "Préparation des sacs",
      //   allowed: ["CENTRAL_OFFICE"],
      //   href: OFFICE_BAGS_PREPARE_ROUTE,
      //   icon: () => WorkOutlineOutlinedIcon,
      //   notifKey: "office_bags_to_prepare",
      //   image: "/assets/icons/Checking boxes-cuate.svg",
      // },
      // {
      //   label: "Sacs entrants",
      //   allowed: ["CENTRAL_OFFICE"],
      //   href: INNER_BAGS_PREPARE_ROUTE,
      //   icon: () => MoveToInboxOutlinedIcon,
      //   notifKey: "innerBags",
      //   image: "/assets/icons/Heavy box-amico.svg",
      // },
      {
        label: "Préparation des Sacs",
        href: OUTER_BAGS_PREPARE_ROUTE,
        page: () => PrepareOuterBagsPage,
        icon: () => OutboxOutlinedIcon,
        visibility: true,
        notifKey: "outerBags",
        image: "/assets/icons/Heavy box-amico.svg",
        ability: (user: UserModel): boolean => {
          const ability = buildAbilityFor(user);
          return ability.can("read", "BAG_PREPARATION");
        },
      },
      {
        label: "Transfert des sacs",
        notifKey: "bagsToSend",
        href: FORWARD_BAGS_ROUTE,
        page: () => ForwardBagsPage,
        visibility: true,
        icon: () => NextWeekOutlinedIcon,
        image: "/assets/icons/Heavy box-pana.svg",
        ability: (user: UserModel): boolean => {
          const ability = buildAbilityFor(user);
          return ability.can("read", "TRANSFER_OF_BAGS");
        },
      },
      {
        label: "Réception des sacs",
        notifKey: "bagsToReceive",
        href: RECEIVE_BAGS_ROUTE,
        page: () => ReceiveBagsPage,
        visibility: true,
        icon: () => InboxOutlinedIcon,
        image: "/assets/icons/Checking boxes-cuate.svg",
        ability: (user: UserModel): boolean => {
          const ability = buildAbilityFor(user);
          return ability.can("read", "RECEIPT_OF_BAGS");
        },
      },
      {
        label: "Réception des sacs from platforme",
        notifKey: "bagsToReceiveFromPlatform",
        href: RECEIVE_BAGS_IN_PLATFORM_ROUTE,
        page: () => ReceiveBagsInPlatformPage,
        visibility: true,
        icon: () => InboxOutlinedIcon,
        image: "/assets/icons/Post office-pana.svg",
        ability: (user: UserModel): boolean => {
          const ability = buildAbilityFor(user);
          return ability.can("read", "RECEIPT_OF_BAGS_FROM_PLATFORM");
        },
      },
      {
        label: "Mes sacs",
        notifKey: "bagsToReceiveFromPlatform",
        href: MY_TRIP_BAGS_ROUTE,
        page: () => MyTripBagsPage,
        visibility: false,
        icon: () => InboxOutlinedIcon,
        image: "/assets/icons/Logistics-rafiki.svg",
        ability: (user: UserModel): boolean => {
          const ability = buildAbilityFor(user);
          return ability.can("read", "BAG_MANAGEMENT");
        },
      },
    ],
  },
  {
    label: "Livraison",
    icon: () => LocalShippingOutlinedIcon,
    visibility: true,
    ability: (user: UserModel): boolean => {
      const ability = buildAbilityFor(user);
      return ability.can("read", "DELIVERY");
    },
    subMenuItems: [
      {
        label: "Prêt à être livré",
        href: TAKE_FOR_DELIVERY_ROUTE,
        page: () => TakeForDeliveryPage,
        icon: () => AssignmentTurnedInOutlinedIcon,
        visibility: true,
        notifKey: "shipmentReadyToDeliver",
        image: "/assets/icons/Messenger-cuate.svg",
        ability: (user: UserModel): boolean => {
          const ability = buildAbilityFor(user);
          return ability.can("read", "DELIVERY");
        },
      },
      {
        label: "Expéditions de livraison",
        href: TRIP_SHIPMENTS_ROUTE,
        page: () => TripShipmentsPage,
        icon: () => EventAvailableOutlinedIcon,
        visibility: true,
        notifKey: "tripShipments",
        image: "/assets/icons/Mail sent-bro.svg",
        ability: (user: UserModel): boolean => {
          const ability = buildAbilityFor(user);
          return ability.can("read", "DELIVERY");
        },
      },
    ],
  },
  {
    label: "Fonds & CRBT",
    icon: () => MonetizationOnOutlinedIcon,
    visibility: true,
    ability: (user: UserModel): boolean => {
      const ability = buildAbilityFor(user);
      return ability.can("read", "FUNDS_&_CRBT");
    },
    subMenuItems: [
      {
        label: "Ma caisse",
        href: FUNDS_MINE_ROUTE,
        page: () => FundsMinePage,
        icon: () => AssignmentTurnedInOutlinedIcon,
        visibility: false,
        notifKey: "incomeReimbursement",
        image: "/assets/icons/Money income-amico.svg",
        ability: (user: UserModel): boolean => {
          const ability = buildAbilityFor(user);
          return ability.can("read", "FUNDS_&_CRBT");
        },
      },
      {
        label: "Contrôle des fonds",
        href: FUNDS_OFFICE_ROUTE,
        page: () => FundsOfficePage,
        icon: () => AssignmentTurnedInOutlinedIcon,
        visibility: true,
        notifKey: "incomeReimbursement",
        image: "/assets/icons/Money income-amico.svg",
        ability: (user: UserModel): boolean => {
          const ability = buildAbilityFor(user);
          return ability.can("read", "FUNDS_&_CRBT");
        },
      },
      {
        label: "CRBT: Entrants",
        href: REIMBURSEMENT_IN_ROUTE,
        page: () => ReimbursementInPage,
        icon: () => AssignmentTurnedInOutlinedIcon,
        visibility: true,
        notifKey: "incomeReimbursement",
        image: "/assets/icons/Wallet-amico.svg",
        ability: (user: UserModel): boolean => {
          const ability = buildAbilityFor(user);
          return ability.can("read", "FUNDS_&_CRBT");
        },
      },
      {
        label: "CRBT: Sortants",
        href: REIMBURSEMENT_OUT_ROUTE,
        page: () => ReimbursementOutPage,
        icon: () => EventAvailableOutlinedIcon,
        visibility: true,
        notifKey: "outcomeReimbursement",
        image: "/assets/icons/Cash Payment-pana.png",
        ability: (user: UserModel): boolean => {
          const ability = buildAbilityFor(user);
          return ability.can("read", "FUNDS_&_CRBT");
        },
      },
    ],
  },

  {
    label: "Management",
    icon: () => SettingsIcon,
    visibility: true,
    ability: (user: UserModel): boolean => {
      const ability = buildAbilityFor(user);
      return ability.can("read", "MANAGEMENT");
    },
    subMenuItems: [
      {
        label: "Les Factures",
        href: LIST_INVOICES_ROUTE,
        page: () => InvoicesPage,
        visibility: true,
        icon: () => DescriptionOutlinedIcon,
        image: "/assets/icons/Invoice-amico.svg",
        ability: (user: UserModel): boolean => {
          return true;
        },
      },
      {
        label: "Detail Factures",
        href: DETAILS_INVOICES_ROUTE,
        page: () => InvoicesPage,
        visibility: false,
        icon: () => DescriptionOutlinedIcon,
        image: "/assets/icons/Invoice-amico.svg",
        ability: (user: UserModel): boolean => {
          return true;
        },
      },
      {
        label: "Gestion des clients",
        href: LIST_CUSTOMERS_ROUTE,
        page: () => CustomersListPage,
        icon: () => PeopleAltOutlinedIcon,
        visibility: true,
        image: "/assets/icons/User research-bro.svg",
        ability: (user: UserModel): boolean => {
          return true;
        },
      },
      {
        label: "Profile client",
        href: PROFILE_CUSTOMER_ROUTE,
        page: () => CustomerProfilePage,
        icon: () => PeopleAltOutlinedIcon,
        visibility: false,
        image: "/assets/icons/User research-bro.svg",
        ability: (user: UserModel): boolean => {
          return true;
        },
      },
      {
        label: "Gestion des utilisateurs",
        href: LIST_USERS_ROUTE,
        page: () => UsersListPage,
        icon: () => PeopleAltOutlinedIcon,
        visibility: true,
        image: "/assets/icons/Personal files-cuate.svg",
        ability: (user: UserModel): boolean => {
          return true;
        },
      },
      {
        label: "Nouveau utilisateur",
        href: CREATE_USER_ROUTE,
        page: () => CreateUserPage,
        icon: () => PeopleAltOutlinedIcon,
        visibility: false,
        image: "/assets/icons/Personal files-cuate.svg",
        ability: (user: UserModel): boolean => {
          return true;
        },
      },
      {
        label: "Modification utilisateur",
        href: `${EDIT_USER_ROUTE}/:id?`,
        page: () => UpdateUserPage,
        icon: () => PeopleAltOutlinedIcon,
        visibility: false,
        image: "/assets/icons/Personal files-cuate.svg",
        ability: (user: UserModel): boolean => {
          return true;
        },
      },
      {
        label: "Gestion des districts",
        href: LIST_DISTRICT_ROUTE,
        page: () => ListDistrictsPage,
        icon: () => MapOutlinedIcon,
        visibility: true,
        image: "/assets/icons/Post office-amico.svg",
        ability: (user: UserModel): boolean => {
          return true;
        },
      },
      {
        label: "Nouveau district",
        href: CREATE_DISTRICT_ROUTE,
        page: () => CreateDistrictPage,
        icon: () => MapOutlinedIcon,
        visibility: false,
        image: "/assets/icons/Post office-amico.svg",
        ability: (user: UserModel): boolean => {
          return true;
        },
      },
      {
        label: "Modification district",
        href: `${EDIT_DISTRICT_ROUTE}/:id?`,
        page: () => UpdateDistrictPage,
        icon: () => MapOutlinedIcon,
        visibility: false,
        image: "/assets/icons/Post office-amico.svg",
        ability: (user: UserModel): boolean => {
          return true;
        },
      },
      {
        label: "Gestion des bureaux",
        href: LIST_OFFICE_ROUTE,
        page: () => ListOfficesPage,
        icon: () => HomeWorkOutlinedIcon,
        visibility: true,
        image: "/assets/icons/Post office-bro.svg",
        ability: (user: UserModel): boolean => {
          return true;
        },
      },
      {
        label: "Modification de bureau",
        href: `${EDIT_OFFICE_ROUTE}/:id?`,
        page: () => UpdateOfficePage,
        icon: () => HomeWorkOutlinedIcon,
        visibility: false,
        image: "/assets/icons/Post office-bro.svg",
        ability: (user: UserModel): boolean => {
          return true;
        },
      },
      {
        label: "Creation de bureau",
        href: `${CREATE_OFFICE_ROUTE}`,
        page: () => CreateOfficePage,
        icon: () => HomeWorkOutlinedIcon,
        visibility: false,
        image: "/assets/icons/Post office-bro.svg",
        ability: (user: UserModel): boolean => {
          return true;
        },
      },
    ],
  },
  // { label: "Autre", allowed: ["CENTRAL_OFFICE"], icon: () => DashboardIcon },
];

export const managmentMenuItems: MenuModel[] = [
  {
    label: "Gestion des clients",
    href: LIST_CUSTOMERS_ROUTE,
    icon: () => PeopleAltOutlinedIcon,
    visibility: true,
    image: "/assets/icons/Personal files-cuate.svg",
    ability: (user: UserModel): boolean => {
      return true;
    },
  },

  {
    label: "Gestion des utilisateurs",
    href: LIST_USERS_ROUTE,
    icon: () => PeopleAltOutlinedIcon,
    visibility: true,
    image: "/assets/icons/Personal files-cuate.svg",
    ability: (user: UserModel): boolean => {
      return true;
    },
  },
  {
    label: "Gestion des districts",
    href: LIST_DISTRICT_ROUTE,
    icon: () => MapOutlinedIcon,
    visibility: true,
    image: "/assets/icons/Post office-amico.svg",
    ability: (user: UserModel): boolean => {
      return true;
    },
  },
  {
    label: "Gestion des bureaux",
    href: LIST_OFFICE_ROUTE,
    icon: () => HomeWorkOutlinedIcon,
    visibility: true,
    image: "/assets/icons/Post office-bro.svg",
    ability: (user: UserModel): boolean => {
      return true;
    },
  },
  // {
  //   label: "Livraison",
  //   allowed: ["CENTRAL_OFFICE"],
  //   href: LIST_DELIVERY_ROUTE,
  //   icon: () => LocalShippingOutlinedIcon,
  //   image: "/assets/icons/Post office-amico.svg",
  // },
  // { label: "Autre", allowed: ["CENTRAL_OFFICE"], icon: () => DashboardIcon },
];
