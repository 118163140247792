import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Button, Container, Stack, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { NotificationsMenu } from "../../../components/NotificationsMenu";
import { useAuth } from "../../contexts/authContex";
import { AccountMenu } from "./components/AccountMenu";
import { HorizontalNavBar } from "./components/horizontalNavBar";
import { VerticalNavBar } from "./components/verticalNavBar";
import MapsHomeWorkOutlinedIcon from "@mui/icons-material/MapsHomeWorkOutlined";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  height: 110,
}));

export const PrivateWorkSpaceLayout: React.FC = (props) => {
  const theme = useTheme();
  const auth = useAuth();
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth="xl" sx={{ display: "flex", flexDirection: "column" }}>
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          backgroundColor: "background.default",
          color: "#000",
        }}
      >
        <Container maxWidth="xl" disableGutters>
          <Toolbar sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                borderRadius: theme.shape.borderRadius,
                width: "100%",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  width: 40,
                  height: 40,
                  display: { md: "none", xs: "flex" },
                }}
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ p: 1, maxWidth: 170 }}>
                <img
                  className="img-fluid"
                  src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/logo.png`}
                  alt="logo"
                  style={{ height: 45, width: "80%" }}
                />{" "}
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                // alignItems="flex-end"
              >
                <NotificationsMenu />

                {!isDownSm && (
                  <>
                    <Divider orientation="vertical" flexItem />
                    <Button
                      size="small"
                      sx={{ ml: 2 }}
                      startIcon={<MapsHomeWorkOutlinedIcon />}
                    >
                      {auth.user?.office?.name}
                    </Button>
                  </>
                )}

                <Divider orientation="vertical" flexItem />
                <AccountMenu />
              </Stack>
            </Box>

            <Box
              sx={{
                //display: "flex",
                display: { md: "flex", xs: "none" },
                flexGrow: 1,
                borderRadius: theme.shape.borderRadius,
                mt: 1,
                p: 2,
                width: "100%",
                backgroundColor: "#fff",
                boxShadow:
                  "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px",
              }}
            >
              <HorizontalNavBar />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        sx={{
          width: 320,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 320,
            boxSizing: "border-box",
          },
        }}
        transitionDuration={500}
        onClose={handleDrawerClose}
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? <CloseIcon /> : <CloseIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <VerticalNavBar />
      </Drawer>

      <DrawerHeader />
      <Box sx={{ mb: 4, mt: { md: 14, xs: 0 } }}>{props.children}</Box>
    </Container>
  );
};
