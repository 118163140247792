import { isEqual } from "lodash";
import create from "zustand";
import { BagModel } from "../../../application/models/shared/bagModel";
import { DistrictShipmentsModel } from "../../../application/models/shared/districtShipmentModel";

export interface PrepareBagsStateModel {
  loading: boolean;
  error: string;
  currentDistrict: number | undefined;
  data: DistrictShipmentsModel[] | [];
  districtsBags: DistrictsBagsStateModel[] | [];
  alert: AlertStateModel;
  setLoading: (isLoading: boolean, error: string) => void;
  setData: (data: DistrictShipmentsModel[] | []) => void;
  setBarCode: (barCodeScanned: string) => void;
  addNewBag: (districtId: number) => void;
  setActiveBag: (districtId: number, bagIndex: number) => void;
  removeBag: (districtId: number, bagIndex: number) => void;
  removeShipmentFromBag: (
    districtId: number,
    bagIndex: number,
    barcode: string
  ) => void;
  setSavedBag: (
    districtId: number,
    bagIndex: number,
    dataSaved: BagModel
  ) => void;
  nbrBagsByDistrict: (districtId: number) => number;
  barcodesByDistrict: (districtId: number) => string[];
  checkedBarcodesByDistrict: (districtId: number) => string[];
  needNewBag: (districtId: number) => boolean;
  handleChangeDistrict: (districtId: number) => void;
}

export interface DistrictsBagsStateModel {
  id: number;
  bags: BagItemStateModel[] | [];
}

export interface BagItemStateModel {
  active: boolean;
  saved: boolean;
  shipments: string[];
  dataSaved?: BagModel;
}

export interface AlertStateModel {
  type: "success" | "error";
  message: string;
}

export const usePrepareBagsStore = create<PrepareBagsStateModel>(
  (set, get) => ({
    loading: false,
    error: "",
    data: [],
    districtsBags: [],
    alert: {
      type: "success",
      message: "",
    },
    currentDistrict: undefined,
    setLoading: (isLoading: boolean, error: string) => {
      set((state) => ({ ...state, loading: isLoading, error }));
    },
    setData: (data: DistrictShipmentsModel[] | []) => {
      const generateInitialState = (
        responseData: DistrictShipmentsModel[] | []
      ): DistrictsBagsStateModel[] | [] => {
        return (responseData || []).map((item) => {
          return {
            id: item.district.id,
            bags: [
              {
                active: true,
                saved: false,
                shipments: [],
              },
            ],
          } as DistrictsBagsStateModel;
        });
      };

      const _data = generateInitialState(data);

      set((state) => ({
        ...state,
        loading: false,
        error: "",
        data,
        currentDistrict: _data[0]?.id || undefined,
        districtsBags: _data,
      }));
    },
    needNewBag: (districtId: number) => {
      return (
        get().checkedBarcodesByDistrict(districtId).length > 0 &&
        get().checkedBarcodesByDistrict(districtId).length <
          get().barcodesByDistrict(districtId).length &&
        get().barcodesByDistrict(districtId).length >
          get().nbrBagsByDistrict(districtId)
      );
    },
    addNewBag: (districtId: number) => {
      set((state) => ({
        ...state,
        districtsBags: state.districtsBags.map((item) => {
          if (item.id === districtId) {
            return {
              ...item,
              bags: [
                ...item.bags,
                {
                  open: true,
                  active: false,
                  saved: false,
                  shipments: [],
                },
              ],
            };
          } else {
            return item;
          }
        }),
      }));
    },
    handleChangeDistrict: (districtId: number) => {
      set((state) => ({
        ...state,
        currentDistrict: districtId,
      }));
    },
    setActiveBag: (districtId: number, bagIndex: number) => {
      set((state) => ({
        ...state,
        districtsBags: state.districtsBags.map((item) => {
          if (item.id === districtId) {
            return {
              ...item,
              bags: item.bags.map((bag, index) => {
                return {
                  ...bag,
                  active: index === bagIndex,
                };
              }),
            };
          } else {
            return item;
          }
        }),
      }));
    },
    removeBag: (districtId: number, bagIndex: number) => {
      set((state) => ({
        ...state,
        districtsBags: state.districtsBags.map((item) => {
          if (item.id === districtId) {
            return {
              ...item,
              bags: item.bags.filter((bag, index) => index !== bagIndex),
            };
          } else {
            return item;
          }
        }),
      }));
    },
    removeShipmentFromBag: (
      districtId: number,
      bagIndex: number,
      barcode: string
    ) => {
      set((state) => ({
        ...state,
        districtsBags: state.districtsBags.map((item) => {
          if (item.id === districtId) {
            return {
              ...item,
              bags: item.bags.map((bag, index) => {
                if (index === bagIndex) {
                  return {
                    ...bag,
                    shipments: bag.shipments.filter((ship) => ship !== barcode),
                  };
                }
                return bag;
              }),
            };
          } else {
            return item;
          }
        }),
      }));
    },
    setSavedBag: (
      districtId: number,
      bagIndex: number,
      dataSaved: BagModel
    ) => {
      set(
        (state) =>
          ({
            ...state,
            districtsBags: state.districtsBags.map((item) => {
              if (item.id === districtId) {
                return {
                  ...item,
                  bags: item.bags.map((bag, index) => {
                    return {
                      ...bag,
                      saved: index === bagIndex ? true : bag.saved,
                      ...(() =>
                        index === bagIndex
                          ? { dataSaved }
                          : { dataSaved: bag?.dataSaved || undefined })(),
                    };
                  }),
                };
              } else {
                return item;
              }
            }),
          } as PrepareBagsStateModel)
      );
    },

    checkedBarcodesByDistrict: (districtId: number) => {
      const districtInState = get().districtsBags.find(
        (district) => district.id === districtId
      );
      return (districtInState?.bags || []).reduce((acc: string[], curr) => {
        return [...acc, ...curr.shipments];
      }, []);
    },

    barcodesByDistrict: (districtId: number) => {
      const district = get().data.find(
        (item) => item.district.id === districtId
      );
      return (district?.shipments || []).map((item) => item.identifier);
    },
    nbrBagsByDistrict: (districtId: number) => {
      const districtInState = get().districtsBags.find(
        (district) => district.id === districtId
      );
      return districtInState?.bags.length || 0;
    },

    setBarCode: (barCodeScanned: string) => {
      const getDistrictIncludedBarCode = (
        barCodeScanned: string
      ): DistrictShipmentsModel | undefined => {
        return get()
          .data.filter((item) => item.district.id === get().currentDistrict)
          .find((item) => {
            return item.shipments
              .map((shipment) => shipment.identifier)
              .includes(barCodeScanned);
          });
      };

      const _currentDistrict = get().data.find(
        (item) => item.district.id === get().currentDistrict
      );

      const districtData = getDistrictIncludedBarCode(barCodeScanned);
      if (districtData && barCodeScanned) {
        const updatedState = {
          ...get(),
          districtsBags: get().districtsBags.map((item) => {
            if (item.id === districtData.district.id) {
              return {
                ...item,
                bags: item.bags.map((bag) => {
                  if (bag.active === true && bag.saved === false) {
                    return {
                      ...bag,
                      shipments: !get()
                        .checkedBarcodesByDistrict(item.id)
                        .includes(barCodeScanned)
                        ? [...bag.shipments, barCodeScanned]
                        : bag.shipments,
                    };
                  } else {
                    return bag;
                  }
                }),
              };
            } else {
              return item;
            }
          }),
        };
        if (!isEqual(get(), updatedState)) {
          set((state) => ({
            ...updatedState,
            alert: {
              type: "success",
              message: `Barcode ${barCodeScanned}  ajouté à ${districtData.district.name}`,
            },
          }));
        } else {
          if (
            get()
              .checkedBarcodesByDistrict(Number(districtData.district.id))
              .includes(barCodeScanned)
          ) {
            set((state) => ({
              ...state,
              alert: {
                type: "error",
                message: `Barcode déja ajouté à ${districtData.district.name}`,
              },
            }));
          } else {
            set((state) => ({
              ...state,
              alert: {
                type: "error",
                message: "Merci de bien vouloir vérifier la bag active",
              },
            }));
          }
        }
      } else {
        set((state) => ({
          ...state,
          alert: {
            type: "error",
            message: `Barrecode non trouvé dans ${_currentDistrict?.district.name}`,
          },
        }));
      }
    },
  })
);
