import { yupResolver } from "@hookform/resolvers/yup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import React, { FC, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import * as yup from "yup";
import { postOfficeService } from "../../../../application/api/services/postOffice.service";
import { PostOfficeModel } from "../../../../application/models/shared/postOfficeModel";
import { OfficeFormModel } from "./models";
import StructureForm from "../structureForm";
import { useFetchDistrictsQuery } from "../../../../application/api/hooks/district.hook";
import { get } from "lodash";
import { DistrictModel } from "../../../../application/models/shared/districtModel";

const postOfficeSchame = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  zip_code: yup.string().required(),
});

const structureSchame = yup.object().shape({
  id: yup.number().required(),
  type: yup.string().required(),
});

const addressSchame = yup.object().shape({
  address_line: yup.string().required(),
  post_office: postOfficeSchame,
});

const schema = yup.object().shape({
  district: yup.mixed().required(),
  name: yup.string().required(),
  address: addressSchame,
  is_central: yup.boolean(),
  structure: yup
    .array()
    .of(structureSchame)
    .when("is_central", {
      is: false,
      then: yup
        .array()
        .of(structureSchame)
        .required("Doit avoir des codes postaux")
        .min(1, "Au moins 1 code postal"),
    }),
  // structure: yup
  //   .array()
  //   .of(structureSchame)
  //   .required("Doit avoir des codes postaux")
  //   .min(1, "Au moins 1 code postal"),
});

const OfficeForm: FC<{
  defaultValues?: OfficeFormModel;
  loading?: boolean;
  onValide: (data: OfficeFormModel) => void;
}> = (props) => {
  const districtsQuery = useFetchDistrictsQuery({
    pagination: { page: 1, per_page: 100 },
  });
  const [cityOptions, setCityOptions] = useState<PostOfficeModel[] | []>([]);

  const fetchPostOfficesOptions = (searchTerm: string) => {
    postOfficeService
      .fetch({ search: searchTerm, per_page: 20, page: 1 })
      .then(({ data }) => {
        setCityOptions(data.data);
      })
      .catch((error) => setCityOptions([]));
  };

  const districts = get(districtsQuery, ["data", "data", "data"], []);

  const formCtx = useForm<OfficeFormModel>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: props?.defaultValues || {
      name: "",
      district: undefined,
      address: {
        address_line: "",
        post_office: undefined,
      },
      structure: [],
      is_central: false,
      is_platform: false,
    },
  });

  const formValues = formCtx.watch();

  const {
    formState: { errors },
  } = formCtx;

  const onSubmit: SubmitHandler<OfficeFormModel> = (data) => {
    props.onValide(data);
  };

  return (
    <FormProvider {...formCtx}>
      <form onSubmit={formCtx.handleSubmit(onSubmit)}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item md={5} xs={12}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography sx={{ fontWeight: 500 }}>Bureau</Typography>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Controller
                  name="district"
                  control={formCtx.control}
                  render={({ field }) => (
                    <Autocomplete
                      fullWidth={true}
                      popupIcon={<KeyboardArrowDownIcon />}
                      id="district"
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onInputChange={(event, newInputValue) => {
                        fetchPostOfficesOptions(newInputValue);
                      }}
                      getOptionLabel={(option) => `${option.name}`}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > *": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {`${option.name}`}
                        </Box>
                      )}
                      options={districts}
                      {...field}
                      value={field?.value || null}
                      onChange={(
                        event: React.SyntheticEvent<Element, Event>,
                        newValue: DistrictModel | null
                      ) => {
                        event.preventDefault();
                        field.onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="District"
                          error={Boolean(errors?.district)}
                          helperText={
                            errors?.district ? errors?.district?.message : null
                          }
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          autoComplete="off"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="name"
                  control={formCtx.control}
                  render={({ field }) => (
                    <TextField
                      autoComplete="new-password"
                      fullWidth
                      label="Bureau"
                      {...field}
                      error={Boolean(errors?.name)}
                      helperText={errors?.name ? errors?.name.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="address.address_line"
                  control={formCtx.control}
                  render={({ field }) => (
                    <TextField
                      autoComplete="new-password"
                      fullWidth
                      label="Adresse"
                      {...field}
                      error={Boolean(errors?.address?.address_line)}
                      helperText={
                        errors?.address?.address_line
                          ? errors?.address?.address_line?.message
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="address.post_office"
                  control={formCtx.control}
                  render={({ field }) => (
                    <Autocomplete
                      fullWidth={true}
                      popupIcon={<KeyboardArrowDownIcon />}
                      id="post_office"
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onInputChange={(event, newInputValue) => {
                        fetchPostOfficesOptions(newInputValue);
                      }}
                      getOptionLabel={(option) =>
                        `${option.name}-${option.zip_code}`
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > *": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {`${option.name}-${option.zip_code}`}
                        </Box>
                      )}
                      groupBy={(option) => option.zip_code}
                      options={cityOptions}
                      {...field}
                      value={field.value || null}
                      onChange={(
                        event: any,
                        newValue: PostOfficeModel | null
                      ) => {
                        field.onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Adresse postale"
                          error={Boolean(errors?.address?.post_office)}
                          helperText={
                            errors?.address?.post_office
                              ? errors?.address?.post_office?.message
                              : null
                          }
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          autoComplete="off"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="is_central"
                  control={formCtx.control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                        />
                      }
                      label="Bureau central"
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="is_platform"
                  control={formCtx.control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                        />
                      }
                      label="Platforme"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation="vertical" variant="middle" flexItem />

          <Grid item md={6} xs={12}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography sx={{ fontWeight: 500 }}>
                      Codes postaux de bureau
                    </Typography>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              </Grid>

              <Grid item>
                <StructureForm />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                {/* <Button
                  color="primary"
                  type="submit"
                  variant="outlined"
                  {...props.previousButton}
                  startIcon={<KeyboardArrowLeftOutlinedIcon />}
                >
                  Retour
                </Button> */}
              </Grid>
              <Grid item>
                <Box sx={{ m: 1, position: "relative" }}>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={props?.loading}
                  >
                    Sauvegarder
                  </Button>
                  {props?.loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default OfficeForm;
