import * as React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Box, TableCell } from "@mui/material";

import { visuallyHidden } from "@mui/utils";
import { OrderDirectionModel } from "../../application/models/shared/orderDirectionModel";
import { StyledTableCell } from "../Styled/StyledTableCell";

export interface HeadCell {
  disablePadding: boolean;
  sortable: boolean;
  id: string;
  label: string;
  align?: "left" | "right" | "center";
  width?: number | string;
}

interface EnhancedTableHeadProps {
  cells: HeadCell[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: OrderDirectionModel;
  orderBy: string;
}

export const EnhancedTableHead = (
  props: EnhancedTableHeadProps
): JSX.Element => {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {props.cells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell?.align ? headCell?.align : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell?.width || undefined}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
