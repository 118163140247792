import { Alert, Grid, LinearProgress } from "@mui/material";
import { FC, useEffect } from "react";
import { priceFormat } from "../../application/helpers/priceFormat";
import CardControler from "./CardControler";
import { useDashboardController } from "./dashboardController";

export const StateOffice: FC = (): JSX.Element => {
  const { ui, filter, methods } = useDashboardController();

  useEffect(() => {
    ui.stateOffice();
  }, [filter.formValues]);

  if (ui.isLoading) {
    return <LinearProgress />;
  }

  if (!ui.isLoading && ui.isError) {
    return <Alert severity="error">Error</Alert>;
  }

  const sum = methods.sumOfficesState();

  return (
    <>
      {!ui.isLoading && ui.dataStateOffice && (
        <Grid
          container
          justifyContent="space-between"
          alignItems={"stretch"}
          spacing={2}
        >
          <Grid item md={6} xs={12}>
            <CardControler
              mainTitle="Réceptionnée(s)"
              secondaryTitle={priceFormat(
                ui.dataStateOffice.shipments_total_amount
              )}
              imgSrc="/assets/images/undraw_logistics_x4dc.png"
              value={ui.dataStateOffice.shipments_total_number}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CardControler
              mainTitle="CRBT"
              secondaryTitle={priceFormat(
                ui.dataStateOffice.reimbursement_total_amount
              )}
              imgSrc="/assets/icons/Cash Payment-pana.png"
              value={ui.dataStateOffice.reimbursement_total_number}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
