import { ErrorMessage } from "@hookform/error-message";
import {
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { get, isUndefined } from "lodash";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomerModel } from "../../../../application/models/shared/customerModel";
import { DropzoneFile } from "../../../../components/dropzoneFile";

const AccountForm: FC = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const formValues = watch() as CustomerModel;

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Controller
          name="account.is_company"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              }
              label="Une entreprise"
            />
          )}
        />
      </Grid>

      <Grid item>
        <Controller
          name={`account.email`}
          control={control}
          render={({ field }) => (
            <TextField
              label="E-mail"
              {...field}
              size="small"
              fullWidth
              type="email"
              error={!isUndefined(get(errors, ["account", "email"], undefined))}
              helperText={get(errors, ["account", "email", "message"], null)}
            />
          )}
        />
      </Grid>
      {!formValues?.account?.is_company && (
        <Grid item>
          <Controller
            render={({ field }) => (
              <>
                <DropzoneFile
                  file={field?.value || undefined}
                  onChange={(file) => field.onChange(file)}
                  label="Identité personnelle"
                  error={
                    !isUndefined(
                      get(errors, ["personal_identity_file"], undefined)
                    )
                  }
                />
                <ErrorMessage
                  errors={errors}
                  name="personal_identity_file"
                  render={({ message }) => (
                    <Typography color="error" variant="caption" sx={{ ml: 2 }}>
                      {message}
                    </Typography>
                  )}
                />
              </>
            )}
            name="personal_identity_file"
            control={control}
          />
        </Grid>
      )}

      {formValues?.account?.is_company && (
        <Grid item>
          <Controller
            name={`account.tax_registry_number`}
            control={control}
            render={({ field }) => (
              <TextField
                label="Numéro de registre fiscal"
                {...field}
                size="small"
                fullWidth
                error={
                  !isUndefined(
                    get(errors, ["account", "tax_registry_number"], undefined)
                  )
                }
                helperText={get(
                  errors,
                  ["account", "tax_registry_number", "message"],
                  null
                )}
              />
            )}
          />
        </Grid>
      )}

      {formValues?.account?.is_company && (
        <Grid item>
          <Controller
            render={({ field }) => (
              <>
                <DropzoneFile
                  file={field?.value || undefined}
                  onChange={(file) => field.onChange(file)}
                  label="Identité de l'entreprise"
                  error={
                    !isUndefined(
                      get(errors, ["company_identity_file"], undefined)
                    )
                  }
                />
                <ErrorMessage
                  errors={errors}
                  name="company_identity_file"
                  render={({ message }) => (
                    <Typography color="error" variant="caption" sx={{ ml: 2 }}>
                      {message}
                    </Typography>
                  )}
                />
              </>
            )}
            name="company_identity_file"
            control={control}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AccountForm;
