import {
  BagStatusEnumModel,
  RecordBagStatusModel,
} from "../models/shared/bagStatusModel";

export const bagStatus: RecordBagStatusModel = {
  [BagStatusEnumModel.ready_for_forwarding_to_reception_district]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Prêt à être acheminé vers le district d'accueil",
    activeStyle: {
      color: "black",
      bgColor: "#6690FF",
    },
  },
  [BagStatusEnumModel.forwarded_to_reception_district]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Transmis au district d'accueil",
    activeStyle: {
      color: "black",
      bgColor: "#6690FF",
    },
  },
  [BagStatusEnumModel.received_in_reception_district]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Reçu dans le district d'accueil",
    activeStyle: {
      color: "black",
      bgColor: "#6690FF",
    },
  },
  [BagStatusEnumModel.ready_for_forwarding_to_delivery_district]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Prêt à être acheminé vers le district de livraison",
    activeStyle: {
      color: "black",
      bgColor: "#6690FF",
    },
  },
  [BagStatusEnumModel.forwarded_to_delivery_district]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "transmis au district de livraison",
    activeStyle: {
      color: "black",
      bgColor: "#6690FF",
    },
  },
  [BagStatusEnumModel.received_in_delivery_district]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Reçu dans le district de livraison",
    activeStyle: {
      color: "black",
      bgColor: "#6690FF",
    },
  },
  [BagStatusEnumModel.ready_for_forwarding_to_delivery_office]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Prêt à être envoyé au bureau de livraison",
    activeStyle: {
      color: "black",
      bgColor: "#6690FF",
    },
  },
  [BagStatusEnumModel.forwarded_to_delivery_office]: {
    color: "black",
    bgColor: "#BCBCBA",
    label: "Transmis au bureau de livraison",
    activeStyle: {
      color: "black",
      bgColor: "#6690FF",
    },
  },
};
