import React, { ReactElement } from "react";

type ChildType = ReactElement;
type ChildrenType = ChildType | ChildType[];

export interface IfProps {
  condition: boolean;
  render: () => ChildrenType;
  otherwise?: () => ChildrenType;
}

export default function If(props: IfProps): ChildType {
  if (props.condition === true) {
    return <>{props.render()}</>;
  }

  return typeof props.otherwise !== "undefined" ? (
    <>{props.otherwise()}</>
  ) : (
    <></>
  );
}
