import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { palette } from "./palette";
const FONT_PRIMARY = "'Ubuntu', sans-serif";
const regular = 400;
const light = 300;
const lineHeight = 1.2;

const theme = createTheme({
  palette,
  shape: {
    borderRadius: 0,
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*::-webkit-scrollbar": {
          width: "0.4em",
        },
        "*::-webkit-scrollbar-track": {
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.1)",
          outline: "0px solid slategrey",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: { textTransform: "none", letterSpacing: 0.5 },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          transform: "translate(13px, -9px) scale(0.80)",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "none",
          "&.Mui-disabled": {
            backgroundColor: "#0000000d",
          },
        },
        notchedOutline: {
          backgroundColor: "none",
        },
      },
    },
    // MuiTableCell: {
    //   styleOverrides: {
    //     head: {
    //       backgroundColor: grey[800],
    //       color: "white",
    //     },
    //   },
    // },
    MuiInput: {
      styleOverrides: {
        root: {
          border: "solid 1px red",
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none",
            },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
        },
      },
    },
    // MuiTableHead: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: grey[800],
    //       color: "white",
    //     },
    //   },
    // },
    // MuiTableSortLabel: {
    //   styleOverrides: {

    //     icon: {
    //       color: "inherit !important",
    //     },
    //   },
    // },

    // MuiListItemButton: {
    //   styleOverrides: {
    //     root: {
    //       "&:hover": {
    //         borderRight: `solid 3px ${palette.secondary.dark}`,
    //       },
    //       "&.Mui-selected": {
    //         color: palette.primary.main,
    //         backgroundColor: "#EBF0FF",
    //         borderRight: `solid 3px ${palette.primary.dark}`,
    //         "&:hover": {
    //           color: palette.primary.main,
    //           backgroundColor: "transparent",
    //         },
    //       },
    //     },
    //   },
    // },
  },
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 600,
  //     md: 960,
  //     lg: 1280,
  //     xl: 1920,
  //   },
  // },
  shadows: [
    "none",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
  ],

  typography: {
    fontFamily: FONT_PRIMARY,

    h1: {
      fontFamily: FONT_PRIMARY,
      fontSize: "3.75rem",
      letterSpacing: "-1.5%",
      fontWeight: light,
      lineHeight: lineHeight,
    },
    h2: {
      fontFamily: FONT_PRIMARY,
      fontSize: "3.5rem",
      letterSpacing: "-0.5%",
      lineHeight: lineHeight,
      fontWeight: light,
    },
    h3: {
      fontFamily: FONT_PRIMARY,
      fontSize: "3rem",
      letterSpacing: "0%",
      lineHeight: lineHeight,
      fontWeight: regular,
    },
    h4: {
      fontFamily: FONT_PRIMARY,
      fontSize: "2rem",
      letterSpacing: "0.25%",
      lineHeight: lineHeight,
      fontWeight: regular,
    },
    h5: {
      fontFamily: FONT_PRIMARY,
      fontSize: "1.75rem",
      letterSpacing: "0%",
      lineHeight: lineHeight,
      fontWeight: regular,
    },
    h6: {
      fontFamily: FONT_PRIMARY,
      fontSize: "1rem",
      letterSpacing: "0%",
      fontWeight: regular,
    },
    body1: {
      fontSize: "0.95rem",
      fontFamily: FONT_PRIMARY,
      fontWeight: regular,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.95rem",
      fontFamily: FONT_PRIMARY,
      fontWeight: regular,
      lineHeight: 1.5,
    },
  },
});

export default theme;
