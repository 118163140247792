import axios from "axios";
import { ReimbursementFormModel } from "../../../pages_components/shipments/forms/reimbursementForm/index";
import httpClient from "../../httpClient";
import {
  ReimbursementInCtrlModel,
  ReimbursementOutCtrlModel,
} from "../../models/shared/reimbursementCtrlModel";

const FormData = require("form-data");
const fs = require("fs");

const fetchReimbursementIn = async (): Promise<{
  data: ReimbursementInCtrlModel[];
}> => {
  return httpClient.get(`reimbursement/in`);
};

const fetchReimbursementOut = async (): Promise<{
  data: ReimbursementOutCtrlModel[];
}> => {
  return httpClient.get(`reimbursement/out`);
};

const reimbursementControl = async (identifier: string): Promise<any> => {
  return httpClient.post(`reimbursement/control`, { identifier });
};

const reimbursementDeliver = async (
  data: ReimbursementFormModel
): Promise<any> => {
  const formData = new FormData();
  formData.append("code", data.code);
  formData.append("identifier", data.identifier);
  formData.append("check_number", data.check_number);
  formData.append("check_picture", data?.check_picture);

  return httpClient.post(`reimbursement/deliver`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const reimbursementService = {
  fetchReimbursementIn,
  fetchReimbursementOut,
  reimbursementControl,
  reimbursementDeliver,
};
