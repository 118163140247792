import { StateOfficesModel } from "./../../models/shared/stateShipmentsModel";
import httpClient from "../../httpClient";
import { FundModel } from "../../models/shared/fundModel";
import { FundOfficeModel } from "../../models/shared/FundsOfficeModel";
import { StateOfficeModel } from "../../models/shared/stateShipmentsModel";
import { NotificationsModel } from "../../notifications/models";

export interface OfficeShipmentsRequestModel {
  start_date: string;
  end_date: string;
}

const fetch = async (): Promise<{ data: NotificationsModel }> => {
  return httpClient.get(`state/global`);
};

const fetchFundsOffice = async (): Promise<{
  data: FundOfficeModel[];
}> => {
  return httpClient.get(`state/funds/office`);
};

const fetchFundsMine = async (): Promise<{ data: { funds: FundModel[] } }> => {
  return httpClient.get(`state/funds/mine`);
};

const controlFunds = async (funds: number[]): Promise<{ data: any }> => {
  return httpClient.post(`state/funds/control`, {
    funds,
  });
};

const stateOffice = async (
  params: OfficeShipmentsRequestModel
): Promise<StateOfficeModel> => {
  return httpClient.get(`state/office`, { params }).then(({ data }) => data);
};

const stateOffices = async (
  params: OfficeShipmentsRequestModel
): Promise<StateOfficesModel> => {
  return httpClient.get(`state/platform`, { params }).then(({ data }) => data);
};

export const globalStateService = {
  fetch,
  fetchFundsOffice,
  fetchFundsMine,
  controlFunds,
  stateOffice,
  stateOffices,
};
