import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import {
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import { reimbursementService } from "../../../application/api/services/reimbursement.service";
import { priceFormat } from "../../../application/helpers/priceFormat";
import { ReimbursementItemCtrlModel } from "../../../application/models/shared/reimbursementCtrlModel";
import { useReimbursementInStore } from "../../../pages/reimbursement/hooks/reimbursementInState";
import ReimbursementForm from "../../shipments/forms/reimbursementForm";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";

interface ReimbursementCtrlItemStateModel {
  openDialog: boolean;
  isLoading: boolean;
  error: string;
  isValide: boolean;
}

export const ReimbursementOutCtrlItem: FC<{
  itemReimbursement: ReimbursementItemCtrlModel;
}> = ({ itemReimbursement }): JSX.Element => {
  const { fetch } = useReimbursementInStore();
  const [state, setState] = useState<ReimbursementCtrlItemStateModel>({
    openDialog: false,
    isLoading: false,
    error: "",
    isValide: false,
  });

  useEffect(() => {
    setState((state) => ({
      openDialog: false,
      isLoading: false,
      error: "",
      isValide: false,
    }));
  }, [itemReimbursement]);

  const setOpenDialog = (open: boolean) => {
    setState((state) => ({ ...state, openDialog: open }));
  };

  const save = () => {
    setState((state) => ({
      ...state,
      isLoading: true,
      error: "",
      openDialog: false,
    }));
    reimbursementService
      .reimbursementControl(itemReimbursement.shipment.identifier)
      .then((response) => {
        setState((state) => ({
          ...state,
          isLoading: false,
          isValide: true,
          error: "",
        }));
        fetch();
      })
      .catch((error) => {
        setState((state) => ({
          ...state,
          isLoading: false,
          error: "Error",
        }));
      });
  };

  return (
    <Paper
      sx={{
        backgroundColor: state.isValide ? "#e1efeb" : null,
        p: 3,
      }}
    >
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Box
            sx={{
              borderLeft: "solid 5px #eee",
              p: 1,
              height: "100%",
            }}
          >
            <Grid container direction="column">
              <Grid item>
                <Typography sx={{ fontWeight: 500 }} variant="body2">
                  Destinataire
                </Typography>
              </Grid>
              <Grid item>
                <Stack spacing={2} direction="row" alignItems="flex-end">
                  <PersonOutlineOutlinedIcon fontSize="small" />
                  <Typography variant="body2" sx={{ fontSize: 13 }}>
                    {itemReimbursement.shipment.recipient.first_name}{" "}
                    {itemReimbursement.shipment.recipient.last_name}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing={2} direction="row" alignItems="flex-end">
                  <LocalPhoneOutlinedIcon fontSize="small" />
                  <Typography variant="body2" sx={{ fontSize: 13 }}>
                    {itemReimbursement.shipment.recipient?.phone_number}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing={2} direction="row" alignItems="flex-end">
                  <PlaceOutlinedIcon fontSize="small" />
                  <Typography variant="body2" sx={{ fontSize: 13 }}>
                    {
                      itemReimbursement.shipment.recipient?.address
                        ?.address_line
                    }
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing={2} direction="row" alignItems="flex-end">
                  <HomeWorkOutlinedIcon fontSize="small" />
                  <Typography variant="body2" noWrap sx={{ fontSize: 13 }}>
                    {
                      itemReimbursement.shipment.recipient?.address?.post_office
                        ?.name
                    }{" "}
                    /{" "}
                    {
                      itemReimbursement.shipment.recipient?.address?.post_office
                        ?.zip_code
                    }
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>

          {/* <Stack spacing={1} direction="row" alignItems="flex-end">
            <QueryBuilderOutlinedIcon fontSize="small" />
            <Typography variant="body2" noWrap sx={{ fontSize: 14 }}>
              {itemReimbursement?.received_at}
            </Typography>
          </Stack> */}
        </Grid>

        <Grid item>
          <Box sx={{ borderLeft: "solid 5px #eee", p: 1 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-start"
              wrap="nowrap"
            >
              <Grid item>
                <Typography variant="body2" noWrap sx={{ fontSize: 13 }}>
                  <b>Date :</b> {itemReimbursement.shipment?.received_at}
                </Typography>
                <Typography variant="body2" noWrap sx={{ fontSize: 13 }}>
                  <b> Poids :</b> {itemReimbursement.shipment.weight} Kg
                </Typography>
                {itemReimbursement.shipment.reimbursement.enable &&
                  itemReimbursement.shipment.reimbursement.amount > 0 && (
                    <Typography variant="body2" noWrap sx={{ fontSize: 13 }}>
                      <b> CRBT :</b>{" "}
                      <span style={{ fontWeight: 500, color: "red" }}>
                        {priceFormat(
                          itemReimbursement.shipment.reimbursement.amount
                        )}
                      </span>
                    </Typography>
                  )}
                <Typography variant="body2" noWrap sx={{ fontSize: 13 }}>
                  <b> Identifiant :</b> {itemReimbursement.shipment.identifier}
                </Typography>
              </Grid>
              <Grid item>
                {" "}
                {/* <QRCode value={shipment.identifier} size={50} /> */}
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item>
          {!state.isValide ? (
            <Button
              color="success"
              onClick={() => setOpenDialog(!state.openDialog)}
            >
              {state.isLoading ? <CircularProgress /> : "Valider"}
            </Button>
          ) : (
            <CheckOutlinedIcon fontSize="large" color="success" />
          )}
        </Grid>
      </Grid>
      <Collapse in={state.openDialog} timeout="auto" unmountOnExit>
        <div style={{ marginTop: 30 }}>
          <Divider sx={{ mb: 3 }} />
          <ReimbursementForm shipment={itemReimbursement?.shipment} />
        </div>
      </Collapse>
    </Paper>
  );
};
