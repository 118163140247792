import FilterListIcon from "@mui/icons-material/FilterList";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import {
  Alert,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { green } from "@mui/material/colors";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import { format } from "date-fns";
import { get } from "lodash";
import * as React from "react";
import { useFetchCustomerShipmentsQuery } from "../../application/api/hooks/customer.hook";
import { FetchShipmentsResponseModel } from "../../application/api/services/dispatching.service";
import { invoiceService } from "../../application/api/services/invoice.service";
import { DATE_FORMAT } from "../../application/constants/appConstants";
import { DETAILS_PACKAGE_ROUTE } from "../../application/constants/routeConstants";
import { shipmentStatus } from "../../application/data/ShipmentStatus";
import { ShipmentStatusEnumModel } from "../../application/models/shared/shipmentStatusModel";
import { ShipmentStatusChip } from "../../components/shipmentStatusChip";
import { SnackbarContext } from "../../components/snackbarProvider";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";
import { ShipmentsFilterForm } from "../../pages_components/customers/Forms/shipmentsFilterForm";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

interface StateModel {
  currentPage: number;
  searchText: string;
  status: ShipmentStatusEnumModel[];
  start_date: string | null;
  end_date: string | null;
  selected: number[];
  generatePdfProgress: boolean;
  generatePdfError?: string;
}

const CustomerShipments: React.FC<{ customerId: number }> = ({
  customerId,
}): JSX.Element => {
  const [snackbar, setSnackbar] = React.useContext(SnackbarContext);
  const [state, setState] = React.useState<StateModel>({
    currentPage: 1,
    searchText: "",
    status: [],
    start_date: null,
    end_date: null,
    selected: [],
    generatePdfProgress: false,
    generatePdfError: "",
  });

  const generatePdf = () => {
    if (state.selected.length > 0) {
      setState((state) => ({
        ...state,
        generatePdfProgress: true,
        generatePdfError: "",
      }));
      invoiceService
        .generate({
          customer: customerId,
          shipments: state.selected,
        })
        .then((response) => {
          setState((state) => ({
            ...state,
            generatePdfProgress: false,
            generatePdfError: "",
          }));

          setSnackbar({
            open: true,
            severity: "success",
            message: "Facture generée avec succès",
          });
        })
        .catch((error) => {
          setState((state) => ({
            ...state,
            generatePdfProgress: false,
            generatePdfError: "Error",
          }));

          setSnackbar({
            open: true,
            severity: "error",
            message: "Erruer se produite",
          });
        });
    }
  };

  const shipmentsQuery = useFetchCustomerShipmentsQuery({
    id: customerId,
    pagination: { page: state.currentPage, per_page: 10 },
    status: state.status,
    start_date: state.start_date,
    end_date: state.end_date,
  });
  const {
    data: { data, current_page, total },
  } = get(shipmentsQuery, ["data"], {
    data: [],
    current_page: 1,
    total: 0,
  }) as FetchShipmentsResponseModel;

  const [open, setOpen] = React.useState(false);
  const toggleFilter = () => {
    setOpen((prev) => !prev);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setState((state) => ({ ...state, currentPage: value, selected: [] }));
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((item) => item.id);
      setState((state) => ({ ...state, selected: newSelecteds }));
      return;
    }
    setState((state) => ({ ...state, selected: [] }));
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = state.selected.indexOf(id);

    event.stopPropagation();

    setState((state) => ({
      ...state,
      selected:
        selectedIndex > -1
          ? state.selected.filter((item) => item !== id)
          : [...state.selected, id],
    }));
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        {shipmentsQuery.isError === true && shipmentsQuery.isLoading === false && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="error">Error</Alert>
          </Box>
        )}
        {shipmentsQuery.isLoading === false && data?.length === 0 && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="info">Aucune donnée disponible!</Alert>
          </Box>
        )}
        {shipmentsQuery.isLoading && <LinearProgress />}

        <Paper>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(state.selected.length > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
          >
            {state.selected.length > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%", fontWeight: 500 }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {state.selected.length} Sélectionnés
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%", fontWeight: 500 }}
                id="tableTitle"
                component="div"
              >
                {total} Colis
              </Typography>
            )}
            {state.selected.length > 0 ? (
              <Tooltip title="Générer une facture">
                <IconButton
                  onClick={() => generatePdf()}
                  disabled={state.generatePdfProgress}
                >
                  {state.generatePdfProgress ? (
                    <CircularProgress
                      size={30}
                      sx={{
                        color: green[500],
                      }}
                    />
                  ) : (
                    <InsertDriveFileOutlinedIcon />
                  )}
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Filter list">
                <IconButton onClick={toggleFilter}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            )}
          </Toolbar>

          <Collapse in={open}>
            <Box sx={{ p: 2 }}>
              <ShipmentsFilterForm
                onSubmit={(filterData) => {
                  setState((state) => ({
                    ...state,
                    status: filterData.status,
                    start_date: filterData.start_date
                      ? format(filterData.start_date, DATE_FORMAT)
                      : null,
                    end_date: filterData.end_date
                      ? format(filterData.end_date, DATE_FORMAT)
                      : null,
                  }));
                }}
              />
              {/* <FormControl size="small" sx={{ width: 350 }}>
                <InputLabel id="demo-simple-select-label">Statut</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  multiple
                  // value={[]}
                  value={state.status}
                  input={<OutlinedInput label="Statut" />}
                  onChange={(event: SelectChangeEvent<typeof state.status>) => {
                    const {
                      target: { value },
                    } = event;

                    const _value =
                      typeof value === "string"
                        ? value
                            .split(",")
                            .map((item) => item as ShipmentStatusEnumModel)
                        : (value as ShipmentStatusEnumModel[]);

                    setState((state) => ({ ...state, status: _value }));
                  }}
                  MenuProps={MenuProps}
                >
                  {(
                    Object.keys(
                      shipmentStatus
                    ) as Array<ShipmentStatusEnumModel>
                  ).map((statusOfshipment, index) => (
                    <MenuItem key={index} value={statusOfshipment}>
                      {shipmentStatus[statusOfshipment].label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Box>
          </Collapse>

          {total > 0 && data?.length > 0 && (
            <TableContainer>
              <Table
                sx={{ width: "100%" }}
                //  size="small"
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          state.selected.length > 0 &&
                          state.selected.length < data?.length
                        }
                        checked={
                          data?.length > 0 &&
                          state.selected.length === data?.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Date d'entrée
                    </StyledTableCell>
                    {/* <StyledTableCell align="left">Expéditeur</StyledTableCell> */}
                    <StyledTableCell align="left">Destinateur</StyledTableCell>
                    <StyledTableCell align="left">Poids</StyledTableCell>
                    <StyledTableCell align="left">Code</StyledTableCell>
                    <StyledTableCell align="left">Statut</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(data || []).map((row, index) => {
                    const isItemSelected = state.selected.includes(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        key={index}
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.received_at}
                        </StyledTableCell>
                        {/* <StyledTableCell component="th">
                          {row.sender?.first_name || "--"}{" "}
                          {row.sender?.last_name || "--"}
                        </StyledTableCell> */}
                        <StyledTableCell>
                          {row.recipient?.first_name || "--"}{" "}
                          {row.recipient.last_name || "--"}
                        </StyledTableCell>
                        <StyledTableCell>{row.weight} Kg</StyledTableCell>
                        <StyledTableCell>{row?.identifier}</StyledTableCell>
                        <StyledTableCell>
                          <ShipmentStatusChip
                            active={true}
                            status={row?.status as ShipmentStatusEnumModel}
                            size="small"
                            variant="outlined"
                          />
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            color="inherit"
                            href={`${DETAILS_PACKAGE_ROUTE}/${row.identifier}`}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          >
                            <ReadMoreIcon fontSize="small" />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>{" "}
            </TableContainer>
          )}
        </Paper>

        <div style={{ float: "right", padding: 20 }}>
          {total > 0 && (
            <Pagination
              count={Math.ceil(Number(total) / 10)}
              color="primary"
              showFirstButton
              showLastButton
              page={state.currentPage}
              onChange={handleChangePage}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default CustomerShipments;
