import * as React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface ConfirmDialogPromsModel {
  dialogProps: DialogProps;
  title?: JSX.Element | string;
  body: JSX.Element | string;
  cancelButton: ButtonProps;
  confirmButton: ButtonProps;
}

export const ConfirmDialog: React.FC<ConfirmDialogPromsModel> = (
  props
): JSX.Element => {
  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props.dialogProps}
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="contained" {...props.cancelButton}>
          Annuler
        </Button>
        <Button
          color="primary"
          variant="contained"
          {...props.confirmButton}
          autoFocus
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
