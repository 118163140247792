import { createContext } from "react";
import {
  StructureItemModel,
  StructureItemTypeModel,
} from "../../../../application/models/shared/officeModel";

export const StructureContext = createContext<{
  id?: number;
  levelController: number;
  structureItems: StructureItemModel[] | [];
  dispatch: {
    isChecked: (item: StructureItemModel) => boolean;
    handleCheck: (
      item: StructureItemModel,
      _checked: boolean,
      path?: StructureItemModel[]
    ) => void;
    handleCheckAll: (items: StructureItemModel[]) => void;
    nbrChildrenChecked: (id: number, type: StructureItemTypeModel) => number;
    setStructureItems: (items: StructureItemModel[]) => void;
  };
}>({
  levelController: 3,
  structureItems: [],
  dispatch: {
    isChecked: (item) => false,
    handleCheck: (item, _checked) => {
      console.log(item);
    },
    handleCheckAll: (items) => {
      console.log(items);
    },
    nbrChildrenChecked: (id, type) => 0,
    setStructureItems: (items) => console.log(items),
  },
});
