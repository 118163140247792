import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import {
  Alert,
  Button,
  ClickAwayListener,
  Fab,
  Grid,
  LinearProgress,
  ListItemIcon,
  Paper,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import * as React from "react";
import { bagService } from "../../application/api/services/bag.service";
import { DistrictShipmentsModel } from "../../application/models/shared/districtShipmentModel";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { BarCodeScanner } from "../../components/barCodeScanner";
import { CircularProgressWithLabel } from "../../components/CircularProgressWithLabel";
import { PageTitle } from "../../components/PageTitle";
import { SnackbarContext } from "../../components/snackbarProvider";
import { BagContainer } from "../../pages_components/bags/bagContainer";
import { ShipmentsToControl } from "../../pages_components/shipments/shipmentsToControl";
import { usePrepareBagsStore } from "./hooks/prepareState";

const PrepareOuterBagsPage: React.FC<PagePropsModel> = (): JSX.Element => {
  const refInputScanner = React.useRef<HTMLInputElement>(null);
  const ref = React.useRef<HTMLDivElement>(null);
  const [snackbar, setSnackbar] = React.useContext(SnackbarContext);
  const [scannerStatus, setScannerStatus] = React.useState<boolean>(false);
  const {
    alert,
    loading,
    setLoading,
    error,
    data,
    setData,
    setBarCode,
    currentDistrict,
  } = usePrepareBagsStore();

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Préparation des Sacs",
    },
  ];

  React.useEffect(() => {
    if (alert.message !== "") {
      setSnackbar({
        open: true,
        severity: alert.type,
        message: alert.message,
      });
    }
  }, [alert]);

  React.useEffect(() => {
    loadData();

    ref.current?.addEventListener("mouseenter", inputAutoFocus);
    ref.current?.addEventListener("click", inputAutoFocus);
    return () => {
      ref.current?.removeEventListener("mouseenter", inputAutoFocus);
      ref.current?.removeEventListener("click", inputAutoFocus);
    };
  }, []);

  const inputAutoFocus = () => {
    refInputScanner.current?.focus();
    setScannerStatus(true);
  };

  const handleClickAway = () => {
    setScannerStatus(false);
  };

  const loadData = () => {
    setLoading(true, "");
    bagService
      .fetchOuterBagsToPrepare()
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        setLoading(false, String(error));
      });
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{ minHeight: 400 }}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <PageTitle title="Préparation des Sacs" />
            <Grid container justifyContent="space-between">
              <Grid item>
                <AppBreadcrumbs breadcrumbs={breadcrumbs} />
              </Grid>
            </Grid>
          </Grid>
          {data.length > 0 && (
            <Grid item>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                {data.length > 1 ? (
                  <SelectDistrict />
                ) : (
                  <>Total: {data[0].shipments.length} colis</>
                )}
                <Fab
                  size="small"
                  aria-label="add"
                  sx={{
                    color: scannerStatus ? "#1A90FF" : "#C4C4C4",
                    backgroundColor: "transparent",
                    border: "solid 1px #C4C4C4",
                  }}
                  onClick={inputAutoFocus}
                >
                  <DocumentScannerIcon />
                </Fab>
              </Stack>
            </Grid>
          )}

          <Grid item>
            <Box pb={1} ref={ref}>
              {loading && (
                <Box p={1}>
                  <LinearProgress />
                </Box>
              )}
              {error !== "" && (
                <Box p={1}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}

              {!loading && data.length === 0 && (
                <Box sx={{ pb: 3 }}>
                  <Paper>
                    <Alert severity="info">Aucune donnée disponible!</Alert>
                  </Paper>
                </Box>
              )}

              {data.length > 0 && (
                <Paper
                  sx={{ p: 3, backgroundColor: "transparent" }}
                  elevation={0}
                >
                  <BarCodeScanner
                    ref={refInputScanner}
                    onChange={(barCodeScanned) => setBarCode(barCodeScanned)}
                  />
                  <DistrictItemsList data={data} />
                </Paper>
              )}
            </Box>{" "}
          </Grid>
        </Grid>
      </div>
    </ClickAwayListener>
  );
};

interface DistrictItemsListPropsModel {
  data: DistrictShipmentsModel[] | [];
}
const DistrictItemsList: React.FC<DistrictItemsListPropsModel> = ({
  data,
}): JSX.Element => {
  const { currentDistrict } = usePrepareBagsStore();

  return (
    <List sx={{ width: "100%", bgcolor: "transparent" }}>
      {data
        .filter((item) => item.district.id === currentDistrict)
        .map((item, index) => (
          <DistrictItem
            key={index}
            item={item}
            divider={index + 1 < data.length}
          />
        ))}
    </List>
  );
};

interface DistrictItemPropsModel {
  item: DistrictShipmentsModel;
  divider: boolean;
}
const DistrictItem: React.FC<DistrictItemPropsModel> = ({
  item,
  divider,
}): JSX.Element => {
  const { districtsBags, needNewBag, addNewBag } = usePrepareBagsStore();
  const districtInState = districtsBags.find(
    (district) => district.id === item.district.id
  );

  const checkedBarcodes = (districtInState?.bags || []).reduce(
    (acc: string[], curr) => {
      return [...acc, ...curr.shipments];
    },
    []
  );

  const canRemove = Boolean(
    districtInState && districtInState?.bags.length > 1
  );

  const hasNeedNewBag = needNewBag(Number(item.district.id));
  const getProgressPourcent = (): number => {
    return Number((checkedBarcodes.length * 100) / item.shipments.length);
  };

  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemIcon>
          <HomeWorkOutlinedIcon color="secondary" />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item sx={{ width: 315 }}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Grid item>
                    <Typography sx={{ height: 30 }}>
                      {item.district.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CircularProgressWithLabel
                      size={50}
                      thickness={3}
                      color="success"
                      value={getProgressPourcent()}
                      typographyProps={{ style: { fontSize: 10 } }}
                      label={`${checkedBarcodes.length} / ${item.shipments.length}`}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {hasNeedNewBag && (
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => addNewBag(Number(item.district.id))}
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                  >
                    Sac
                  </Button>
                )}
              </Grid>
            </Grid>
          }
          secondary={
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <ShipmentsToControl
                  data={item.shipments}
                  checkedBarcode={checkedBarcodes}
                />
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={2}>
                  {(districtInState?.bags || []).map((bag, index) => (
                    <Grid item key={index}>
                      <BagContainer
                        saveBag={bagService.prepareOuterBags}
                        {...bag}
                        index={index}
                        shipments={bag.shipments}
                        canRemove={canRemove}
                        districtId={Number(item.district.id)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </ListItem>
      {/* {divider && <Divider variant="inset" component="li" />} */}
    </>
  );
};

const SelectDistrict: React.FC = (): JSX.Element => {
  const { currentDistrict, data, handleChangeDistrict } = usePrepareBagsStore();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    handleChangeDistrict(Number(newValue));
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={currentDistrict}
        onChange={handleChange}
        aria-label="basic tabs example"
        indicatorColor="secondary"
        textColor="secondary"
      >
        {data.map((item, index) => (
          <Tab
            key={index}
            label={`${item.district.name} : ${item.shipments.length} Colis`}
            value={Number(item.district.id)}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default PrepareOuterBagsPage;
