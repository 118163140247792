import {
  Alert,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Skeleton,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { get, isUndefined } from "lodash";
import { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  useFindOneOfficeQuery,
  useUpdateOfficeQuery,
} from "../../application/api/hooks/office.hooks";
import {
  CREATE_OFFICE_ROUTE,
  LIST_OFFICE_ROUTE,
} from "../../application/constants/routeConstants";
import { OfficeModel } from "../../application/models/shared/officeModel";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import If from "../../components/conditionalViwer";
import { PageTitle } from "../../components/PageTitle";
import { SnackbarContext } from "../../components/snackbarProvider";
import OfficeForm from "../../pages_components/offices/forms/editForm";
import { OfficeFormModel } from "../../pages_components/offices/forms/editForm/models";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
}));

const UpdateOfficePage: FC<PagePropsModel> = (props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const [snackbar, setSnackbar] = useContext(SnackbarContext);
  const id = get(props, ["match", "params", "id"], undefined);
  const oneDistrictQuery = useFindOneOfficeQuery(Number(id));
  const districtData = get(oneDistrictQuery, ["data", "data"], undefined);

  const [data, setData] = useState<OfficeFormModel | undefined>(undefined);

  const updateDistrictQuery = useUpdateOfficeQuery(data);
  const districtUpdated = get(
    updateDistrictQuery,
    ["data", "data"],
    undefined
  ) as OfficeModel | undefined;

  useEffect(() => {
    if (!isUndefined(districtUpdated)) {
      history.push(LIST_OFFICE_ROUTE);
      setSnackbar({
        open: true,
        severity: "success",
        message: "Modification effectué avec succès",
      });
    }
  }, [districtUpdated]);

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Nouveau bureau",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title={"Nouveau bureau"} />
        <AppBreadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid item>
        <Paper className={classes.paper}>
          <If
            condition={updateDistrictQuery.isLoading}
            render={() => <LinearProgress sx={{ marginBottom: 4 }} />}
            otherwise={() => (
              <If
                condition={updateDistrictQuery.isError}
                render={() => (
                  <Alert severity="error" sx={{ marginBottom: 4 }}>
                    Une erreur se produise l'hort de la modification!
                  </Alert>
                )}
              />
            )}
          />

          <If
            condition={!isUndefined(districtUpdated)}
            render={() => (
              <Alert severity="success">
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography>
                      La modification de <b> {districtUpdated?.name} </b> a été
                      effectué avec succès
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <Button
                          color="inherit"
                          variant="text"
                          href={LIST_OFFICE_ROUTE}
                        >
                          Afficher tout
                        </Button>{" "}
                      </Grid>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                      <Grid item>
                        <Button
                          color="inherit"
                          variant="text"
                          href={CREATE_OFFICE_ROUTE}
                        >
                          Ajouter nouveau bureau
                        </Button>{" "}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Alert>
            )}
            otherwise={() => (
              <If
                condition={oneDistrictQuery?.isLoading}
                render={() => (
                  <Skeleton variant="rectangular" width={"100%"} height={118} />
                )}
                otherwise={() => (
                  <If
                    condition={oneDistrictQuery?.isError}
                    render={() => (
                      <Alert severity="error">Bureau n'est pas trouvé !</Alert>
                    )}
                    otherwise={() => (
                      <If
                        condition={!isUndefined(districtData)}
                        render={() => (
                          <OfficeForm
                            loading={updateDistrictQuery.isLoading}
                            defaultValues={districtData}
                            onValide={(data) => setData(data)}
                          />
                        )}
                        otherwise={() => (
                          <Alert severity="error">
                            Bureau n'est pas trouvé !
                          </Alert>
                        )}
                      />
                    )}
                  />
                )}
              />
            )}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UpdateOfficePage;
