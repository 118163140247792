import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { Badge, Chip, ListItemText } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useNotificationsStore } from "../../application/notifications/notificationState";

export const NotificationsMenu: React.FC = () => {
  const history = useHistory();
  const { notifications } = useNotificationsStore();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const countAllNotifications = ((): number => {
    return (
      Object.keys(notifications) as Array<keyof typeof notifications>
    ).reduce((accumulator, current) => {
      return accumulator + notifications[current].count;
    }, 0);
  })();

  return (
    <React.Fragment>
      <Tooltip title="Account settings">
        <Badge badgeContent={countAllNotifications} color="error">
          <IconButton
            disabled={countAllNotifications === 0}
            onClick={handleClick}
            size="small"
            color={countAllNotifications > 0 ? "error" : "primary"}
            sx={{ ml: 2 }}
          >
            <AssignmentOutlinedIcon />
          </IconButton>
        </Badge>
      </Tooltip>

      <Menu
        disablePortal
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            p: 1,
            // borderRadius: 5,
            width: 420,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {(Object.keys(notifications) as Array<keyof typeof notifications>)
          .filter((item) => notifications[item].count > 0)
          .map((item, key) => (
            <MenuItem
              key={key}
              onClick={() => {
                history.replace(notifications[item].link);
                handleClose();
              }}
              href={notifications[item].link}
              sx={{
                borderBottom: "solid 1px #eee",
                "&:last-child": {
                  borderBottom: "solid 0px #eee",
                },
              }}
            >
              <ListItemText>{notifications[item].label}</ListItemText>

              <Chip
                size="small"
                color="success"
                label={notifications[item].count}
              />
            </MenuItem>
          ))}
      </Menu>
    </React.Fragment>
  );
};
