import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { get, isUndefined } from "lodash";
import { FC, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Fieldset } from "../../../../components/Fieldset";

export const ReimbursementSectionForm: FC<{
  parentIndex: number;
  index: number;
}> = ({ parentIndex, index }) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const enableReimbursement = watch(
    `shipments[${parentIndex}].packages.${index}.reimbursement.enable`
  );

  useEffect(() => {
    if (!enableReimbursement) {
      setValue(
        `shipments[${parentIndex}].packages.${index}.reimbursement.amount`,
        0,
        {
          shouldValidate: true,
        }
      );
      setValue(
        `shipments[${parentIndex}].packages.${index}.reimbursement.return_mode`,
        "De main à main",
        {
          shouldValidate: true,
        }
      );
    }
  }, [enableReimbursement]);

  return (
    <Fieldset
      legend={
        <Controller
          name={`shipments[${parentIndex}].packages.${index}.reimbursement.enable`}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              labelPlacement="end"
              control={
                <Checkbox {...field} color="secondary" checked={field.value} />
              }
              label="CRBT"
            />
          )}
        />
      }
    >
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item md={12} xs={12}>
          <Controller
            name={`shipments[${parentIndex}].packages.${index}.reimbursement.amount`}
            control={control}
            render={({ field }) => (
              <TextField
                label="Montant"
                {...field}
                size="small"
                fullWidth
                disabled={enableReimbursement === false}
                type="number"
                inputProps={{
                  min: 0,
                  step: 0.001,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">TND</InputAdornment>
                  ),
                }}
                error={
                  !isUndefined(
                    get(
                      errors,
                      [
                        "shipments",
                        parentIndex,
                        "packages",
                        index,
                        "reimbursement",
                        "amount",
                      ],
                      undefined
                    )
                  )
                }
                helperText={get(
                  errors,
                  [
                    "shipments",
                    parentIndex,
                    "packages",
                    index,
                    "reimbursement",
                    "amount",
                    "message",
                  ],
                  null
                )}
              />
            )}
          />
        </Grid>
        {/* <Grid item md={6} xs={12}>
          <Controller
            name={`shipments[${parentIndex}].packages.${index}.reimbursement.return_mode`}
            control={control}
            render={({ field }) => (
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                label="Mode de retour"
                {...field}
                size="small"
                fullWidth
                disabled={enableReimbursement === false}
                error={
                  !isUndefined(
                    get(
                      errors,
                      [
                        "shipments",
                        parentIndex,
                        "packages",
                        index,
                        "reimbursement",
                        "return_mode",
                      ],
                      undefined
                    )
                  )
                }
                helperText={get(
                  errors,
                  [
                    "shipments",
                    parentIndex,
                    "packages",
                    index,
                    "reimbursement",
                    "return_mode",
                    "message",
                  ],
                  null
                )}
              />
            )}
          />
        </Grid> */}
      </Grid>
    </Fieldset>
  );
};
