import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import SaveIcon from "@mui/icons-material/Save";
import {
  Alert,
  ClickAwayListener,
  Divider,
  Fab,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { get } from "lodash";
import React, { FC, useEffect } from "react";
import {
  useFetchShipmentsQuery,
  useFetchVerifyShipmentsQuery,
} from "../../application/api/hooks/dispatching";
import { FetchShipmentsResponseModel } from "../../application/api/services/dispatching.service";
import { shipmentStatusService } from "../../application/api/services/shipment.service";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { ShipmentStatusEnumModel } from "../../application/models/shared/shipmentStatusModel";
import { useNotificationsStore } from "../../application/notifications/notificationState";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { BarCodeScanner } from "../../components/barCodeScanner";
import { CircularProgressWithLabel } from "../../components/CircularProgressWithLabel";
import { PageTitle } from "../../components/PageTitle";
import { SnackbarContext } from "../../components/snackbarProvider";
import { ShipmentsToControl } from "../../pages_components/shipments/shipmentsToControl";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(3),
  },

  input: {
    width: 1,
    height: 1,
    opacity: 0,
    overflow: "hidden",
    position: "relative",
    zIndex: -1,
    "&::before": {
      content: "''",
      width: 300,
      height: 300,
      backgroundColor: "red",
      border: "solid 1px red",
      position: "absolute",
      top: 0,
    },
  },
}));

const ControlShipmentsPage: FC<PagePropsModel> = (props): JSX.Element => {
  const { refresh } = useNotificationsStore();
  const classes = useStyles();
  const refInputScanner = React.useRef<HTMLInputElement>(null);
  const ref = React.useRef<HTMLDivElement>(null);
  const [checkedBarcode, setCheckedBarcode] = React.useState<string[]>([]);
  const [snackbar, setSnackbar] = React.useContext(SnackbarContext);
  const [scannerStatus, setScannerStatus] = React.useState<boolean>(false);

  const shipmentsQuery = useFetchVerifyShipmentsQuery({
    pagination: { page: 1, per_page: 100 },
    status: [
      ShipmentStatusEnumModel.waiting_for_forwarding,
      ShipmentStatusEnumModel.given_for_delivery_officer,
      ShipmentStatusEnumModel.received_from_customer,
    ],
  });
  const {
    data: { data, current_page, total },
  } = get(shipmentsQuery, ["data"], {
    data: [],
    current_page: 1,
    total: 0,
  }) as FetchShipmentsResponseModel;

  useEffect(() => {
    ref.current?.addEventListener("mousemove", inputAutoFocus);
    ref.current?.addEventListener("click", inputAutoFocus);
    return () => {
      ref.current?.removeEventListener("mousemove", inputAutoFocus);
      ref.current?.removeEventListener("click", inputAutoFocus);
    };
  }, []);

  const inputAutoFocus = () => {
    refInputScanner.current?.focus();
    setScannerStatus(true);
  };

  const handleClickAway = () => {
    setScannerStatus(false);
  };
  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Réception Colis",
    },
  ];

  const save = () => {
    shipmentStatusService
      .control({ shipments: checkedBarcode })
      .then((response) => {
        setSnackbar({
          open: true,
          severity: "success",
          message: "sauvegarde effectuée avec succès",
        });
        refresh();
      })
      .catch((error) => {
        setSnackbar({
          open: true,
          severity: "error",
          message: "Une erreur est survenue lors de l'enregistrement",
        });
      });
  };

  const getProgressPourcent = (): number => {
    return Number((checkedBarcode.length * 100) / total);
  };

  const checkBarCode = (inputBarcodeValue: string) => {
    const shipment = data.find((item) => item.identifier === inputBarcodeValue);
    if (shipment && checkedBarcode.includes(shipment.identifier) === false) {
      setCheckedBarcode((prev) => [...prev, shipment.identifier]);
    } else {
      setSnackbar({
        open: true,
        severity: "error",
        message: "Barrecode déja scanné",
      });
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div ref={ref} style={{ minHeight: 400 }}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <PageTitle title="Réception Colis" />
            {/* <Typography variant="h5">Réception Colis</Typography> */}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>

          {shipmentsQuery.isLoading && (
            <Grid item>
              <LinearProgress />
            </Grid>
          )}
          {shipmentsQuery.isError && (
            <Grid item>
              <Alert severity="info">
                This is an info alert — check it out!
              </Alert>
            </Grid>
          )}

          {!shipmentsQuery.isLoading && data && (
            <Grid item>
              <Paper className={classes.paper}>
                {data && data.length > 0 && (
                  <Grid container spacing={3} justifyContent="space-between">
                    <Grid item>
                      <Stack direction={"column"} spacing={1}>
                        <BarCodeScanner
                          ref={refInputScanner}
                          onChange={(value) => checkBarCode(value)}
                        />

                        {/* <Divider flexItem /> */}

                        <ShipmentsToControl
                          data={data}
                          checkedBarcode={checkedBarcode}
                        />
                      </Stack>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <Grid container alignItems="flex-start" spacing={2}>
                            <Grid item>
                              <Fab
                                aria-label="add"
                                sx={{
                                  color: scannerStatus ? "#1A90FF" : "#C4C4C4",
                                  backgroundColor: "transparent",
                                  border: "solid 1px #C4C4C4",
                                }}
                                onClick={inputAutoFocus}
                              >
                                <DocumentScannerIcon />
                              </Fab>
                            </Grid>
                            <Grid item>
                              <CircularProgressWithLabel
                                size={56}
                                thickness={4}
                                color="success"
                                value={getProgressPourcent()}
                                label={`${checkedBarcode.length} / ${total}`}
                              />
                            </Grid>
                            <Grid item>
                              {checkedBarcode.length > 0 && (
                                <Fab
                                  color="secondary"
                                  aria-label="add"
                                  sx={{ color: "#fff" }}
                                  onClick={save}
                                >
                                  <SaveIcon />
                                </Fab>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </Grid>
          )}
        </Grid>
      </div>
    </ClickAwayListener>
  );
};

export default ControlShipmentsPage;
