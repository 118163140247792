import { dispatchingService } from "./../../../application/api/services/dispatching.service";
import create from "zustand";
import { ShipmentModel } from "../../../application/models/shared/ShipmentModel";

export interface TripShipmentsStateModel {
  isLoading: boolean;
  isError: boolean;
  data: ShipmentModel[] | [];
  setLoading: (isLoading: boolean, isError: boolean) => void;
  fetch: () => void;
}

export const useTripShipmentsStore = create<TripShipmentsStateModel>(
  (set, get) => ({
    isLoading: false,
    isError: false,
    data: [],
    setLoading: (isLoading: boolean, isError: boolean) => {
      set((state) => ({ ...state, isLoading, isError }));
    },
    fetch: async () => {
      set((state) => ({ ...state, isLoading: true, isError: false }));
      const response = await dispatchingService.tripShipments();
      set((state) => ({
        ...state,
        data: response.data,
        isLoading: false,
        isError: false,
      }));
    },
  })
);
