import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FC } from "react";
import { shipmentStatus } from "../../application/data/ShipmentStatus";
import { ShipmentStatusEnumModel } from "../../application/models/shared/shipmentStatusModel";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

export interface SelectShipmentStatusProps {
  value: ShipmentStatusEnumModel[];
  onChange: (value: ShipmentStatusEnumModel[]) => void;
}

export const SelectShipmentStatus: FC<SelectShipmentStatusProps> = ({
  value,
  onChange,
}): JSX.Element => {
  return (
    <FormControl size="small" sx={{ width: 350 }}>
      <InputLabel id="demo-simple-select-label">Statut</InputLabel>
      <Select
        size="small"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        multiple
        value={value}
        input={<OutlinedInput label="Statut" />}
        onChange={(event: SelectChangeEvent<ShipmentStatusEnumModel[]>) => {
          const {
            target: { value },
          } = event;

          const _value =
            typeof value === "string"
              ? value.split(",").map((item) => item as ShipmentStatusEnumModel)
              : value;

          onChange(_value as ShipmentStatusEnumModel[]);
        }}
        MenuProps={MenuProps}
        renderValue={(selected) =>
          selected.map((item) => shipmentStatus[item].label).join(", ")
        }
      >
        {(Object.keys(shipmentStatus) as Array<ShipmentStatusEnumModel>).map(
          (statusOfshipment, index) => (
            <MenuItem dense={true} key={index} value={statusOfshipment}>
              <Checkbox
                checked={(value || []).indexOf(statusOfshipment) > -1}
              />
              <ListItemText primary={shipmentStatus[statusOfshipment].label} />
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};
