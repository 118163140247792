import {
  Alert,
  Grid,
  LinearProgress,
  Paper,
  Skeleton,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { get, isUndefined } from "lodash";
import React, { FC, useState } from "react";
import {
  useFetchRolesQuery,
  useFindOneUserQuery,
  useUpdateUserQuery,
} from "../../application/api/hooks/user.hooks";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { RoleModel } from "../../application/models/shared/roleModel";
import { UserModel } from "../../application/models/shared/userModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import If from "../../components/conditionalViwer";
import { PageTitle } from "../../components/PageTitle";
import UserEditeForm from "../../pages_components/users/Forms/userEditeForm";
import { UserEditeFormModel } from "../../pages_components/users/Forms/userEditeForm/models";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
}));

const UpdateUserPage: FC<PagePropsModel> = (props) => {
  const classes = useStyles();
  const id = props?.match?.params?.id || undefined;

  const oneUserQuery = useFindOneUserQuery(Number(id));
  const userData = get(oneUserQuery, ["data", "data"], undefined);

  const [data, setData] = useState<UserEditeFormModel | undefined>(undefined);

  const rolesQuery = useFetchRolesQuery();
  const roleOptions = get(rolesQuery, ["data", "data"], []) as RoleModel[];

  const updateDistrictQuery = useUpdateUserQuery(data);
  const districtUpdated = get(
    updateDistrictQuery,
    ["data", "data"],
    undefined
  ) as UserModel | undefined;

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Modification utilisateur",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title={"Modification utilisateur"} />
        <AppBreadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>
      {updateDistrictQuery.isLoading && (
        <Grid item>
          <LinearProgress />
        </Grid>
      )}
      {!updateDistrictQuery.isLoading && updateDistrictQuery.isError && (
        <Grid item>
          <Alert severity="error">
            {" "}
            Une erreur s'est produite lors de l'enregistrement
          </Alert>
        </Grid>
      )}
      {!updateDistrictQuery.isLoading &&
        !updateDistrictQuery.isError &&
        updateDistrictQuery.data !== undefined && (
          <Grid item>
            <Alert severity="success"> Opération réalisée avec succès</Alert>
          </Grid>
        )}
      <Grid item>
        <Paper className={classes.paper}>
          <If
            condition={oneUserQuery?.isLoading}
            render={() => (
              <Skeleton variant="rectangular" width={"100%"} height={118} />
            )}
            otherwise={() => (
              <If
                condition={oneUserQuery?.isError}
                render={() => (
                  <Alert severity="error">District n'est pas trouvé !</Alert>
                )}
                otherwise={() => (
                  <If
                    condition={!isUndefined(userData)}
                    render={() => (
                      <UserEditeForm
                        defaultValues={{
                          id: userData.id,
                          first_name: userData.first_name,
                          last_name: userData.last_name,
                          phone_number: userData.phone_number,
                          email: userData.email,
                          roles: [],
                        }}
                        roleOptions={roleOptions}
                        onValide={(data) => setData(data)}
                      />
                    )}
                    otherwise={() => (
                      <Alert severity="error">user n'est pas trouvé !</Alert>
                    )}
                  />
                )}
              />
            )}
          />{" "}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UpdateUserPage;
