import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { ACCESS_TOKEN } from "../../application/constants/authConstants";
import { useAuth } from "../../application/contexts/authContex";
import { setStorageItem } from "../../application/helpers/localStorage";
import { FormLoginModel } from "./models";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // height: "100%",
    display: "flex",
    // flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(4),
  },
  formContainer: {
    padding: theme.spacing(6),
  },
  action: {
    marginTop: theme.spacing(2),
  },
}));

const LoginPage: FC = () => {
  const auth = useAuth();
  const classes = useStyles();
  const history = useHistory();

  const [state, setState] = React.useState({
    loading: false,
    serverErrors: null,
    success: false,
  });

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormLoginModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (data: FormLoginModel) => {
    setState((state) => ({ ...state, loading: true, serverErrors: null }));

    const params = {
      username: data.email,
      password: data.password,
    };
    auth.signin(
      params,
      (response) => {
        setStorageItem(ACCESS_TOKEN, response.data.token);
        setState((state) => ({ ...state, loading: false }));
        auth.whoAmI(
          (responseWhoAmI) => {
            if (responseWhoAmI?.data?.office?.is_platform) {
              history.push("/private/bags/receive");
            }
          },
          ({ message }) => {
            setState((state) => ({
              ...state,
              serverErrors: message,
              loading: false,
            }));
          }
        );
      },
      ({ message }) => {
        setState((state) => ({
          ...state,
          serverErrors: message,
          loading: false,
        }));
      }
    );

    // authenticationService
    //   .signIn(data.email, data.password)
    //   .then((response) => {
    //     setStorageItem(ACCESS_TOKEN, response.data.token);
    //     setState((state) => ({ ...state, loading: false }));
    //   })
    //   .then((_) => {
    //     authenticationService
    //       .whoAmI()
    //       .then((response) => {
    //         _dispatch(setLoggedUser(response.data));
    //         history.push("/private");
    //       })
    //       .catch(() => history.push("/public"));
    //   })
    //   .catch(({ message }) => {
    //     setState((state) => ({
    //       ...state,
    //       serverErrors: message,
    //       loading: false,
    //     }));
    //   });
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.paper}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <img
                className="img-fluid"
                src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/logo.png`}
                alt="logo"
                style={{ width: "100%" }}
              />
            </Grid>

            <Grid item>
              <div className={classes.formContainer}>
                <Grid container direction="column" spacing={3}>
                  {state.serverErrors && (
                    <Grid item>
                      <Alert severity="error">{` Une erreur d'authentification s'est produite !`}</Alert>
                    </Grid>
                  )}
                  <Grid item>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="E-mail"
                          size="small"
                          {...field}
                          error={Boolean(errors?.email)}
                          helperText={
                            errors?.email ? errors?.email.message : null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="Mot de passe"
                          size="small"
                          {...field}
                          type="password"
                          error={Boolean(errors?.password)}
                          helperText={
                            errors?.password ? errors?.password.message : null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <div className={classes.action}>
                      <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        fullWidth
                        disabled={state.loading}
                      >
                        Login
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Container>
  );
};

export default LoginPage;
