import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { get, isUndefined } from "lodash";
import React, { FC } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { CustomerModel } from "../../../../application/models/shared/customerModel";

const PricingForm: FC = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "pricing",
  });

  const formValues = watch() as CustomerModel;

  const pricingLastLine =
    formValues?.pricing !== undefined &&
    Array.isArray(formValues?.pricing) &&
    formValues.pricing.length > 0
      ? formValues?.pricing[formValues.pricing.length - 1]
      : undefined;

  const canAddLine =
    pricingLastLine === undefined ||
    (pricingLastLine !== undefined &&
      pricingLastLine?.max > 0 &&
      pricingLastLine?.max > pricingLastLine?.min);

  return (
    <>
      <Grid container direction="column" spacing={3}>
        {fields.map((field, index) => (
          <Grid item key={field.id}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={12} md={3}>
                <Controller
                  name={`pricing[${index}].min`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Min"
                      {...field}
                      size="small"
                      fullWidth
                      type="number"
                      inputProps={{
                        readOnly: true,
                        min: 0,
                        step: 0.001,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">KG</InputAdornment>
                        ),
                      }}
                      error={
                        !isUndefined(
                          get(errors, ["pricing", index, "min"], undefined)
                        )
                      }
                      helperText={get(
                        errors,
                        ["pricing", index, "min", "message"],
                        null
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name={`pricing[${index}].max`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Max"
                      {...field}
                      size="small"
                      fullWidth
                      type="number"
                      inputProps={{
                        min: 0,
                        step: 0.001,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">KG</InputAdornment>
                        ),
                      }}
                      error={
                        !isUndefined(
                          get(errors, ["pricing", index, "max"], undefined)
                        )
                      }
                      helperText={get(
                        errors,
                        ["pricing", index, "max", "message"],
                        null
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name={`pricing[${index}].price`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Prix"
                      {...field}
                      size="small"
                      fullWidth
                      type="number"
                      inputProps={{
                        min: 0,
                        step: 0.001,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">DT</InputAdornment>
                        ),
                      }}
                      error={
                        !isUndefined(
                          get(errors, ["pricing", index, "price"], undefined)
                        )
                      }
                      helperText={get(
                        errors,
                        ["pricing", index, "price", "message"],
                        null
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <IconButton
                  aria-label="delete"
                  color="error"
                  size="small"
                  onClick={() => remove(index)}
                  sx={{ float: "right", minWidth: 40 }}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}

        <Grid item>
          <Button
            size="small"
            type="button"
            variant="contained"
            color="info"
            startIcon={<AddIcon />}
            sx={{ float: "left", minWidth: 100 }}
            disabled={!canAddLine}
            onClick={() => {
              // const minValue =
              //   formValues?.pricing !== undefined &&
              //   Array.isArray(formValues?.pricing) &&
              //   formValues.pricing.length > 0
              //     ? formValues?.pricing[formValues.pricing.length - 1].max
              //     : 0;

              append({
                min: pricingLastLine?.max || 0,
                max: 0,
                price: 0,
              });
            }}
          >
            Ligne{" "}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PricingForm;
