import { ChangePasswordFormModel } from "../../../pages_components/users/Forms/changePasswordForm/models";
import { UserCreateFormModel } from "../../../pages_components/users/Forms/userCreateForm/models";
import { UserEditeFormModel } from "../../../pages_components/users/Forms/userEditeForm/models";
import httpClient from "../../httpClient";
import { HttpResponseModel } from "../../models/shared/httpResponseModel";
import { OrderDirectionModel } from "../../models/shared/orderDirectionModel";
import { PaginationModel } from "../../models/shared/paginationModel";
import { RoleModel } from "../../models/shared/roleModel";
import { UserModel } from "../../models/shared/userModel";

export interface CreateResponseModel extends HttpResponseModel {
  data: UserModel;
}

export interface FetchWithPaginationResponseModel extends HttpResponseModel {
  data: { data: UserModel[] | []; current_page: number; total: number };
}

export interface OrderByModel {
  orderField: keyof UserModel;
  orderDirection: OrderDirectionModel;
}

const create = async (
  data: UserCreateFormModel
): Promise<CreateResponseModel> => {
  return httpClient.post("settings/users", {
    ...data,
  });
};

const update = async (
  data: UserEditeFormModel
): Promise<CreateResponseModel> => {
  const { id, ...other } = data;

  return httpClient.patch(`settings/users/${id}`, {
    ...other,
  });
};

const remove = async (id: number): Promise<CreateResponseModel> => {
  return httpClient.delete("users/delete", {
    data: { id },
  });
};

const findOne = async (id: number): Promise<UserModel> => {
  return httpClient.get(`settings/users/${id}`);
};

const fetch = async (params: {
  pagination: PaginationModel;
  search?: string;
  orderParams?: OrderByModel;
}): Promise<FetchWithPaginationResponseModel> => {
  const { pagination, search = undefined, orderParams = undefined } = params;
  return httpClient.get("settings/users", {
    params: {
      ...pagination,
      ...(() => (search ? { search } : {}))(),
      ...(() => (orderParams ? orderParams : {}))(),
    },
  });
};

const fetchRoles = async (): Promise<RoleModel[]> => {
  return httpClient.get("settings/roles ");
};

const changePassword = async (
  data: ChangePasswordFormModel
): Promise<CreateResponseModel> => {
  return httpClient.post(`auth/profile/change_password`, {
    ...data,
  });
};

export const userService = {
  findOne,
  create,
  update,
  remove,
  fetch,
  changePassword,
  fetchRoles,
};
