import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import {
  Alert,
  Button,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, useEffect } from "react";
import { Link, RouteProps } from "react-router-dom";
import shallow from "zustand/shallow";
import { DETAILS_PICKUP_ROUTE } from "../../application/constants/routeConstants";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { BlurBackdrop } from "../../components/blurBackdrop";
import { PageTitle } from "../../components/PageTitle";
import ReceptionForm from "../../pages_components/reception/forms";
import { ScopeWorking, StepModel, usePickupController } from "./controller";

export interface PagePropsModel extends RouteProps {
  match: {
    params: {
      id?: string;
      idParcel?: string;
    };
  };
}

const EditPickupParcelPage: FC<PagePropsModel> = (props): JSX.Element => {
  const id = props?.match?.params?.id || undefined;
  const idParcel = props?.match?.params?.idParcel || undefined;

  const { details, editing, editingParcel, methods } = usePickupController(
    (state) => ({
      details: state.details,
      editing: state.editing,
      editingParcel: state.editingParcel,
      methods: state.methods,
    }),
    shallow
  );

  useEffect(() => {
    if (id) {
      editingParcel.findOne(String(idParcel));
      editing.setStep(StepModel.FORM);
    }
  }, []);

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Modification colis",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle
          title={`Modification colis : ${editingParcel?.originalData?.identifier}`}
        />
        <AppBreadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>

      {editingParcel.isLoading &&
        editingParcel.scopeWorking === ScopeWorking.EDITING_PARCEL_FIND && (
          <Grid item>
            <LinearProgress color="secondary" />
          </Grid>
        )}
      {Boolean(editingParcel.error) && !editingParcel.isLoading && (
        <Grid item>
          <Alert severity="error">{editingParcel?.error}</Alert>
        </Grid>
      )}

      <Grid item>
        <Box
          sx={{
            position: "relative",
            display: "felx",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <BlurBackdrop
            open={
              editingParcel.isLoading &&
              editingParcel.scopeWorking === ScopeWorking.EDITING_PARCEL_SAVE
            }
          />

          {editingParcel.storedData !== undefined && (
            <Box>
              <Alert severity="success">
                <Stack spacing={2} alignItems="flex-start">
                  <Typography>
                    {" "}
                    Les modifications ont été effectuées avec succès
                  </Typography>

                  <Button
                    component={Link}
                    aria-label="more"
                    id="long-button"
                    color="inherit"
                    to={`${DETAILS_PICKUP_ROUTE}/${id}`}
                    startIcon={<ArrowBackIosOutlinedIcon />}
                  >
                    Retour à la page de pickup
                  </Button>
                </Stack>
              </Alert>
            </Box>
          )}

          {editingParcel?.originalData &&
            editingParcel.storedData === undefined &&
            editing.step === StepModel.FORM && (
              <ReceptionForm
                settings={{
                  hideSenderForm: true,
                  isNested: false,
                  isLoading:
                    editingParcel.isLoading &&
                    editingParcel.scopeWorking ===
                      ScopeWorking.EDITING_PARCEL_SAVE,
                }}
                defaultValues={methods.pickupParcelToReceptionFormModel(
                  editingParcel.originalData
                )}
                onSubmit={(formValues) => {
                  editingParcel.save(
                    methods.receptionFormToShipmentModel(formValues)
                  );
                }}
              />
            )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default EditPickupParcelPage;
