import { DistrictModel } from "./districtModel";
import { PostOfficeModel } from "./postOfficeModel";

export interface OfficeModel {
  id?: number;
  name: string;
  address: {
    address_line: string;
    post_office: PostOfficeModel;
  };
  structure: Omit<StructureItemModel, "name">[] | [];
  district?: DistrictModel;
  is_central?: boolean;
  is_platform?: boolean;
}

export interface StructureItemModel {
  id: number;
  name: string;
  type: StructureItemTypeModel;
  parent_id?: number | null;
}

export enum StructureItemTypeModel {
  GOVERNORATE = "governorates",
  CITY = "cities",
  POST_OFFICE = "post_office",
}
