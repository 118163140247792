import { Button, List, Paper, Popper } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Box } from "@mui/system";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../contexts/authContex";
import { communMenuItems } from "../../../data/menuItems";
import { MenuModel } from "../../../models/shared/menuModel";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

export const HorizontalNavBar: FC = () => {
  const auth = useAuth();
  const menuFilter = (item: MenuModel) => {
    let ability = false;
    if (auth?.user) {
      ability = item.ability(auth?.user) && item.visibility;
    }
    return ability;
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      disablePadding
      dense
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      {communMenuItems.filter(menuFilter).map((menuItem, index) => (
        <MenuItem {...menuItem} level={0} key={index} />
      ))}
    </List>
  );
};

const MenuItem: React.FC<MenuModel> = (props): JSX.Element => {
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleClickAway = () => {
    handleClose();
  };

  const Icon = props.icon();

  const level = props?.level || 0;

  const hasSubMenuItems =
    props &&
    props?.subMenuItems !== undefined &&
    props.subMenuItems?.length > 0;

  const menuFilter = (item: MenuModel) => {
    let ability = false;
    if (auth?.user) {
      ability = item.ability(auth?.user) && item.visibility;
    }
    return ability;
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        onMouseOver={handleOpen}
        onMouseLeave={handleClose}
        sx={{
          position: "relative",
          pl: 0,
          pr: 1,
          color: "#6e6b7b",
          "&:hover > .MuiButton-root::after": {
            content: "' '",
            position: "absolute",
            left: 0,
            bottom: -17,
            minWidth: "100%",
            borderBottom: level === 0 ? "solid 5px red" : "none",
          },
        }}
      >
        <Button
          fullWidth
          component={Link}
          to={hasSubMenuItems ? "#" : props.href ? props.href : "/"}
          color="inherit"
          sx={{
            justifyContent: "flex-start",
            fontWeight: 400,
          }}
          startIcon={<Icon />}
          endIcon={hasSubMenuItems ? <ExpandMore /> : null}
        >
          {props.label}
        </Button>

        {hasSubMenuItems && (
          <Popper
            placement="bottom-start"
            disablePortal={true}
            open={open}
            anchorEl={anchorEl}
            modifiers={[
              {
                name: "flip",
                enabled: true,
                options: {
                  altBoundary: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  tether: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
            ]}
          >
            <Paper elevation={13} sx={{ minWidth: 400, mt: 2, p: 2 }}>
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                disablePadding
                dense
              >
                {(props?.subMenuItems || [])
                  .filter(menuFilter)
                  .map((menuItem, index) => (
                    <MenuItem {...menuItem} level={level + 1} key={index} />
                  ))}
              </List>
            </Paper>
          </Popper>
        )}
      </Box>
    </ClickAwayListener>
  );
};
