import ReducerAuthModels from "../models/reducers/auth/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { UserModel } from "../models/shared/userModel";

const initialState: ReducerAuthModels.StateModel = {
  loggedIn: false,
  user: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedUser: (state, action: PayloadAction<UserModel | undefined>) => {
      state.loggedIn = true;
      state.user = action.payload;
    },
    doLogout: (state) => {
      state.loggedIn = false;
      state.user = undefined;
    },
  },
});

export const { setLoggedUser, doLogout } = authSlice.actions;
export const authState = (state: RootState): ReducerAuthModels.StateModel =>
  state.auth;
export default authSlice.reducer;
