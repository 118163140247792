import { DatePicker } from "@mui/lab";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { format, parse } from "date-fns";
import { FC, useState } from "react";
import { DATE_FORMAT } from "../../../../application/constants/appConstants";
import { shipmentStatus } from "../../../../application/data/ShipmentStatus";
import { ShipmentStatusEnumModel } from "../../../../application/models/shared/shipmentStatusModel";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

interface StateModel {
  status: ShipmentStatusEnumModel[];
  start_date: Date | null;
  end_date: Date | null;
}

interface PropsModel {
  onSubmit: (data: StateModel) => void;
}

export const ShipmentsFilterForm: FC<PropsModel> = (props): JSX.Element => {
  const [state, setState] = useState<StateModel>({
    status: [],
    start_date: null,
    end_date: null,
  });

  return (
    <Grid container spacing={1}>
      <Grid item md={3}>
        <FormControl size="small" sx={{ width: "100%" }}>
          <InputLabel id="demo-simple-select-label">Statut</InputLabel>
          <Select
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            multiple
            value={state.status}
            input={<OutlinedInput label="Statut" />}
            onChange={(event: SelectChangeEvent<typeof state.status>) => {
              const {
                target: { value },
              } = event;

              const _value =
                typeof value === "string"
                  ? value
                      .split(",")
                      .map((item) => item as ShipmentStatusEnumModel)
                  : (value as ShipmentStatusEnumModel[]);

              setState((state) => ({ ...state, status: _value }));
            }}
            MenuProps={MenuProps}
          >
            {(
              Object.keys(shipmentStatus) as Array<ShipmentStatusEnumModel>
            ).map((statusOfshipment, index) => (
              <MenuItem key={index} value={statusOfshipment}>
                {shipmentStatus[statusOfshipment].label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={3}>
        <DatePicker
          label="Date début"
          value={state.start_date}
          onChange={(newValue) => {
            setState((state) => ({ ...state, start_date: newValue }));
          }}
          renderInput={(params) => <TextField {...params} size="small" />}
        />
      </Grid>
      <Grid item md={3}>
        <DatePicker
          label="Date fin"
          value={state.end_date}
          onChange={(newValue) => {
            setState((state) => ({ ...state, end_date: newValue }));
          }}
          renderInput={(params) => <TextField {...params} size="small" />}
        />
      </Grid>
      <Grid item md={3}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => props.onSubmit(state)}
        >
          Filter
        </Button>
      </Grid>
    </Grid>
  );
};
