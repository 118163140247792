import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import {
  ReimbursementInCtrlModel,
  ReimbursementItemCtrlModel,
} from "../../../application/models/shared/reimbursementCtrlModel";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { priceFormat } from "../../../application/helpers/priceFormat";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

const OfficierCard: FC<{ data: ReimbursementInCtrlModel }> = ({
  data,
}): JSX.Element => {
  const { officer } = data;
  const sumCRBTReduce = (
    acc: number,
    cur: ReimbursementItemCtrlModel
  ): number => {
    const curCRBT = cur.amount;
    return acc + curCRBT;
  };

  const sumCRBT = (reimbursementItems: ReimbursementItemCtrlModel[]) => {
    return (reimbursementItems as ReimbursementItemCtrlModel[]).reduce(
      sumCRBTReduce,
      0
    );
  };

  return (
    <Paper
      sx={{
        width: "100%",
        p: 3,
        height: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          borderLeft: "solid 5px #eee",
          p: 1,
          position: "sticky",
          top: 190,
        }}
      >
        <Grid container direction="column">
          <Grid item>
            <Typography
              sx={{ fontWeight: 700, fontSize: 20, mb: 2 }}
              variant="body2"
            >
              Livreur
            </Typography>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row" alignItems="flex-end">
              <PersonOutlineOutlinedIcon fontSize="small" />
              <Typography variant="body2" sx={{ fontSize: 14 }}>
                {officer.first_name} {officer.last_name}
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row" alignItems="flex-end">
              <LocalPhoneOutlinedIcon fontSize="small" />
              <Typography variant="body2" sx={{ fontSize: 14 }}>
                {officer.phone_number}
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row" alignItems="flex-end">
              <MailOutlinedIcon fontSize="small" />
              <Typography variant="body2" sx={{ fontSize: 14 }}>
                {officer.email}
              </Typography>
            </Stack>
          </Grid>

          {/* {distination?.shipments.length > 1 && (
            <Grid item>
              <Stack spacing={2} direction="row" alignItems="flex-end">
                <LocalPostOfficeOutlinedIcon fontSize="small" />
                <Typography variant="body2" noWrap sx={{ fontSize: 14 }}>
                  {distination?.shipments.length}
                  {" Colis"}
                </Typography>
              </Stack>
            </Grid>
          )} */}

          {data?.reimbursements.length > 1 && (
            <Grid item>
              <Stack spacing={2} direction="row" alignItems="flex-end">
                <MonetizationOnOutlinedIcon fontSize="small" />
                <Typography
                  variant="body2"
                  color="red"
                  noWrap
                  sx={{ fontSize: 14, fontWeight: 500 }}
                >
                  {priceFormat(sumCRBT(data?.reimbursements))}
                </Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

export default OfficierCard;
