import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { get } from "lodash";
import React, { FC, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { ReceptionResponseModel } from "../../../application/api/services/dispatching.service";
import { priceFormat } from "../../../application/helpers/priceFormat";
import {
  PackageResponseModel,
  ReceptionResponseDataModel,
  RecipientResponseModel,
  SenderResponseModel,
} from "../../../application/models/shared/receptionResponseDataModel";
import QRCode from "qrcode.react";
import { BarCode } from "../../../components/barCode";
export const Voutcher: FC<{ data: ReceptionResponseModel }> = ({ data }) => {
  const { shipments, sender } = get(data, ["data"], {
    shipments: [],
    sender: {},
  }) as ReceptionResponseDataModel;

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Button startIcon={<PrintOutlinedIcon />} onClick={handlePrint}>
        Imprimer
      </Button>
      <div ref={componentRef}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={0}
        >
          {shipments.map((shipment, i) => {
            return (
              <>
                {shipment.packages.map((packageItem, k) => (
                  <Grid item key={k}>
                    <BackegeVoutcher
                      sender={sender}
                      recipient={shipment.recipient}
                      packageItem={packageItem}
                    />
                  </Grid>
                ))}
              </>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

const BackegeVoutcher: FC<{
  sender: SenderResponseModel;
  recipient: RecipientResponseModel;
  packageItem: PackageResponseModel;
}> = ({ sender, recipient, packageItem }) => {
  return (
    <Paper
      sx={{
        width: 384,
        height: 384,
        p: 1,
        mt: 0,
        mb: 0,
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
        spacing={0.5}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              className="img-fluid"
              src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/logo_grey.png`}
              alt="logo"
              style={{ height: 30, width: 130 }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            alignItems="stretch"
            spacing={0.5}
            sx={{ height: "100%" }}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  border: "solid 1px #000",
                  p: 1,
                  height: "100%",
                  backgroundImage:
                    "linear-gradient(45deg, #000 2%, transparent 0%, transparent 50%, #000 126%, #000 50%, transparent 47%, #fff)",
                  backgroundSize: "100%",
                }}
              >
                <Grid container direction="column">
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: 500, fontSize: 11 }}
                      variant="body2"
                    >
                      Expéditeur
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" sx={{ fontSize: 10 }}>
                      {sender.first_name} {sender.last_name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" sx={{ fontSize: 10 }}>
                      {sender.address.address_line}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" noWrap sx={{ fontSize: 10 }}>
                      {sender.address.post_office.name} /{" "}
                      {sender.address.post_office.zip_code}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ border: "solid 1px #000", p: 1, height: "100%" }}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: 500, fontSize: 11 }}
                      variant="body2"
                    >
                      Destinataire
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" sx={{ fontSize: 10 }}>
                      {recipient.first_name} {recipient.last_name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" sx={{ fontSize: 10 }}>
                      {recipient.address.address_line}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" noWrap sx={{ fontSize: 10 }}>
                      {recipient.address.post_office.name} /{" "}
                      {recipient.address.post_office.zip_code}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ border: "solid 1px #000", p: 1 }}>
            <Grid
              container
              //  spacing={1}
              justifyContent="space-between"
              alignItems="flex-start"
              wrap="nowrap"
            >
              <Grid item>
                <Typography variant="body2" noWrap sx={{ fontSize: 10 }}>
                  <b>Date :</b> {packageItem.received_at}
                </Typography>
                <Typography variant="body2" noWrap sx={{ fontSize: 10 }}>
                  <b> Poids :</b> {packageItem.weight} Kg
                </Typography>
                {packageItem.reimbursement.enable &&
                  packageItem.reimbursement.amount > 0 && (
                    <Typography variant="body2" noWrap sx={{ fontSize: 10 }}>
                      <b> CRBT :</b>{" "}
                      {priceFormat(packageItem.reimbursement.amount)}
                    </Typography>
                  )}
                <Typography variant="body2" noWrap sx={{ fontSize: 10 }}>
                  <b> Prix :</b> {priceFormat(packageItem.price)}
                </Typography>
                <Typography variant="body2" noWrap sx={{ fontSize: 10 }}>
                  <b> Identifiant :</b> {packageItem.identifier}
                </Typography>
              </Grid>
              <Grid item>
                {" "}
                <QRCode value={packageItem.identifier} size={80} />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              border: "solid 1px #000",
              p: 0.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="body2" sx={{ fontSize: 10 }}>
              ODD n'a aucune responsabilité en cas de saisie de votre envoi par
              les autorités de l'Etat Tunisien
            </Typography>
            {/* <BarCode value={packageItem.identifier} /> */}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
