import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import React, { FC } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import * as yup from "yup";
import { DistrictFormModel } from "./models";

const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().optional(),
});

const DistrictForm: FC<{
  defaultValues?: DistrictFormModel;
  loading?: boolean;
  onValide: (data: DistrictFormModel) => void;
}> = (props) => {
  const formCtx = useForm<DistrictFormModel>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: props?.defaultValues || {
      name: "",
      description: "",
    },
  });

  const formValues = formCtx.watch();

  const {
    formState: { errors },
  } = formCtx;

  const onSubmit: SubmitHandler<DistrictFormModel> = (data) => {
    props.onValide(data);
  };

  return (
    <FormProvider {...formCtx}>
      <form onSubmit={formCtx.handleSubmit(onSubmit)}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item md={5} xs={12}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Controller
                  name="name"
                  control={formCtx.control}
                  render={({ field }) => (
                    <TextField
                      autoComplete="new-password"
                      fullWidth
                      label="District"
                      {...field}
                      error={Boolean(errors?.name)}
                      helperText={errors?.name ? errors?.name.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="description"
                  control={formCtx.control}
                  render={({ field }) => (
                    <TextField
                      autoComplete="new-password"
                      fullWidth
                      label="Description"
                      {...field}
                      multiline
                      maxRows={6}
                      rows={6}
                      error={Boolean(errors?.description)}
                      helperText={
                        errors?.description
                          ? errors?.description?.message
                          : null
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item></Grid>
              <Grid item>
                <Box sx={{ m: 1, position: "relative" }}>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={props?.loading}
                  >
                    Sauvegarder
                  </Button>
                  {props?.loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default DistrictForm;
