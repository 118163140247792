import { isUndefined } from "lodash";
import { CityModel } from "../../../../application/models/shared/cityModel";
import {
  StructureItemModel,
  StructureItemTypeModel,
} from "../../../../application/models/shared/officeModel";

/** */
export const isStructureChecked = (
  item: StructureItemModel,
  structureItems: StructureItemModel[] | []
): boolean => {
  if (structureItems.length === 0) return false;
  const isChecked = structureItems.find(
    (itemChecked) =>
      itemChecked.id === item.id &&
      itemChecked.type === item.type &&
      itemChecked?.parent_id === item?.parent_id
  );
  return isChecked !== undefined;
};

/** */
export const getStructureChildType = (
  item: StructureItemModel
): StructureItemTypeModel | null => {
  if (item.type === StructureItemTypeModel.GOVERNORATE)
    return StructureItemTypeModel.CITY;
  if (item.type === StructureItemTypeModel.CITY)
    return StructureItemTypeModel.POST_OFFICE;
  return null;
};

/** */
export const getStructureParentType = (
  item: StructureItemModel
): StructureItemTypeModel | null => {
  if (item.type === StructureItemTypeModel.POST_OFFICE)
    return StructureItemTypeModel.CITY;
  if (item.type === StructureItemTypeModel.CITY)
    return StructureItemTypeModel.GOVERNORATE;
  return null;
};

/** */
export const getNbrOfChildrenChecked = (
  params: { id: number; type: StructureItemTypeModel },
  structureItems: StructureItemModel[] | []
): number => {
  const { id, type } = params;
  if (structureItems.length === 0) return 0;
  return [...structureItems].filter(
    (itemChecked) => itemChecked?.parent_id === id && itemChecked.type === type
  ).length;
};

/** */
export const isIndeterminateChecked = (
  nbrOfChildrenChecked: number,
  nbrOfAllChildren: number
): boolean => {
  return (
    nbrOfChildrenChecked > 0 &&
    (nbrOfChildrenChecked < nbrOfAllChildren || nbrOfAllChildren === 0)
  );
};

/** */
export const isAassignedToAnother = (
  currentId: number | undefined,
  affected: boolean,
  affectedTo: number | undefined,
  nbrOfChildrenChecked: number
): boolean => {
  if (!isUndefined(currentId)) {
    return affected && affectedTo !== currentId;
  } else {
    return affected;
  }
};

/** */
export const citiesToStructureItems = (
  cities: CityModel[] | [],
  governorateId: number
): StructureItemModel[] | [] => {
  return cities.map((item) => ({
    id: item.id,
    name: item.name,
    type: StructureItemTypeModel.CITY,
    parent_id: governorateId,
  }));
};
