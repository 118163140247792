import FilterListIcon from "@mui/icons-material/FilterList";
import PrintIcon from "@mui/icons-material/Print";
import {
  Alert,
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { get } from "lodash";
import * as React from "react";
import { useFetchBagsQuery } from "../../application/api/hooks/bag.hook";
import { BagsPaginationResponseModel } from "../../application/api/services/bag.service";
import { BagModel } from "../../application/models/shared/bagModel";
import { BagStatusEnumModel } from "../../application/models/shared/bagStatusModel";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { BagStatusChip } from "../../components/bagStatusChip";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";
import { BagBarcodePrint } from "../../pages_components/bags/bagBarcodePrint";

interface StateModel {
  currentPage: number;
  searchText: string;
}

const ListBagsPage: React.FC<PagePropsModel> = (): JSX.Element => {
  const [state, setState] = React.useState<StateModel>({
    currentPage: 1,
    searchText: "",
  });

  const bagsQuery = useFetchBagsQuery({
    pagination: { page: state.currentPage, per_page: 10 },
    // status: [BagStatusEnumModel.forwarded_to_reception_district],
  });
  const {
    data: { data, current_page, total },
  } = get(bagsQuery, ["data"], {
    data: [],
    current_page: 1,
    total: 0,
  }) as BagsPaginationResponseModel;

  const [open, setOpen] = React.useState(false);
  const toggleFilter = () => {
    setOpen((prev) => !prev);
  };

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Liste des sacs",
    },
  ];

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setState((state) => ({ ...state, currentPage: value }));
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Typography variant="h5">List des sacs</Typography>
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box pb={1}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography>
                <b>{total}</b> Sacs
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={toggleFilter}
              >
                <FilterListIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>{" "}
        <Collapse in={open}>
          <div></div>
        </Collapse>
        {bagsQuery.isLoading === false && data?.length === 0 && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="info">Aucune donnée disponible!</Alert>
          </Box>
        )}
        {bagsQuery.isLoading && <LinearProgress />}
        {total > 0 && (
          <TableContainer component={Paper}>
            <Table
              sx={{ width: "100%" }}
              size="small"
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">De</StyledTableCell>
                  <StyledTableCell align="left">À</StyledTableCell>
                  <StyledTableCell align="left">Nbr colis</StyledTableCell>
                  <StyledTableCell align="left">Code</StyledTableCell>
                  <StyledTableCell align="left">Statut</StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(data || []).map((row, index) => (
                  <RowItem item={row} key={index} />
                ))}
              </TableBody>
            </Table>{" "}
          </TableContainer>
        )}
        <div style={{ float: "right", padding: 20 }}>
          {total > 0 && (
            <Pagination
              count={Math.ceil(Number(total) / 10)}
              color="primary"
              showFirstButton
              showLastButton
              page={state.currentPage}
              onChange={handleChangePage}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ListBagsPage;

const RowItem: React.FC<{ item: BagModel }> = ({ item }): JSX.Element => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <StyledTableRow>
        <StyledTableCell component="th" scope="row">
          {item.sender.name}
        </StyledTableCell>
        <StyledTableCell component="th">{item.recipient.name}</StyledTableCell>
        <StyledTableCell>{item?.shipments?.length || 0}</StyledTableCell>
        <StyledTableCell>{item.identifier}</StyledTableCell>

        <StyledTableCell>
          <BagStatusChip
            active={true}
            status={item?.status as BagStatusEnumModel}
            size="small"
            variant="outlined"
          />
        </StyledTableCell>
        <StyledTableCell align="right">
          <IconButton
            aria-label="more"
            id="long-button"
            color="inherit"
            onClick={() => setOpen(true)}
          >
            <PrintIcon fontSize="small" />
          </IconButton>

          {/* <IconButton
            aria-label="more"
            id="long-button"
            color="error"
            onClick={() => console.log(item)}
          >
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </IconButton> */}
        </StyledTableCell>
      </StyledTableRow>

      <Drawer
        anchor={"right"}
        sx={{ marginTop: 40, maxHeight: 400 }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div style={{ minWidth: 400 }}>
          <Toolbar>
            <Grid container spacing={1}>
              <Grid item>
                <PrintIcon />
              </Grid>
              <Grid item>
                <Typography sx={{ fontWeight: 500 }}>Print</Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <Divider />

          <Box sx={{ padding: 4 }}>
            <BagBarcodePrint {...item} />
          </Box>
        </div>
      </Drawer>
    </>
  );
};
