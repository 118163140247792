import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { Box } from "@mui/system";
import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { dispatchingService } from "../../../../application/api/services/dispatching.service";
import { priceFormat } from "../../../../application/helpers/priceFormat";
import { ShipmentModel } from "../../../../application/models/shared/ShipmentModel";
import { SnackbarContext } from "../../../../components/snackbarProvider";
import { useTripShipmentsStore } from "../../../../pages/shipments/hooks/TripShipmentsState";

export interface CheckTrackingIdFormModel {
  identifier: string;
  delivery_code: string;
}

interface StateModel {
  isLoading: boolean;
  error: string;
  openDialog: boolean;
  formValues: CheckTrackingIdFormModel | null;
}

const schema = yup.object().shape({
  identifier: yup.string().required(),
  delivery_code: yup.string().required(),
});

const CheckTrackingIdForm: FC<{
  shipment: ShipmentModel;
  showLabel?: boolean;
}> = ({ shipment, showLabel }): JSX.Element => {
  const { fetch } = useTripShipmentsStore();

  const [state, setState] = useState<StateModel>({
    isLoading: false,
    error: "",
    openDialog: false,
    formValues: null,
  });

  const [snackbar, setSnackbar] = React.useContext(SnackbarContext);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CheckTrackingIdFormModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      identifier: `${shipment.identifier}`,
      delivery_code: "",
    },
  });

  const formValues = watch();

  useEffect(() => {
    setValue("identifier", shipment.identifier);
  }, [shipment]);

  const onSubmit = (data: CheckTrackingIdFormModel) => {
    if (shipment.reimbursement.enable && shipment.reimbursement.amount > 0) {
      setState((state) => ({
        ...state,
        isLoading: false,
        error: "",
        formValues: data,
        openDialog: true,
      }));
    } else {
      setShipmentDelivery(data);
    }
  };

  const setShipmentDelivery = (data: CheckTrackingIdFormModel) => {
    if (data) {
      setState((state) => ({
        ...state,
        isLoading: true,
        error: "",
        openDialog: false,
      }));
      dispatchingService
        .shipmentDelivery({
          ...data,
        })
        .then((response) => {
          setSnackbar({
            open: true,
            severity: "success",
            message: "Opération effectuée avec succes",
          });
          setValue("delivery_code", "");
          setState((state) => ({
            ...state,
            isLoading: false,
            error: "",
            formValues: null,
          }));
          fetch();
        })
        .catch((error) => {
          setState((state) => ({
            ...state,
            isLoading: false,
            formValues: null,
            error: String(error),
          }));
        });
    }
  };

  if (state.openDialog && state.formValues !== null) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        spacing={0}
      >
        <Grid item>
          <Typography sx={{ mr: 3, fontWeight: 500, fontSize: 14 }}>
            Avez-vous bien reçu {priceFormat(shipment.reimbursement.amount)}
          </Typography>
        </Grid>

        <Grid item>
          <Button
            color="success"
            onClick={() => {
              if (state.formValues !== null) {
                setShipmentDelivery(state.formValues);
              }
            }}
          >
            Oui
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="error"
            onClick={() => {
              setState((state) => ({
                ...state,
                openDialog: false,
                formValues: null,
              }));
            }}
          >
            Non
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        alignItems="stretch"
        justifyContent="flex-start"
        direction={"column"}
        spacing={2}
      >
        {(showLabel === undefined || showLabel === true) && (
          <Grid item>
            <Typography sx={{ mr: 3, fontWeight: 500, fontSize: 14 }}>
              Saisissez le code confidentiel
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Stack direction={"row"} spacing={0}>
            <Controller
              render={({ field }) => (
                <TextField
                  size="small"
                  id="outlined-basic"
                  autoComplete="off"
                  fullWidth={true}
                  {...field}
                  placeholder="Code confidentiel"
                  error={Boolean(errors?.delivery_code)}
                  // sx={{
                  //   border: errors?.delivery_code ? "solid 1px red" : "0px",
                  // }}
                />
              )}
              name="delivery_code"
              control={control}
            />
            <Box sx={{ position: "relative" }}>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={state.isLoading}
                sx={{
                  height: 38.84,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                Vérifier
              </Button>
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </Stack>
        </Grid>
        {(state.error !== "" || Boolean(errors?.delivery_code)) && (
          <Grid item>
            <Alert
              severity="error"
              variant="outlined"
              sx={{
                // ml: 3,
                padding: "0px 16px",
                width: "100%",
              }}
            >
              {errors?.delivery_code
                ? errors?.delivery_code.message
                : "Code confidentiel incorrect"}
            </Alert>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default CheckTrackingIdForm;
