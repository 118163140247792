export enum InvoiceStatusEnumModel {
  paid = "paid",
  unpaid = "unpaid",
  partial_paid = "partial_paid",
}

export interface InvoiceStatusModel {
  color: string;
  bgColor: string;
  label: string;
}

export type RecordInvoiceStatusModel = Record<
  InvoiceStatusEnumModel,
  InvoiceStatusModel
>;
