import { TextField, TextFieldProps } from "@mui/material";
import React, { FC } from "react";
import { IMaskInput } from "react-imask";
import InputMask from "react-input-mask";

export const PhoneInputField: FC = (props: TextFieldProps): JSX.Element => {
  return (
    <InputMask mask="(0)999 999 99 99">
      {() => <TextField {...props} />}
    </InputMask>
  );
};

interface PhoneInputMaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const PhoneInputMask = React.forwardRef<
  HTMLElement,
  PhoneInputMaskProps
>(function PhoneInputMask(props, ref) {
  const { onChange, ...other } = props;

  const setRef = React.useCallback(
    (maskedInputRef) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null;

      if (typeof ref === "function") {
        ref(value);
      } else if (ref) {
        ref.current = value;
      }
    },
    [ref]
  );

  return (
    <IMaskInput
      {...other}
      mask="21600000000"
      definitions={{
        "#": /[1-9]/,
      }}
      ref={setRef}
      onAccept={(value: any) =>
        onChange({ target: { name: props.name, value } })
      }
      overwrite
    />
  );
});
