import { Typography } from "@mui/material";
import { FC } from "react";
import { invoiceStatus } from "../../application/data/InvoiceStatus";
import { InvoiceStatusEnumModel } from "../../application/models/shared/invoiceStatusModel";

export const InvoiceStatusChip: FC<{ status: InvoiceStatusEnumModel }> = (
  props
): JSX.Element => {
  const propsStatus = invoiceStatus[props.status];
  const { color } = propsStatus;

  return (
    <Typography
      variant="caption"
      sx={{
        color: color,
        fontWeight: 500,
      }}
    >
      {" "}
      {propsStatus.label}
    </Typography>
  );
};
