import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { bagService } from "../../application/api/services/bag.service";
import { MY_TRIP_BAGS_ROUTE } from "../../application/constants/routeConstants";
import { BagModel } from "../../application/models/shared/bagModel";

interface MyTipCardStateModel {
  isLoading: boolean;
  isError: boolean;
  data: BagModel[] | [];
}

export const MyTripCard: FC = (): JSX.Element => {
  const history = useHistory();
  const [state, setState] = useState<MyTipCardStateModel>({
    isLoading: false,
    isError: false,
    data: [],
  });

  const sumPiecesReduce = (acc: number, cur: BagModel): number => {
    const _cur = cur.shipments?.length || 0;
    return acc + _cur;
  };

  const sumPieces = (items: BagModel[]): number => {
    return (items as BagModel[]).reduce(sumPiecesReduce, 0);
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setState((state) => ({ ...state, isLoading: true, isError: false }));
    bagService
      .fetchMyTrip()
      .then((response) => {
        setState((state) => ({
          ...state,
          isLoading: false,
          isError: false,
          data: response.data,
        }));
      })
      .catch((error) => {
        setState((state) => ({
          ...state,
          isLoading: false,
          isError: true,
        }));
      });
  };

  return (
    <Card
      sx={{
        display: "flex",
        p: 2,
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography style={{ marginBottom: 20, fontWeight: 500 }}>
            Mes sacs
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            sx={{ cursor: "pointer" }}
            onClick={() => history.push(MY_TRIP_BAGS_ROUTE)}
          >
            {state.data.length} sacs
          </Typography>
        </CardContent>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ fontSize: 24, fontWeight: 600 }}>
            {sumPieces(state.data)} Pièces
          </Typography>
        </Box>
      </Box>
      <CardMedia
        component="img"
        sx={{ maxWidth: 151 }}
        image={"/assets/icons/Logistics-rafiki.svg"}
        alt="Live from space album cover"
      />
    </Card>
  );
};
