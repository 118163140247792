import { Alert, Grid, LinearProgress } from "@mui/material";
import { isUndefined } from "lodash";
import React, { FC, useState } from "react";
import {
  dispatchingService,
  ReceptionResponseModel,
} from "../../application/api/services/dispatching.service";
import { ReceptionFormModel } from "../../application/models/forms/recptionFormModel";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import ReceptionForm from "../../pages_components/reception/forms";
import { Validation } from "../../pages_components/shipments/validation";
import { Voutcher } from "../../pages_components/shipments/voutcher";

enum StepModel {
  FORM = "FORM",
  VALIDATION = "VALIDATION",
}

const ReceptionPage: FC<PagePropsModel> = (props): JSX.Element => {
  const [state, setState] = useState<{
    formValues: ReceptionFormModel | undefined;
    step: StepModel;
    store: boolean;
    storedData: ReceptionResponseModel | undefined;
    isLoading: boolean;
    isError: boolean;
  }>({
    formValues: undefined,
    storedData: undefined,
    step: StepModel.FORM,
    store: false,
    isLoading: false,
    isError: false,
  });
  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Envoie Colis",
    },
  ];

  const save = () => {
    if (!isUndefined(state.formValues)) {
      setState((state) => ({ ...state, isLoading: true, store: true }));

      dispatchingService
        .reception(state.formValues)
        .then((response) => {
          setState((state) => ({
            ...state,
            isError: false,
            isLoading: false,
            storedData: response,
            store: true,
          }));
        })
        .catch((error) => {
          setState((state) => ({
            ...state,
            isError: true,
            isLoading: false,
            store: true,
          }));
        });
    }
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="Nouveau Colis" />
        <AppBreadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>

      {state.isLoading && (
        <Grid item>
          <LinearProgress color="secondary" />
        </Grid>
      )}
      {state.isError && (
        <Grid item>
          <Alert severity="error">
            Une erreur s'est produite lors de l'enregistrement
          </Alert>
        </Grid>
      )}

      <Grid item>
        {state.step === StepModel.VALIDATION &&
        state.formValues &&
        isUndefined(state.storedData) ? (
          <Validation
            isLoading={state.isLoading}
            formValue={state.formValues}
            previousButton={{
              onClick: () =>
                setState((state) => ({ ...state, step: StepModel.FORM })),
            }}
            onValide={() => save()}
          />
        ) : !isUndefined(state.storedData) ? (
          <div>
            <Voutcher data={state.storedData} />
          </div>
        ) : (
          <ReceptionForm
            settings={{
              hideSenderForm: false,
              isNested: true,
            }}
            defaultValues={state.formValues}
            onSubmit={(formValues) =>
              setState((state) => ({
                ...state,
                formValues: formValues,
                step: StepModel.VALIDATION,
              }))
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ReceptionPage;
