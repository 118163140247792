export const PUBLIC_ROUTE = `/public`;
export const PRIVATE_ROUTE = `/private`;
export const LIST_PACKAGE_ROUTE = `${PRIVATE_ROUTE}/colis`;
export const DETAILS_PACKAGE_ROUTE = `${PRIVATE_ROUTE}/colis/details`;
export const TRIP_SHIPMENTS_ROUTE = `${PRIVATE_ROUTE}/colis/trip`;

export const OFFICE_BAGS_PREPARE_ROUTE = `${PRIVATE_ROUTE}/bags/office-prepare`;
export const INNER_BAGS_PREPARE_ROUTE = `${PRIVATE_ROUTE}/bags/inner-prepare`;
export const OUTER_BAGS_PREPARE_ROUTE = `${PRIVATE_ROUTE}/bags/outer-prepare`;
export const FORWARD_BAGS_ROUTE = `${PRIVATE_ROUTE}/bags/forward`;
export const RECEIVE_BAGS_ROUTE = `${PRIVATE_ROUTE}/bags/receive`;
export const RECEIVE_BAGS_IN_PLATFORM_ROUTE = `${PRIVATE_ROUTE}/bags/receive-from-platform`;
export const MY_TRIP_BAGS_ROUTE = `${PRIVATE_ROUTE}/bags/my-trip`;

export const BAGS_ROUTE = `${PRIVATE_ROUTE}/bags`;

export const RECEIPT_PACKAGE_ROUTE = `${PRIVATE_ROUTE}/colis/receipt`;
export const CREATE_PACKAGE_ROUTE = `${PRIVATE_ROUTE}/colis/add`;
export const LIST_DELIVERY_ROUTE = `${PRIVATE_ROUTE}/deliveries`;
export const CREATE_DELIVERY_ROUTE = `${PRIVATE_ROUTE}/deliveries/add`;

export const LIST_CUSTOMERS_ROUTE = `${PRIVATE_ROUTE}/customers`;
export const EDIT_CUSTOMER_ROUTE = `${PRIVATE_ROUTE}/customers/edit`;
export const CREATE_CUSTOMER_ROUTE = `${PRIVATE_ROUTE}/customers/create`;
export const PROFILE_CUSTOMER_ROUTE = `${PRIVATE_ROUTE}/customers/profile`;

export const LIST_USERS_ROUTE = `${PRIVATE_ROUTE}/users`;
export const EDIT_USER_ROUTE = `${PRIVATE_ROUTE}/users/edit`;
export const CREATE_USER_ROUTE = `${PRIVATE_ROUTE}/users/create`;
export const PROFILE_USER_ROUTE = `${PRIVATE_ROUTE}/users/profile`;
export const CHANGE_PASSWORD_ROUTE = `${PRIVATE_ROUTE}/users/change_password`;

export const LIST_OFFICE_ROUTE = `${PRIVATE_ROUTE}/offices`;
export const EDIT_OFFICE_ROUTE = `${PRIVATE_ROUTE}/offices/edit`;
export const CREATE_OFFICE_ROUTE = `${PRIVATE_ROUTE}/offices/create`;

export const LIST_DISTRICT_ROUTE = `${PRIVATE_ROUTE}/districts`;
export const EDIT_DISTRICT_ROUTE = `${PRIVATE_ROUTE}/districts/edit`;
export const CREATE_DISTRICT_ROUTE = `${PRIVATE_ROUTE}/districts/create`;

export const TAKE_FOR_DELIVERY_ROUTE = `${PRIVATE_ROUTE}/districts/take-for-delivery`;

export const REIMBURSEMENT_IN_ROUTE = `${PRIVATE_ROUTE}/reimbursement/in`;
export const REIMBURSEMENT_OUT_ROUTE = `${PRIVATE_ROUTE}/reimbursement/out`;

export const LIST_INVOICES_ROUTE = `${PRIVATE_ROUTE}/invoices`;
export const DETAILS_INVOICES_ROUTE = `${PRIVATE_ROUTE}/invoices/details`;

export const FUNDS_OFFICE_ROUTE = `${PRIVATE_ROUTE}/funds-office`;
export const FUNDS_MINE_ROUTE = `${PRIVATE_ROUTE}/funds-mine`;

export const LIST_PICKUPS_ROUTE = `${PRIVATE_ROUTE}/pickups`;
export const DETAILS_PICKUP_ROUTE = `${PRIVATE_ROUTE}/pickups/details`;
export const EDIT_PICKUP_ROUTE = `${PRIVATE_ROUTE}/pickups/edit`;
export const EDIT_PICKUP_PARCEL_ROUTE = `${PRIVATE_ROUTE}/pickups/edit-parcel`;
