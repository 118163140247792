import { Breadcrumbs, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FC } from "react";

import { AppBreadcrumbsProps } from "./models";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(1),
  },
}));

export const AppBreadcrumbs: FC<AppBreadcrumbsProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {props.breadcrumbs.map((item, index) => {
          return !item.href ? (
            <Typography key={index} variant="body2" color="text.primary">
              {item.label}
            </Typography>
          ) : (
            <Typography key={index} variant="body2" color="text.primary">
              {item.label}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};
