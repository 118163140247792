import { Grid, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { priceFormat } from "../../../application/helpers/priceFormat";
import { DestinationModel } from "../../../pages/shipments/TripShipments";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { ShipmentModel } from "../../../application/models/shared/ShipmentModel";

const DistinationCard: FC<{ distination: DestinationModel }> = ({
  distination,
}): JSX.Element => {
  const sumDestinationCRBTReduce = (
    acc: number,
    cur: ShipmentModel
  ): number => {
    const curCRBT = cur.reimbursement.enable
      ? Number(cur.reimbursement.amount)
      : 0;
    return acc + curCRBT;
  };

  const sumDestinationCRBT = (shipments: ShipmentModel[]) => {
    return (shipments as ShipmentModel[]).reduce(sumDestinationCRBTReduce, 0);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        p: 3,
        height: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          borderLeft: "solid 5px #eee",
          p: 1,
          position: "sticky",
          top: 190,
        }}
      >
        <Grid container direction="column">
          <Grid item>
            <Typography
              sx={{ fontWeight: 700, fontSize: 20, mb: 3 }}
              variant="body2"
            >
              Destinataire
            </Typography>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row" alignItems="flex-end">
              <PersonOutlineOutlinedIcon fontSize="small" />
              <Typography variant="body2" sx={{ fontSize: 14 }}>
                {distination.recipient.first_name}{" "}
                {distination.recipient.last_name}
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row" alignItems="flex-end">
              <LocalPhoneOutlinedIcon fontSize="small" />
              <Typography variant="body2" sx={{ fontSize: 14 }}>
                {distination.recipient.phone_number}
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row" alignItems="flex-end">
              <PlaceOutlinedIcon fontSize="small" />
              <Typography variant="body2" sx={{ fontSize: 14 }}>
                {distination.recipient?.address?.address_line}
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction="row" alignItems="flex-end">
              <HomeWorkOutlinedIcon fontSize="small" />
              <Typography variant="body2" noWrap sx={{ fontSize: 14 }}>
                {distination?.recipient?.address?.post_office?.name} /{" "}
                {distination?.recipient?.address?.post_office?.zip_code}
              </Typography>
            </Stack>
          </Grid>

          {distination?.shipments.length > 1 && (
            <Grid item>
              <Stack spacing={2} direction="row" alignItems="flex-end">
                <LocalPostOfficeOutlinedIcon fontSize="small" />
                <Typography variant="body2" noWrap sx={{ fontSize: 14 }}>
                  {distination?.shipments.length}
                  {" Colis"}
                </Typography>
              </Stack>
            </Grid>
          )}

          {distination?.shipments.length > 1 && (
            <Grid item>
              <Stack spacing={2} direction="row" alignItems="flex-end">
                <MonetizationOnOutlinedIcon fontSize="small" />
                <Typography
                  variant="body2"
                  color="red"
                  noWrap
                  sx={{ fontSize: 14, fontWeight: 500 }}
                >
                  {priceFormat(sumDestinationCRBT(distination?.shipments))}
                </Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

export default DistinationCard;
