import { DistrictFormModel } from "../../../pages_components/districts/forms/editForm/models";
import httpClient from "../../httpClient";
import { DistrictModel } from "../../models/shared/districtModel";
import { HttpResponseModel } from "../../models/shared/httpResponseModel";
import { OrderDirectionModel } from "../../models/shared/orderDirectionModel";
import { PaginationModel } from "../../models/shared/paginationModel";

export interface CreateResponseModel extends HttpResponseModel {
  data: DistrictModel;
}

export interface FetchWithPaginationResponseModel extends HttpResponseModel {
  data: { data: DistrictModel[] | []; current_page: number; total: number };
}

export interface OrderByModel {
  orderField: keyof DistrictModel;
  orderDirection: OrderDirectionModel;
}

const create = async (
  data: DistrictFormModel
): Promise<CreateResponseModel> => {
  return httpClient.post("settings/districts", {
    ...data,
  });
};

const update = async (
  data: DistrictFormModel
): Promise<CreateResponseModel> => {
  const { id, ...other } = data;

  return httpClient.patch(`settings/districts/${id}`, {
    ...other,
  });
};

const remove = async (id: number): Promise<CreateResponseModel> => {
  return httpClient.delete(`settings/districts/${id}`);
};

const findOne = async (id: number): Promise<DistrictModel> => {
  return httpClient.get(`settings/districts/${id}`);
};

export interface FetchParamsQueryModel {
  pagination: PaginationModel;
  search?: string;
  orderParams?: OrderByModel;
}

const fetch = async (
  params: FetchParamsQueryModel
): Promise<FetchWithPaginationResponseModel> => {
  const { pagination, search = undefined, orderParams = undefined } = params;
  return httpClient.get("settings/districts", {
    params: {
      ...pagination,
      ...(() => (search ? search : {}))(),
      ...(() => (orderParams ? orderParams : {}))(),
    },
  });
};

export const districtService = {
  create,
  update,
  remove,
  fetch,
  findOne,
};
