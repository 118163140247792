import { isNaN, isUndefined } from "lodash";
import { useQuery, UseQueryResult } from "react-query";
import { DistrictFormModel } from "../../../pages_components/districts/forms/editForm/models";
import { DistrictModel } from "../../models/shared/districtModel";

import {
  CreateResponseModel,
  districtService,
  FetchParamsQueryModel,
  FetchWithPaginationResponseModel,
} from "../services/district.service";

export const useFetchDistrictsQuery = (
  params: FetchParamsQueryModel
): UseQueryResult<FetchWithPaginationResponseModel> => {
  return useQuery<FetchWithPaginationResponseModel>({
    queryKey: ["find-query", JSON.stringify(params)].join("-"),
    queryFn: () => {
      return !isUndefined(params)
        ? districtService.fetch(params)
        : Promise.reject();
    },
    enabled: !isUndefined(params),
  });
};

export const useFindOneDistrictQuery = (
  id: number
): UseQueryResult<DistrictModel> => {
  return useQuery<DistrictModel>({
    queryKey: ["findOne-query", id].join("-"),
    queryFn: () => {
      return !isUndefined(id) ? districtService.findOne(id) : Promise.reject();
    },
    enabled: !isUndefined(id) && !isNaN(id),
  });
};

export const useCreateDistrictQuery = (
  data?: DistrictFormModel
): UseQueryResult<CreateResponseModel> => {
  return useQuery<CreateResponseModel>({
    queryKey: ["createDistrict-query", JSON.stringify(data)].join("-"),
    queryFn: () => {
      return !isUndefined(data)
        ? districtService.create(data)
        : Promise.reject();
    },
    enabled: !isUndefined(data),
  });
};

export const useUpdateDistrictQuery = (
  data?: DistrictFormModel
): UseQueryResult<CreateResponseModel> => {
  return useQuery<CreateResponseModel>({
    queryKey: ["createDistrict-query", JSON.stringify(data)].join("-"),
    queryFn: () => {
      return !isUndefined(data)
        ? districtService.update(data)
        : Promise.reject();
    },
    enabled: !isUndefined(data),
  });
};
