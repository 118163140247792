import httpClient from "../../httpClient";
import { RecipientPackagesFormModel } from "../../models/forms/recipientPackagesFormModel";
import { HttpResponseModel } from "../../models/shared/httpResponseModel";
import { PaginationModel } from "../../models/shared/paginationModel";
import { PickupModel } from "../../models/shared/pickupModel";

export interface FetchQueryModel {
  pagination: PaginationModel;
  search?: string;
}

export interface PickupsResponseModel extends HttpResponseModel {
  data: PickupModel[] | [];
  current_page: number;
  total: number;
}

const fetch = async (
  params: FetchQueryModel
): Promise<PickupsResponseModel> => {
  const { pagination, search = undefined } = params;
  return httpClient
    .get("dispatching/pickups", {
      params: {
        ...pagination,
      },
    })
    .then(({ data }) => data);
};

const findOne = async (id: number): Promise<PickupModel> => {
  return httpClient.get(`dispatching/pickups/${id}`).then(({ data }) => data);
};

const receive = async (params: {
  pickupId: number;
  shipments: RecipientPackagesFormModel[];
}): Promise<string[]> => {
  const { pickupId, shipments } = params;
  return httpClient.post(`dispatching/pickups/${pickupId}/receive`, {
    shipments,
  });
};

const cancelShipment = async (params: {
  pickupId: number;
  shipmentId: number;
}): Promise<string[]> => {
  const { pickupId, shipmentId } = params;
  return httpClient.post(
    `dispatching/pickups/${pickupId}/reject/${shipmentId}`,
    {}
  );
};

const receiveShipment = async (params: {
  pickupId: number;
  shipmentId: number;
}): Promise<string[]> => {
  const { pickupId, shipmentId } = params;
  return httpClient.post(
    `dispatching/pickups/${pickupId}/receive/${shipmentId}`,
    {}
  );
};

export const pickupService = {
  fetch,
  findOne,
  receive,
  receiveShipment,
  cancelShipment,
};
