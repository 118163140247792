import { Alert, Grid, LinearProgress, Paper, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isUndefined } from "lodash";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { userService } from "../../application/api/services/user.service";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from "../../application/constants/authConstants";
import { useAuth } from "../../application/contexts/authContex";
import { removeStorageItem } from "../../application/helpers/localStorage";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import If from "../../components/conditionalViwer";
import { PageTitle } from "../../components/PageTitle";
import { SnackbarContext } from "../../components/snackbarProvider";
import ChangePasswordForm from "../../pages_components/users/Forms/changePasswordForm";
import { ChangePasswordFormModel } from "../../pages_components/users/Forms/changePasswordForm/models";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
}));

const ChangePasswordPage: FC<PagePropsModel> = (props) => {
  const history = useHistory();
  const auth = useAuth();
  const classes = useStyles();
  const [snackbar, setSnackbar] = React.useContext(SnackbarContext);

  const [state, setState] = useState<{
    isLoading: boolean;
    isError: boolean;
    saved: boolean;
  }>({
    isLoading: false,
    isError: false,
    saved: false,
  });

  const save = (data: ChangePasswordFormModel) => {
    setState((state) => ({ ...state, isLoading: true, isError: false }));
    userService
      .changePassword(data)
      .then((response) => {
        setSnackbar({
          open: true,
          message:
            "Votre mot de passe à été changé avec succès!.  veuillez vous reconnecter",
        });
        removeStorageItem(ACCESS_TOKEN);
        removeStorageItem(REFRESH_TOKEN);
        auth.signout(() => {
          history.push("/public");
        });
      })
      .catch((error) => {
        setState((state) => ({ ...state, isLoading: false, isError: true }));
      });
  };

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Modification mot de passe",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title={"Modification mot de passe"} />
        <AppBreadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>
      {state.isLoading && (
        <Grid item>
          <LinearProgress />
        </Grid>
      )}
      {!state.isLoading && state.isError && (
        <Grid item>
          <Alert severity="error">
            {" "}
            Une erreur s'est produite lors de l'enregistrement : Merci de
            verifier votre ancien mot de passe
          </Alert>
        </Grid>
      )}
      {!state.isLoading && !state.isError && state.saved && (
        <Grid item>
          <Alert severity="success"> Opération réalisée avec succès</Alert>
        </Grid>
      )}
      {!state.saved && (
        <Grid item>
          <Paper className={classes.paper}>
            <If
              condition={!isUndefined(auth.user)}
              render={() => (
                <ChangePasswordForm
                  defaultValues={{
                    old_password: "",
                    new_password: "",
                    new_password_confirmation: "",
                  }}
                  onValide={(data) => save(data)}
                />
              )}
              otherwise={() => (
                <Alert severity="error">user n'est pas trouvé !</Alert>
              )}
            />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default ChangePasswordPage;
