import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import PrintIcon from "@mui/icons-material/Print";
import SaveIcon from "@mui/icons-material/Save";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import {
  CardActionArea,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemText,
  Toolbar,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import * as React from "react";
import {
  prepareBagsRequestParamsModel,
  prepareBagsResponseModel,
} from "../../../application/api/services/bag.service";
import { SnackbarContext } from "../../../components/snackbarProvider";
import {
  BagItemStateModel,
  usePrepareBagsStore,
} from "../../../pages/bags/hooks/prepareState";
import { BagBarcodePrint } from "../bagBarcodePrint";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export const BagContainer: React.FC<
  BagItemStateModel & {
    index: number;
    canRemove: boolean;
    districtId: number;
    saveBag: (
      params: prepareBagsRequestParamsModel[]
    ) => Promise<prepareBagsResponseModel>;
  }
> = ({
  saveBag,
  shipments,
  active,
  saved,
  index,
  canRemove,
  districtId,
  dataSaved = null,
}): JSX.Element => {
  const [snackbar, setSnackbar] = React.useContext(SnackbarContext);
  const refElement = React.useRef<HTMLButtonElement | null>();
  // const contex = React.useContext(DistrictsBagsContext);
  const { setActiveBag, setSavedBag, removeBag, removeShipmentFromBag } =
    usePrepareBagsStore();
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClick = () => {
    if (!active && !saved) {
      setActiveBag(districtId, index);
    }
  };

  const doSaveBag = () => {
    setLoading(true);
    saveBag([
      {
        district: {
          id: districtId,
        },
        bags: [
          {
            shipments,
          },
        ],
      },
    ])
      .then((response) => {
        if (response.data[0]?.id) {
          setSavedBag(districtId, index, response.data[0]);
          setLoading(false);
          setSnackbar({
            open: true,
            severity: "success",
            message: `Sac numéro ${index + 1} a bien été enregistré  `,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <Card
      sx={{
        maxWidth: 345,
        minWidth: 260,
        border: active ? "solid 2px #4E148C" : null,
      }}
    >
      <CardActionArea onClick={handleClick}>
        <CardHeader
          avatar={
            <ShoppingBagOutlinedIcon color={active ? "success" : "inherit"} />
          }
          title={`Sac - ${index + 1}`}
        />

        <CardContent>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Typography variant="body2" align="center">
                <span
                  style={{ fontSize: 20, fontWeight: 500 }}
                >{`${shipments.length}`}</span>{" "}
                colis
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
      <CardActions disableSpacing>
        <Grid container spacing={0}>
          <Grid item>
            <IconButton
              size="small"
              color="info"
              disabled={shipments.length === 0 || saved === true || loading}
              onClick={doSaveBag}
            >
              {saved ? <InventoryOutlinedIcon /> : <SaveIcon />}
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              size="small"
              color="info"
              disabled={!saved}
              onClick={() => setOpen(true)}
            >
              <PrintIcon />
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              size="small"
              color="error"
              disabled={!canRemove || saved}
              onClick={() => removeBag(districtId, index)}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>

        {shipments.length > 0 && (
          <IconButton size="small" onClick={handleExpandClick}>
            <ExpandMoreIcon fontSize="small" />
          </IconButton>
        )}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Divider />
          <List dense={true}>
            {shipments.map((shipment, i) => (
              <ListItem
                key={i}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    disabled={saved}
                    onClick={() => {
                      removeShipmentFromBag(districtId, index, shipment);
                      if (shipments.length === 1) {
                        handleExpandClick();
                      }
                    }}
                  >
                    <DeleteOutlineOutlinedIcon
                      fontSize="small"
                      color={saved ? "disabled" : "error"}
                    />
                  </IconButton>
                }
              >
                <ListItemText
                  primaryTypographyProps={{ variant: "caption" }}
                  primary={shipment}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Collapse>

      <Drawer
        anchor={"right"}
        sx={{ marginTop: 40, maxHeight: 400 }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div style={{ minWidth: 400 }}>
          <Toolbar>
            <Grid container spacing={1}>
              <Grid item>
                <PrintIcon />
              </Grid>
              <Grid item>
                <Typography sx={{ fontWeight: 500 }}>Print</Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <Divider />
          {dataSaved && (
            <Box sx={{ padding: 4 }}>
              <BagBarcodePrint {...dataSaved} />
            </Box>
          )}
        </div>
      </Drawer>
    </Card>
  );
};
