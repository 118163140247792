import { Input, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { forwardRef, KeyboardEvent, useState } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(3),
  },

  input: {
    border: "solid 1px #C4C4C4",
    padding: theme.spacing(1),
    width: 1,
    height: 1,
    opacity: 0,
    overflow: "hidden",
    // position: "relative",
    // zIndex: -1,
  },
}));
const ariaLabel = { "aria-label": "description" };

interface BarCodeScannerPropsModel {
  onChange: (value: string) => void;
}

export const BarCodeScanner = forwardRef<
  HTMLInputElement,
  BarCodeScannerPropsModel
>((props, ref) => {
  const classes = useStyles();
  // const ref = useRef<HTMLInputElement>();
  // const [inputRef, setFocus] = useInputFocus();
  const [inputBarcode, setInputBarcode] = useState<string>("");

  // const inputAutoFocus = () => {
  //   if (document.activeElement !== ref?.current) {
  //     ref.current?.focus();
  //   }
  // };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      props.onChange(inputBarcode);
      setInputBarcode("");
      // inputAutoFocus();
    }
  };

  return (
    <Input
      placeholder="Barcode"
      inputRef={ref}
      autoFocus
      value={inputBarcode}
      inputProps={ariaLabel}
      onChange={(event) => setInputBarcode(event.target.value)}
      onKeyDown={onKeyDown}
      disableUnderline={true}
      className={classes.input}
    />
  );
});
