import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Alert,
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { FC, useEffect } from "react";
import { priceFormat } from "../../application/helpers/priceFormat";
import { InvoiceStatusEnumModel } from "../../application/models/shared/invoiceStatusModel";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { InvoiceStatusChip } from "../../components/invoiceStatusChip";
import { PageTitle } from "../../components/PageTitle";
import { Pagination } from "../../components/pagination";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";
import { SettlementForm } from "../../pages_components/invoices/settlementForm";
import { useInvoiceController } from "./controller";

const InvoicesPage: FC<PagePropsModel> = (): JSX.Element => {
  const { settings, fetching, settlement } = useInvoiceController();

  useEffect(() => {
    fetching.fetch();
    settings.fetchPaymentMethods();
  }, [fetching.currentPage]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    fetching.handleChangePage(value);
  };

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Liste des factures",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="List des Colis" />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box pb={1}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography>
                <b>{fetching.total}</b> Factures
              </Typography>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Box>{" "}
        {Boolean(fetching.error) && fetching.isLoading === false && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="error">Error</Alert>
          </Box>
        )}
        {fetching.isLoading === false && fetching.data?.length === 0 && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="info">Aucune donnée disponible!</Alert>
          </Box>
        )}
        {fetching.isLoading && <LinearProgress />}
        {fetching.total > 0 && fetching.data?.length > 0 && (
          <TableContainer component={Paper}>
            <Table
              sx={{ width: "100%" }}
              size="small"
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Date</StyledTableCell>
                  <StyledTableCell align="left">Client</StyledTableCell>
                  <StyledTableCell align="left">Contact</StyledTableCell>
                  <StyledTableCell align="left">Total TTC</StyledTableCell>
                  <StyledTableCell align="left">Statut</StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(fetching.data || []).map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {format(new Date(row.created_at), "dd-MM-yyyy HH:mm:ss")}
                    </StyledTableCell>
                    <StyledTableCell component="th">
                      {row.customer_name}
                    </StyledTableCell>
                    <StyledTableCell>{row.customer_phone}</StyledTableCell>
                    <StyledTableCell>
                      {priceFormat(Number(row?.total))}
                    </StyledTableCell>
                    <StyledTableCell>
                      <InvoiceStatusChip status={row?.status} />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.status !== InvoiceStatusEnumModel.paid && (
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          color="info"
                          onClick={() => {
                            fetching.openDrawer(row);
                          }}
                        >
                          <CreditScoreOutlinedIcon fontSize="small" />
                        </IconButton>
                      )}

                      <IconButton
                        aria-label="more"
                        id="long-button"
                        color="inherit"
                        href={`${row.file_url}`}
                        target="_blank"
                      >
                        <FileDownloadIcon fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>{" "}
          </TableContainer>
        )}
        <div style={{ float: "right", padding: 20 }}>
          {fetching.total > 0 && (
            <Pagination
              count={Math.ceil(Number(fetching.total) / 10)}
              color="primary"
              showFirstButton
              showLastButton
              page={fetching.currentPage}
              onChange={handleChangePage}
            />
          )}
        </div>
        <Drawer
          anchor={"right"}
          sx={{ marginTop: 40, maxHeight: 400 }}
          open={fetching.drawerProps.open}
          onClose={() => fetching.handleCloseDrawer()}
        >
          <div style={{ minWidth: 400 }}>
            <Toolbar>
              <Grid container spacing={1}>
                <Grid item>
                  <CreditScoreOutlinedIcon />
                </Grid>
                <Grid item>
                  <Typography sx={{ fontWeight: 500 }}>Règlement</Typography>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
            <Box sx={{ padding: 4 }}>
              {Boolean(settlement.error) && (
                <Alert sx={{ mb: 3 }} severity="error">
                  {settlement.error}
                </Alert>
              )}
              <SettlementForm
                defaultValues={{
                  amount: fetching.drawerProps.item?.total || 0,
                  payment_method: "",
                  reference: "",
                }}
                loading={settlement.isLoading}
                onValide={(formValues) => {
                  settlement.settle({
                    invoice_id: Number(fetching.drawerProps.item?.id),
                    settlements: [formValues],
                  });
                }}
              />
            </Box>
          </div>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default InvoicesPage;
