import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";
import Can from "../../application/casl/abilityContext";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import { useDashboardController } from "../../pages_components/dashboard/dashboardController";
import FilterForm from "../../pages_components/dashboard/filterForm";
import { ReimbursementOfficeDisptach } from "../../pages_components/dashboard/reimbursementOfficeDisptach";
import { ReimbursementOfficesDisptach } from "../../pages_components/dashboard/reimbursementOfficesDisptach";
import { StateOffice } from "../../pages_components/dashboard/StateOffice";
import { StateOffices } from "../../pages_components/dashboard/StateOffices";

const DashboardPage: FC<PagePropsModel> = (props): JSX.Element => {
  const { ui, filter } = useDashboardController();

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Tableau de bord",
      href: "/",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="Tableau de bord" />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box sx={{ float: "right" }}>
          <FilterForm />
        </Box>
      </Grid>
      <Can I="read" a="STATE_OFFICE">
        <Grid item>
          <StateOffice />
        </Grid>
      </Can>
      {ui.dataStateOffice &&
        ui.dataStateOffice?.reimbursement_to_send?.length > 0 && (
          <Can I="read" a="STATE_OFFICE">
            <Grid item>
              <ReimbursementOfficeDisptach />
            </Grid>
          </Can>
        )}

      <Can I="read" a="STATE_OFFICES">
        <Grid item>
          <StateOffices />
        </Grid>
      </Can>

      <Can I="read" a="STATE_OFFICES">
        <Grid item>
          <Typography variant="h5" sx={{ mt: 3, mb: 2, fontWeight: 900 }}>
            Versement de CRTB{" "}
          </Typography>
          <ReimbursementOfficesDisptach />
        </Grid>
      </Can>
    </Grid>
  );
};

export default DashboardPage;
