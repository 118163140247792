import { Alert, Grid, LinearProgress } from "@mui/material";
import { isUndefined } from "lodash";
import React, { FC, useEffect } from "react";
import shallow from "zustand/shallow";
import { RecipientPackagesFormModel } from "../../application/models/forms/recipientPackagesFormModel";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { PickupModel } from "../../application/models/shared/pickupModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import ReceptionForm from "../../pages_components/reception/forms";
import { Validation } from "../../pages_components/shipments/validation";
import { StepModel, usePickupController } from "./controller";

const EditPickupsPage: FC<PagePropsModel> = (props): JSX.Element => {
  const id = props?.match?.params?.id || undefined;
  const { details, editing, methods } = usePickupController(
    (state) => ({
      details: state.details,
      editing: state.editing,
      methods: state.methods,
    }),
    shallow
  );

  useEffect(() => {
    if (id) {
      details.findOne(Number(id));
      editing.setStep(StepModel.FORM);
    }
  }, []);

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Modification pickup",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="Modification pickup" />
        <AppBreadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>

      {details.isLoading && (
        <Grid item>
          <LinearProgress color="secondary" />
        </Grid>
      )}
      {Boolean(details.error || editing.error) && (
        <Grid item>
          <Alert severity="error">
            Une erreur s'est produite lors de l'enregistrement
          </Alert>
        </Grid>
      )}

      <Grid item>
        {editing.step === StepModel.VALIDATION && !isUndefined(editing.data) && (
          <Validation
            isLoading={editing.isLoading}
            formValue={editing.data}
            previousButton={{
              onClick: () => console.log(""),
            }}
            onValide={() => {
              editing.receive({
                pickupId: Number(id),
                shipments: editing.data
                  ?.shipments as RecipientPackagesFormModel[],
              });
            }}
          />
        )}

        {editing.data && !isUndefined(editing.data) && (
          <div>{/* <Voutcher data={editing.data} /> */}</div>
        )}

        {details?.data && editing.step === StepModel.FORM && (
          <ReceptionForm
            settings={{
              hideSenderForm: true,
              isNested: true,
            }}
            defaultValues={methods.pickupToReceptionFormModel(
              details.data as PickupModel
            )}
            onSubmit={(formValues) => {
              editing.setData(formValues);
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default EditPickupsPage;
