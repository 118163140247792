import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Box } from "@mui/system";
import React, { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { dispatchingService } from "../../../../application/api/services/dispatching.service";
import { FailureReasonModel } from "../../../../application/models/shared/failureReasonModel";
import { ShipmentModel } from "../../../../application/models/shared/ShipmentModel";
import { SnackbarContext } from "../../../../components/snackbarProvider";
import { useTripShipmentsStore } from "../../../../pages/shipments/hooks/TripShipmentsState";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import DeviceThermostatOutlinedIcon from "@mui/icons-material/DeviceThermostatOutlined";
import MessageIcon from "@mui/icons-material/Message";
import { AttemptsTimeLine } from "../../attemptsTimeLine";

interface FailureDeliveryFormModel {
  identifier: string;
  reason: string;
}

interface StateModel {
  isLoading: boolean;
  error: string;
}
const FailureDeliveryForm: FC<{
  failureReasons: FailureReasonModel[];
  shipment: ShipmentModel;
}> = ({ failureReasons, shipment }): JSX.Element => {
  const { fetch } = useTripShipmentsStore();
  const [snackbar, setSnackbar] = React.useContext(SnackbarContext);

  const [state, setState] = useState<StateModel>({
    isLoading: false,
    error: "",
  });
  const { control, handleSubmit } = useForm<FailureDeliveryFormModel>({
    defaultValues: {
      identifier: `${shipment.identifier}`,
      reason: `${failureReasons[0]?.id}`,
    },
  });

  const onSubmit = (data: FailureDeliveryFormModel) => {
    setState((state) => ({ ...state, isLoading: true, eroor: "" }));
    dispatchingService
      .postponeDelivery({
        ...data,
        reason: Number(data.reason),
      })
      .then((response) => {
        setSnackbar({
          open: true,
          severity: "success",
          message: "Opération effectuée avec succes",
        });
        setState((state) => ({ ...state, isLoading: false, eroor: "" }));
        fetch();
      })
      .catch((error) => {
        setState((state) => ({
          ...state,
          isLoading: false,
          eroor: String(error),
        }));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item md={6} xs={12}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Controller
                render={({ field }) => (
                  <RadioGroup aria-label="gender" {...field}>
                    {(failureReasons || []).map((item, index) => (
                      <FormControlLabel
                        key={index}
                        value={`${item.id}`}
                        control={<Radio />}
                        label={item.reason}
                      />
                    ))}
                  </RadioGroup>
                )}
                name="reason"
                control={control}
              />
            </Grid>
            <Grid item>
              <Box sx={{ position: "relative" }}>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  Sauvegarder
                </Button>
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <AttemptsTimeLine shipment={shipment} />
        </Grid>
      </Grid>
    </form>
  );
};

export default FailureDeliveryForm;
