import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { UserModel } from "../../../../application/models/shared/userModel";
import If from "../../../../components/conditionalViwer";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";

interface StaffItemPropsModel {
  handleEditeUsers: (user: UserModel) => void;
  user: UserModel;
}

export const StaffListItem: FC<StaffItemPropsModel> = ({
  handleEditeUsers,
  user,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  // const ref = useRef(null);

  useEffect(() => {
    const ref = setTimeout(() => {
      if (loading) {
        handleEditeUsers(user);
        setLoading(false);
      } else {
        clearTimeout(ref);
      }
    }, 2000);

    return () => {
      clearTimeout(ref);
    };
  }, [loading]);

  return (
    <ListItem
      disablePadding
      secondaryAction={
        <If
          condition={!loading}
          render={() => (
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => setLoading(true)}
              color="error"
            >
              <DeleteOutlineOutlinedIcon fontSize="small" />
            </IconButton>
          )}
          otherwise={() => (
            <Slide direction="left" in={loading} mountOnEnter unmountOnExit>
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={() => setLoading(false)}
                startIcon={<CircularProgress color="inherit" size={10} />}
              >
                Annuler
              </Button>
            </Slide>
          )}
        />
      }
      dense
    >
      <ListItemButton dense onClick={() => console.log(user)}>
        <ListItemAvatar>
          <Avatar>
            <PersonOutlineIcon fontSize="small" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${user.first_name} ${user.last_name}`}
          secondary={
            <Stack direction={"column"}>
              <Stack direction={"row"} spacing={1} alignItems="center">
                <AlternateEmailOutlinedIcon
                  fontSize="small"
                  sx={{ fontSize: 15 }}
                />
                <Typography variant="caption">{user.email}</Typography>
              </Stack>
              <Stack direction={"row"} spacing={1} alignItems="center">
                <PhoneEnabledOutlinedIcon
                  fontSize="small"
                  sx={{ fontSize: 15 }}
                />
                <Typography variant="caption">{user.phone_number}</Typography>
              </Stack>
            </Stack>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};
