import httpClient from "../../httpClient";

interface ControlRequestModel {
  shipments: string[];
}

const fetch = async (): Promise<string[]> => {
  return httpClient.get("dispatching/shipments/status");
};

const control = async (params: ControlRequestModel): Promise<any> => {
  return httpClient.post("dispatching/shipments/control", {
    ...params,
  });
};

export const shipmentStatusService = {
  control,
  fetch,
};
