import {
  Alert,
  Container,
  Grid,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { userService } from "../../application/api/services/user.service";
import { LIST_USERS_ROUTE } from "../../application/constants/routeConstants";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import UserCreateForm from "../../pages_components/users/Forms/userCreateForm";
import { UserCreateFormModel } from "../../pages_components/users/Forms/userCreateForm/models";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
}));

const CreateUserPage: FC<PagePropsModel> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const id = props?.match?.params?.id || undefined;

  const [state, setState] = useState<{
    loading: boolean;
    formValues: UserCreateFormModel | undefined;
    serverError: string | undefined;
  }>({
    loading: false,
    formValues: undefined,
    serverError: undefined,
  });

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: id ? "Modification utilisateur" : "Ajout d'un nouveau utilisateur",
    },
  ];

  const store = (data: UserCreateFormModel) => {
    setState((state) => ({
      ...state,
      loading: true,
      serverError: undefined,
      formValues: data,
    }));

    // const dataUser: UserModel = data;
    userService
      .create(data)
      .then((response) => {
        setState((state) => ({
          ...state,
          loading: false,
          formValues: undefined,
        }));
        history.push(LIST_USERS_ROUTE);
      })
      .catch(({ message }) =>
        setState((state) => ({
          ...state,
          loading: false,
          serverError: message,
        }))
      );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle
          title={id ? `Modification utilisateur` : `Nouveau utilisateur`}
        />
        <AppBreadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid item>
        {state.serverError && (
          <Alert severity="error">{state?.serverError}</Alert>
        )}
        <Paper className={classes.paper}>
          <UserCreateForm
            defaultValues={state.formValues}
            onValide={(data) => store(data)}
          />{" "}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CreateUserPage;
