import {
  Alert,
  Box,
  Checkbox,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC, useContext, useEffect } from "react";
import { FundOfficeModel } from "../../application/models/shared/FundsOfficeModel";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import { SnackbarContext } from "../../components/snackbarProvider";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import FundOfficierCard from "../../pages_components/fundsOffice/fundOfficierCard";
import { FundCtrlItem } from "./fundsCtrlItems";
import { useFundsOfficeStore } from "./fundsOfficeState";

const FundsOfficePage: FC<PagePropsModel> = (props): JSX.Element => {
  const { isLoading, isError, fetch, data, alert } = useFundsOfficeStore();
  const [snackbar, setSnackbar] = useContext(SnackbarContext);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (alert.show) {
      setSnackbar({
        open: true,
        severity: alert.type,
        message: alert.message,
      });
    }
  }, [alert]);

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Fonds du bureau",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="List des sacs" />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box pb={1}>
          {data.length > 0 && (
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography>
                  <b>{data.length}</b> Elements
                </Typography>
              </Grid>
              <Grid item></Grid>
            </Grid>
          )}
        </Box>{" "}
        {isError && isLoading === false && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="error">Error</Alert>
          </Box>
        )}
        {isLoading === false && data?.length === 0 && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="info">Aucune donnée disponible!</Alert>
          </Box>
        )}
        {isLoading && <LinearProgress />}
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={3}>
          {data.map((item, index) => {
            return (
              <Grid item key={index}>
                <FundCtrl key={index} data={item} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

const FundCtrl: FC<{ data: FundOfficeModel }> = ({ data }): JSX.Element => {
  const {
    toggleCheckItem,
    sumPrice,
    isCheckedItem,
    sumPriceChecked,
    isCheckedAll,
    checkAll,
    uncheckAll,
  } = useFundsOfficeStore();

  const _isCheckedAll = isCheckedAll(Number(data.officer.id));

  return (
    <Grid container spacing={1}>
      <Grid item container md={3} xs={12}>
        <FundOfficierCard
          officer={data.officer}
          sumPrice={sumPrice(data.funds)}
          sumPriceChecked={sumPriceChecked(data.funds, Number(data.officer.id))}
          totalItems={data.funds.length}
        />
      </Grid>

      <Grid item md={9} xs={12}>
        <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
          <Table
            sx={{ width: "100%" }}
            //size="small"

            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={false}
                    checked={_isCheckedAll}
                    onChange={() => {
                      if (_isCheckedAll) {
                        uncheckAll(Number(data.officer.id));
                      } else {
                        checkAll(Number(data.officer.id));
                      }
                    }}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">Date d'entrée</StyledTableCell>
                <StyledTableCell align="left">Identifiant</StyledTableCell>
                <StyledTableCell align="left">Poids</StyledTableCell>
                <StyledTableCell align="left">CRBT</StyledTableCell>
                <StyledTableCell align="right">Prix</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data.funds || []).map((itemFund, idx) => (
                <FundCtrlItem
                  key={idx}
                  fund={itemFund}
                  checked={isCheckedItem(Number(data.officer.id), itemFund.id)}
                  handleCheck={() =>
                    toggleCheckItem(Number(data.officer.id), itemFund.id)
                  }
                />
              ))}
            </TableBody>
          </Table>{" "}
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default FundsOfficePage;
