import { blue, deepPurple, green, grey } from "@mui/material/colors";
import {
  InvoiceStatusEnumModel,
  RecordInvoiceStatusModel,
} from "../models/shared/invoiceStatusModel";

export const invoiceStatus: RecordInvoiceStatusModel = {
  [InvoiceStatusEnumModel.paid]: {
    color: green[500],
    bgColor: green[200],
    label: "Payée",
  },
  [InvoiceStatusEnumModel.unpaid]: {
    color: grey[500],
    bgColor: grey[300],
    label: "En attente de règlement",
  },
  [InvoiceStatusEnumModel.partial_paid]: {
    color: blue[500],
    bgColor: blue[100],
    label: "Payée partiellement",
  },
};
