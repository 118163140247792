import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { AuthProvider } from "./application/contexts/authContex";
import Router from "./application/route";
import { ErrorFallback, myErrorHandler } from "./components/errorBoundary";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import frLocale from "date-fns/locale/fr";

export default function App(): JSX.Element {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </LocalizationProvider>
    </ErrorBoundary>
  );
}
