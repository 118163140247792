import ExpandLess from "@mui/icons-material/ExpandLess";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import _ from "lodash";
import { FC, useContext, useEffect, useState } from "react";
import { governorateService } from "../../../../application/api/services/governorate.service";
import { usePrevious } from "../../../../application/hooks/usePrevious";
import { CityModel } from "../../../../application/models/shared/cityModel";
import {
  StructureItemModel,
  StructureItemTypeModel,
} from "../../../../application/models/shared/officeModel";
import { GovernorateModel } from "../../../../application/models/shared/governorateModel";
import If from "../../../../components/conditionalViwer";
import {
  getNbrOfChildrenChecked,
  isAassignedToAnother,
  isIndeterminateChecked,
  isStructureChecked,
} from "../editForm/methods";
import { CitiesList } from "./citiesList";
import { ItemLabelComponent } from "./itemLabelComponent";
import { SkeletonListItems } from "./skeletonListItems";
import { StructureContext } from "./structureContext";

export const GovernoratesList: FC<{
  governorates: GovernorateModel[] | [];
}> = ({ governorates }) => {
  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }} dense={true}>
      {governorates.map((governorate, index) => (
        <ListItemGovernorate
          key={`governorate_${governorate.id}`}
          governorate={governorate}
        />
      ))}
    </List>
  );
};

/****************** ListItemGovernorate ******************/

interface StateModel {
  expand: boolean;
  checked: boolean;
  cities: CityModel[] | [];
  loading: boolean;
}

const ListItemGovernorate: FC<{
  governorate: GovernorateModel;
}> = (props) => {
  const { governorate } = props;

  const {
    id,
    structureItems,
    dispatch: { setStructureItems },
  } = useContext(StructureContext);

  const [state, setState] = useState<StateModel>({
    expand: false,
    checked: false,
    cities: [],
    loading: false,
  });

  const { expand, checked, cities, loading } = state;

  const selfStructure: StructureItemModel = {
    id: governorate.id,
    name: governorate.name,
    type: StructureItemTypeModel.GOVERNORATE,
    parent_id: null,
  };

  const isChecked = isStructureChecked(selfStructure, structureItems);

  const loadCities = async () => {
    if (cities.length === 0) {
      setState((state) => ({ ...state, loading: true }));
      const _cities = await governorateService.fetchCitiesOfGovernorate(
        String(governorate.id)
      );

      const citiesResult: CityModel[] = _cities;
      setState((state) => ({ ...state, loading: false, cities: citiesResult }));
      return citiesResult;
    }
    return cities;
  };

  useEffect(() => {
    setState((state) => ({ ...state, checked: isChecked }));
  }, [isChecked]);

  useEffect(() => {
    if (expand === true) {
      loadCities();
    }
  }, [expand]);

  const nbrOfChildrenChecked = getNbrOfChildrenChecked(
    {
      id: governorate.id,
      type: StructureItemTypeModel.CITY,
    },
    structureItems
  );

  const indeterminateChecked = isIndeterminateChecked(
    nbrOfChildrenChecked,
    cities.length
  );

  const assignedToAnother = checked
    ? false
    : isAassignedToAnother(
        id,
        governorate.affected,
        governorate.district?.id,
        nbrOfChildrenChecked
      );

  const doCheck = (cities: CityModel[] | []) => {
    setStructureItems([
      ...structureItems,
      selfStructure,
      // ...citiesToStructureItems(
      //   cities.filter((item) => !(item.affected && item.district?.id !== id)),
      //   governorate.id
      // ),
    ]);
  };

  const doUnCheck = () => {
    setStructureItems(
      [...structureItems].filter(
        (item) =>
          !(item.id === selfStructure.id && item.type === selfStructure.type)
      )
      // .filter(
      //   (item) =>
      //     !(
      //       item.parent_id === selfStructure.id &&
      //       item.type === getStructureChildType(selfStructure)
      //     )
      // )
    );
  };

  const toggleCheck = async () => {
    const _cities = await loadCities();
    if (assignedToAnother === false) {
      if (checked === false) {
        doCheck(_cities);
      } else {
        doUnCheck();
      }
    }
  };

  return (
    <>
      <ListItem
        disabled={assignedToAnother}
        secondaryAction={
          <Checkbox
            disabled={assignedToAnother}
            edge="start"
            indeterminate={indeterminateChecked}
            checked={checked}
            tabIndex={-1}
            disableRipple
            inputProps={{
              "aria-labelledby": `governorate-${String(governorate.id)}`,
            }}
            onClick={() => {
              toggleCheck();
            }}
          />
        }
        disablePadding
      >
        <ListItemButton
          role={undefined}
          onClick={() => {
            toggleCheck();
          }}
        >
          <ListItemIcon>
            <IconButton
              size="small"
              edge="end"
              aria-label="comments"
              onClick={(event) => {
                event.stopPropagation();
                if (assignedToAnother === false) {
                  setState((state) => ({ ...state, expand: !state.expand }));
                }
              }}
            >
              {expand ? <ExpandLess /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </ListItemIcon>
          <ListItemText
            id={String(governorate.id)}
            primary={
              <ItemLabelComponent
                loading={loading}
                label={governorate.name}
                nbrChildren={nbrOfChildrenChecked}
              />
            }
          />
        </ListItemButton>
      </ListItem>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <If
          condition={loading}
          render={() => (
            <SkeletonListItems prefixKey={`citiesOf${governorate.id}`} />
          )}
          otherwise={() => (
            <CitiesList cities={cities} governorate={governorate} />
          )}
        />
      </Collapse>
    </>
  );
};
