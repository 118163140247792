import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import { reimbursementService } from "../../../application/api/services/reimbursement.service";
import { priceFormat } from "../../../application/helpers/priceFormat";
import { ReimbursementItemCtrlModel } from "../../../application/models/shared/reimbursementCtrlModel";
import { ConfirmDialog } from "../../../components/confirmDialog";
import { useReimbursementInStore } from "../../../pages/reimbursement/hooks/reimbursementInState";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";

interface ReimbursementCtrlItemStateModel {
  openDialog: boolean;
  isLoading: boolean;
  error: string;
  isValide: boolean;
}

export const ReimbursementInCtrlItem: FC<{
  itemReimbursement: ReimbursementItemCtrlModel;
}> = ({ itemReimbursement }): JSX.Element => {
  const { fetch } = useReimbursementInStore();
  const [state, setState] = useState<ReimbursementCtrlItemStateModel>({
    openDialog: false,
    isLoading: false,
    error: "",
    isValide: false,
  });

  useEffect(() => {
    setState((state) => ({
      openDialog: false,
      isLoading: false,
      error: "",
      isValide: false,
    }));
  }, [itemReimbursement]);

  const setOpenDialog = (open: boolean) => {
    setState((state) => ({ ...state, openDialog: open }));
  };

  const save = () => {
    setState((state) => ({
      ...state,
      isLoading: true,
      error: "",
      openDialog: false,
    }));
    reimbursementService
      .reimbursementControl(itemReimbursement.shipment.identifier)
      .then((response) => {
        setState((state) => ({
          ...state,
          isLoading: false,
          isValide: true,
          error: "",
        }));
        fetch();
      })
      .catch((error) => {
        setState((state) => ({
          ...state,
          isLoading: false,
          error: "Error",
        }));
      });
  };

  return (
    <Paper
      sx={{
        backgroundColor: state.isValide ? "#e1efeb" : null,
        p: 3,
        mb: 1,
        "&:last-child": {
          mb: 0,
        },
      }}
    >
      <Grid
        container
        // spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Box
            sx={{
              borderLeft: "solid 5px #eee",
              p: 1,
              height: "100%",
            }}
          >
            <Grid container direction="column">
              <Grid item>
                <Typography sx={{ fontWeight: 500 }} variant="body2">
                  Expéditeur
                </Typography>
              </Grid>
              <Grid item>
                <Stack spacing={2} direction="row" alignItems="flex-end">
                  <PersonOutlineOutlinedIcon fontSize="small" />
                  <Typography variant="body2" sx={{ fontSize: 13 }}>
                    {itemReimbursement.shipment.sender.first_name}{" "}
                    {itemReimbursement.shipment.sender.last_name}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing={2} direction="row" alignItems="flex-end">
                  <LocalPhoneOutlinedIcon fontSize="small" />
                  <Typography variant="body2" sx={{ fontSize: 13 }}>
                    {itemReimbursement.shipment.sender?.phone_number}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing={2} direction="row" alignItems="flex-end">
                  <PlaceOutlinedIcon fontSize="small" />
                  <Typography variant="body2" sx={{ fontSize: 13 }}>
                    {itemReimbursement.shipment.sender?.address?.address_line}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing={2} direction="row" alignItems="flex-end">
                  <HomeWorkOutlinedIcon fontSize="small" />
                  <Typography variant="body2" noWrap sx={{ fontSize: 13 }}>
                    {
                      itemReimbursement.shipment.sender?.address?.post_office
                        ?.name
                    }{" "}
                    /{" "}
                    {
                      itemReimbursement.shipment.sender?.address?.post_office
                        ?.zip_code
                    }
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              borderLeft: "solid 5px #eee",
              p: 1,
              height: "100%",
            }}
          >
            <Grid container direction="column">
              <Grid item>
                <Typography sx={{ fontWeight: 500 }} variant="body2">
                  Destinataire
                </Typography>
              </Grid>
              <Grid item>
                <Stack spacing={2} direction="row" alignItems="flex-end">
                  <PersonOutlineOutlinedIcon fontSize="small" />
                  <Typography variant="body2" sx={{ fontSize: 13 }}>
                    {itemReimbursement.shipment.recipient.first_name}{" "}
                    {itemReimbursement.shipment.recipient.last_name}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing={2} direction="row" alignItems="flex-end">
                  <LocalPhoneOutlinedIcon fontSize="small" />
                  <Typography variant="body2" sx={{ fontSize: 13 }}>
                    {itemReimbursement.shipment.recipient?.phone_number}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing={2} direction="row" alignItems="flex-end">
                  <PlaceOutlinedIcon fontSize="small" />
                  <Typography variant="body2" sx={{ fontSize: 13 }}>
                    {
                      itemReimbursement.shipment.recipient?.address
                        ?.address_line
                    }
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing={2} direction="row" alignItems="flex-end">
                  <HomeWorkOutlinedIcon fontSize="small" />
                  <Typography variant="body2" noWrap sx={{ fontSize: 13 }}>
                    {
                      itemReimbursement.shipment.recipient?.address?.post_office
                        ?.name
                    }{" "}
                    /{" "}
                    {
                      itemReimbursement.shipment.recipient?.address?.post_office
                        ?.zip_code
                    }
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item>
          <Box sx={{ borderLeft: "solid 5px #eee", p: 1 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-start"
              wrap="nowrap"
            >
              <Grid item>
                <Typography variant="body2" noWrap sx={{ fontSize: 13 }}>
                  <b>Date :</b> {itemReimbursement.shipment?.received_at}
                </Typography>
                <Typography variant="body2" noWrap sx={{ fontSize: 13 }}>
                  <b> Poids :</b> {itemReimbursement.shipment.weight} Kg
                </Typography>
                {itemReimbursement.shipment.reimbursement.enable &&
                  itemReimbursement.shipment.reimbursement.amount > 0 && (
                    <Typography variant="body2" noWrap sx={{ fontSize: 13 }}>
                      <b> CRBT :</b>{" "}
                      <span style={{ fontWeight: 500, color: "red" }}>
                        {priceFormat(
                          itemReimbursement.shipment.reimbursement.amount
                        )}
                      </span>
                    </Typography>
                  )}
                <Typography variant="body2" noWrap sx={{ fontSize: 13 }}>
                  <b> Identifiant :</b> {itemReimbursement.shipment.identifier}
                </Typography>
              </Grid>
              <Grid item>
                {" "}
                {/* <QRCode value={shipment.identifier} size={50} /> */}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* <Grid item>
          <Stack spacing={1} direction="row" alignItems="flex-end">
            <QueryBuilderOutlinedIcon fontSize="small" />
            <Typography variant="body2" noWrap sx={{ fontSize: 14 }}>
              {itemReimbursement?.received_at}
            </Typography>
          </Stack>
        </Grid>
        <Grid item>
          <Stack spacing={1} direction="row" alignItems="flex-end">
            <MonetizationOnOutlinedIcon fontSize="small" />
            <Typography
              variant="body2"
              noWrap
              sx={{ fontSize: 14, color: state.isValide ? "green" : "red" }}
            >
              {priceFormat(itemReimbursement?.amount)}
            </Typography>
          </Stack>
        </Grid> 
        <Grid item>
          <Stack spacing={1} direction="row" alignItems="flex-end">
            <LocalPostOfficeOutlinedIcon fontSize="small" />
            <Typography variant="body2" noWrap sx={{ fontSize: 14 }}>
              {itemReimbursement?.shipment?.identifier}
            </Typography>
          </Stack>
        </Grid>*/}
        <Grid item>
          {!state.isValide ? (
            <Button color="success" onClick={() => setOpenDialog(true)}>
              {state.isLoading ? <CircularProgress /> : "Valider"}
            </Button>
          ) : (
            <CheckOutlinedIcon fontSize="large" color="success" />
          )}
        </Grid>
      </Grid>
      {state.openDialog && (
        <ConfirmDialog
          title="Validation"
          body={
            <Typography>
              Avez-vous bien reçu{" "}
              <b style={{ color: "red", fontSize: 16 }}>
                {priceFormat(itemReimbursement?.amount)}
              </b>
            </Typography>
          }
          dialogProps={{
            open: state.openDialog,
            onClose: () => setOpenDialog(false),
          }}
          cancelButton={{
            onClick: () => setOpenDialog(false),
          }}
          confirmButton={{
            onClick: () => {
              save();
            },
          }}
        />
      )}
    </Paper>
  );
};
