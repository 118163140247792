import { format, startOfDay } from "date-fns";
import create from "zustand";
import {
  globalStateService,
  OfficeShipmentsRequestModel,
} from "../../application/api/services/globalState.service";
import { DATE_TIME_ISO_FORMAT } from "../../application/constants/appConstants";
import {
  StateOfficeModel,
  StateOfficesItemModel,
  StateOfficesModel,
  StateSumOfficesModel,
  SumStateOfficesModel,
} from "../../application/models/shared/stateShipmentsModel";
import { FilterFormModel } from "./filterForm/models";

export interface DashboardControllerModel {
  filter: {
    formValues: FilterFormModel;
    setFormValues: (data: FilterFormModel) => void;
  };
  ui: {
    isLoading: boolean;
    isError: boolean;
    dataStateOffice: StateOfficeModel | undefined;
    dataStateOffices: StateOfficesModel | [];
    stateOffice: () => void;
    stateOffices: () => void;
  };
  api: {
    stateOffice: (
      params: FilterFormModel,
      callbackThen: (response: StateOfficeModel) => void,
      callbackCatch: (error: any) => void
    ) => void;
    stateOffices: (
      params: FilterFormModel,
      callbackThen: (response: StateOfficesModel) => void,
      callbackCatch: (error: any) => void
    ) => void;
  };
  methods: {
    requestAdapter: (data: FilterFormModel) => OfficeShipmentsRequestModel;
    sumOfficesState: () => StateSumOfficesModel;
  };
}

export const useDashboardController = create<DashboardControllerModel>(
  (set, get) => ({
    ui: {
      isLoading: false,
      isError: false,
      dataStateOffice: undefined,
      dataStateOffices: [],
      stateOffice: () => {
        get().api.stateOffice(
          get().filter.formValues,
          (response) => {
            set((state) => ({
              ...state,
              ui: {
                ...state.ui,
                isLoading: false,
                isError: false,
                dataStateOffice: response as any,
              },
            }));
          },
          (error) => {
            set((state) => ({
              ...state,
              ui: {
                ...state.ui,
                isLoading: false,
                isError: true,
              },
            }));
          }
        );
      },
      stateOffices: () => {
        get().api.stateOffices(
          get().filter.formValues,
          (response) => {
            set((state) => ({
              ...state,
              ui: {
                ...state.ui,
                isLoading: false,
                isError: false,
                dataStateOffices: response as any,
              },
            }));
          },
          (error) => {
            set((state) => ({
              ...state,
              ui: {
                ...state.ui,
                isLoading: false,
                isError: true,
              },
            }));
          }
        );
      },
    },
    filter: {
      formValues: {
        startDate: format(startOfDay(new Date()), DATE_TIME_ISO_FORMAT),
        endDate: format(new Date(), DATE_TIME_ISO_FORMAT),
      },
      setFormValues: (data: FilterFormModel) => {
        set((state) => ({
          ...state,
          filter: {
            ...state.filter,
            formValues: data,
          },
        }));
      },
    },
    api: {
      stateOffice: (
        params: FilterFormModel,
        callbackThen: (response: any) => void,
        callbackCatch: (error: any) => void
      ) => {
        globalStateService
          .stateOffice(get().methods.requestAdapter(params))
          .then(callbackThen)
          .catch(callbackCatch);
      },
      stateOffices: (
        params: FilterFormModel,
        callbackThen: (response: any) => void,
        callbackCatch: (error: any) => void
      ) => {
        globalStateService
          .stateOffices(get().methods.requestAdapter(params))
          .then(callbackThen)
          .catch(callbackCatch);
      },
    },
    methods: {
      requestAdapter: (data: FilterFormModel) => {
        return {
          start_date: data.startDate,
          end_date: data.endDate,
        } as OfficeShipmentsRequestModel;
      },
      sumOfficesState: () => {
        const sum = (
          get().ui.dataStateOffices as Array<StateOfficesItemModel>
        ).reduce(
          (acc, cur) => {
            return {
              ...acc,
              shipments_total_amount:
                acc.shipments_total_amount + cur.shipments_total_amount,
              shipments_total_number:
                acc.shipments_total_number + cur.shipments_total_number,
              reimbursement_total_amount:
                acc.reimbursement_total_amount + cur.reimbursement_total_amount,
              reimbursement_total_number:
                acc.reimbursement_total_number + cur.reimbursement_total_number,

              reimbursement_total_amount_to_pay:
                acc.reimbursement_total_amount_to_pay +
                cur.reimbursement_total_amount_to_pay,
              reimbursement_total_number_to_pay:
                acc.reimbursement_total_number_to_pay +
                cur.reimbursement_total_number_to_pay,
            } as StateSumOfficesModel;
          },
          {
            shipments_total_amount: 0,
            shipments_total_number: 0,
            reimbursement_total_amount: 0,
            reimbursement_total_number: 0,
            reimbursement_total_amount_to_pay: 0,
            reimbursement_total_number_to_pay: 0,
          }
        );

        return sum;
      },
    },
  })
);
