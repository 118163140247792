import * as yup from "yup";
import { PricingModel } from "../../../../application/models/shared/pricingModel";

export const postOfficeSchame = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  zip_code: yup.string().required(),
});

export const addressSchame = yup.object().shape({
  address_line: yup.string().required("L'adresse est obligatoire"),
  post_office: postOfficeSchame,
});

export const packagingSchema = yup.object().shape({
  enable: yup.boolean(),
  type: yup.mixed().when("enable", {
    is: true,
    then: yup.mixed().required("Ce champs est obligatoire"),
    otherwise: yup.mixed().nullable(),
  }),
});

export const reimbursementSchema = yup.object().shape({
  enable: yup.boolean(),
  return_mode: yup.string().when("enable", {
    is: true,
    then: yup.string().required("Ce champs est obligatoire"),
  }),
  amount: yup.number().when("enable", {
    is: true,
    then: yup
      .number()
      .min(0.01, "Montant doit être supérieur à 0")
      .required("Ce champs est obligatoire"),
  }),
});

interface rulesValidationModel {
  maxWeight: number;
}
export const validationSchema = (rules: rulesValidationModel): any => {
  const { maxWeight } = rules;

  const validation = yup.object().shape({
    sender: yup.object().shape({
      first_name: yup.string().required("Non est obligatoire"),
      last_name: yup.string().required("Prénon est obligatoire"),
      address: addressSchame,
      phone_number: yup
        .string()
        .required("Num téléphone est obligatoire")
        .length(11),
    }),
    shipments: yup.array().of(
      yup.object().shape({
        recipient: yup.object().shape({
          first_name: yup.string().required("Non est obligatoire"),
          last_name: yup.string().required("Prénon est obligatoire"),
          address: addressSchame,
          phone_number: yup
            .string()
            .required("Num téléphone est obligatoire")
            .length(11),
        }),

        packages: yup.array().of(
          yup.object().shape({
            weight: yup
              .number()
              .min(0.01, "Le poids doit être supérieur à 0")
              .max(
                Number(maxWeight),
                `Le poids doit être inférieur à ${maxWeight}`
              )
              .required("Ce champs est obligatoire"),
            packaging: packagingSchema,
            reimbursement: reimbursementSchema,
          })
        ),
      })
    ),
  });
  return validation;
};
