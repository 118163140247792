import { governorateService } from "./../../../application/api/services/governorate.service";
import { GovernorateModel } from "./../../../application/models/shared/governorateModel";
import { ParcelModel } from "../../../application/models/shared/parcelModel";
import { PackagingTypeModel } from "../../../application/models/shared/packagingTypeModel";
import { PricingModel } from "../../../application/models/shared/pricingModel";
import create from "zustand";
import { packagingService } from "../../../application/api/services/packaging.service";
import { pricingService } from "../../../application/api/services/pricing.service";
import { receptionIntValuesForm } from "./receptionIntValues";
import { ReceptionFormModel } from "../../../application/models/forms/recptionFormModel";
import { RecipientPackagesFormModel } from "../../../application/models/forms/recipientPackagesFormModel";

interface ReceptionFormSettings {
  hideSenderForm: boolean;
  isNested: boolean;
}

export interface ReceptionControllerModel {
  intFormValues: ReceptionFormModel;
  governorates: GovernorateModel[];
  isLoading: boolean;
  isError: boolean;
  maxWeight: number;
  packagingOptions: PackagingTypeModel[] | [];
  pricingSettings: PricingModel[] | [];
  customizedPricing: PricingModel[] | undefined;
  formSettings: ReceptionFormSettings | undefined;
  setCustomizedPricing: (pricing: PricingModel[] | undefined) => void;
  getInitSettings: (formSettings: ReceptionFormSettings | undefined) => void;
  getTotalPrice: (shipments: ReceptionFormModel["shipments"]) => number;
  reducerShipment: (
    previousValue: number,
    currentValue: RecipientPackagesFormModel
  ) => number;
  reducerPackages: (previousValue: number, currentValue: ParcelModel) => number;
  getPricing: () => PricingModel[];
  getParcelPrice: (parcelValues: ParcelModel) => Number;
}

export const useReceptionController = create<ReceptionControllerModel>(
  (set, get) => ({
    governorates: [],
    intFormValues: receptionIntValuesForm,
    isLoading: true,
    isError: false,
    maxWeight: 30,
    packagingOptions: [],
    pricingSettings: [],
    customizedPricing: undefined,
    formSettings: {
      hideSenderForm: false,
      isNested: true,
    },

    getInitSettings: (formSettings: ReceptionFormSettings | undefined) => {
      Promise.all([
        packagingService.fetch(),
        pricingService.fetch(),
        governorateService.fetch({ search: undefined, per_page: 24, page: 1 }),
      ])
        .then((response) => {
          const [packagingResponse, pricingResponse, governoratesResponse] =
            response;

          set((state) => ({
            ...state,
            isLoading: false,
            isError: false,
            formSettings:
              formSettings !== undefined ? formSettings : state.formSettings,
            packagingOptions: packagingResponse || [],
            pricingSettings: pricingResponse || [],
            governorates: governoratesResponse?.data || [],
            maxWeight: Math.max(...pricingResponse.map((item) => item.max)),
          }));
        })
        .catch((error) => {
          set((state) => ({
            ...state,
            isLoading: false,
            isError: true,
            packagingOptions: [],
            pricingSettings: [],
          }));
        });
    },
    setCustomizedPricing: (pricing: PricingModel[] | undefined) => {
      set((state) => ({
        ...state,
        customizedPricing:
          pricing && Array.isArray(pricing) && pricing.length > 0
            ? pricing
            : undefined,
        maxWeight:
          pricing && Array.isArray(pricing) && pricing.length > 0
            ? Math.max(...pricing.map((item) => item.max))
            : Math.max(...get().pricingSettings.map((item) => item.max)),
      }));
    },
    getTotalPrice: (shipments: RecipientPackagesFormModel[]) => {
      return shipments.reduce(get().reducerShipment, 0);
    },
    reducerShipment: (
      previousValue: number,
      currentValue: RecipientPackagesFormModel
    ) => {
      const { packages } = currentValue;
      const totalPerPackages = packages.reduce(get().reducerPackages, 0);
      return Number(previousValue) + Number(totalPerPackages);
    },
    reducerPackages: (
      previousValue: number,
      currentValue: ParcelModel
    ): number => {
      return Number(previousValue) + Number(currentValue.price);
    },
    getPricing: () => {
      return get().customizedPricing || get().pricingSettings;
    },
    getParcelPrice: (parcelValues: ParcelModel) => {
      const weightPrice =
        get()
          .getPricing()
          ?.find(
            (item) =>
              item.min < Number(parcelValues.weight) &&
              item.max >= Number(parcelValues.weight)
          )?.price || 0;

      const packagingTypePrice =
        parcelValues.packaging.enable &&
        parcelValues.packaging.type !== undefined
          ? parcelValues.packaging.type.price
          : 0;

      return Number(weightPrice) + Number(packagingTypePrice);
    },
  })
);
