import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { Checkbox, IconButton } from "@mui/material";
import { FC } from "react";
import { priceFormat } from "../../application/helpers/priceFormat";
import { FundModel } from "../../application/models/shared/fundModel";
import { ShipmentModel } from "../../application/models/shared/ShipmentModel";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";

export const FundCtrlItem: FC<{
  fund: FundModel;
  handleCheck: () => void;
  checked: boolean;
}> = ({ fund, handleCheck, checked }): JSX.Element => {
  const labelId = `enhanced-table-checkbox-${fund.id}`;

  return (
    <StyledTableRow
      hover
      role="checkbox"
      tabIndex={-1}
      onClick={() => handleCheck()}
      aria-checked={checked}
      selected={checked}
    >
      <StyledTableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={checked}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />

        {/* <IconButton
          color={checked ? "success" : "error"}
          onClick={() => handleCheck()}
        >
          {checked ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankIcon />}
        </IconButton> */}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {fund.shipment.received_at}
      </StyledTableCell>
      <StyledTableCell component="th">
        {fund.shipment.identifier}
      </StyledTableCell>
      <StyledTableCell> {fund.shipment.weight} KG</StyledTableCell>
      <StyledTableCell>
        {fund.shipment.reimbursement.enable &&
          fund.shipment.reimbursement.amount > 0 && (
            <span style={{ fontWeight: 500 }}>
              {priceFormat(fund.shipment.reimbursement.amount)}
            </span>
          )}
      </StyledTableCell>
      <StyledTableCell align="right">
        <b>{priceFormat(fund.shipment.price)}</b>
      </StyledTableCell>
    </StyledTableRow>
  );
};
