import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { SettlementModel } from "../../../application/models/shared/settlementModel";
import { useInvoiceController } from "../../../pages/invoices/controller";

const schema = yup.object().shape({
  amount: yup.number().min(1).required(),
  payment_method: yup.string().required(),
  reference: yup.string().required(),
});

export const SettlementForm: FC<{
  defaultValues?: SettlementModel;
  loading?: boolean;
  onValide: (data: SettlementModel) => void;
}> = (props): JSX.Element => {
  const { settings, fetching, settlement } = useInvoiceController();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SettlementModel>({
    resolver: yupResolver(schema),
    defaultValues: props?.defaultValues || {
      amount: 0,
      payment_method: "",
      reference: "",
    },
  });

  const onSubmit = (data: SettlementModel) => {
    props.onValide(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Controller
                render={({ field }) => (
                  <FormControl error={Boolean(errors?.payment_method)}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Moyen de paiement{" "}
                    </FormLabel>
                    <RadioGroup row aria-label="payment_method" {...field}>
                      {(settings.data || []).map((method, index) => (
                        <FormControlLabel
                          key={index}
                          value={method}
                          control={<Radio size="small" />}
                          label={method}
                        />
                      ))}
                      {/* <FormControlLabel
                        value="Chèque"
                        control={<Radio size="small" />}
                        label="Chèque"
                      />
                      <FormControlLabel
                        value="virement"
                        control={<Radio size="small" />}
                        label="Virement"
                      /> */}
                    </RadioGroup>
                    <FormHelperText>
                      {errors?.payment_method
                        ? errors?.payment_method.message
                        : ""}
                    </FormHelperText>
                  </FormControl>
                )}
                name="payment_method"
                control={control}
              />
            </Grid>
            <Grid item>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    autoComplete="new-password"
                    fullWidth
                    label="Montant"
                    {...field}
                    type="number"
                    inputProps={{
                      min: 0,
                      step: 0.01,
                    }}
                    error={Boolean(errors?.amount)}
                    helperText={errors?.amount ? errors?.amount.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="reference"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    autoComplete="new-password"
                    fullWidth
                    label="Référence"
                    {...field}
                    error={Boolean(errors?.reference)}
                    helperText={
                      errors?.reference ? errors?.reference.message : null
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Box sx={{ mt: 1, position: "relative" }}>
            <Button
              fullWidth
              color="primary"
              type="submit"
              variant="contained"
              disabled={props?.loading}
            >
              Sauvegarder
            </Button>
            {props?.loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
