import { Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FC } from "react";
import { AttemptModel } from "../../../application/models/shared/attemptModel";
import { StyledTableCell } from "../../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../../components/Styled/StyledTableRow";

export const ShipmentAttempts: FC<{ data: AttemptModel[] | [] }> = ({
  data,
}): JSX.Element => {
  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: 420 }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell align="left">Responsable</StyledTableCell>
              <StyledTableCell align="left">Raison</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data || []).map((item, index) => (
              <StyledTableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {item.created_at}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {item?.officer.first_name || "--"}{" "}
                  {item?.officer.last_name || "--"}
                </StyledTableCell>
                <StyledTableCell align="left">{item.reason}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
