import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { priceFormat } from "../../../../application/helpers/priceFormat";
import { StyledTableCell } from "../../../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../../../components/Styled/StyledTableRow";
import { useReceptionController } from "../receptionController";

export const PricingDetails: FC = (): JSX.Element => {
  const { getPricing } = useReceptionController();

  const pricingSettings = getPricing();

  if (pricingSettings.length === 0) {
    return <></>;
  }
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography sx={{ fontWeight: 500 }}>Tarification</Typography>
      </Grid>
      <Grid item>
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ mt: 1, border: "solid 1px #eee" }}
        >
          <Table sx={{ minWidth: "330" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Min</StyledTableCell>
                <StyledTableCell align="left">Max</StyledTableCell>
                <StyledTableCell align="right">Prix</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(pricingSettings || []).map((item, index) => (
                <StyledTableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {item.min} KG
                  </StyledTableCell>
                  <StyledTableCell align="left">{item?.max} KG</StyledTableCell>
                  <StyledTableCell align="right">
                    {priceFormat(item?.price)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
