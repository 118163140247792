import { red } from "@mui/material/colors";

const defaultPalette = {
  light: "#AC6BDC",
  main: "#4E148C",
  dark: "#2D0A64",
};

const blackPalette = {
  light: "#000000",
  main: "#000000",
  dark: "#000000",
};

export const palette = {
  common: {
    black: "#353333fa",
    white: "#ffff",
  },
  primary: defaultPalette,
  secondary: {
    light: "#FFB566",
    main: "#FF6600",
    dark: "#B73300",
  },
  error: {
    main: red.A400,
  },
  background: {
    default: "#F8F8F8",

    // default: "#F3F6F9",
    // default: "#fff",
  },
};
