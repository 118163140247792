import { yupResolver } from "@hookform/resolvers/yup";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { UsersSearchFormModel } from "./models";

const UserFilterForm: FC<{
  defaultValues?: UsersSearchFormModel;
  loading?: boolean;
  onValide: (data: UsersSearchFormModel) => void;
}> = (props) => {
  const schema = yup
    .object()
    .shape({
      first_name: yup.string().nullable(),
      last_name: yup.string().nullable(),
      email: yup.string().nullable(),
      phone_number: yup.string().nullable(),
    })
    .required();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<UsersSearchFormModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      ...(() => (props?.defaultValues ? props?.defaultValues : {}))(),
    },
  });

  const onSubmit = (data: any) => {
    const clearedData = Object.keys(data).reduce((acc, cur) => {
      const value = data[cur];
      return value ? { ...acc, [cur]: value } : acc;
    }, {});
    // props.onValide(clearedData);
  };

  const hendhleResetForm = (newValues: UsersSearchFormModel): void => {
    reset(newValues);
    handleSubmit(onSubmit)();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              {" "}
              <Typography sx={{ fontWeight: 500 }}>Filtrer</Typography>
            </Grid>
            <Grid item>
              {props.defaultValues && (
                <RenderFilterFields
                  fields={props.defaultValues}
                  reset={hendhleResetForm}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12} md={3}>
              <Grid container direction="column" spacing={3}>
                <Grid item></Grid>
                <Grid item>
                  <Controller
                    name="first_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Nom"
                        {...field}
                        size="small"
                        error={Boolean(errors?.first_name)}
                        helperText={
                          errors?.first_name ? errors?.first_name.message : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Prenom"
                        {...field}
                        size="small"
                        error={Boolean(errors?.last_name)}
                        helperText={
                          errors?.last_name ? errors?.last_name.message : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="phone_number"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Téléphone"
                        {...field}
                        size="small"
                        error={Boolean(errors?.phone_number)}
                        helperText={
                          errors?.phone_number
                            ? errors?.phone_number.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={false}>
              <Divider orientation="vertical" variant="middle" />
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid container direction="column" spacing={3}>
                <Grid item></Grid>
              </Grid>
            </Grid>
            <Grid item xs={false}>
              <Divider orientation="vertical" variant="middle" />
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid container direction="column" spacing={3}>
                <Grid item></Grid>
                <Grid item>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="E-mail"
                        {...field}
                        size="small"
                        error={Boolean(errors?.email)}
                        helperText={
                          errors?.email ? errors?.email.message : null
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item></Grid>
            <Grid item>
              <Box sx={{ mt: 1, position: "relative" }}>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={props?.loading}
                  startIcon={<SearchIcon />}
                >
                  Rechercher
                </Button>
                {props?.loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserFilterForm;

const RenderFilterFields: FC<{
  fields: UsersSearchFormModel;
  reset: (newValues: UsersSearchFormModel) => void;
}> = ({ fields, reset }) => {
  const handleDelete = (id: string) => {
    reset({ ...fields, [id]: "" });
  };
  return (
    <Stack direction="row" spacing={1}>
      {(Object.keys(fields) as Array<keyof typeof fields>).map((id) => {
        const value = fields[id];
        return (
          <Chip
            key={id}
            label={`${id} : ${value}`}
            onDelete={() => handleDelete(id)}
          />
        );
      })}
    </Stack>
  );
};
