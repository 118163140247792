import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import _ from "lodash";
import * as React from "react";
import { districtService } from "../../application/api/services/district.service";
import {
  CREATE_DISTRICT_ROUTE,
  EDIT_DISTRICT_ROUTE,
  EDIT_OFFICE_ROUTE,
} from "../../application/constants/routeConstants";
import { DistrictModel } from "../../application/models/shared/districtModel";
import { OrderByModel } from "../../application/models/shared/orderByModel";
import { OrderDirectionModel } from "../../application/models/shared/orderDirectionModel";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { ConfirmDialog } from "../../components/confirmDialog";
import {
  EnhancedTableHead,
  HeadCell,
} from "../../components/EnhancedTableHead";
import { PageTitle } from "../../components/PageTitle";
import { Pagination } from "../../components/pagination";
import { SearchTextField } from "../../components/searchTextField";
import { SnackbarContext } from "../../components/snackbarProvider";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";
import UserFilterForm from "../../pages_components/users/Forms/userFilterForm";
import { UsersSearchFormModel } from "../../pages_components/users/Forms/userFilterForm/models";

const headCells: HeadCell[] = [
  {
    id: "name",
    disablePadding: false,
    sortable: true,
    label: "Disctrict",
    align: "left",
    width: "20%",
  },
  {
    id: "action",
    disablePadding: false,
    sortable: true,
    label: " ",
    align: "right",
    width: "10%",
  },
];

interface StateModel {
  loading: boolean;
  data: DistrictModel[] | [];
  totalCount: number;
  currentPage: number;
  searchText: string;
  openSearchForm: boolean;
  filterParams: UsersSearchFormModel | undefined;
  orderBy: keyof DistrictModel;
  order: OrderDirectionModel;
  toRemoveItem: DistrictModel | undefined;
  openRemoveDialogProps: boolean;
}
const ListDistrictsPage: React.FC<PagePropsModel> = (): JSX.Element => {
  const [snackbar, setSnackbar] = React.useContext(SnackbarContext);
  const [state, setState] = React.useState<StateModel>({
    loading: true,
    data: [],
    totalCount: 0,
    currentPage: 1,
    searchText: "",
    openSearchForm: false,
    filterParams: undefined,
    orderBy: "name",
    order: OrderDirectionModel.ASC,
    toRemoveItem: undefined,
    openRemoveDialogProps: false,
  });

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setState((state) => ({ ...state, currentPage: value }));
  };

  const showConfirmDeleteDialog = (item: DistrictModel) => {
    setState((state) => ({
      ...state,
      toRemoveItem: item,
      openRemoveDialogProps: true,
    }));
  };

  const hideConfirmDeleteDialog = () => {
    setState((state) => ({
      ...state,
      toRemoveItem: undefined,
      openRemoveDialogProps: false,
    }));
  };

  const remove = () => {
    districtService
      .remove(state.toRemoveItem?.id as number)
      .then((response) => {
        loadData();
        hideConfirmDeleteDialog();
        setSnackbar({ open: true, message: "Supprimer avec succès" });
      })
      .catch(({ response }) => {
        hideConfirmDeleteDialog();
        setSnackbar({
          open: true,
          severity: "error",
          message:
            response?.data?.message ||
            "Un problème est survenu lors de la suppression",
        });
      });
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc =
      state.orderBy === property && state.order === OrderDirectionModel.ASC;
    setState((state) => ({
      ...state,
      order: isAsc ? OrderDirectionModel.DESC : OrderDirectionModel.ASC,
      orderBy: property as keyof DistrictModel,
    }));
  };

  const getOrderParams = (): OrderByModel<DistrictModel> => {
    const { orderBy, order } = state;
    return { orderField: orderBy, orderDirection: order };
  };

  const loadData = () => {
    districtService
      .fetch({
        pagination: { page: state.currentPage },
        search: undefined,
        orderParams: getOrderParams(),
      })
      .then((response) => {
        const data = _.get(response, ["data", "data"], []) as DistrictModel[];
        const totalCount = _.get(response, ["data", "total"], 0) as number;

        setState((state) => ({
          ...state,
          loading: false,
          data,
          totalCount,
        }));
      });
  };

  React.useEffect(() => {
    loadData();
  }, [state.currentPage, state.filterParams, state.order, state.orderBy]);

  const toggleFilter = () => {
    setState((state) => ({ ...state, openSearchForm: !state.openSearchForm }));
  };

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Liste des districts",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title={"Liste des districts"} />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              href={CREATE_DISTRICT_ROUTE}
              color="primary"
              startIcon={<AddIcon />}
            >
              Nouveau district
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box pb={1}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography>
                <b>{state.totalCount}</b> Districts
              </Typography>
            </Grid>
            <Grid item>
              <SearchTextField
                TextFieldProps={{
                  inputProps: {
                    readOnly: state.openSearchForm,
                  },
                }}
                onChange={(value) =>
                  setState((state) => ({
                    ...state,
                    searchText: value,
                    currentPage: 1,
                    openSearchForm: false,
                    filterParams: undefined,
                  }))
                }
                collapsButton={true}
                toggleCollaps={toggleFilter}
              />
            </Grid>
          </Grid>
          <Collapse in={state.openSearchForm}>
            <Box pt={2}>
              <Divider sx={{ marginBottom: 1 }} />
              <UserFilterForm
                defaultValues={state.filterParams}
                onValide={(data) =>
                  setState((state) => ({
                    ...state,
                    currentPage: 1,
                    filterParams: data,
                  }))
                }
              />
            </Box>
          </Collapse>
        </Box>

        <TableContainer component={Paper}>
          <Table
            sx={{ width: "100%" }}
            size="small"
            aria-label="customized table"
          >
            <EnhancedTableHead
              cells={headCells}
              onRequestSort={handleRequestSort}
              order={state.order}
              orderBy={state.orderBy}
            />
            <TableBody>
              {(state.data || []).map((item, index) => {
                return (
                  <RowItem
                    item={item}
                    showConfirmDeleteDialog={showConfirmDeleteDialog}
                    key={index}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ float: "right", padding: 20 }}>
          <Pagination
            count={Math.ceil(state.totalCount / 10)}
            color="primary"
            showFirstButton
            showLastButton
            page={state.currentPage}
            onChange={handleChangePage}
          />
        </div>
        {state.toRemoveItem && (
          <ConfirmDialog
            title="Suppression"
            body={`Êtes-vous sûr de vouloir supprimer ${state.toRemoveItem?.name}`}
            dialogProps={{
              open: state.openRemoveDialogProps,
              onClose: () => hideConfirmDeleteDialog(),
            }}
            cancelButton={{
              onClick: () => hideConfirmDeleteDialog(),
            }}
            confirmButton={{
              onClick: () => remove(),
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

interface RowItemPropsModel {
  item: DistrictModel;
  showConfirmDeleteDialog: (item: DistrictModel) => void;
}

const RowItem: React.FC<RowItemPropsModel> = ({
  item,
  showConfirmDeleteDialog,
}) => {
  return (
    <>
      <StyledTableRow>
        <StyledTableCell component="th" scope="row">
          {item.name}
        </StyledTableCell>

        <StyledTableCell align="right">
          <IconButton
            aria-label="more"
            id="long-button"
            color="inherit"
            href={`${EDIT_DISTRICT_ROUTE}/${item.id}`}
          >
            <ModeEditOutlinedIcon fontSize="small" />
          </IconButton>

          <IconButton
            aria-label="more"
            id="long-button"
            color="error"
            onClick={() => showConfirmDeleteDialog(item)}
          >
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default ListDistrictsPage;
