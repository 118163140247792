import { useQuery, UseQueryResult } from "react-query";
import {
  bagService,
  BagsPaginationResponseModel,
  DistrictShipmentsResponseModel,
  FetchBagsQueryParamsModel,
  OfficeShipmentsResponseModel,
} from "../services/bag.service";

export const useFetchBagsQuery = (
  params: FetchBagsQueryParamsModel
): UseQueryResult<BagsPaginationResponseModel> => {
  return useQuery<BagsPaginationResponseModel>({
    queryKey: ["fetchPricing-query"].join("-"),
    queryFn: () => {
      return bagService.fetch(params);
    },
  });
};

export const useFetchInnerBagsToPrepareQuery =
  (): UseQueryResult<OfficeShipmentsResponseModel> => {
    return useQuery<OfficeShipmentsResponseModel>({
      queryKey: ["useFetchInnerBagsToPrepare-query"].join("-"),
      queryFn: () => {
        return bagService.fetchInnerBagsToPrepare();
      },
      refetchInterval: 5000,
    });
  };

export const useFetchOfficeBagsToPrepareQuery =
  (): UseQueryResult<DistrictShipmentsResponseModel> => {
    return useQuery<DistrictShipmentsResponseModel>({
      queryKey: ["useFetchOfficeBagsToPrepare-query"].join("-"),
      queryFn: () => {
        return bagService.fetchOfficeBagsToPrepare();
      },
      refetchInterval: 5000,
    });
  };

export const useFetchOuterBagsToPrepareQuery =
  (): UseQueryResult<DistrictShipmentsResponseModel> => {
    return useQuery<DistrictShipmentsResponseModel>({
      queryKey: ["useFetchOuterBagsToPrepare-query"].join("-"),
      queryFn: () => {
        return bagService.fetchOuterBagsToPrepare();
      },
      refetchInterval: 5000,
    });
  };

export const useFetchReadyForForwardingBagsQuery = (
  params: FetchBagsQueryParamsModel
): UseQueryResult<BagsPaginationResponseModel> => {
  return useQuery<BagsPaginationResponseModel>({
    queryKey: ["fetchPricing-query"].join("-"),
    queryFn: () => {
      return bagService.fetch(params);
    },
    refetchInterval: 5000,
  });
};

export const useFetchForwardedBagsQuery = (
  params: FetchBagsQueryParamsModel
): UseQueryResult<BagsPaginationResponseModel> => {
  return useQuery<BagsPaginationResponseModel>({
    queryKey: ["fetchPricing-query", JSON.stringify(params)].join("-"),
    queryFn: () => {
      return bagService.fetch(params);
    },
    refetchInterval: 5000,
  });
};
