import { RoleModel } from "./../../models/shared/roleModel";
import { isNaN, isUndefined } from "lodash";
import { useQuery, UseQueryResult } from "react-query";
import { ChangePasswordFormModel } from "../../../pages_components/users/Forms/changePasswordForm/models";
import { UserCreateFormModel } from "../../../pages_components/users/Forms/userCreateForm/models";
import { UserEditeFormModel } from "../../../pages_components/users/Forms/userEditeForm/models";

import { UserModel } from "../../models/shared/userModel";
import {
  CreateResponseModel,
  FetchWithPaginationResponseModel,
  userService,
} from "../services/user.service";

export const useFindUsersQuery = (
  input: string
): UseQueryResult<FetchWithPaginationResponseModel> => {
  return useQuery<FetchWithPaginationResponseModel>({
    queryKey: ["fetch-query", input].join("-"),
    queryFn: () => {
      return userService.fetch({
        pagination: { page: 1 },
        search: input,
      });
    },
    enabled: input.length > 2,
  });
};

export const useFetchRolesQuery = (): UseQueryResult<RoleModel[]> => {
  return useQuery<RoleModel[]>({
    queryKey: ["fetch-roles-query"].join("-"),
    queryFn: () => {
      return userService.fetchRoles();
    },
  });
};

export const useFindOneUserQuery = (id: number): UseQueryResult<UserModel> => {
  return useQuery<UserModel>({
    queryKey: ["findOne-query", id].join("-"),
    queryFn: () => {
      return !isUndefined(id) ? userService.findOne(id) : Promise.reject();
    },
    enabled: !isUndefined(id) && !isNaN(id),
  });
};

export const useCreateUserQuery = (
  data?: UserCreateFormModel
): UseQueryResult<CreateResponseModel> => {
  return useQuery<CreateResponseModel>({
    queryKey: ["createUser-query", JSON.stringify(data)].join("-"),
    queryFn: () => {
      return !isUndefined(data) ? userService.create(data) : Promise.reject();
    },
    enabled: !isUndefined(data),
  });
};

export const useUpdateUserQuery = (
  data?: UserEditeFormModel
): UseQueryResult<CreateResponseModel> => {
  return useQuery<CreateResponseModel>({
    queryKey: ["createUser-query", JSON.stringify(data)].join("-"),
    queryFn: () => {
      return !isUndefined(data) ? userService.update(data) : Promise.reject();
    },
    enabled: !isUndefined(data),
  });
};

export const useChangePasswordQuery = (
  data?: ChangePasswordFormModel
): UseQueryResult<CreateResponseModel> => {
  return useQuery<CreateResponseModel>({
    queryKey: ["createUser-query", JSON.stringify(data)].join("-"),
    queryFn: () => {
      return !isUndefined(data)
        ? userService.changePassword(data)
        : Promise.reject();
    },
    enabled: !isUndefined(data),
  });
};
