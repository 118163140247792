import httpClient from "../../httpClient";

const resend = async (params: string[]): Promise<any> => {
  return httpClient.post("dispatching/reception/resend-sms", {
    shipments: params,
  });
};

export const smsService = {
  resend,
};
