import CheckIcon from "@mui/icons-material/Check";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import {
  Badge,
  Button,
  Collapse,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { isUndefined } from "lodash";
import React, { FC, useEffect } from "react";
import { priceFormat } from "../../../application/helpers/priceFormat";
import { FailureReasonModel } from "../../../application/models/shared/failureReasonModel";
import { ShipmentModel } from "../../../application/models/shared/ShipmentModel";
import CheckTrackingIdForm from "../forms/check-tracking-id-form";
import FailureDeliveryForm from "../forms/failure-delivery-form";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import CompassCalibrationOutlinedIcon from "@mui/icons-material/CompassCalibrationOutlined";
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

enum ActionType {
  DELIVERY = "DELIVERY",
  FAILURE = "FAILURE",
}

interface ShipmentCardState {
  expanded: boolean;
  actionType: ActionType;
}

export const ShipmentCard: FC<{
  shipment: ShipmentModel;
  failureReasons: FailureReasonModel[];
}> = ({ shipment, failureReasons }) => {
  const [state, setState] = React.useState<ShipmentCardState>({
    expanded: false,
    actionType: ActionType.DELIVERY,
  });

  useEffect(() => {
    setState((state) => ({ ...state, expanded: false }));
  }, [shipment]);

  return (
    <Box
      sx={{
        p: 1,
        pt: 0,
        m: 0,
        overflow: "hidden",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={1}
      >
        <Grid item container>
          <Grid
            container
            alignItems="stretch"
            spacing={1}
            sx={{ height: "100%" }}
          >
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  borderLeft: "solid 5px #eee",
                  p: 1,
                  height: "100%",
                }}
              >
                <Grid container direction="column">
                  <Grid item>
                    <Typography sx={{ fontWeight: 500 }} variant="body2">
                      Expéditeur
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Stack spacing={2} direction="row" alignItems="flex-end">
                      <PersonOutlineOutlinedIcon fontSize="small" />
                      <Typography variant="body2" sx={{ fontSize: 13 }}>
                        {shipment.sender?.first_name || "--"}{" "}
                        {shipment.sender?.last_name || "--"}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Stack spacing={2} direction="row" alignItems="flex-end">
                      <LocalPhoneOutlinedIcon fontSize="small" />
                      <Typography variant="body2" sx={{ fontSize: 13 }}>
                        {shipment.sender?.phone_number || "--"}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Stack spacing={2} direction="row" alignItems="flex-end">
                      <PlaceOutlinedIcon fontSize="small" />
                      <Typography variant="body2" sx={{ fontSize: 13 }}>
                        {shipment.sender?.address?.address_line || "--"}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Stack spacing={2} direction="row" alignItems="flex-end">
                      <HomeWorkOutlinedIcon fontSize="small" />
                      <Typography variant="body2" noWrap sx={{ fontSize: 13 }}>
                        {shipment.sender?.address?.post_office?.name || "--"} /{" "}
                        {shipment.sender?.address?.post_office?.zip_code ||
                          "--"}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <Box
                sx={{
                  borderLeft: "solid 5px #eee",
                  p: 1,
                  height: "100%",
                }}
              >
                <Grid container direction="column">
                  <Grid item>
                    <Typography sx={{ fontWeight: 500 }} variant="body2">
                      Destinataire
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" sx={{ fontSize: 13 }}>
                      {shipment.recipient.first_name}{" "}
                      {shipment.recipient.last_name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" sx={{ fontSize: 13 }}>
                      {shipment.recipient?.address?.address_line}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" noWrap sx={{ fontSize: 13 }}>
                      {shipment?.recipient?.address?.post_office?.name} /{" "}
                      {shipment?.recipient?.address?.post_office?.zip_code}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid> */}
            <Grid item xs={12} md={5}>
              <Box sx={{ borderLeft: "solid 5px #eee", p: 1 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="flex-start"
                  wrap="nowrap"
                >
                  <Grid item>
                    <Stack spacing={2} direction="row" alignItems="flex-end">
                      <CalendarTodayOutlinedIcon fontSize="small" />
                      <Typography variant="body2" noWrap sx={{ fontSize: 13 }}>
                        {shipment?.received_at}
                      </Typography>
                    </Stack>

                    <Stack spacing={2} direction="row" alignItems="flex-end">
                      <CompassCalibrationOutlinedIcon fontSize="small" />

                      <Typography variant="body2" noWrap sx={{ fontSize: 13 }}>
                        <b> {shipment.weight} Kg</b>
                      </Typography>
                    </Stack>

                    {shipment.reimbursement.enable &&
                      shipment.reimbursement.amount > 0 && (
                        <Stack
                          spacing={2}
                          direction="row"
                          alignItems="flex-end"
                        >
                          <MonetizationOnOutlinedIcon fontSize="small" />
                          <Typography
                            variant="body2"
                            noWrap
                            sx={{ fontSize: 13 }}
                          >
                            <b> CRBT :</b>{" "}
                            <span style={{ fontWeight: 500, color: "red" }}>
                              {priceFormat(shipment.reimbursement.amount)}
                            </span>
                          </Typography>
                        </Stack>
                      )}
                    <Stack spacing={2} direction="row" alignItems="flex-end">
                      <QrCode2OutlinedIcon fontSize="small" />

                      <Typography variant="body2" noWrap sx={{ fontSize: 13 }}>
                        {shipment.identifier}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item>
                    {" "}
                    {/* <QRCode value={shipment.identifier} size={50} /> */}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Stack spacing={2} direction="row">
                <Button
                  size="small"
                  fullWidth
                  color="success"
                  startIcon={<CheckIcon />}
                  onClick={() => {
                    setState((state) => ({
                      ...state,
                      expanded:
                        state.actionType === ActionType.DELIVERY
                          ? !state.expanded
                          : true,
                      actionType: ActionType.DELIVERY,
                    }));
                  }}
                >
                  LIVRAISON
                </Button>
                <Divider orientation="vertical" variant="middle" flexItem />

                <Button
                  size="small"
                  fullWidth
                  color="error"
                  startIcon={<PriorityHighOutlinedIcon />}
                  onClick={() => {
                    setState((state) => ({
                      ...state,
                      expanded:
                        state.actionType === ActionType.FAILURE
                          ? !state.expanded
                          : true,
                      actionType: ActionType.FAILURE,
                    }));
                  }}
                >
                  <Badge
                    badgeContent={
                      shipment.attempts && shipment.attempts.length > 0
                        ? shipment.attempts && shipment.attempts.length
                        : 0
                    }
                    color="error"
                    sx={{
                      "& .MuiBadge-badge": {
                        right: -10,
                        top: 0,
                        // border: `2px solid ${theme.palette.background.paper}`,
                        padding: "0 4px",
                      },
                    }}
                  >
                    {" "}
                    ÉCHEC
                  </Badge>
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={state.expanded} timeout="auto" unmountOnExit>
        <div style={{ marginTop: 30 }}>
          {state.actionType === ActionType.DELIVERY && (
            <DeliveryForm shipment={shipment} />
          )}
          {state.actionType === ActionType.FAILURE && (
            <FailureForm failureReasons={failureReasons} shipment={shipment} />
          )}
        </div>
      </Collapse>
    </Box>
  );
};

const DeliveryForm: FC<{ shipment: ShipmentModel }> = ({
  shipment,
}): JSX.Element => {
  return (
    <>
      {" "}
      <Divider textAlign="left">
        <Typography color="green" sx={{ fontWeight: 500 }}>
          LIVRAISON
        </Typography>
      </Divider>
      <Box sx={{ pt: 3 }}>
        <CheckTrackingIdForm shipment={shipment} />
      </Box>
    </>
  );
};

const FailureForm: FC<{
  failureReasons: FailureReasonModel[];
  shipment: ShipmentModel;
}> = ({ failureReasons, shipment }): JSX.Element => {
  return (
    <Box>
      {" "}
      <Divider textAlign="left">
        <Typography color="red" sx={{ fontWeight: 500 }}>
          RAISON D'ÉCHEC
        </Typography>
      </Divider>
      <Box sx={{ pt: 3 }}>
        <FailureDeliveryForm
          failureReasons={failureReasons}
          shipment={shipment}
        />
      </Box>
    </Box>
  );
};
