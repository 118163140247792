import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import SendToMobileOutlinedIcon from "@mui/icons-material/SendToMobileOutlined";
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { green } from "@mui/material/colors";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import { get } from "lodash";
import * as React from "react";
import { Link } from "react-router-dom";
import { useFetchShipmentsQuery } from "../../application/api/hooks/dispatching";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  dispatchingService,
  FetchShipmentsResponseModel,
} from "../../application/api/services/dispatching.service";
import { smsService } from "../../application/api/services/sms.service";
import {
  CREATE_PACKAGE_ROUTE,
  DETAILS_PACKAGE_ROUTE,
} from "../../application/constants/routeConstants";
import { shipmentStatus } from "../../application/data/ShipmentStatus";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { ShipmentStatusEnumModel } from "../../application/models/shared/shipmentStatusModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import { SelectShipmentStatus } from "../../components/SelectShipmentStatus";
import { ShipmentStatusChip } from "../../components/shipmentStatusChip";
import { SnackbarContext } from "../../components/snackbarProvider";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";
import SearchIcon from "@mui/icons-material/Search";
import { ShipmentModel } from "../../application/models/shared/ShipmentModel";
import { ConfirmDialog } from "../../components/confirmDialog";
import Can from "../../application/casl/abilityContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

interface StateModel {
  currentPage: number;
  searchText: string;
  status: ShipmentStatusEnumModel[];
  selected: string[];
  sendSmsProgress: boolean;
  removeProgress: boolean;
  fetchingProgress: boolean;
  fetchingError?: "";
  data: ShipmentModel[];
  total: number;
}

const ListShipmentsPage: React.FC<PagePropsModel> = (): JSX.Element => {
  const [snackbar, setSnackbar] = React.useContext(SnackbarContext);
  const [state, setState] = React.useState<StateModel>({
    currentPage: 1,
    searchText: "",
    status: [],
    selected: [],
    sendSmsProgress: false,
    removeProgress: false,
    fetchingProgress: true,
    fetchingError: "",
    data: [],
    total: 0,
  });

  React.useEffect(() => {
    loadData();
  }, [state.currentPage, state.status, state.searchText]);

  const resendSms = () => {
    if (state.selected.length > 0) {
      setState((state) => ({ ...state, sendSmsProgress: true }));
      smsService
        .resend(state.selected)
        .then((response) => {
          setState((state) => ({
            ...state,
            sendSmsProgress: false,
            selected: [],
          }));

          setSnackbar({
            open: true,
            severity: "success",
            message: "SMS(s) envoyés avec succès",
          });
        })
        .catch((error) => {
          setState((state) => ({ ...state, sendSmsProgress: false }));
          setSnackbar({
            open: true,
            severity: "error",
            message:
              "Un problème est survenu lors de l'envoi, veuillez réessayer de nouveau",
          });
        });
    }
  };

  const remove = (id: number) => {
    setState((state) => ({ ...state, removeProgress: true }));
    dispatchingService
      .removeShipment(id)
      .then((response) => {
        setState((state) => ({
          ...state,
          removeProgress: false,
          currentPage: 1,
        }));

        setSnackbar({
          open: true,
          severity: "success",
          message: "Suppression avec succès",
        });
        loadData();
      })
      .catch((error) => {
        setState((state) => ({ ...state, sendSmsProgress: false }));
        setSnackbar({
          open: true,
          severity: "error",
          message:
            "Un problème est survenu lors de la suppression, veuillez réessayer de nouveau",
        });
      });
  };

  const loadData = () => {
    setState((state) => ({ ...state, fetchingProgress: true }));
    dispatchingService
      .fetch({
        pagination: { page: state.currentPage, per_page: 10 },
        status: state.status,
        search: state.searchText,
      })
      .then(({ data }) => {
        setState((state) => ({
          ...state,
          fetchingProgress: false,
          data: data.data,
          total: data.total,
        }));
      })
      .catch((error) => {
        setState((state) => ({
          ...state,
          fetchingProgress: false,
          fetchingError: error,
        }));
      });
  };

  // const shipmentsQuery = useFetchShipmentsQuery({
  //   pagination: { page: state.currentPage, per_page: 10 },
  //   status: state.status,
  //   search: state.searchText,
  // });
  // const {
  //   data: { data, current_page, total },
  // } = get(shipmentsQuery, ["data"], {
  //   data: [],
  //   current_page: 1,
  //   total: 0,
  // }) as FetchShipmentsResponseModel;

  const [open, setOpen] = React.useState(false);
  const toggleFilter = () => {
    setOpen((prev) => !prev);
  };

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Liste des colis envoyés",
    },
  ];

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setState((state) => ({ ...state, currentPage: value, selected: [] }));
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = state.data.map((item) => item.identifier);
      setState((state) => ({ ...state, selected: newSelecteds }));
      return;
    }
    setState((state) => ({ ...state, selected: [] }));
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = state.selected.indexOf(id);

    event.stopPropagation();

    setState((state) => ({
      ...state,
      selected:
        selectedIndex > -1
          ? state.selected.filter((item) => item !== id)
          : [...state.selected, id],
    }));
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="List des Colis" />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item>
            <Button
              component={Link}
              variant="contained"
              to={CREATE_PACKAGE_ROUTE}
              color="primary"
              startIcon={<AddIcon />}
            >
              Nouveau colis
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {state.fetchingError && state.fetchingProgress === false && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="error">Error</Alert>
          </Box>
        )}
        {state.fetchingProgress === false && state.data?.length === 0 && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="info">Aucune donnée disponible!</Alert>
          </Box>
        )}
        {state.fetchingProgress && <LinearProgress />}

        <Paper sx={{ overflow: "hidden" }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(state.selected.length > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
          >
            {state.selected.length > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%", fontWeight: 500 }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {state.selected.length} Sélectionnés
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%", fontWeight: 500 }}
                id="tableTitle"
                component="div"
              >
                {state.total} Colis
              </Typography>
            )}
            {state.selected.length > 0 ? (
              <Tooltip title="Envoyer un sms">
                <IconButton
                  onClick={() => resendSms()}
                  disabled={state.sendSmsProgress}
                >
                  {state.sendSmsProgress ? (
                    <CircularProgress
                      size={30}
                      sx={{
                        color: green[500],
                      }}
                    />
                  ) : (
                    <SendToMobileOutlinedIcon />
                  )}
                </IconButton>
              </Tooltip>
            ) : (
              <>
                <TextField
                  id="input-with-icon-textfield"
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      searchText: e.target.value,
                      currentPage: 1,
                    }));
                    // console.log(e.target.value)
                  }}
                  value={state.searchText}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  size="small"
                />

                <Tooltip title="Filter list">
                  <IconButton onClick={toggleFilter}>
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Toolbar>

          <Collapse in={open}>
            <Box sx={{ p: 2 }}>
              <SelectShipmentStatus
                value={state.status}
                onChange={(value) => {
                  setState((state) => ({ ...state, status: value }));
                }}
              />
            </Box>
          </Collapse>

          {state.total > 0 && state.data?.length > 0 && (
            <TableContainer>
              <Table
                sx={{ width: "100%", minWidth: 650 }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          state.selected.length > 0 &&
                          state.selected.length < state.data?.length
                        }
                        checked={
                          state.data?.length > 0 &&
                          state.selected.length === state.data?.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Date d'entrée
                    </StyledTableCell>
                    <StyledTableCell align="left">Expéditeur</StyledTableCell>
                    <StyledTableCell align="left">Destinateur</StyledTableCell>
                    <StyledTableCell align="left">Poids</StyledTableCell>
                    <StyledTableCell align="left">Code</StyledTableCell>
                    <StyledTableCell align="left">Statut</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(state.data || []).map((row, index) => {
                    const isItemSelected = state.selected.includes(
                      row.identifier
                    );
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <ShipmentRowItem
                        key={index}
                        row={row}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        handleClick={handleClick}
                        remove={remove}
                      />
                    );
                  })}
                </TableBody>
              </Table>{" "}
            </TableContainer>
          )}
        </Paper>

        <div style={{ float: "right", padding: 20 }}>
          {state.total > 0 && (
            <Pagination
              count={Math.ceil(Number(state.total) / 10)}
              color="primary"
              showFirstButton
              showLastButton
              page={state.currentPage}
              onChange={handleChangePage}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ListShipmentsPage;

const ShipmentRowItem: React.FC<{
  row: ShipmentModel;
  isItemSelected: boolean;
  labelId: string;
  handleClick: (event: React.MouseEvent<unknown>, id: string) => void;
  remove: (id: number) => void;
}> = ({ row, isItemSelected, labelId, handleClick, remove }): JSX.Element => {
  const [open, setOpen] = React.useState(false);

  const hideDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <StyledTableRow
        hover
        onClick={(event) => handleClick(event, row.identifier)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected}
      >
        <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row.received_at}
        </StyledTableCell>
        <StyledTableCell component="th">
          {row?.sender?.first_name || "--"} {row?.sender?.last_name || "--"}
        </StyledTableCell>
        <StyledTableCell>
          {row?.recipient?.first_name || "--"}{" "}
          {row?.recipient?.last_name || "--"}
        </StyledTableCell>
        <StyledTableCell>{row.weight} Kg</StyledTableCell>
        <StyledTableCell>
          <Typography color={row?.returned_shipment_id ? "error" : "inherit"}>
            {row?.identifier}
          </Typography>
        </StyledTableCell>
        <StyledTableCell>
          <ShipmentStatusChip
            active={true}
            status={row?.status as ShipmentStatusEnumModel}
            size="small"
            variant="outlined"
          />
        </StyledTableCell>
        <StyledTableCell align="right">
          <Can I="delete" a="SHIPMENT">
            <IconButton
              aria-label="more"
              id="long-button"
              color="error"
              onClick={(event) => {
                event.stopPropagation();
                setOpen(true);
              }}
            >
              <DeleteOutlineOutlinedIcon fontSize="small" />
            </IconButton>
          </Can>
          <IconButton
            component={Link}
            aria-label="more"
            id="long-button"
            color="inherit"
            to={`${DETAILS_PACKAGE_ROUTE}/${row.identifier}`}
          >
            <ReadMoreIcon fontSize="small" />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>

      <ConfirmDialog
        title="Suppression"
        body={`Êtes-vous sûr de vouloir supprimer ${row.identifier}`}
        dialogProps={{
          open: open,
          onClose: () => hideDialog(),
        }}
        cancelButton={{
          onClick: () => hideDialog(),
        }}
        confirmButton={{
          onClick: () => {
            setOpen(false);
            remove(row.id);
          },
        }}
      />
    </>
  );
};
