import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: "0",
    borderTop: "solid 1px #C4C4C4",
    padding: 0,
    paddingBottom: 20,

    borderRadius: theme.shape.borderRadius,
  },
}));

interface FieldsetPropsModel {
  legend: JSX.Element;
}

export const Fieldset: FC<FieldsetPropsModel> = (props) => {
  const classes = useStyles();
  return (
    <fieldset className={classes.root}>
      <legend>{props.legend}</legend>
      <Box>{props?.children}</Box>
    </fieldset>
  );
};
