import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { isFileImage } from "../../application/helpers/file";
import { Fieldset } from "../Fieldset";

interface DropzoneFileProps {
  file?: File;
  label?: string;
  error?: boolean;
  onChange: (file?: File) => void;
}

export const DropzoneFile: FC<DropzoneFileProps> = ({
  file,
  onChange,
  label = "Cliquez pour sélectionner une image",
  error = false,
}) => {
  const theme = useTheme();
  const onDrop = useCallback((acceptedFiles) => {
    onChange(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      {!file && (
        <Box
          {...getRootProps()}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "solid 1px #ddd",
            borderColor: error ? "red" : "#ddd",
            borderRadius: theme.shape.borderRadius,
            cursor: "pointer",
            height: 40,
          }}
        >
          <input {...getInputProps()} />
          <Typography color="textSecondary">{label}</Typography>
        </Box>
      )}
      {!!file && !isFileImage(file) && typeof file !== "string" && (
        <FileThumbnail
          file={file}
          onChange={onChange}
          label={label}
          error={error}
        />
      )}
      {!!file && isFileImage(file) && typeof file !== "string" && (
        <Box
          sx={{
            p: 3,
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "solid 1px #708090",

            borderColor: error ? "red" : "#ddd",
            cursor: "pointer",
            "& .MuiIconButton-root": {
              visibility: "hidden",

              position: "absolute",
            },
            "&:hover": {
              "& .MuiIconButton-root": {
                zIndex: 9999,
                position: "absolute",
                color: "red",
                //   border: "solid 1px red",
                visibility: "visible",
                backgroundColor: "#fff",
              },
              "&::after": {
                content: "' '",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "#f3f6f970",
              },
            },
          }}
        >
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            style={{
              height: "200px",
            }}
          />
          <IconButton onClick={() => onChange(undefined)}>
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      )}
      {!!file && typeof file === "string" && (
        <Box
          sx={{
            p: 3,
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "solid 1px #708090",

            borderColor: error ? "red" : "#ddd",
            cursor: "pointer",
            "& .MuiIconButton-root": {
              visibility: "hidden",

              position: "absolute",
            },
            "&:hover": {
              "& .MuiIconButton-root": {
                zIndex: 9999,
                position: "absolute",
                color: "red",
                //   border: "solid 1px red",
                visibility: "visible",
                backgroundColor: "#fff",
              },
              "&::after": {
                content: "' '",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "#f3f6f970",
              },
            },
          }}
        >
          <img
            src={file}
            alt={""}
            style={{
              height: "200px",
            }}
          />
          <IconButton onClick={() => onChange(undefined)}>
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
};

const FileThumbnail: FC<DropzoneFileProps> = ({
  file,
  label = "",
  onChange,
  error = false,
}): JSX.Element => {
  const theme = useTheme();
  return (
    <fieldset
      style={{
        border: "solid 1px #ddd",
        borderColor: error ? "red" : "#ddd",
        borderRadius: theme.shape.borderRadius,
      }}
    >
      <legend>{label}</legend>
      <Stack direction={"row"} spacing={1} alignItems="center">
        <AttachFileOutlinedIcon fontSize="small" />
        <Typography color="textSecondary">{file?.name || "--"}</Typography>

        <IconButton color="error" onClick={() => onChange(undefined)}>
          <DeleteOutlineIcon fontSize="small" />
        </IconButton>
      </Stack>
    </fieldset>
  );
};
