import React, { FC } from "react";
import { RouteProps } from "react-router-dom";
import LoginPage from "../../pages/Login";
import { PublicLayout } from "../layouts/PublicLayout";

const PublicLayoutRoute: FC<RouteProps> = (props) => {
  return (
    <PublicLayout>
      <LoginPage />
    </PublicLayout>
  );
};

export default PublicLayoutRoute;
