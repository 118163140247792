import { Alert, Grid, Paper, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { customerService } from "../../application/api/services/customer.service";
import { LIST_CUSTOMERS_ROUTE } from "../../application/constants/routeConstants";
import { CustomerModel } from "../../application/models/shared/customerModel";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import CustomerForm from "../../pages_components/customers/Forms/customerForm";

const CreateCustomerPage: FC<PagePropsModel> = (props) => {
  const history = useHistory();
  const id = props?.match?.params?.id || undefined;

  const [state, setState] = useState<{
    loading: boolean;
    formValues: CustomerModel | undefined;
    serverError: string | undefined;
  }>({
    loading: false,
    formValues: undefined,
    serverError: undefined,
  });

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: id ? "Modification client" : "Ajout d'un nouveau client",
    },
  ];

  const store = (data: CustomerModel) => {
    setState((state) => ({
      ...state,
      loading: true,
      serverError: undefined,
      formValues: data,
    }));

    customerService
      .create(data)
      .then((response) => {
        setState((state) => ({
          ...state,
          loading: false,
          formValues: undefined,
        }));
        history.push(LIST_CUSTOMERS_ROUTE);
      })
      .catch(({ message }) =>
        setState((state) => ({
          ...state,
          loading: false,
          serverError: message,
        }))
      );
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title={id ? `Modification client` : `Nouveau client`} />
        <AppBreadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid item>
        {state.serverError && (
          <Alert severity="error">{state?.serverError}</Alert>
        )}
        <CustomerForm
          defaultValues={state.formValues}
          onValide={(data) => store(data)}
        />{" "}
      </Grid>
    </Grid>
  );
};

export default CreateCustomerPage;
