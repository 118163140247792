import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  alpha,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import _ from "lodash";
import * as React from "react";
import { customerService } from "../../application/api/services/customer.service";
import {
  CREATE_CUSTOMER_ROUTE,
  CREATE_USER_ROUTE,
  EDIT_CUSTOMER_ROUTE,
  PROFILE_CUSTOMER_ROUTE,
} from "../../application/constants/routeConstants";
import { CustomerModel } from "../../application/models/shared/customerModel";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { ConfirmDialog } from "../../components/confirmDialog";
import { PageTitle } from "../../components/PageTitle";
import { Pagination } from "../../components/pagination";
import { SnackbarContext } from "../../components/snackbarProvider";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { AccountTypeModel } from "../../application/models/shared/accountTypeModel";
import { Link } from "react-router-dom";

interface StateModel {
  loading: boolean;
  data: CustomerModel[];
  totalCount: number;
  currentPage: number;
  searchText: string;
  toRemoveItem: CustomerModel | undefined;
  openRemoveDialogProps: boolean;
  selected: number[];
}
const CustomersListPage: React.FC<PagePropsModel> = (): JSX.Element => {
  const [snackbar, setSnackbar] = React.useContext(SnackbarContext);
  const [state, setState] = React.useState<StateModel>({
    loading: true,
    data: [],
    totalCount: 0,
    currentPage: 1,
    searchText: "",
    toRemoveItem: undefined,
    openRemoveDialogProps: false,
    selected: [],
  });

  React.useEffect(() => {
    loadData();
  }, [state.currentPage, state.searchText]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setState((state) => ({ ...state, currentPage: value }));
  };

  const showConfirmDeleteDialog = (item: CustomerModel) => {
    setState((state) => ({
      ...state,
      toRemoveItem: item,
      openRemoveDialogProps: true,
    }));
  };

  const hideConfirmDeleteDialog = () => {
    setState((state) => ({
      ...state,
      toRemoveItem: undefined,
      openRemoveDialogProps: false,
    }));
  };

  const remove = () => {
    customerService
      .remove(state.toRemoveItem?.id as number)
      .then((response) => {
        loadData();
        hideConfirmDeleteDialog();
        setSnackbar({ open: true, message: "Supprimer avec succès" });
      })
      .catch(({ response }) => {
        hideConfirmDeleteDialog();
        setSnackbar({
          open: true,
          severity: "error",
          message:
            response?.data?.message ||
            "Un problème est survenu lors de la suppression",
        });
      });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = state.data.map((item) => Number(item.id));
      setState((state) => ({ ...state, selected: newSelecteds }));
      return;
    }
    setState((state) => ({ ...state, selected: [] }));
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = state.selected.indexOf(id);
    event.stopPropagation();
    setState((state) => ({
      ...state,
      selected:
        selectedIndex > -1
          ? state.selected.filter((item) => item !== id)
          : [...state.selected, id],
    }));
  };

  const loadData = () => {
    customerService
      .fetchPagination({
        pagination: { page: state.currentPage },
        search: state.searchText,
      })
      .then((response) => {
        const data = _.get(response, ["data", "data"], []) as CustomerModel[];
        const totalCount = _.get(response, ["data", "total"], 0) as number;
        setState((state) => ({
          ...state,
          loading: false,
          data,
          totalCount,
        }));
      });
  };

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Liste des client",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="Liste des clients" />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item>
            <Button
              component={Link}
              variant="contained"
              to={CREATE_CUSTOMER_ROUTE}
              color="primary"
              startIcon={<AddIcon />}
            >
              Nouveau client
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Paper sx={{ overflow: "hidden" }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(state.selected.length > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
          >
            {state.selected.length > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%", fontWeight: 500 }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {state.selected.length} Sélectionnés
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%", fontWeight: 500 }}
                id="tableTitle"
                component="div"
              >
                {state.totalCount} clients
              </Typography>
            )}
            {state.selected.length > 0 ? (
              <Tooltip title="Delete">
                <IconButton onClick={() => console.log("Delete")}>
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <TextField
                id="input-with-icon-textfield"
                onChange={(e) => {
                  setState((state) => ({
                    ...state,
                    searchText: e.target.value,
                    currentPage: 1,
                  }));
                  // console.log(e.target.value)
                }}
                value={state.searchText}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                size="small"
              />
            )}
          </Toolbar>
          <TableContainer>
            <Table
              sx={{ width: "100%" }}
              size="small"
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        state.selected.length > 0 &&
                        state.selected.length < state.data?.length
                      }
                      checked={
                        state.data?.length > 0 &&
                        state.selected.length === state.data?.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">Nom & Prénom</StyledTableCell>
                  <StyledTableCell align="left">Téléphone</StyledTableCell>
                  <StyledTableCell align="left">Code Postal</StyledTableCell>
                  <StyledTableCell align="center">Business</StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(state.data || []).map((row, index) => {
                  const isItemSelected = state.selected.includes(
                    Number(row.id)
                  );
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow
                      key={index}
                      hover
                      onClick={(event) => handleClick(event, Number(row.id))}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.first_name} {row.last_name}
                      </StyledTableCell>
                      <StyledTableCell component="th">
                        {row.phone_number}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.address.post_office?.name} :
                        <b>{row.address.post_office?.zip_code}</b>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <CheckCircleOutlineOutlinedIcon
                          color={
                            row.account_type !== undefined &&
                            row.account_type ===
                              AccountTypeModel.business_account
                              ? "success"
                              : "disabled"
                          }
                        />
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <IconButton
                          component={Link}
                          aria-label="more"
                          id="long-button"
                          color="inherit"
                          to={`${PROFILE_CUSTOMER_ROUTE}/${row.id}`}
                        >
                          <PermContactCalendarOutlinedIcon fontSize="small" />
                        </IconButton>

                        <IconButton
                          component={Link}
                          aria-label="more"
                          id="long-button"
                          color="inherit"
                          to={`${EDIT_CUSTOMER_ROUTE}/${row.id}`}
                        >
                          <ModeEditOutlinedIcon fontSize="small" />
                        </IconButton>

                        <IconButton
                          aria-label="more"
                          id="long-button"
                          color="error"
                          onClick={(event) => {
                            event.stopPropagation();
                            showConfirmDeleteDialog(row);
                          }}
                        >
                          <DeleteOutlineOutlinedIcon fontSize="small" />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>{" "}
          </TableContainer>
        </Paper>

        <div style={{ float: "right", padding: 20 }}>
          <Pagination
            count={Math.ceil(state.totalCount / 10)}
            color="primary"
            showFirstButton
            showLastButton
            page={state.currentPage}
            onChange={handleChangePage}
          />
        </div>

        {state.toRemoveItem && (
          <ConfirmDialog
            title="Suppression"
            body={`Êtes-vous sûr de vouloir supprimer ${state.toRemoveItem?.first_name} ${state.toRemoveItem?.last_name}`}
            dialogProps={{
              open: state.openRemoveDialogProps,
              onClose: () => hideConfirmDeleteDialog(),
            }}
            cancelButton={{
              onClick: () => hideConfirmDeleteDialog(),
            }}
            confirmButton={{
              onClick: () => remove(),
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CustomersListPage;
