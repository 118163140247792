import {
  Alert,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Skeleton,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { get, isUndefined } from "lodash";
import { FC, useEffect, useState } from "react";
import {
  useFindOneDistrictQuery,
  useUpdateDistrictQuery,
} from "../../application/api/hooks/district.hook";

import {
  CREATE_OFFICE_ROUTE,
  LIST_DISTRICT_ROUTE,
  LIST_OFFICE_ROUTE,
} from "../../application/constants/routeConstants";
import { DistrictModel } from "../../application/models/shared/districtModel";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import If from "../../components/conditionalViwer";
import DistrictForm from "../../pages_components/districts/forms/editForm";
import { DistrictFormModel } from "../../pages_components/districts/forms/editForm/models";
import { useHistory } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
}));

const UpdateDistrictPage: FC<PagePropsModel> = (props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const id = get(props, ["match", "params", "id"], undefined);
  const oneDistrictQuery = useFindOneDistrictQuery(Number(id));
  const districtData = get(oneDistrictQuery, ["data", "data"], undefined);

  const [data, setData] = useState<DistrictFormModel | undefined>(undefined);

  const updateDistrictQuery = useUpdateDistrictQuery(data);
  const districtUpdated = get(
    updateDistrictQuery,
    ["data", "data"],
    undefined
  ) as DistrictModel | undefined;

  useEffect(() => {
    if (!isUndefined(districtUpdated)) {
      history.push(LIST_DISTRICT_ROUTE);
    }
  }, [districtUpdated]);

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Nouveau bureau",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title={"Nouveau district"} />
        <AppBreadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid item>
        <Paper className={classes.paper}>
          <If
            condition={updateDistrictQuery.isLoading}
            render={() => <LinearProgress sx={{ marginBottom: 4 }} />}
            otherwise={() => (
              <If
                condition={updateDistrictQuery.isError}
                render={() => (
                  <Alert severity="error" sx={{ marginBottom: 4 }}>
                    Une erreur se produise l'hort de la modification!
                  </Alert>
                )}
              />
            )}
          />

          <If
            condition={oneDistrictQuery?.isLoading}
            render={() => (
              <Skeleton variant="rectangular" width={"100%"} height={118} />
            )}
            otherwise={() => (
              <If
                condition={oneDistrictQuery?.isError}
                render={() => (
                  <Alert severity="error">District n'est pas trouvé !</Alert>
                )}
                otherwise={() => (
                  <If
                    condition={!isUndefined(districtData)}
                    render={() => (
                      <DistrictForm
                        loading={updateDistrictQuery.isLoading}
                        defaultValues={districtData}
                        onValide={(data) => setData(data)}
                      />
                    )}
                    otherwise={() => (
                      <Alert severity="error">
                        District n'est pas trouvé !
                      </Alert>
                    )}
                  />
                )}
              />
            )}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UpdateDistrictPage;
