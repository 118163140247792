import { yupResolver } from "@hookform/resolvers/yup";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Alert,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { priceFormat } from "../../../application/helpers/priceFormat";
import { ReceptionFormModel } from "../../../application/models/forms/recptionFormModel";
import { useReceptionController } from "./receptionController";
import ShipmentForm from "./RecipientForm";
import SenderForm from "./SenderForm";
import { PricingDetails } from "./SenderForm/pricingDetails";
import { validationSchema } from "./ValidationSchema";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(3),
  },
}));

interface ReceptionFormSettings {
  hideSenderForm: boolean;
  isNested: boolean;
  isLoading?: boolean;
}

interface ReceptionFormPropsModel {
  defaultValues: ReceptionFormModel | undefined;
  onSubmit: (value: ReceptionFormModel) => void;
  settings?: ReceptionFormSettings;
}

const ReceptionForm: FC<ReceptionFormPropsModel> = (props): JSX.Element => {
  const {
    formSettings,
    isLoading,
    isError,
    packagingOptions,
    pricingSettings,
    governorates,
    getInitSettings,
    intFormValues,
  } = useReceptionController();

  useEffect(() => {
    getInitSettings(props.settings || undefined);
  }, []);

  if (isLoading) {
    return <LinearProgress />;
  }
  if (!isLoading && isError) {
    return <Alert severity="error">Erreur de configuration !</Alert>;
  }

  if (
    !isLoading &&
    !isError &&
    (packagingOptions === [] || pricingSettings === [])
  ) {
    return <Alert severity="error"> Configuration manquante </Alert>;
  }

  return <Form {...props} />;
};

const Form: FC<ReceptionFormPropsModel> = (props): JSX.Element => {
  const classes = useStyles();
  const {
    setCustomizedPricing,
    intFormValues,
    getTotalPrice,
    maxWeight,
    formSettings,
  } = useReceptionController();

  const formCtx = useForm<ReceptionFormModel>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema({ maxWeight })),
    defaultValues: props.defaultValues || intFormValues,
  });
  const formValues = formCtx.watch();

  useEffect(() => {
    if (formValues?.sender?.id !== undefined) {
      setCustomizedPricing(formValues?.sender?.pricing || undefined);
    } else {
      setCustomizedPricing(undefined);
    }
  }, [formValues?.sender?.id]);

  const onSubmit = (data: ReceptionFormModel) => {
    props.onSubmit(data);
  };

  const totalPrice = getTotalPrice(formValues.shipments);

  return (
    <FormProvider {...formCtx}>
      <form onSubmit={formCtx.handleSubmit(onSubmit)} autoComplete="off">
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Grid container spacing={4} alignItems="stretch">
              <Grid item xs={12} md={12}>
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <Grid container spacing={3} justifyContent="space-between">
                      {!formSettings?.hideSenderForm && (
                        <Grid item xs={12}>
                          <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                            alignItems={"stretch"}
                          >
                            <Grid item xs={12} md={8}>
                              <Paper sx={{ p: 3, height: "100%" }}>
                                <SenderForm
                                  handleSlectSender={(sender) => {
                                    formCtx.setValue(`sender`, sender, {
                                      shouldValidate: true,
                                    });
                                    formCtx.setValue(
                                      `sender.address.post_office` as any,
                                      sender.address.post_office,
                                      { shouldValidate: true }
                                    );
                                  }}
                                  resetSenderForm={() => {
                                    formCtx.setValue(
                                      `sender`,
                                      {
                                        id: undefined,
                                        uuid: "",
                                        first_name: "",
                                        last_name: "",
                                        phone_number: "",
                                        second_phone_number: "",
                                        address: {
                                          address_line: "",
                                          post_office: undefined,
                                        },
                                        pricing: undefined,
                                      },
                                      { shouldValidate: false }
                                    );
                                  }}
                                />
                              </Paper>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Paper sx={{ p: 3, height: "100%" }}>
                                <PricingDetails />
                              </Paper>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <ShipmentForm />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Paper sx={{ p: 3 }}>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Button variant="outlined">Annuler</Button>
                        </Grid>
                        <Grid item></Grid>
                        <Grid item>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                              <Typography
                                sx={{ fontWeight: 600 }}
                                color="blueviolet"
                              >
                                Total à payer : {priceFormat(totalPrice)}
                              </Typography>
                            </Grid>
                            <Grid item>
                              {" "}
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={props.settings?.isLoading}
                                endIcon={<KeyboardArrowRightIcon />}
                              >
                                Valider
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ReceptionForm;
