import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { FC } from "react";
import { CityModel } from "../../../../application/models/shared/cityModel";
import { GovernorateModel } from "../../../../application/models/shared/governorateModel";
import { PostOfficeModel } from "../../../../application/models/shared/postOfficeModel";

export const PostOfficesList: FC<{
  postOffices: PostOfficeModel[] | [];
  city: CityModel;
  governorate: GovernorateModel;
}> = ({ city, governorate, postOffices }) => {
  return (
    <List component="div" disablePadding dense={false}>
      {postOffices.map((postOffice, index) => (
        <ListItemPostOffice
          key={`postOffice_${postOffice.id}`}
          city={city}
          governorate={governorate}
          postOffice={postOffice}
        />
      ))}
    </List>
  );
};

const ListItemPostOffice: FC<{
  postOffice: PostOfficeModel;
  city: CityModel;
  governorate: GovernorateModel;
}> = (props) => {
  const { postOffice, city, governorate } = props;

  return (
    <ListItem sx={{ pl: 8 }} disablePadding>
      <ListItemButton role={undefined} dense>
        <ListItemIcon>
          <LabelOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          id={String(postOffice.id)}
          primary={
            <div>
              <b>{postOffice.zip_code}</b> - {postOffice.name}
            </div>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};
