import { Alert, Grid, LinearProgress, Paper, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { get, isUndefined } from "lodash";
import React, { FC, useEffect } from "react";
import { useFetchFailureReasonsQuery } from "../../application/api/hooks/dispatching";
import { FailureReasonModel } from "../../application/models/shared/failureReasonModel";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { ShipmentModel } from "../../application/models/shared/ShipmentModel";
import { useNotificationsStore } from "../../application/notifications/notificationState";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import DistinationCard from "../../pages_components/shipments/distinationCard";
import { ShipmentCard } from "../../pages_components/shipments/shipmentCard";
import { useTripShipmentsStore } from "./hooks/TripShipmentsState";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(3),
  },

  input: {
    width: 1,
    height: 1,
    opacity: 0,
    overflow: "hidden",
    position: "relative",
    zIndex: -1,
    "&::before": {
      content: "''",
      width: 300,
      height: 300,
      backgroundColor: "red",
      border: "solid 1px red",
      position: "absolute",
      top: 0,
    },
  },
}));

export interface DestinationModel {
  recipient: ShipmentModel["recipient"];
  shipments: ShipmentModel[];
}

type DestinationsModel = Record<number, DestinationModel>;

const TripShipmentsPage: FC<PagePropsModel> = (props): JSX.Element => {
  const classes = useStyles();
  const { isLoading, setLoading, isError, data, fetch } =
    useTripShipmentsStore();
  const { notifications } = useNotificationsStore();
  const failureReasonsQuery = useFetchFailureReasonsQuery();

  useEffect(() => {
    fetch();
  }, [notifications.tripShipments]);

  const failureReasons = get(
    failureReasonsQuery,
    ["data", "data"],
    []
  ) as FailureReasonModel[];

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Expéditions de livraison",
    },
  ];

  const destinationsReduce = (
    acc: DestinationsModel,
    cur: ShipmentModel
  ): DestinationsModel => {
    const recepientId = cur.recipient.id;
    return {
      ...acc,
      [recepientId]: isUndefined(acc[recepientId])
        ? {
            recipient: cur.recipient,
            shipments: [cur],
          }
        : {
            ...acc[recepientId],
            shipments: [...acc[recepientId].shipments, cur],
          },
    };
  };

  const sumDestinationCRBTReduce = (
    acc: number,
    cur: ShipmentModel
  ): number => {
    const curCRBT = cur.reimbursement.enable
      ? Number(cur.reimbursement.amount)
      : 0;
    return acc + curCRBT;
  };

  const destinations = ((): DestinationsModel => {
    return (data as ShipmentModel[]).reduce<DestinationsModel>(
      destinationsReduce,
      {} as DestinationsModel
    );
  })();

  const sumDestinationCRBT = (shipments: ShipmentModel[]) => {
    return (shipments as ShipmentModel[]).reduce(sumDestinationCRBTReduce, 0);
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="Expéditions de livraison" />
        <AppBreadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>

      {isLoading && (
        <Grid item>
          <LinearProgress />
        </Grid>
      )}
      {isError && (
        <Grid item>
          <Alert severity="info">This is an info alert — check it out!</Alert>
        </Grid>
      )}

      {!isLoading && !isError && data.length === 0 && (
        <Box sx={{ pb: 3, m: 3 }}>
          <Paper>
            <Alert severity="info">Aucune donnée disponible!</Alert>
          </Paper>
        </Box>
      )}

      <Grid item>
        <Grid container direction="column" spacing={3}>
          {(Object.values(destinations) || []).map((distination, index) => {
            return (
              <Grid item key={index}>
                <Grid container spacing={1}>
                  <Grid item container md={3} xs={12}>
                    <DistinationCard distination={distination} />
                  </Grid>
                  <Grid item md={9} xs={12}>
                    {(distination.shipments || []).map((item, idx) => (
                      <Paper
                        key={idx}
                        sx={{
                          p: 3,
                          mb: 1,
                          "&:last-child": {
                            mb: 0,
                          },
                          // border:
                          //   item.attempts && item?.attempts?.length > 0
                          //     ? "solid 3px #FF6600"
                          //     : "none",
                        }}
                      >
                        <ShipmentCard
                          shipment={item}
                          failureReasons={failureReasons}
                        />
                      </Paper>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TripShipmentsPage;
