import httpClient from "../../httpClient";
import { ReceptionFormModel } from "../../models/forms/recptionFormModel";
import { FailureReasonModel } from "../../models/shared/failureReasonModel";
import { HttpResponseModel } from "../../models/shared/httpResponseModel";
import { PaginationModel } from "../../models/shared/paginationModel";
import { ReceptionResponseDataModel } from "../../models/shared/receptionResponseDataModel";
import { ShipmentModel } from "../../models/shared/ShipmentModel";
import { ShipmentStatusEnumModel } from "../../models/shared/shipmentStatusModel";

export interface CreateResponseModel extends HttpResponseModel {
  data: ReceptionFormModel;
}

export interface ReceptionResponseModel extends HttpResponseModel {
  data: ReceptionResponseDataModel;
}

export interface FetchShipmentsResponseModel extends HttpResponseModel {
  data: { data: ShipmentModel[] | []; current_page: number; total: number };
}

const reception = async (
  data: ReceptionFormModel
): Promise<ReceptionResponseModel> => {
  return httpClient.post("dispatching/reception", {
    ...data,
    shipments: data.shipments.map((item) => ({
      ...item,
      packages: item.packages.map((pack) => ({ ...pack, from_desktop: true })),
    })),
  });
};

const updateShipment = async (data: ShipmentModel): Promise<ShipmentModel> => {
  return httpClient.post("dispatching/shipments/update", {
    ...data,
  });
};

export interface ShipmentsFetchQueryModel {
  pagination: PaginationModel;
  search?: string;
  bar_code?: string;
  status?: ShipmentStatusEnumModel[] | [];
}

const fetch = async (
  params: ShipmentsFetchQueryModel
): Promise<FetchShipmentsResponseModel> => {
  const {
    pagination,
    search = undefined,
    bar_code = undefined,
    status = undefined,
  } = params;
  return httpClient.get("dispatching/shipments", {
    params: {
      ...pagination,
      ...(() => (status ? { status } : {}))(),
      ...(() => (bar_code ? { bar_code } : {}))(),
      ...(() => (search ? { bar_code: search } : {}))(),
    },
  });
};

const fetchVerify = async (
  params: ShipmentsFetchQueryModel
): Promise<FetchShipmentsResponseModel> => {
  const {
    pagination,
    search = undefined,
    bar_code = undefined,
    status = undefined,
  } = params;
  return httpClient.get("dispatching/shipments/verify", {
    params: {
      ...pagination,
      ...(() => (status ? { status } : {}))(),
      ...(() => (bar_code ? { bar_code } : {}))(),
    },
  });
};

const removeShipment = async (id: number): Promise<any> => {
  return httpClient.delete(`dispatching/reception/delete/${id}`);
};

const findOneShipment = async (id: string): Promise<ShipmentModel> => {
  return httpClient.get(`dispatching/shipments/${id}`).then(({ data }) => data);
};

const takeForDelivery = async (identifier: string): Promise<string[]> => {
  return httpClient.post(
    `dispatching/delivery/take-for-delivery?identifier=${identifier}`
  );
};

const postponeDelivery = async (params: {
  identifier: string;
  reason: number;
}): Promise<string[]> => {
  return httpClient.post(`dispatching/delivery/postpone-delivery`, {
    ...params,
  });
};

const shipmentDelivery = async (params: {
  identifier: string;
  delivery_code: string;
}): Promise<string[]> => {
  return httpClient.post(`dispatching/delivery`, {
    ...params,
  });
};

const tripShipments = async (): Promise<{ data: ShipmentModel[] }> => {
  return httpClient.get(`dispatching/delivery/trip`);
};

const readyDeliveryShipments = async (
  params: ShipmentsFetchQueryModel
): Promise<FetchShipmentsResponseModel> => {
  const { pagination } = params;
  return httpClient.get(`dispatching/delivery/ready`, {
    params: {
      ...pagination,
    },
  });
};

const fetchFailureReasons = async (): Promise<FailureReasonModel[]> => {
  return httpClient.get(`settings/failure-reasons`);
};

const doReturn = async (id: number): Promise<any> => {
  return httpClient.post(`dispatching/reception/return/${id}`);
};

export const dispatchingService = {
  fetchVerify,
  reception,
  fetch,
  findOneShipment,
  takeForDelivery,
  postponeDelivery,
  tripShipments,
  fetchFailureReasons,
  readyDeliveryShipments,
  shipmentDelivery,
  updateShipment,
  doReturn,
  removeShipment,
};
