import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { FC, useState } from "react";

interface SearchTextFieldProps {
  TextFieldProps?: TextFieldProps;
  onChange: (value: string) => void;
  collapsButton?: boolean;
  toggleCollaps?: () => void;
}
export const SearchTextField: FC<SearchTextFieldProps> = (props) => {
  const [enteredText, setEnteredText] = useState("");

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === "Enter") {
      props.onChange(enteredText);
    }
  };

  const clear = () => {
    setEnteredText("");
    props.onChange("");
  };

  const endAdornment =
    enteredText !== "" ? (
      <InputAdornment position="end">
        <IconButton
          size="small"
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={clear}
        >
          <ClearOutlinedIcon />
        </IconButton>
      </InputAdornment>
    ) : props?.collapsButton ? (
      <InputAdornment position="end">
        <IconButton
          size="small"
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={props.toggleCollaps}
        >
          <TuneIcon />
        </IconButton>
      </InputAdornment>
    ) : null;
  return (
    <TextField
      id="input-with-icon-textfield"
      onKeyDown={handleKeyPress}
      onChange={(e) => setEnteredText(e.target.value)}
      value={enteredText}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: endAdornment,
      }}
      variant="outlined"
      size="small"
      {...props.TextFieldProps}
    />
  );
};
