import { Stack, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { communMenuItems } from "../../application/data/menuItems";
import { MenuModel } from "../../application/models/shared/menuModel";

interface PageTitleProps {
  title: string;
}

export const PageTitle: FC<PageTitleProps> = ({ title }): JSX.Element => {
  const history = useHistory();
  const [currentMenu, setCurrentMenu] = useState<MenuModel | undefined>(
    undefined
  );

  useEffect(() => {
    const pathname = history?.location.pathname;
    const _currentMenu = findMenu(communMenuItems, "href", pathname);
    setCurrentMenu(_currentMenu);
  }, [history?.location.pathname]);

  function findMenu(
    entireObj: MenuModel[],
    keyToFind: string,
    valToFind: string
  ): MenuModel | undefined {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyToFind] === valToFind) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    return foundObj;
  }

  return (
    <Stack
      direction={"row"}
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h5" sx={{ fontWeight: 800 }}>
        {currentMenu?.label || title}
      </Typography>

      {/* {currentMenu !== undefined && (
        <img src={currentMenu.image} width="80px" height="80px" />
      )} */}
    </Stack>
  );
};
