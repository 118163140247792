import { InvoiceModel } from "./../../models/shared/invoiceModel";
import httpClient from "../../httpClient";
import { PaginationModel } from "../../models/shared/paginationModel";
import { HttpResponseModel } from "../../models/shared/httpResponseModel";
import { SettlementModel } from "../../models/shared/settlementModel";

export interface InvoiceGenerateParmsModel {
  customer: number;
  shipments: number[];
}

export interface FetchInvoicesResponseModel extends HttpResponseModel {
  data: InvoiceModel[] | [];
  current_page: number;
  total: number;
}

export interface InvoicesFetchQueryModel {
  pagination: PaginationModel;
  search?: string;
  bar_code?: string;
}

const fetch = async (
  params: InvoicesFetchQueryModel
): Promise<FetchInvoicesResponseModel> => {
  const { pagination, search = undefined } = params;
  return httpClient
    .get("invoices", {
      params: {
        ...pagination,
        ...(() => (search ? search : {}))(),
      },
    })
    .then(({ data }) => data);
};

const fetchPaymentMethods = async (): Promise<any> => {
  return httpClient.get("settings/payment_methods").then(({ data }) => data);
};

const settle = async (params: {
  invoice_id: number;
  settlements: SettlementModel[];
}): Promise<any> => {
  const { invoice_id, settlements } = params;

  return httpClient.post(`invoices/settle/${invoice_id}`, {
    settlements,
  });
};

const generate = async (params: InvoiceGenerateParmsModel): Promise<any> => {
  return httpClient.post("invoices/generate", {
    ...params,
  });
};

const findOne = async (id: number): Promise<InvoiceModel> => {
  return httpClient.get(`invoices/${id}`).then(({ data }) => data);
};

export const invoiceService = {
  fetch,
  generate,
  settle,
  fetchPaymentMethods,
  findOne,
};
