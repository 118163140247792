import { Chip, CircularProgress, Grid } from "@mui/material";
import { FC } from "react";
import If from "../../../../components/conditionalViwer";

export const ItemLabelComponent: FC<{
  loading: boolean;
  label: string;
  nbrChildren?: number;
}> = (props) => {
  const showSelectedNumber = Boolean(
    props?.nbrChildren && props?.nbrChildren > 0
  );
  return (
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item>{props.label}</Grid>

      <Grid item sx={{ paddingRight: 5 }}>
        <If
          condition={props.loading}
          render={() => <CircularProgress size={10} />}
          otherwise={() => (
            <If
              condition={showSelectedNumber}
              render={() => (
                <Chip
                  label={
                    <span>
                      <b>{props?.nbrChildren}</b> elements sectionnées
                    </span>
                  }
                  size="small"
                />
              )}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
