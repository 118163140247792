import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { priceFormat } from "../../application/helpers/priceFormat";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";
import { useDashboardController } from "./dashboardController";

export const ReimbursementOfficesDisptach: FC = (): JSX.Element => {
  const { ui } = useDashboardController();
  return (
    <Grid container direction="column" spacing={3}>
      {ui.dataStateOffices.map((item, index) => {
        return (
          <Grid item key={index}>
            <Grid container spacing={1}>
              <Grid item container md={3} xs={12}>
                <Paper
                  sx={{
                    width: "100%",
                    p: 3,
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      borderLeft: "solid 5px #eee",
                      p: 1,
                      position: "sticky",
                      top: 190,
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: 900 }}>
                      {item.office}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>

              <Grid item md={9} xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ width: "100%", minWidth: 650 }}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">Bureau</StyledTableCell>
                        <StyledTableCell align="left">Colis</StyledTableCell>
                        <StyledTableCell align="left">CRBT</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(item?.reimbursement_to_send || []).map((row, idx) => {
                        return (
                          <StyledTableRow key={idx} hover>
                            <StyledTableCell component="th" scope="row">
                              {row.office}
                            </StyledTableCell>
                            <StyledTableCell>{row.number}</StyledTableCell>

                            <StyledTableCell>
                              <Typography
                                variant="caption"
                                sx={
                                  row.amount > 0
                                    ? { fontWeight: 500, color: "red" }
                                    : {}
                                }
                              >
                                {priceFormat(row.amount)}{" "}
                              </Typography>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>{" "}
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};
