import { Button } from "@mui/material";
import { FC, useContext, useState } from "react";
import AssignmentReturnOutlinedIcon from "@mui/icons-material/AssignmentReturnOutlined";
import { ShipmentModel } from "../../../application/models/shared/ShipmentModel";
import { ConfirmDialog } from "../../../components/confirmDialog";
import { dispatchingService } from "../../../application/api/services/dispatching.service";
import { SnackbarContext } from "../../../components/snackbarProvider";

interface ReturnToSenderStateModel {
  open: boolean;
  isLoading: boolean;
}

export const ReturnToSender: FC<{ shipment: ShipmentModel }> = ({
  shipment,
}): JSX.Element => {
  const [snackbar, setSnackbar] = useContext(SnackbarContext);
  const [state, setState] = useState<ReturnToSenderStateModel>({
    open: false,
    isLoading: false,
  });

  const doReturn = () => {
    setState((state) => ({ ...state, isLoading: true }));
    dispatchingService
      .doReturn(shipment.id)
      .then((response) => {
        setState((state) => ({ ...state, isLoading: false, open: false }));
        setSnackbar({
          open: true,
          severity: "success",
          message: "Requete exécutée avec succés",
        });
      })
      .catch((error) => {
        setState((state) => ({ ...state, isLoading: false, open: false }));
        setSnackbar({
          open: true,
          severity: "error",
          message: "Une erreur s'est produite lors de l'opération",
        });
      });
  };

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        color="error"
        startIcon={<AssignmentReturnOutlinedIcon />}
        onClick={() => setState((state) => ({ ...state, open: true }))}
      >
        Retourner à l'expéditeur
      </Button>

      <ConfirmDialog
        title="Retourner"
        body={`Êtes-vous sûr de vouloir retourner ${shipment?.identifier}`}
        dialogProps={{
          open: state.open,
          onClose: () => {
            setState((state) => ({ ...state, open: false }));
          },
        }}
        cancelButton={{
          onClick: () => setState((state) => ({ ...state, open: false })),
        }}
        confirmButton={{
          onClick: () => doReturn(),
        }}
      />
    </>
  );
};
