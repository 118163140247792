import { Alert, Grid, LinearProgress } from "@mui/material";
import { get } from "lodash";
import { useFindOneCustomerQuery } from "../../application/api/hooks/customer.hook";
import { useAuth } from "../../application/contexts/authContex";
import { CustomerModel } from "../../application/models/shared/customerModel";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import { PageTitle } from "../../components/PageTitle";
import CustomerCard from "../../pages_components/customers/customerCard";
import CustomerShipments from "../../pages_components/customers/shipments";

const CustomerProfilePage: React.FC<PagePropsModel> = (props): JSX.Element => {
  const id = props?.match?.params?.id || undefined;

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Profile",
    },
  ];

  const oneCustomerQuery = useFindOneCustomerQuery(Number(id));
  const customerData = get(
    oneCustomerQuery,
    ["data"],
    undefined
  ) as CustomerModel;

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="Profile" />

        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      {oneCustomerQuery.isLoading && (
        <Grid item>
          <LinearProgress />
        </Grid>
      )}
      {!oneCustomerQuery.isLoading && oneCustomerQuery.isError && (
        <Grid item>
          <Alert severity="error"> Ce client n'existe pas</Alert>
        </Grid>
      )}
      {!oneCustomerQuery.isLoading &&
        !oneCustomerQuery.isError &&
        customerData !== undefined && (
          <Grid item>
            <Grid container spacing={3}>
              <Grid item md={3} xs={12}>
                <CustomerCard customer={customerData} />
              </Grid>
              <Grid item md={9} xs={12}>
                <CustomerShipments customerId={Number(id)} />
              </Grid>
            </Grid>
          </Grid>
        )}
    </Grid>
  );
};

export default CustomerProfilePage;
