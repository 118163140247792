import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { Avatar, Grid, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { CustomerModel } from "../../application/models/shared/customerModel";

const CustomerCard: FC<{ customer: CustomerModel }> = ({
  customer,
}): JSX.Element => {
  return (
    <Paper
      sx={{
        width: "100%",
        p: 3,
        height: "100%",
        position: "relative",
      }}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Box
            sx={{
              width: "100%",
              height: 150,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 4,
            }}
          >
            <Box
              sx={{
                width: 144,
                height: 144,
                margin: "auto",
                borderRadius: "50%",
                padding: 8,
                borderWidth: "1px",
                borderStyle: "dashed",
                borderColor: "rgba(145, 158, 171, 0.32)",
                borderImage: "initial",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Avatar
                sx={{ height: 100, width: 100 }}
                src={`${process.env.REACT_APP_PUBLIC_URL}assets/images/avatar.png`}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Stack spacing={2} direction="row" alignItems="flex-end">
            <PersonOutlineOutlinedIcon fontSize="small" />
            <Typography
              variant="body2"
              color={"textSecondary"}
              sx={{ fontSize: 14 }}
            >
              {customer.first_name} {customer.last_name}
            </Typography>
          </Stack>
        </Grid>
        <Grid item>
          <Stack spacing={2} direction="row" alignItems="flex-end">
            <LocalPhoneOutlinedIcon fontSize="small" />
            <Typography
              variant="body2"
              color={"textSecondary"}
              sx={{ fontSize: 14 }}
            >
              {customer.phone_number}
            </Typography>
          </Stack>
        </Grid>
        <Grid item>
          <Stack spacing={2} direction="row" alignItems="flex-end">
            <PlaceOutlinedIcon fontSize="small" />
            <Typography
              variant="body2"
              color={"textSecondary"}
              sx={{ fontSize: 14 }}
            >
              {customer.address?.address_line}
            </Typography>
          </Stack>
        </Grid>
        <Grid item>
          <Stack spacing={2} direction="row" alignItems="flex-end">
            <HomeWorkOutlinedIcon fontSize="small" />
            <Typography
              variant="body2"
              color={"textSecondary"}
              noWrap
              sx={{ fontSize: 14 }}
            >
              {customer?.address?.post_office?.name} /{" "}
              {customer?.address?.post_office?.zip_code}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CustomerCard;
