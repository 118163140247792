import { FC, useEffect, useState } from "react";
import { globalStateService } from "../../application/api/services/globalState.service";
import { FundModel } from "../../application/models/shared/fundModel";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { AppBreadcrumbsItem } from "../../components/Appbreadcrumbs/models";
import {
  Alert,
  Box,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { PageTitle } from "../../components/PageTitle";
import { AppBreadcrumbs } from "../../components/Appbreadcrumbs";
import { StyledTableCell } from "../../components/Styled/StyledTableCell";
import { StyledTableRow } from "../../components/Styled/StyledTableRow";
import { priceFormat } from "../../application/helpers/priceFormat";

interface FundsMineStateModel {
  isLoading: boolean;
  isError: boolean;
  data: FundModel[];
}

const FundsMinePage: FC<PagePropsModel> = (props): JSX.Element => {
  const [state, setState] = useState<FundsMineStateModel>({
    isLoading: true,
    isError: false,
    data: [],
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setState((state) => ({ ...state, isLoading: true, error: "" }));
    globalStateService
      .fetchFundsMine()
      .then((response) => {
        setState((state) => ({
          ...state,
          isLoading: false,
          isError: false,
          data: response.data.funds,
        }));
      })
      .catch((error) => {
        setState((state) => ({
          ...state,
          isLoading: false,
          isError: true,
        }));
      });
  };

  const breadcrumbs: AppBreadcrumbsItem[] = [
    {
      label: "Accueil",
      href: "/",
    },
    {
      label: "Mes envois",
    },
  ];

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <PageTitle title="Mes envois" />
        <Grid container justifyContent="space-between">
          <Grid item>
            {" "}
            <AppBreadcrumbs breadcrumbs={breadcrumbs} />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box pb={1}>
          {state.data.length > 0 && (
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography>
                  <b>{state.data.length}</b> Colis
                </Typography>
              </Grid>
              <Grid item></Grid>
            </Grid>
          )}
        </Box>{" "}
        {state.isError && state.isLoading === false && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="error">Error</Alert>
          </Box>
        )}
        {state.isLoading === false && state.data?.length === 0 && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="info">Aucune donnée disponible!</Alert>
          </Box>
        )}
        {state.isLoading && <LinearProgress />}
        {state.data.length > 0 && state.data?.length > 0 && !state.isError && (
          <TableContainer component={Paper}>
            <Table
              sx={{ width: "100%" }}
              // size="small"
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Date d'entrée</StyledTableCell>
                  <StyledTableCell align="left">Identifiant</StyledTableCell>
                  <StyledTableCell align="left">Poids</StyledTableCell>
                  <StyledTableCell align="right">Prix</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(state.data || []).map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {row.shipment.received_at}
                    </StyledTableCell>
                    <StyledTableCell component="th">
                      {row.shipment.identifier}
                    </StyledTableCell>
                    <StyledTableCell> {row.shipment.weight} KG</StyledTableCell>
                    <StyledTableCell align="right">
                      <b>{priceFormat(row.shipment.price)}</b>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>{" "}
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};

export default FundsMinePage;
