import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { format } from "date-fns";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { DATE_ISO_FORMAT } from "../../../../application/constants/appConstants";
import { usePickupController } from "../../../../pages/pickups/controller";

export interface PickupsFilterFormModel {
  date: string;
}

const PickupsFilterForm: FC = (props): JSX.Element => {
  const { fetching, filter } = usePickupController();

  const schema = yup.object().shape({
    date: yup.string().required(),
  });

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<PickupsFilterFormModel>({
    resolver: yupResolver(schema),
    defaultValues: filter.formValues || {
      date: format(new Date(), DATE_ISO_FORMAT),
    },
  });

  const onSubmit = (data: PickupsFilterFormModel) => {
    filter.setFormValues(data);
  };

  const formValues = watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="row" alignItems={"flex-end"} spacing={2}>
        <Grid item>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                fullWidth
                id="date"
                label="Date"
                type="date"
                {...field}
                value={field.value}
                // inputProps={{
                //   min: format(new Date(), DATE_ISO_FORMAT),
                // }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors?.date)}
                helperText={errors?.date ? errors?.date.message : null}
              />
            )}
          />
        </Grid>

        <Grid item>
          <Box sx={{ mt: 1, position: "relative" }}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={fetching.isLoading}
            >
              Envoyer
            </Button>
            {fetching.isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default PickupsFilterForm;
