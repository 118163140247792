import create from "zustand";
import {
  FetchInvoicesResponseModel,
  invoiceService,
  InvoicesFetchQueryModel,
} from "../../application/api/services/invoice.service";
import { InvoiceModel } from "../../application/models/shared/invoiceModel";
import { SettlementModel } from "../../application/models/shared/settlementModel";

interface InvoiceControllerModel {
  settings: {
    isLoading: boolean;
    error?: string;
    data: string[];
    fetchPaymentMethods: () => void;
  };
  fetching: {
    isLoading: boolean;
    error?: string;
    currentPage: number;
    total: number;
    data: InvoiceModel[];
    drawerProps: {
      open: boolean;
      item: InvoiceModel | undefined;
    };
    handleChangePage: (page: number) => void;
    handleCloseDrawer: () => void;
    openDrawer: (item: InvoiceModel) => void;
    fetch: () => void;
  };
  details: {
    isLoading: boolean;
    error?: string;
    data: InvoiceModel | undefined;
    findOne: (id: number) => void;
  };
  settlement: {
    isLoading: boolean;
    error?: string;
    settle: (params: {
      invoice_id: number;
      settlements: SettlementModel[];
    }) => void;
  };
  api: {
    findOne: (
      id: number,
      callbackThen: (response: InvoiceModel) => void,
      callbackCatch: (error: any) => void
    ) => void;
    fetch: (
      params: InvoicesFetchQueryModel,
      callbackThen: (response: FetchInvoicesResponseModel) => void,
      callbackCatch: (error: any) => void
    ) => void;
    fetchPaymentMethods: (
      callbackThen: (response: string[]) => void,
      callbackCatch: (error: any) => void
    ) => void;
    settle: (
      params: {
        invoice_id: number;
        settlements: SettlementModel[];
      },
      callbackThen: (response: any) => void,
      callbackCatch: (error: any) => void
    ) => void;
  };
}

export const useInvoiceController = create<InvoiceControllerModel>(
  (set, get) => ({
    settings: {
      isLoading: false,
      error: "",
      data: [],
      fetchPaymentMethods: () => {
        get().api.fetchPaymentMethods(
          (response) => {
            set((state) => ({
              ...state,
              settings: {
                ...state.settings,
                isLoading: false,
                error: "",
                data: response,
              },
            }));
          },
          (error) => {
            set((state) => ({
              ...state,
              settings: {
                ...state.settings,
                isLoading: false,
                error: error?.message ? String(error?.message) : String(error),
              },
            }));
          }
        );
      },
    },
    fetching: {
      isLoading: false,
      error: "",
      currentPage: 1,
      total: 0,
      data: [],
      drawerProps: {
        open: false,
        item: undefined,
      },
      handleChangePage: (page: number) => {
        set((state) => ({
          ...state,
          fetching: {
            ...state.fetching,
            currentPage: page,
          },
        }));
      },
      handleCloseDrawer: () => {
        set((state) => ({
          ...state,
          fetching: {
            ...state.fetching,
            drawerProps: {
              open: false,
              item: undefined,
            },
          },
        }));
      },
      openDrawer: (item: InvoiceModel) => {
        set((state) => ({
          ...state,
          fetching: {
            ...state.fetching,
            drawerProps: {
              ...state.fetching.drawerProps,
              open: true,
              item,
            },
          },
        }));
      },
      fetch: () => {
        const params = {
          pagination: { page: get().fetching.currentPage, per_page: 10 },
        };

        get().api.fetch(
          params,
          (response) => {
            set((state) => ({
              ...state,
              fetching: {
                ...state.fetching,
                isLoading: false,
                error: "",
                data: response.data,
                total: response.total,
                drawerProps: {
                  open: false,
                  item: undefined,
                },
              },
            }));
          },
          (error) => {
            set((state) => ({
              ...state,
              fetching: {
                ...state.fetching,
                isLoading: false,
                error: String(error),
              },
            }));
          }
        );
      },
    },
    details: {
      isLoading: true,
      error: "",
      data: undefined,
      findOne: (id: number) => {
        get().api.findOne(
          id,
          (response) => {
            set((state) => ({
              ...state,
              details: {
                ...state.details,
                data: response,
                isLoading: false,
                error: "",
              },
            }));
            get().fetching.fetch();
          },
          (error) => {
            set((state) => ({
              ...state,
              details: {
                ...state.details,
                isLoading: false,
                error: error?.response?.data?.message
                  ? error?.response?.data?.message
                  : String(error),
              },
            }));
          }
        );
      },
    },
    settlement: {
      isLoading: false,
      error: "",
      settle: (params: {
        invoice_id: number;
        settlements: SettlementModel[];
      }) => {
        get().api.settle(
          params,
          (response) => {
            set((state) => ({
              ...state,
              settlement: {
                ...state.settlement,
                isLoading: false,
                error: "",
              },
            }));
            get().fetching.fetch();
          },
          (error) => {
            set((state) => ({
              ...state,
              settlement: {
                ...state.settlement,
                isLoading: false,
                error: error?.response?.data?.message
                  ? error?.response?.data?.message
                  : String(error),
              },
            }));
          }
        );
      },
    },
    api: {
      findOne: (
        id: number,
        callbackThen: (response: InvoiceModel) => void,
        callbackCatch: (error: any) => void
      ) => {
        invoiceService.findOne(id).then(callbackThen).catch(callbackCatch);
      },
      fetch: (
        params: InvoicesFetchQueryModel,
        callbackThen: (response: FetchInvoicesResponseModel) => void,
        callbackCatch: (error: any) => void
      ) => {
        invoiceService.fetch(params).then(callbackThen).catch(callbackCatch);
      },
      fetchPaymentMethods: (
        callbackThen: (response: any) => void,
        callbackCatch: (error: any) => void
      ) => {
        invoiceService
          .fetchPaymentMethods()
          .then(callbackThen)
          .catch(callbackCatch);
      },
      settle: (
        params: {
          invoice_id: number;
          settlements: SettlementModel[];
        },
        callbackThen: (response: any) => void,
        callbackCatch: (error: any) => void
      ) => {
        invoiceService.settle(params).then(callbackThen).catch(callbackCatch);
      },
    },
  })
);
