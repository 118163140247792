import { useRef } from "react";
import { ShipmentModel } from "../../../application/models/shared/ShipmentModel";
import { useReactToPrint } from "react-to-print";
import { Button, Grid } from "@mui/material";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { ShipmentBarCodeCard } from "../barCodeContainer";

export const ShipmentBarcodePrint: React.FC<{ shipment: ShipmentModel }> = ({
  shipment,
}): JSX.Element => {
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Button startIcon={<PrintOutlinedIcon />} onClick={handlePrint}>
        Imprimer
      </Button>
      <div ref={componentRef}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            {" "}
            <ShipmentBarCodeCard shipment={shipment} />{" "}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
