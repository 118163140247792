import axios from "axios";
import { ACCESS_TOKEN } from "../constants/authConstants";
import { getStorageItem } from "../helpers/localStorage";

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API,
});

instance.interceptors.request.use(
  (request) => {
    const access_token = getStorageItem(ACCESS_TOKEN) || "";
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${access_token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      charset: "utf-8",
    };
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    const { data, status, statusText } = response;
    return { data, status, statusText };
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
