import create from "zustand";
import { globalStateService } from "../../application/api/services/globalState.service";
import { FundModel } from "../../application/models/shared/fundModel";
import { FundOfficeModel } from "../../application/models/shared/FundsOfficeModel";

export type CheckedItemModel = Record<number, number[] | []>;

export enum EnumCurrentAction {
  FETCH = "FETCH",
  SAVE = "SAVE",
}

export interface AlertStateModel {
  show: boolean;
  type: "success" | "error";
  message: string;
}

export interface FundsOfficeStateModel {
  isLoading: boolean;
  isError: boolean;
  currentAction: EnumCurrentAction | undefined;
  currentItemId: number | undefined;
  data: FundOfficeModel[];
  checkedItems: CheckedItemModel;
  setLoading: (isLoading: boolean, isError: boolean) => void;
  alert: AlertStateModel;
  fetch: () => void;
  control: (userId: number) => void;
  toggleCheckItem: (userId: number, item: number) => void;
  sumPriceReduce: (acc: number, cur: FundModel) => number;
  sumPrice: (items: FundModel[]) => number;
  sumPriceChecked: (items: FundModel[], userId: number) => number;
  getCheckedItem: (userId: number) => number[];
  isCheckedItem: (userId: number, shipmentId: number) => boolean;
  checkAll: (userId: number) => void;
  uncheckAll: (userId: number) => void;
  isCheckedAll: (userId: number) => boolean;
}

export const useFundsOfficeStore = create<FundsOfficeStateModel>(
  (set, get) => ({
    isLoading: false,
    isError: false,
    currentAction: EnumCurrentAction.FETCH,
    currentItemId: undefined,
    isLoadingSave: false,
    isErrorSave: false,
    data: [],
    checkedItems: {},
    setLoading: (isLoading: boolean, isError: boolean) => {
      set((state) => ({ ...state, isLoading, isError }));
    },
    alert: {
      show: false,
      type: "success",
      message: "",
    },
    fetch: async () => {
      set((state) => ({
        ...state,
        isLoading: true,
        isError: false,
        currentAction: EnumCurrentAction.FETCH,
        currentItemId: undefined,
        // alert: {
        //   show: false,
        //   type: "success",
        //   message: "",
        // },
      }));
      const response = await globalStateService.fetchFundsOffice();
      set((state) => ({
        ...state,
        data: (response.data || []).map((item) => ({
          ...item,
          funds: (item.funds || []).filter((fund) => fund.shipment !== null),
        })),
        isLoading: false,
        isError: false,
      }));
    },
    control: (userId: number) => {
      const _checkedItems = get().getCheckedItem(userId);
      if (_checkedItems.length > 0) {
        set((state) => ({
          ...state,
          isLoading: true,
          isError: false,
          currentAction: EnumCurrentAction.SAVE,
          currentItemId: userId,
        }));
        globalStateService
          .controlFunds(_checkedItems)
          .then((response) => {
            set((state) => ({
              ...state,
              isLoading: false,
              isError: false,
              currentItemId: undefined,
              alert: {
                show: true,
                type: "success",
                message: `Enregistrement effectué avec succès`,
              },
            }));
            get().fetch();
          })
          .catch((error) => {
            set((state) => ({
              ...state,
              isLoading: false,
              isError: true,
            }));
          });
      }
    },
    toggleCheckItem: (userId: number, item: number) => {
      const _items =
        get().checkedItems[userId] !== undefined
          ? get().checkedItems[userId]
          : [];

      const _currentItem = _items.includes(item)
        ? _items.filter((_item) => _item !== item)
        : [..._items, item];

      set((state) => ({
        ...state,
        checkedItems: {
          ...state.checkedItems,
          [userId]: _currentItem,
        },
      }));
    },

    sumPriceReduce: (acc: number, cur: FundModel): number => {
      const curCRBT = cur.shipment.price;
      return acc + curCRBT;
    },

    sumPrice: (items: FundModel[]): number => {
      return (items as FundModel[]).reduce(get().sumPriceReduce, 0);
    },

    getCheckedItem: (userId: number): number[] => {
      return get().checkedItems[userId] !== undefined
        ? get().checkedItems[userId]
        : [];
    },
    isCheckedItem: (userId: number, fundId: number): boolean => {
      const _checkedItems = get().getCheckedItem(userId);
      return _checkedItems.includes(fundId);
    },
    sumPriceChecked: (items: FundModel[], userId: number): number => {
      return (items as FundModel[])
        .filter((item) => get().isCheckedItem(userId, Number(item.id)))
        .reduce(get().sumPriceReduce, 0);
    },
    checkAll: (userId: number) => {
      const userFundsIds = get()
        .data.filter((item) => item.officer.id === userId)[0]
        .funds.map((fund) => fund.id);
      set((state) => ({
        ...state,
        checkedItems: {
          ...state.checkedItems,
          [userId]: userFundsIds,
        },
      }));
    },
    uncheckAll: (userId: number) => {
      set((state) => ({
        ...state,
        checkedItems: {
          ...state.checkedItems,
          [userId]: [],
        },
      }));
    },
    isCheckedAll: (userId: number): boolean => {
      return (
        (get().data || []).filter((item) => item.officer.id === userId)[0]
          ?.funds.length === get().getCheckedItem(userId).length
      );
    },
  })
);
