import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationStateModel } from "../models/reducers/notification/models";
import { CountTasksModel } from "./../models/reducers/notification/models";
import { RootState } from "./store";

const initialState: NotificationStateModel = {
  updatedAt: new Date().toLocaleDateString(),
  countTasks: {
    shipments_to_control: 0,
    office_bags_to_prepare: 0,
    innerBags: 0,
    outerBags: 0,
    bagsToSend: 0,
    bagsToReceive: 0,
    bagsToReceiveFromPlatform: 0,
    shipmentReadyToDeliver: 0,
    tripShipments: 0,
    incomeReimbursement: 0,
    outcomeReimbursement: 0,
  },
};

export const notificationSlice = createSlice({
  name: "notif",
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<CountTasksModel>) => {
      state.updatedAt = new Date().toLocaleDateString();
      state.countTasks = action.payload;
    },
  },
});

export const { setNotification } = notificationSlice.actions;
export const notificationState = (state: RootState): NotificationStateModel =>
  state.notif;

export default notificationSlice.reducer;
