import { FC, useEffect } from "react";
import { PagePropsModel } from "../../application/models/shared/pagePropsModel";
import { useInvoiceController } from "./controller";

const InvoiceDetailsPage: FC<PagePropsModel> = (props): JSX.Element => {
  const id = props?.match?.params?.id || undefined;
  const { details } = useInvoiceController();

  useEffect(() => {
    details.findOne(Number(id));
  }, [id]);

  return <></>;
};

export default InvoiceDetailsPage;
